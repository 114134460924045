<template>
  <div class="relative inline-block" :style="style">
    <template v-if="slots.default">
      <div>
        <slot v-bind="{ activator, saving }"/>

        <label v-if="canEdit" class="sr-only" :for="uId">Edit {{ name }}</label>
      </div>
    </template>
    <template v-else>
      <component
        :is="src ? 'img' : 'div'"
        :alt="alt"
        class=" bg-center absolute left-0 top-0 z-0 bg-cover inline-block align-middle overflow-hidden"
        :class="[{ 'border border-orange': canEdit && isEmpty, 'bg-gray-700': isEmpty || !proportional, 'rounded-full': !square, 'object-contain object-left bg-transparent': proportional }, classes.image]"
        :style="style"
        :src="url"
      />
      <div v-if="canEdit" class="relative z-10" :style="style">
        <div v-if="saving" class="loading-box loading" :class="{ 'rounded': !square }"/>

        <template v-else>
          <div v-if="isEmpty" class="absolute flex flex-col size-full items-center text-center justify-center pointer-events-none">
            <span class="font-semibold">Upload {{ name }}</span>
            <small class="px-2" v-text="description"/>
          </div>

          <label class="sr-only" :for="uId">Edit {{ name }}</label>

          <input
            :id="uId"
            ref="input"
            class="hidden"
            type="file"
            @change="updateImage"
          >

          <div
            v-if="canEdit"
            class="
        bg-black/75 size-full flex items-center justify-around overflow-hidden opacity-0
        hover:opacity-100 focus-within:opacity-100
      "
            :class="{ 'rounded-full': !square }"
          >
            <g-button
              :class="[
                'size-full text-gray-400 hover:text-gray-400 hover:bg-silver/5',
                'focus-visible:bg-silver/5 focus-visible:ring-2',
                'ring-inset', { [canDelete && !isGravatar ? 'rounded-l-full' : 'rounded-full']: !square }
              ]"
              :title="`Edit ${name}`"
              variant="link"
              @click="activator"
            >
              <icon v-if="!isEmpty" name="edit"/>
            </g-button>

            <g-button
              v-if="canDelete && !isGravatar"
              class="
          size-full text-gray-400 hover:text-gray-400 hover:bg-silver/5 focus-visible:bg-silver/5
          focus-visible:ring-2 ring-inset
          rounded-r-full
        "
              :title="`Delete ${name}`"
              variant="link"
              @click="deleteImage"
            >
              <icon name="delete"/>
            </g-button>
          </div>
        </template>
      </div>
    </template>

    <input
      v-if="canEdit"
      :id="uId"
      ref="input"
      class="hidden"
      type="file"
      @change="updateImage"
    >
  </div>
</template>

<script setup lang="ts">
import GButton from "@/components/buttons/GButton.vue"
import { computed, ref, useSlots, watch } from "vue"
import { uniqueId } from "lodash-es"
import { useNotify } from "@/composables/useNotify"
import type { ClassesProp } from "@/models/ClassesProp"

const props = withDefaults(defineProps<{
  id?: string
  name?: string
  description?: string
  src?: string
  canEdit?: boolean
  canDelete?: boolean
  isIdenticon?: boolean
  proportional?: boolean
  square?: boolean
  width?: number
  classes?: ClassesProp
  alt?: string
}>(), {
  name: "avatar",
  description: "High-res profile photo of just your face",
  width: 6,
  classes: () => ({} as ClassesProp),
})
const emit = defineEmits(["delete", "update"])
const slots = useSlots()

const { notify } = useNotify()

const clint = "https://res.cloudinary.com/gun/image/upload/v1592242547/41lbVJFc8aL._AC__n1za2m.jpg"
const saving = ref(false)
const input = ref(null)

const uId = computed(() => {
  return props.id ?? `el-${uniqueId()}`
})

const isGravatar = computed(() => {
  return !props.src?.includes("profile_photos")
})

const isEmpty = computed(() => {
  return props.isIdenticon || !props.src
})

const url = computed(() => {
  if (props.canEdit && isEmpty.value) {
    return null
  }

  return props.src || clint
})

const style = computed(() => {
  if (slots.default) {
    return ""
  }

  if (url.value == null) {
    return `
        width: ${props.width}rem;
        height: ${props.width}rem;
      `
  }

  if (props.proportional) {
    return `
        width: 100%;
        height: 100%;
      `
  }

  return `
      width: ${props.width}rem;
      height: ${props.width}rem;
    `
})

const activator = () => {
  if (saving.value || !props.canEdit) {
    return
  }

  input.value.click()
}

const deleteImage = () => {
  saving.value = true
  emit("delete")
}

const validateImage = (file) => {
  const isValid = !!file.type.match(/^image\/.*/)

  if (!isValid) {
    notify("Invalid image format", { type: "error" })
  }

  return isValid
}

const updateImage = () => {
  const file = input.value.files[0]

  if (file && validateImage(file)) {
    saving.value = true
    emit("update", { [props.name]: file })
  }
}

watch(() => props.src, () => {
  saving.value = false

  if (input.value) {
    input.value.value = ""
  }
})
</script>
