<template>
  <router-link
    v-if="to"
    :class="classes"
    :data-test="to.name"
    :exact="exact"
    :to="to"
  >
    <slot/>
  </router-link>

  <a
    v-else-if="href"
    :class="classes"
    :href="href"
    target="_blank"
  >
    <slot/>
  </a>

  <g-button
    v-else
    :class="classes"
    variant="link"
  >
    <slot/>
  </g-button>
</template>

<script setup lang="ts">
import GButton from "@/components/buttons/GButton.vue"
import { computed } from "vue"

const props = defineProps<{
  href?: string
  to?: any
}>()

const classes = `
    bg-base border-black flex h-12 px-4 text-left text-white items-center ring-inset select-none
    hover:bg-green hover:text-black hover:no-underline
    focus-visible:bg-green focus-visible:text-black
  `

const exact = computed(() => {
  return ["company-profile", "freelancer-profile"].includes(props.to?.name)
})

</script>

<style lang="scss" scoped>
  .router-link-active {
    @apply bg-green text-black;
  }
</style>
