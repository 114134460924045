import { getFeatureFlag } from "@/plugins/launchdarkly"
import type { RouteLocationNormalized } from "vue-router"

const dtRouteNames = [
  "company-discover-talent",
  "company-missions",
  "company-settings",
  "mission-details",
  "interview-settings",
]

const accountRouteNames = [
  "complete-sign-up",
  "email-confirmation",
  "join-us",
  "login",
  "password-reset",
  "sign-up",
]

const otherRouteNames = [
  "company-hire-reviews",
  "freelancer-public-page",
]

const vipUsers = [
  "f69dd242-255f-4204-b341-dd8165fee172",
  "12ae563f-0b4a-4422-ab8c-ec2ee0f51eb5",
  "bea34b06-5613-4607-8f8b-66fc0ade17b1",
  "86316fed-cf0c-4dc7-9c0f-950ccec86043",
]

export function redirectUser(to: RouteLocationNormalized, userStore: any) {
  if (userStore.isFreelancer || userStore.isStaff || vipUsers.includes(userStore?.profile?.id)) {
    return ""
  }

  const hasDtAccess = getFeatureFlag("rollout-talent-search", false)

  if (hasDtAccess) {
    if (
      !accountRouteNames.includes(to.name as string) &&
      !dtRouteNames.includes(to.name as string) &&
      !otherRouteNames.includes(to.name as string)
    ) {
      return "company-discover-talent"
    }
  } else {
    if (dtRouteNames.includes(to.name as string)) {
      // If they are not trapped and have no access to dt make sure they can't access dt urls
      return "company-jobs"
    }
  }

  return ""
}
