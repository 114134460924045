<template>
  <teleport to="body">
    <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:p-6 z-[2147483003]">
      <div class="flex w-full flex-col space-y-4 items-center">
        <transition-group
          enter-active-class="transform ease-out duration-500 transition"
          enter-class="translate-y-2 opacity-0 sm:translate-y-0"
          enter-to-class="translate-y-0 opacity-100"
          leave-active-class="transition ease-in duration-100"
          leave-class="opacity-100"
          leave-to-class="opacity-0"
          class="flex w-full flex-col space-y-4 items-center"
          tag="div"
        >
          <div
            v-for="notification in visibleNotifications"
            :key="notification.id"
            :class="{
              'w-full max-w-sm border pointer-events-auto': true,
              'bg-blue-800 border-blue-400/50': notification.options.type !== 'error',
              'bg-red-800 border-red-400/50': notification.options.type === 'error',
            }"
            :data-test="`notification-${notification.options.type}`"
          >
            <div class="p-4 py-2">
              <div class="flex justify-start items-baseline">
                <div class="shrink-0">
                  <icon v-if="notification.options.type === 'success'" name="success" class="size-5 text-blue-300" aria-hidden="true"/>
                  <icon v-if="notification.options.type === 'error'" name="error" class="size-5 text-red-400" aria-hidden="true"/>
                </div>
                <div class="ml-3 flex-1 pt-0.5">
                  <p class="text-sm font-semibold text-white">
                    {{ notification.message }}
                  </p>
                  <p v-if="notification.options.secondaryMessage" class="mt-1 text-sm text-gray-200">
                    {{ notification.options.secondaryMessage }}
                  </p>
                </div>
                <div class="ml-4 flex shrink-0">
                  <g-button
                    variant="link"
                    type="button"
                    class="inline-flex items-end text-gray-400 hover:no-underline"
                    :class="{
                      'hover:text-blue-300': notification.options.type === 'success',
                      'hover:text-white': notification.options.type === 'error',
                    }"
                    data-test="dismiss"
                    @click="notification.show = false"
                  >
                    <span class="sr-only">Close</span>
                    <icon class="size-4" name="close" aria-hidden="true"/>
                  </g-button>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
  </teleport>
</template>

<script setup lang="ts">
import GButton from "@/components/buttons/GButton.vue"
import Icon from "@/components/Icon.vue"
import { computed } from "vue"
import { useNotify } from "@/composables/useNotify"

const { notifications } = useNotify()

const visibleNotifications = computed(() => {
  return notifications.value.filter(x => x.show)
})
</script>
