/* eslint-disable no-multi-spaces */

/*
Important! We serve the vue app from python / django!
This means that you need to consider the `urls.py` file!
Depending on types of changes made to front end routes, you may need to:
1. Add a URL to `urls.py`
2. Do a search of the python codebase for URI partials:
  - We have some python methods like `get_public_url` that return a f/e route!
  - We send emails with links into frontend routes as well!
*/

// eslint-disable-next-line import/default
import ApplicationLayout from "@/layouts/ApplicationLayout.vue"
// eslint-disable-next-line import/default
import PublicLayout from "@/layouts/PublicLayout.vue"

const CompleteSignup                        = () => import("@/pages/auth/CompleteSignup.vue")
const Signup                                = () => import("@/pages/auth/Signup.vue")
const Login                                 = () => import("@/pages/auth/Login.vue")
const JoinUs                                = () => import("@/pages/auth/JoinUs.vue")

const PasswordReset                         = () => import("@/pages/auth/PasswordReset.vue")
const EmailConfirmation                     = () => import("@/pages/auth/EmailConfirmation.vue")
const LandingRedirect                       = () => import("@/pages/LandingRedirect.vue")

const ApplicationList                       = () => import("@/pages/freelancers/ApplicationList.vue")
const ApplicationCreate                     = () => import("@/pages/freelancers/ApplicationCreate.vue")
const ApplicationSubmitted                  = () => import("@/pages/freelancers/ApplicationSubmitted.vue")
const InterviewDetail                       = () => import("@/pages/interviews/InterviewDetail.vue")
const FreelancerInterviews                  = () => import("@/pages/freelancers/FreelancerInterviews.vue")
const FreelancerAvailability                = () => import("@/pages/freelancers/FreelancerAvailability.vue")

const CompanyAppointments                   = () => import("@/pages/companies/CompanyAppointments.vue")
const CompanyJobs                           = () => import("@/pages/companies/CompanyJobs.vue")
const CompanyProfile                        = () => import("@/pages/companies/CompanyProfile.vue")
const CompanyMembers                        = () => import("@/pages/companies/CompanyMembers.vue")
const CompanyMerge                          = () => import("@/pages/companies/CompanyMerge.vue")
const CompanyHireReviews                    = () => import("@/pages/companies/CompanyHireReviews.vue")

const GenericJobsList                        = () => import("@/pages/jobs/GenericJobsList.vue")
const GenericJobPage                         = () => import("@/pages/jobs/GenericJobPage.vue")
const Subscriptions                         = () => import("@/pages/Subscriptions.vue")

const StaffInterviews                       = () => import("@/pages/staff/StaffInterviews.vue")
const StaffCompanySearch                    = () => import("@/pages/staff/StaffCompanySearch.vue")
const StaffHires                            = () => import("@/pages/staff/StaffHires.vue")
const StaffJobSearch                        = () => import("@/pages/staff/StaffJobSearch.vue")
const StaffTalentSearch                     = () => import("@/pages/staff/StaffTalentSearch.vue")
const StaffMarketingAttributionSearch       = () => import("@/pages/staff/StaffMarketingAttributionSearch.vue")
const StaffCreateJob                        = () => import("@/pages/staff/StaffCreateJob.vue")
const StaffScopeOfWork                      = () => import("@/pages/staff/StaffScopeOfWork.vue")

const WorkStyleDetail                       = () => import("@/pages/workstyle/WorkStyleDetail.vue")
const WorkStyleResult                       = () => import("@/pages/workstyle/WorkStyleResult.vue")
const WorkStyleAssessment                   = () => import("@/pages/workstyle/WorkStyleAssessment.vue")
const WorkStyleAssessmentDetail             = () => import("@/pages/workstyle/WorkStyleAssessmentDetail.vue")

const AvailabilityWall                      = () => import("@/pages/freelancers/AvailabilityWall.vue")
const FreelancerNotApproved                 = () => import("@/pages/freelancers/FreelancerNotApproved.vue")
const FreelancerSorryWrongFit               = () => import("@/pages/freelancers/FreelancerSorryWrongFit.vue")
const FreelancerPendingScreeningCompletion  = () => import("@/pages/freelancers/FreelancerPendingScreeningCompletion.vue")

const PageNotFound                          = () => import("@/pages/PageNotFound.vue")
const Styleguide                            = () => import("@/pages/Styleguide.vue")

const SettingsLayout                        = () => import("@/pages/settings/SettingsLayout.vue")
const SettingsSubscriptions                 = () => import("@/pages/settings/SettingsSubscriptions.vue")
const SettingsBasicInfo                     = () => import("@/pages/settings/SettingsBasicInfo.vue")
const SettingsCompanyMembers                = () => import("@/pages/settings/SettingsCompanyMembers.vue")
const SettingsContractAndLegal              = () => import("@/pages/settings/SettingsContractAndLegal.vue")
const SettingsDeleteAccount                 = () => import("@/pages/settings/SettingsDeleteAccount.vue")
const SettingsUpdatePassword                = () => import("@/pages/settings/SettingsUpdatePassword.vue")
const SettingsUpdateEmail                   = () => import("@/pages/settings/SettingsUpdateEmail.vue")

const FreelancerResume                      = () => import("@/pages/freelancers/ResumeFreelancer.vue")
const ResumeLanding                         = () => import("@/pages/freelancers/ResumeLanding.vue")
const FreelancerPublicPage                  = () => import("@/pages/freelancers/FreelancerPublicPage.vue")
const FreelancerApprovalCall                = () => import("@/pages/freelancers/FreelancerApprovalCall.vue")
const Hunter                                = () => import("@/pages/jobs/Hunter.vue")

const CompanyBalrog                         = () => import("@/pages/companies/CompanyBalrog.vue")

const ScopeOfWork                           = () => import("@/pages/hires/ScopeOfWork.vue")

const HireOnboardDoc                        = () => import("@/pages/companies/HireOnboardDoc.vue")
const StaffChores                           = () => import("@/pages/staff/StaffChores.vue")
const CompanyTalentRequestForm              = () => import("@/pages/companies/CompanyTalentRequestForm.vue")
const CompanyTalentRequestBonusMaterials    = () => import("@/pages/companies/CompanyTalentRequestBonusMaterials.vue")

const DealsDashboard                        = () => import("@/pages/deals/DealsDashboard.vue")
const TalentReview                          = () => import("@/pages/deals/TalentReview.vue")
// const TalentReviewApplications              = () => import("@/components/devrel/TalentReviewApplications.vue")
// const TalentReviewInterviews                = () => import("@/components/devrel/TalentReviewInterviews.vue")
// const TalentReviewPresentations             = () => import("@/components/devrel/TalentReviewPresentations.vue")
// const TalentReviewPreviews                  = () => import("@/components/devrel/TalentReviewPreviews.vue")

export default [
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // PUBLIC ROUTES
  { path: "/", component: Login, pathToRegexpOptions: { strict: true }, name: "login", meta: { public: true } },
  { path: "/sign-up/", component: Signup, pathToRegexpOptions: { strict: true }, name: "sign-up", meta: { public: true } },
  { path: "/complete-sign-up/", component: CompleteSignup, pathToRegexpOptions: { strict: true }, name: "complete-sign-up", meta: { public: true } },
  {
    path: "/account/",
    pathToRegexpOptions: { strict: true },
    component: PublicLayout,
    children: [
      {
        path: "subscriptions/:hash(\\w{64})/",
        pathToRegexpOptions: { strict: true },
        component: Subscriptions,
        name: "subscriptions",
        props: true,
      },
    ],
    meta: { public: true },
  },
  {
    path: "/password-reset/",
    pathToRegexpOptions: { strict: true },
    component: PasswordReset,
    name: "password-reset",
    meta: { public: true },
  },
  {
    path: "/email-confirmation",
    pathToRegexpOptions: { strict: true },
    component: PublicLayout,
    children: [
      {
        path: "",
        pathToRegexpOptions: { strict: true },
        component: EmailConfirmation,
        name: "email-confirmation",
      },

    ],
    meta: { public: true },
  },
  {
    path: "/share/:id/",
    strict: true,
    name: "freelancer-public-page",
    component: FreelancerPublicPage,
    meta: { public: true },
  },
  {
    path: "/workstyle",
    pathToRegexpOptions: { strict: true },
    component: PublicLayout,
    children: [
      {
        path: "",
        pathToRegexpOptions: { strict: true },
        component: WorkStyleAssessment,
        name: "work-style-assessment",
        meta: { public: true },
        props: { public: true },
      },
      {
        path: "results/:id/",
        pathToRegexpOptions: { strict: true },
        component: WorkStyleResult,
        name: "public-work-style-result",
        meta: { public: true },
        props: route => ({ ...route.params, ...route.meta }),
      },
      {
        path: "quiz/:id/",
        pathToRegexpOptions: { strict: true },
        component: WorkStyleAssessmentDetail,
        name: "work-style-assessment-detail",
        props: { public: true },
        meta: { public: true },
      },
      {
        path: ":slug/",
        pathToRegexpOptions: { strict: true },
        component: WorkStyleDetail,
        name: "public-work-style",
        meta: { public: true },
        props: route => ({ ...route.params, ...route.meta }),
      },
    ],
  },

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // LOGGED-IN ROUTES
  {
    path: "/app/",
    pathToRegexpOptions: { strict: true },
    component: ApplicationLayout,
    children: [

      // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      // COMPANY ROUTES
      {
        path: "company/",
        pathToRegexpOptions: { strict: true },
        component: CompanyProfile,
        name: "company-profile",
        meta: { company: true },
      },
      {
        path: "company/declined-fit/",
        pathToRegexpOptions: { strict: true },
        component: CompanyBalrog,
        name: "company-balrog",
        meta: { company: true },
      },
      {
        path: "company/interviews/",
        pathToRegexpOptions: { strict: true },
        component: CompanyAppointments,
        name: "company-appointment-list",
      },
      {
        path: "company/members/",
        pathToRegexpOptions: { strict: true },
        redirect: { name: "settings-company-members" },
      },
      {
        path: "company/:companyId/members/",
        pathToRegexpOptions: { strict: true },
        component: CompanyMembers,
        name: "company-members",
      },
      {
        path: "company/:id/",
        pathToRegexpOptions: { strict: true },
        component: CompanyProfile,
        name: "specific-company-profile", // LOL 😆
      },
      {
        path: "company/:companyId/jobs/",
        pathToRegexpOptions: { strict: true },
        component: CompanyJobs,
        name: "company-jobs",
        props: true,
      },
      {
        path: "company/:companyId/talent-request/:jobId/",
        strict: true,
        component: CompanyTalentRequestForm,
        name: "company-talent-request-form",
        props: true,
      },
      {
        path: "company/:companyId/talent-request/:jobId/bonus-materials/",
        strict: true,
        component: CompanyTalentRequestBonusMaterials,
        name: "company-talent-request-bonus-materials",
        props: true,
      },
      {
        path: "company/:companyId/reviews/",
        pathToRegexpOptions: { strict: true },
        component: CompanyHireReviews,
        name: "company-hire-reviews",
        props: true,
      },
      {
        path: "company/:companyId/merge/",
        pathToRegexpOptions: { strict: true },
        component: CompanyMerge,
        name: "company-merge",
      },
      {
        path: "company/:companyId/hires/:hireId/scope-of-work/",
        pathToRegexpOptions: { strict: true },
        component: ScopeOfWork,
        name: "scope-of-work",
      },

      // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      // FREELANCER ROUTES
      {
        path: "apply/success/:id/",
        pathToRegexpOptions: { strict: true },
        component: ApplicationSubmitted,
        name: "application-submitted",
        meta: { freelancer: true },
      },
      {
        path: "apply/:id/",
        pathToRegexpOptions: { strict: true },
        component: ApplicationCreate,
        name: "apply",
        meta: { freelancer: true },
      },
      {
        path: "not-approved/",
        pathToRegexpOptions: { strict: true },
        component: FreelancerNotApproved,
        name: "freelancer-not-approved",
        meta: { freelancer: true },
      },
      {
        path: "offer-not-extended/",
        pathToRegexpOptions: { strict: true },
        component: FreelancerSorryWrongFit,
        name: "freelancer-offer-not-extended",
        meta: { freelancer: true },
      },
      {
        path: "pending-offer-review/",
        pathToRegexpOptions: { strict: true },
        component: FreelancerPendingScreeningCompletion,
        name: "freelancer-pending-offer-review",
        meta: { freelancer: true },
      },
      {
        path: "current-availability/",
        pathToRegexpOptions: { strict: true },
        component: AvailabilityWall,
        name: "availability-wall",
        meta: { freelancer: true },
      },
      {
        path: "freelancer/",
        pathToRegexpOptions: { strict: true },
        component: FreelancerResume,
        name: "freelancer-profile",
        meta: { freelancer: true },
      },
      {
        path: "jobs/",
        pathToRegexpOptions: { strict: true },
        component: GenericJobsList,
        name: "auth-jobs-page",
        meta: {
          freelancer: true,
        },
        props: {
          authedView: true,
        },
      },
      {
        path: "freelancer/workstyle/",
        pathToRegexpOptions: { strict: true },
        component: WorkStyleAssessment,
        name: "freelancer-work-style-assessment",
        meta: { freelancer: true },
      },
      {
        path: "freelancer/workstyle/quiz/",
        pathToRegexpOptions: { strict: true },
        component: WorkStyleAssessmentDetail,
        name: "freelancer-work-style-assessment-detail",
        meta: { freelancer: true },
      },
      {
        path: "freelancer/workstyle/:slug/",
        pathToRegexpOptions: { strict: true },
        component: WorkStyleDetail,
        name: "freelancer-work-style",
        meta: { freelancer: true },
        props: true,
      },
      {
        path: "freelancer/applications/",
        pathToRegexpOptions: { strict: true },
        component: ApplicationList,
        name: "freelancer-applications",
        meta: { freelancer: true },
      },
      {
        path: "freelancer/interviews/",
        pathToRegexpOptions: { strict: true },
        component: FreelancerInterviews,
        name: "freelancer-interview-list",
        meta: { freelancer: true },
      },

      // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      // ROUTES FOR ALL USERS
      {
        path: "",
        pathToRegexpOptions: { strict: true },
        component: LandingRedirect,
        name: "redirect",
      },
      { // Note this freelancer route must come after the others, or it will hijack /:id/
        path: "freelancer/:id/",
        pathToRegexpOptions: { strict: true },
        component: ResumeLanding,
        props: true,
        name: "freelancer-resume-landing",
      },
      { // Temporarily leaving this route, but pushing it to the Landing page.  Hopefully after a few responses back from people I can remove it.
        path: "freelancer/:id/staff/",
        pathToRegexpOptions: { strict: true },
        component: ResumeLanding,
        props: true,
        name: "freelancer-resume-staff",
      },
      { // Note this freelancer route must come after the others, or it will hijack /:id/
        path: "freelancer/:id/staff/talent-approval-call/",
        pathToRegexpOptions: { strict: true },
        component: FreelancerApprovalCall,
        props: true,
        name: "freelancer-talent-approval-call",
      },
      {
        path: "availability/",
        pathToRegexpOptions: { strict: true },
        component: FreelancerAvailability,
        name: "availability-page",
      },
      {
        path: "interview/:id/",
        pathToRegexpOptions: { strict: true },
        component: InterviewDetail,
        props: true,
        name: "interview-page",
      },
      {
        path: "jobs/:id/",
        pathToRegexpOptions: { strict: true },
        component: GenericJobPage,
        name: "auth-job-detail-page",
        props: {
          authedView: true,
        },
      },
      {
        path: "invites/:inviteId/",
        pathToRegexpOptions: { strict: true },
        component: () => import("@/pages/freelancers/InviteDetails.vue"),
        name: "auth-invite-detail-page",
        props: true,
      },
      {
        path: "settings/",
        pathToRegexpOptions: { strict: true },
        component: SettingsLayout,
        name: "settings-layout",
        children: [
          {
            path: "basic-info/",
            pathToRegexpOptions: { strict: true },
            component: SettingsBasicInfo,
            name: "settings-page",
          },
          {
            path: "company-members/",
            pathToRegexpOptions: { strict: true },
            component: SettingsCompanyMembers,
            name: "settings-company-members",
          },
          {
            path: "contract-and-legal/",
            pathToRegexpOptions: { strict: true },
            component: SettingsContractAndLegal,
            name: "settings-contract-and-legal",
          },
          {
            path: "delete-account/",
            pathToRegexpOptions: { strict: true },
            component: SettingsDeleteAccount,
            name: "settings-delete-account",
          },
          {
            path: "update-password/",
            pathToRegexpOptions: { strict: true },
            component: SettingsUpdatePassword,
            name: "settings-update-password",
          },
          {
            path: "update-email/",
            pathToRegexpOptions: { strict: true },
            component: SettingsUpdateEmail,
            name: "settings-update-email",
          },
          {
            path: "subscriptions/:action?/:param?/",
            pathToRegexpOptions: { strict: true },
            component: SettingsSubscriptions,
            name: "settings-subscriptions",
            props: true,
            meta: { freelancer: true },
          },
        ],
      },

      // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
      // STAFF ONLY ROUTES
      {
        path: "staff/companies/",
        pathToRegexpOptions: { strict: true },
        component: StaffCompanySearch,
        name: "staff-company-search",
        meta: {
          staff: true,
        },
      },
      {
        path: "join-us/",
        pathToRegexpOptions: { strict: true },
        component: JoinUs,
        name: "join-us",
      },
      {
        path: "staff/hires/",
        pathToRegexpOptions: { strict: true },
        component: StaffHires,
        name: "staff-hires",
        meta: {
          staff: true,
        },
      },
      {
        path: "staff/jobs/",
        pathToRegexpOptions: { strict: true },
        component: StaffJobSearch,
        name: "staff-jobs",
        meta: {
          staff: true,
        },
      },
      {
        path: "staff/interviews/",
        pathToRegexpOptions: { strict: true },
        component: StaffInterviews,
        name: "staff-interviews-list",
        meta: {
          staff: true,
        },
      },
      {
        path: "staff/create-job/:companyId?/:jobId?/",
        pathToRegexpOptions: { strict: true },
        component: StaffCreateJob,
        name: "staff-create-job",
        props: true,
        meta: {
          staff: true,
        },
      },
      {
        path: "staff/tasks/",
        pathToRegexpOptions: { strict: true },
        component: StaffChores,
        name: "staff-chores",
        meta: {
          staff: true,
        },
      },
      {
        path: "staff/talent-search/",
        pathToRegexpOptions: { strict: true },
        component: StaffTalentSearch,
        name: "staff-freelancer-search",
        meta: {
          staff: true,
        },
      },
      {
        path: "staff/:layout/hunter/:jobId?/",
        pathToRegexpOptions: { strict: true },
        component: Hunter,
        name: "staff-hunter",
        props: true,
        meta: { staff: true },
      },
      {
        path: "staff/sales/dashboard/",
        pathToRegexpOptions: { strict: true },
        component: DealsDashboard,
        name: "sales-dashboard",
        meta: {
          staff: true,
        },
      },
      {
        path: "staff/deals/:id/talent-review/",
        pathToRegexpOptions: { strict: true },
        component: TalentReview,
        name: "talent-review",
        props: true,
        meta: {
          staff: true,
        },
        // children: [
        //   { path: "applications/", name: "talent-review-applications", pathToRegexpOptions: { strict: true }, component: TalentReviewApplications, meta: { staff: true } },
        //   { path: "interviews/", name: "talent-review-interviews", pathToRegexpOptions: { strict: true }, component: TalentReviewInterviews, meta: { staff: true } },
        //   { path: "presentations/", name: "talent-review-presentations", pathToRegexpOptions: { strict: true }, component: TalentReviewPresentations, meta: { staff: true } },
        //   { path: "previews/", name: "talent-review-previews", pathToRegexpOptions: { strict: true }, component: TalentReviewPreviews, meta: { staff: true } },
        // ],
      },
      {
        path: "staff/hires/onboard-doc/:id/",
        pathToRegexpOptions: { strict: true },
        component: HireOnboardDoc,
        name: "staff-company-onboard",
        props: true,
        meta: { staff: true },
      },
      {
        path: "staff/hires/:id/scope-of-work/",
        pathToRegexpOptions: { strict: true },
        component: StaffScopeOfWork,
        name: "staff-scope-of-work",
        props: true,
        meta: { staff: true },
      },
      {
        path: "staff/hires/:id/scope-of-work/:scopeOfWorkId/",
        pathToRegexpOptions: { strict: true },
        component: ScopeOfWork,
        name: "staff-scope-of-work-detail",
      },
      {
        path: "styleguide/",
        pathToRegexpOptions: { strict: true },
        component: Styleguide,
        name: "styleguide",
      },
      {
        path: "staff/marketing-attribution/",
        pathToRegexpOptions: { strict: true },
        component: StaffMarketingAttributionSearch,
        name: "marketing-attribution-search",
        meta: {
          staff: true,
        },
      },
    ],
  },
  {
    component: () => import("@/layouts/TalentSearchLayout.vue"),
    meta: {
      company: true,
    },
    path: "/app/company/:companyId/settings/",
    props: true,
    strict: true,
    children: [
      {
        component: () => import("@/pages/companies/CompanySettings.vue"),
        meta: {
          company: true,
        },
        name: "company-settings",
        path: "",
        props: true,
        strict: true,
      },
      {
        path: "interview-settings/",
        pathToRegexpOptions: { strict: true },
        component: () => import("@/pages/discoverTalent/InterviewAvailability.vue"),
        meta: {
          company: true,
        },
        name: "interview-settings",
        props: true,
        strict: true,
      },
    ],
  },
  {
    component: () => import("@/layouts/TalentSearchLayout.vue"),
    pathToRegexpOptions: { strict: true },
    meta: {
      company: true,
    },
    path: "/app/company/:companyId/missions/",
    props: true,
    children: [
      {
        path: "",
        pathToRegexpOptions: { strict: true },
        component: () => import("@/pages/discoverTalent/Missions.vue"),
        meta: {
          company: true,
        },
        name: "company-missions",
        props: true,
        strict: true,
      },
      {
        path: ":missionId/",
        pathToRegexpOptions: { strict: true },
        component: () => import("@/pages/discoverTalent/MissionDetails.vue"),
        meta: {
          company: true,
          mission: true,
        },
        name: "mission-details",
        props: true,
        strict: true,
      },
    ],
  },
  {
    component: () => import("@/layouts/TalentSearchLayout.vue"),
    meta: {
      company: true,
    },
    path: "/app/company/:companyId/discover-talent/",
    props: true,
    strict: true,
    children: [
      {
        component: () => import("@/pages/discoverTalent/SearchDetails.vue"),
        name: "company-discover-talent",
        path: "",
        props: true,
        strict: true,
      },
    ],
  },
  {
    path: "/:pathMatch(.*)",
    pathToRegexpOptions: { strict: true },
    component: PublicLayout,
    children: [
      {
        path: "",
        pathToRegexpOptions: { strict: true },
        component: PageNotFound,
        name: "404",
      },
    ],
  },
]
