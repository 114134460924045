/* eslint-disable @typescript-eslint/no-use-before-define */

import Api from "@/api"
import Intercom from "@/intercom"
import { anonymousContext, setContext } from "@/plugins/launchdarkly"
import { computed, ref } from "vue"
import { defineStore } from "pinia"
import { watchPausable } from "@vueuse/core"

export const useUserStore = defineStore("user", () => {
  const profile = ref(null)
  const isAuthenticated = ref(false)
  const showReleaseWarning = ref(null)

  const setIsAuthenticatedToFalse = () => {
    isAuthenticated.value = false
  }

  const authLogout = async (param = null) => {
    Api.logout().then(() => {
      Intercom.shutdown()

      // @ts-ignore
      window.location = param || "/"
    })
  }

  const logOutUnauthorized = (e) => {
    if(e?.response?.status === 401) {
      setIsAuthenticatedToFalse()
    }
  }

  function loadLDContext() {
    if (isAuthenticated.value && profile.value) {
      return setContext({
        email: profile.value.email,
        hasCompany: hasCompany.value,
        hasFreemail: hasFreemail.value,
        isFreelancer: isFreelancer.value,
        isStaff: isStaff.value,
        key: profile.value.id,
        kind: "user",
        name: profile.value.shortName,
        dateJoined: profile.value.dateJoined,
      })
    }

    return setContext(anonymousContext)
  }

  const { pause, resume } = watchPausable(
    [isAuthenticated, profile],
    async () => {
      await loadLDContext()
    },
  )

  const userGet = async (reload = false) => {
    if (reload || profile.value == null) {
      pause()

      try {
        profile.value = await Api.userDetail()
        isAuthenticated.value = true
      } catch(e) {
        resume()
        logOutUnauthorized(e)

        return
      }

      await loadLDContext()
      resume()
    }
  }

  const userUpdate = (data) => {
    profile.value = data
  }

  const showNewVersion = (show) => {
    // if showReleaseWarning is null then it initiates;  If it's been to false then we won't ever show it again until a full refresh
    if (show == false) {
      showReleaseWarning.value = false
    }

    if (showReleaseWarning.value == null) {
      showReleaseWarning.value = true
    }
  }

  const userUpdateFreelancerSeekingStatus = (data) => {
    profile.value.freelancer.seekingStatus = data
  }

  const hasBetaFeatures = computed<boolean>(() => {
    return profile.value?.hasBetaFeatures ?? false
  })

  const isStaff = computed<boolean>(() => {
    return profile.value?.isStaff ?? false
  })

  const isFreelancer = computed<boolean>(() => {
    return profile.value?.isFreelancer ?? false
  })

  const hasCompany = computed<boolean>(() => {
    return profile.value?.company != null
  })

  const myCompanyId = computed<string | null>(() => {
    return profile.value?.company?.id ?? null
  })

  const hasMissions = computed<boolean>(() => {
    return profile.value?.company?.hasMissions ?? false
  })

  const hasFreemail = computed<boolean>(() => {
    return profile.value?.hasFreemail ?? false
  })

  return {
    profile,
    isAuthenticated,
    showReleaseWarning,

    hasBetaFeatures,
    hasFreemail,
    isStaff,
    isFreelancer,
    hasCompany,
    hasMissions,
    myCompanyId,

    authLogout,
    loadLDContext,
    userGet,
    userUpdate,
    userUpdateFreelancerSeekingStatus,
    showNewVersion,
  }
})
