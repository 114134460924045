<template>
  <focus-trap>
    <div class="bg-base border-t-2 border-transparent lg:hidden w-full h-screen fixed inset-0 z-50 overflow-y-scroll">
      <div class="relative border-b-2 border-black">
        <router-link
          active-class="never-highlight-active"
          :to="{ name: 'redirect' }"
          class="w-32 h-12 block m-auto p-2"
          title="Gun.io"
        >
          <img src="@/images/logo-text.svg" alt="Gun.io">
        </router-link>

        <g-close
          title="Close Menu"
          @close="emit('close')"
        />
      </div>

      <div
        v-for="section in sections"
        :key="section.text"
        class="flex flex-col items-stretch"
      >
        <div class="bg-black/75 border-b-2 border-black font-medium text-silver px-4 py-2">
          {{ section.text }}
        </div>

        <nav-item
          v-for="child in section.children"
          :key="child.text"
          class="border-b-2"
          :href="child.href"
          :to="child.to"
        >
          {{ child.text }}
        </nav-item>
      </div>

      <div class="flex flex-col items-stretch pb-32">
        <div class="bg-black/75 border-b-2 border-black font-medium text-silver px-4 py-2">
          Account
        </div>

        <nav-item
          v-for="link in links"
          :key="link.text"
          class="border-b-2"
          :href="link.href"
          :to="link.to"
        >
          {{ link.text }}
        </nav-item>

        <nav-item
          class="border-b-2"
          :to="{ name: 'settings-page' }"
        >
          Settings
        </nav-item>

        <nav-item
          href="https://gun.io/faq"
          class="border-b-2"
        >
          Help
        </nav-item>

        <nav-item
          class="border-b-2"
          @click="logout"
        >
          Logout
        </nav-item>
      </div>
    </div>
  </focus-trap>
</template>

<script setup lang="ts">
import NavItem from "@/components/NavItem.vue"
import { FocusTrap } from "focus-trap-vue"
import { computed } from "vue"
import { useUserStore } from "@/store/user"

const props = withDefaults(defineProps<{
  items?: any[]
}>(), {
  items: () => [],
})
const emit = defineEmits(["close"])

const userStore = useUserStore()

const links = computed(() => {
  return props.items.filter(item => item.href || item.to)
})

const sections = computed(() => {
  return props.items.filter(item => item.children)
})

const logout = () => {
  userStore.authLogout()
}

</script>
