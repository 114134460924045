<template>
  <g-button
    class="top-0 right-0 w-16 h-12 p-2 ring-inset text-2xl text-white hover:text-white"
    :class="{ 'fixed': fixed, 'absolute': !fixed }"
    variant="link"
    :title="title"
    @click="close"
  >
    <icon name="close"/>
  </g-button>
</template>

<script setup lang="ts">
import GButton from "@/components/buttons/GButton.vue"
import Icon from "@/components/Icon.vue"
import keys from "@/keys"
import { useDocumentEventListener } from "@/composables/useEventListener"

withDefaults(defineProps<{
  title?: string
  fixed?: boolean
}>(), {
  title: "Close",
})

const emit = defineEmits(["close"])

const close = (e) => {
  emit("close", e)
}

useDocumentEventListener("keydown", (e) => {
  if (e.keyCode === keys.ESCAPE_KEY) {
    close(e)
  }
})

</script>
