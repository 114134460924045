import { CSSRulePlugin } from "gsap/dist/CSSRulePlugin"
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin"
import { gsap } from "gsap"

gsap.registerPlugin(CSSRulePlugin)
gsap.registerPlugin(ScrollToPlugin)

const _layoutAnimations = {
  scrollTo(selector: string, highlight: boolean, focus?: boolean, target: string | Window = window) {
    gsap.to(target, { duration: .5, scrollTo: { y: `#${selector}`, offsetY: 50 }, ease: "power2",
      onComplete() {
        if (highlight) {
          _layoutAnimations.highlight(selector)
        }

        if (focus) {
          _layoutAnimations.focus(selector)
        }
      },
    })
  },
  highlight(selector: string) {
    const el = document.getElementById(selector)

    if (el) {
      el.classList.remove("pulse-border")
      el.classList.add("pulse-border")

      setTimeout(() => {
        el.classList.remove("pulse-border")
      }, 3500)
    }
  },
  focus(el?: HTMLInputElement | null) {
    if (el) {
      el.focus?.()
      el.select?.()
    }
  },
}

export default _layoutAnimations
