/* eslint-disable @typescript-eslint/no-unsafe-declaration-merging */

import { BaseChore } from "@/models/base/BaseChore"
import type { Interview } from "@/models/Interview"
import type { Presentation } from "./Presentation"
import type { Profile } from "@/models/Profile"

export class Chore extends BaseChore {}

export interface Chore {
  id: string
  name: string
  description: string
  interview?: Interview
  presentation?: Presentation
  category: "INTERVIEW_REQUEST" | "MISCELLANEOUS" | "FEEDBACK"
  assignees: Profile[]
  dueAt: string | Date
  status: string
  createdAt: string
  createdBy: Profile
  updatedAt: string
  updatedBy: Profile
}
