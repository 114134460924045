/* eslint-disable import/no-unused-modules */
import avatarPlaceholderUrl from "@/images/avatar-placeholder.png"

export const IMG_PLACEHOLDER = avatarPlaceholderUrl

export const SALES_DASHBOARD_DEFAULT_LANDING = "SalesDashboardDefaultLanding"

// export const ONBOARDING_DEFAULT_SCHEDULER_LINK = "https://meetings.hubspot.com/jesse32"
export const ONBOARDING_DEFAULT_SCHEDULER_LINK = "https://meetings.hubspot.com/tyler-newkirk/gunio-intro-call"

export const SELECT_YES_NO = [
  [true, "Yes"],
  [false, "No"],
]

export const NOT_SET = "Not set"

export const DEFAULT_TAKE_RATE = 0.35

export const DAY_NAMES = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
] as const

export const DAY_ABBREVIATIONS = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
] as const

export const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
] as const
