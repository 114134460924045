/* eslint-disable @typescript-eslint/no-unsafe-declaration-merging */

import { BaseFreelancer } from "@/models/base/BaseFreelancer"
import type { Experience } from "@/models/Experience"
import type { Role } from "@/models/Role"
import type { Skill } from "@/models/Skill"

export class Freelancer extends BaseFreelancer {
  public static readonly LINKS = {
    linkedin: {
      title: "LinkedIn",
      icon: "linkedin",
    },
    github: {
      title: "GitHub",
      icon: "github",
    },
    gitlab: {
      title: "GitLab",
      icon: "gitlab",
    },
    bitbucket: {
      title: "Bitbucket",
      icon: "bitbucket",
    },
    stackoverflow: {
      title: "Stack Overflow", // eslint-disable-line
      icon: "stackoverflow",
    },
    twitter: {
      title: "Twitter",
      icon: "twitter",
    },
    portfolio: {
      title: "Portfolio",
      icon: "portfolio",
    },
    other: {
      title: "Other",
      icon: "website",
    },
    resume: {
      title: "Resume",
      icon: "resume",
    },
  }

  public static readonly SEEKING_CONTRACT = "CONTRACT"

  public static readonly SEEKING_SALARY = "SALARY"
}

export interface Freelancer {
  audioCoverLetter: string
  availableSlots?: any[]
  bitbucketUrl: string
  candidate?: any
  capacity: number | null
  codebasePreference: string
  codeTests: CodeTest[]
  completenessScore: number
  fitForTalentEligibility: boolean
  communicationProficiency: string
  englishProficiency: string
  experiences: Experience[]
  id: string
  geoPreferences: number[],
  githubUrl: string
  gitlabUrl: string
  hasCompletedAvailability: boolean
  hasCompletedProfile: boolean
  hasPassedCodeTest: boolean
  hasPassedScreening: boolean
  hasRecordedInterview: boolean
  hasSkills: boolean
  hasWorkExperience: boolean
  headline: string
  highlights: any[] | null
  ignoredSkills: Skill[]
  interviews: any[] | null
  introduction: string
  isAvatarSet: boolean
  isEngaged: boolean
  isStaffApproved: boolean
  staffApprovalStatus: string
  linkedinUrl: string
  otherUrl: string
  portfolioUrl: string
  primaryRole: Role
  qualifiedSkills: any[]
  rateBaseHourly: number | null
  rateBaseMonthly: number | null
  rateBaseSalary: number | null
  ratedSkills: any[]
  rating: number | null
  receiveJobNotificationEmails: boolean
  receiveJobNotificationTelegram: boolean
  references: any[]
  resumeName: string
  resume: string
  roles: Role[]
  screeningScore: number
  screeningScoreOriginal: number
  seekingSetAt: string
  seekingContract: boolean | null
  seekingSalary: boolean | null
  seekingStatus: number | null
  skills: Skill[]
  stackoverflowUrl: string
  staffEndorsement: string
  status: string
  telegramId: number | null
  twitterUrl: string
  topSkills: Skill[]
  tzName: string
  user: User
  updatedAt: string
  videoQueryString: string
  videoRecordingId: string
  video: string
  workEnd: number
  workHistory: WorkHistoryItem[]
  workStart: number
  workStyle: WorkStyleItem[]
  yearExperienceStarted: string | null
  yearFreelancingStarted: string | null
  yearsOfExperience: number | null
  yearsOfFreelancing: number | null
  onboardingDate: string
  approvalCallDate: string
  approvalDate: string
  disapprovalDate: string
  joinedOnboarding: boolean
}

export interface User {
  avatar: string
  country: string
  dateJoined: string
  email: string
  firstName: string
  id: string
  internalId: string | null
  isAvatarSet: boolean
  isEmailDeliverable?: boolean
  lastActiveAt: string
  lastName: string
  location: string
  marketingAttribution: string
  marketingAttributionDetails: string
  name: string
  phone: string
  pronoun: string
  slackId: string | null
  tzName: string
  telegramUsername: boolean
}

export interface CodeTest {
  id: string
  passed: boolean
  percentile: string
  percentileBySkill: string
  pk: number
  score: number | null
  skill: string
  timeEnd: string | null
  timeStart: string
}

export interface WorkHistoryItem {
  current: null | boolean
  description: string
  end: string
  finalized: boolean
  hidden: boolean
  id: number
  references: WorkHistoryItem[]
  role: string
  skills: Skill[]
  start: string
  verifierEmail: string
  verifierName: string
}

export interface WorkHistoryItemReference {
  anonymous: boolean
  comment: string
  confirm: boolean
  createdAt: string
  efficiency: number
  professionalRelationship: string
  professionalism: number
  technical: number
  verifierEmail: null | string
  verifierName: null | string
  wouldRecommend: boolean
}

export interface WorkStyleItem {
  artisan?: number
  partner?: number
  innovator?: number
  producer?: number
  leader?: number
}

export interface Interview {
  createdAt: string
  fields: any
  id: number
  title: string
}

export interface Note {
  author: NoteAuthor
  createdAt: string
  id: number
  note: string
}

export interface NoteAuthor {
  email: string
  name: string
}

