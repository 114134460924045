import { get } from "lodash-es"
import { messages } from "@/locales"

export const useI18n = () => {
  const t = (key: string) => {
    const message = get(messages, key)

    if (message === undefined) {
      console.warn(`Not found '${key}'`)

      return ""
    }

    return message
  }

  return { t }
}
