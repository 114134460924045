<template>
  <div class="bg-base border-t-2 border-transparent md:border-black print:border-white">
    <div
      class="bg-base border-b-2 border-black lg:mb-2 flex w-full justify-between text-white print:hidden"
      :class="isOnlyFreelancerExpandedNav ? 'lg:hidden' : 'md:hidden'"
    >
      <router-link
        active-class="never-highlight-active"
        :to="{ name: 'redirect' }"
        class="flex relative w-36 h-12 border-r-2 border-transparent px-4 py-2 items-center ring-inset"
        title="Gun.io"
      >
        <img src="@/images/logo-text.svg" alt="Gun.io">
      </router-link>

      <g-button
        class="w-16 h-12 p-2 text-2xl text-white hover:text-white"
        title="Open menu"
        variant="link"
        @click="toggleMobileNav"
      >
        <icon name="menu"/>
      </g-button>
    </div>

    <transition name="slide-out">
      <mobile-nav
        v-if="showMobileNavigation"
        ref="mobileNavigation"
        :items="items"
        @close="toggleMobileNav"
      />
    </transition>

    <div
      v-click-away="hideDropdown"
      class="hidden relative items-stretch justify-between border-x-2 border-b-2 border-black"
      :class="isOnlyFreelancerExpandedNav ? 'lg:flex' : 'md:flex'"
    >
      <div class="flex stretch">
        <router-link
          active-class="never-highlight-active"
          :to="{ name: 'redirect' }"
          class="flex relative w-36 h-12 border-r-2 border-black px-4 py-2 items-center ring-inset"
          @click="hideDropdown"
        >
          <img src="@/images/logo-text.svg" alt="Gun.io">
        </router-link>

        <div
          v-for="item in items"
          :key="item.text"
          class="flex stretch h-12"
        >
          <nav-dropdown
            v-if="item.children"
            :id="item.id"
            :data-test="item.dataTest"
            class="border-r-2"
            :items="item.children"
            :active="isActiveDropdown(item)"
            @show="showDropdown(item)"
            @hide="hideDropdown"
            @toggle="toggleDropdown(item)"
          >
            {{ item.text }}
          </nav-dropdown>

          <nav-item
            v-else
            class="border-r-2"
            :href="item.href"
            :to="item.to"
          >
            {{ item.text }}
          </nav-item>
        </div>
      </div>

      <div v-if="isStaff" class="flex items-center justify-end grow">
        <router-link class="text-white hover:no-underline hover:text-black group font-medium px-4 h-full flex items-center hover:bg-green" :to="{ name: 'staff-chores' }">
          Tasks
          <span
            class="ml-2 rounded-full min-w-6 font-medium min-h-6 text-center inline-block"
            :class="{
              'bg-gray-600 group-hover:text-black group-hover:bg-gray-200': myNumberOfTodos < 1,
              'bg-blue/25 text-blue group-hover:bg-black': myNumberOfTodos > 0 && myNumberPastDue < 1,
              'bg-orange/75 text-black group-hover:bg-gray-700 group-hover:text-orange': myNumberPastDue > 0
            }"
          >
            <template v-if="!loadingChores">{{ myNumberOfTodos }}</template>
          </span>
        </router-link>
      </div>
      <div class="border-l-2 border-black">
        <router-link
          active-class="never-highlight-active"
          class="
            flex items-center lg:min-w-64 h-12 px-4 ring-inset font-medium text-white
            hover:text-green hover:no-underline focus-visible:text-green
          "
          :to="profileLinkTo"
        >
          <profile-image :src="profile?.avatar" :width="2" class="mr-2"/>
          <span class="hidden lg:inline">{{ profile?.name }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import GButton from "@/components/buttons/GButton.vue"
import MobileNav from "@/components/MobileNav.vue"
import NavDropdown from "@/components/NavDropdown.vue"
import NavItem from "@/components/NavItem.vue"
import ProfileImage from "@/components/ProfileImage.vue"
import { Chore } from "@/models/Chore"
import { clearAllBodyScrollLocks, disableBodyScroll } from "@/plugins/body-scroll-lock"
import { computed, nextTick, ref, watch } from "vue"
import { filter, kebabCase } from "lodash-es"
import { useChores } from "@/composables/useChores"
import { useFeatureFlag } from "@/composables/useFeatureFlag"
import { useRoute } from "vue-router"
import { useUserStore } from "@/store/user"

const activeDropdown = ref(null)
const showMobileNavigation = ref(false)
const route = useRoute()
const mobileNavigation = ref(null)
const { getChores, myNumberPastDue, myNumberOfTodos, loading: loadingChores } = useChores()
const userStore = useUserStore()

const companyId = computed(() => userStore.myCompanyId)
const hasCompany = computed(() => userStore.hasCompany)
const isFreelancer = computed(() => userStore.isFreelancer)
const isStaff = computed(() => userStore.isStaff)
const profile = computed(() => userStore.profile)

const multipleRoles = computed(() => {
  return filter([hasCompany.value, isFreelancer.value, isStaff.value]).length > 1
})

const canAccessDiscoverTalent = useFeatureFlag("rollout-talent-search", false)

const nav = computed(() => {
  const links = [
    {
      text: "Staff",
      active: isStaff.value,
      dataTest: "staff-nav",
      dropdown: multipleRoles.value,
      items: [
        { text: "Companies", to: { name: "staff-company-search" }, icon: "company" },
        { text: "Hires", to: { name: "staff-hires" }, icon: "hires" },
        { text: "Interviews", to: { name: "staff-interviews-list" }, icon: "interview" },
        { text: "Jobs", to: { name: "sales-dashboard" }, icon: "income" },
        { text: "Talent", to: { name: "staff-freelancer-search" }, icon: "freelancer" },
      ],
    },
    {
      text: "Freelancer",
      active: isFreelancer.value,
      dropdown: multipleRoles.value,
      items: [
        { text: "Interviews", to: { name: "freelancer-interview-list" }, icon: "interview" },
        { text: "Profile", to: { name: "freelancer-profile" }, icon: "freelancer" },
        { text: "WorkStyle", to: { name: "freelancer-work-style-assessment" }, icon: "workstyle" },
        { text: "Your jobs", to: { name: "freelancer-applications" }, icon: "income" },
      ],
    },
    {
      text: "Company",
      active: hasCompany.value,
      dropdown: multipleRoles.value,
      items: [
        { text: "Interviews", to: { name: "company-appointment-list" }, icon: "interview", dataTest: "" },
        { text: "Jobs", to: { name: "company-jobs", params: { companyId: companyId.value } }, icon: "job", dataTest: "" },
        { text: "Profile", to: { name: "company-profile" }, icon: "company", dataTest: "" },
      ],
    },
    {
      text: "Community",
      active: isFreelancer.value,
      dropdown: true,
      items: [
        { text: "Slack", href: "https://gun.io/cantina-signup/", icon: "slack" },
        { text: "Gun.io blog", href: "https://gun.io/blog/", icon: "blog" },
        { text: "LinkedIn", href: "https://www.linkedin.com/company/gun-io/", icon: "linkedin" },
        { text: "Meetup", href: "https://www.meetup.com/gundotio/", icon: "meetup" },
        { text: "Referrals", href: "https://gun.io/refer/", icon: "money" },
        { text: "Spotify", href: "https://open.spotify.com/show/751Zv7jcJsVA4WWwpF3KI0?si=6660ca6ea98b4269", icon: "spotify" },
        { text: "YouTube channel", href: "https://www.youtube.com/@gunio", icon: "youtube" },
      ],
    }
    ,
    {
      text: "Admin",
      active: isStaff.value,
      dropdown: true,
      items: [
        { text: "Django admin", href: "/admin/", icon: "admin" },
        { text: "Email preview", href: "/emails/", icon: "email" },
        { text: "Metabase", href: "https://metabase.gun.io", icon: "dashboard" },
        { text: "Marketing attribution", to: { name: "marketing-attribution-search" }, icon: "report" },
        { text: "Monthly metrics CSV", href: "/api/v2/staff/reports/metrics/monthly/csv/", icon: "download" },
      ],
    },
  ]

  if ((profile.value.freelancer?.isStaffApproved || (profile.value.freelancer?.isStaffApproved === null && profile.value.freelancer?.completenessScore === 100)) && profile.value.freelancer.fitForTalentEligibility) {
    links[1].items.push({ text: "Open jobs", to: { name: "auth-jobs-page" }, icon: "invoice" })
  }

  if (canAccessDiscoverTalent.value) {
    links[2].items.push({
      icon: "compass",
      text: "Discover talent",
      dataTest: "discover-talent-tab",
      to: {
        name: "company-discover-talent",
        params: {
          companyId: companyId.value,
        },
      },
    })

    // TODO: We need to add a link to the "missions" page once that is ready.
  }

  return links
})

const items = computed<any[]>(() => {
  const activeNavItems = nav.value.filter(({ active }) => active)

  return activeNavItems.flatMap(navItem => {
    const { dataTest, dropdown, items: children, text } = navItem

    if (dropdown) {
      const id = kebabCase(text)

      return [{ id, text, children, dataTest }]
    }

    return children
  })
})

const isOnlyFreelancerExpandedNav = computed(() => {
  // these 2 scenarios cover the dropdown situations which doesn't need the isOnlyFreelancerExpandedNav flag
  if (isStaff.value || (isFreelancer.value && hasCompany.value)) {
    return false
  }

  return isFreelancer.value
})

const profileLinkTo = computed(() => {
  return { name: profile.value?.freelancer?.id ? "freelancer-profile" : "settings-page" }
})

const showDropdown = ({ text }) => {
  activeDropdown.value = text
}

const hideDropdown = () => {
  activeDropdown.value = null
}

const isActiveDropdown = ({ text }) => {
  return activeDropdown.value === text
}

const toggleDropdown = (item) => {
  if (isActiveDropdown(item)) {
    hideDropdown()
  } else {
    showDropdown(item)
  }
}

const toggleMobileNav = async () => {
  showMobileNavigation.value = !showMobileNavigation.value

  if (showMobileNavigation.value) {
    await nextTick()
    disableBodyScroll(mobileNavigation.value?.$el ?? mobileNavigation.value)
  } else {
    clearAllBodyScrollLocks()
  }
}

watch(() => route?.fullPath, () => {
  showMobileNavigation.value = false
  clearAllBodyScrollLocks()
  hideDropdown()
})

const setup = () => {
  if (isStaff.value) {
    getChores({ assignee: profile.value?.id, status: Chore.STATUS_TODO }, 1, null)
  }
}

setup()
</script>

<style lang="scss" scoped>
  .slide-out-enter-active, .slide-out-leave-active {
    transition: all .25s;
  }

  .slide-out-enter, .slide-out-leave-to {
    left: -100vw;
    opacity: 0;
  }
</style>
