/* eslint-disable no-multi-spaces */

import axios from "@/axios"
import type { Company } from "@/models/Company"
import type {
  GetFreelancerApplicationList,
  GetFreelancerHireList,
  GetFreelancerInterviewList,
  GetFreelancerPresentationList,
  GetNewRates,
  GetRates,
  GetStaffChoreList,
  GetStaffDealInterviewList,
  GetStaffFreelancerDetail,
  GetStaffHireDetail,
  GetStaffJobApplicationsList,
  GetStaffJobDetal,
  GetStaffJobList,
  GetStaffPresentationList,
  PostStaffInterviewCreate,
} from "@/models/Api"
import type { Hire } from "./models/Hire"
import type { Job } from "@/models/Job"
import type { Profile } from "@/models/Profile"
import type { Recording } from "./models/Recording"

import {
  CompanyMissionJobApplicationActionPutActionEnum,
  DatasetsApi,
  DefaultApi,
} from "@/api_sdk"
import type { FreelancerSharingCreate } from "@/api_sdk"
import type { RawAxiosRequestConfig } from "axios"

const sdk_api = new DefaultApi()
const sdk_datasets_api = new DatasetsApi()

const extract_data_from_response = (response) => response.data

export default {
  /* Auth */

  checkEmail: (email, bundle)                                             => axios.post("v2/auth/check/", { ...bundle, ...{ email } }),
  createCompany: (bundle)                                                 => axios.post("v2/user/create/company/", bundle),
  createFreelancer: ()                                                    => axios.post("v2/user/create/freelancer/"),
  emailConfirmation: (key)                                                => axios.post("v2/auth/email-confirmation/", { key }, { extractData: false }),
  emailResendConfirmation: ()                                             => axios.post("v2/auth/email-resend-confirm/", {}, { extractData: false }),
  emailUpdate: (email, password)                                          => axios.post("v2/auth/email-update/", { email, password }, { extractData: false }),
  login: (email, password)                                                => axios.post("v2/auth/login/", { email, password }),
  logout: ()                                                              => axios.get("v2/auth/logout/"),
  passwordUpdate: (oldPass, newPass)                                      => axios.post("v2/auth/password-update/", { oldPass, newPass }, { extractData: false }),
  resetPassword: (email)                                                  => axios.post("v2/auth/password-reset/", { email }, { extractData: false }),
  setPassword: (token, email, password)                                   => axios.post("v2/auth/password-set/", { token, email, password }, { extractData: false }),
  setPasswordTokenCheck: (token, email)                                   => axios.get("v2/auth/password-set/", { params: { token, email }, extractData: false }),
  signup: (code, password)                                                => axios.post("v2/auth/invite/", { code, password }),
  verifyInviteCode: (code)                                                => axios.get("v2/auth/invite/", { params: { code } }),

  /* V2 */

  codeTestCreate: ()                                                      => axios.post("v2/freelancer/codetests/"),
  codeTestDetail: (id)                                                    => axios.get(`v2/freelancer/codetests/${id}/`),
  codeTestUpdate: (id, bundle)                                            => axios.patch(`v2/freelancer/codetests/${id}/`, bundle),

  companyDetail: (companyId)                                              => axios.get<any, Company>(`v2/company/${companyId}/`),
  companyDetailPublic: (companyId)                                        => axios.get(`v2/company/${companyId}/public/`),
  companyFreelancerDetail: (companyId, freelancerId)                      => sdk_api.companyIdFreelancersFreelancerIdGet(companyId, freelancerId).then(extract_data_from_response),
  companyFreelancerList: (companyId, params, config = {})                 => axios.get(`v2/company/${companyId}/freelancers/`, { params, ...config }),
  companyGunAIHistoryCreate: (companyId, bundle)                          => axios.post(`v2/company/${companyId}/gun_ai_histories/`, bundle),
  companyHireDetail: (companyId, hireId)                                  => axios.get(`v2/company/${companyId}/hires/${hireId}/`),
  companyHireList: (companyId, page)                                      => axios.get(`v2/company/${companyId}/hires/`, { params: { page } }),
  companyInterviewCreate: (companyId, bundle)                             => axios.post(`v2/company/${companyId}/interviews/`, bundle),
  companyJobArchive: (companyId, jobId)                                   => axios.post(`v2/company/${companyId}/jobs/${jobId}/archive/`),
  companyJobCreate: (companyId, bundle)                                   => axios.post<any, Job>(`v2/company/${companyId}/jobs/`, bundle),
  companyJobCreateFromMission: (companyId, bundle)                                   => axios.post<any, Job>(`v2/company/${companyId}/jobs/from_mission/`, bundle),
  companyJobDelete: (companyId, jobId)                                    => axios.delete(`v2/company/${companyId}/jobs/${jobId}/`),
  companyJobDetail: (companyId, jobId)                                    => axios.get<any, Job>(`v2/company/${companyId}/jobs/${jobId}/`),
  companyJobList: (companyId)                                             => axios.get(`v2/company/${companyId}/jobs/`),
  companyJobReview: (companyId, jobId)                                    => axios.post(`v2/company/${companyId}/jobs/${jobId}/review/`),
  companyJobUpdate: (companyId, jobId, bundle)                            => axios.patch(`v2/company/${companyId}/jobs/${jobId}/`, bundle),
  companyMemberCreate: (companyId, bundle)                                => axios.post(`v2/company/${companyId}/members/`, bundle),
  companyMemberDetail: (companyId, userId)                                => axios.get<any, Profile>(`v2/company/${companyId}/members/${userId}/`),
  companyMemberList: (companyId)                                          => axios.get(`v2/company/${companyId}/members/`),
  companyMemberUpdate: (companyId, userId, bundle)                        => axios.patch(`v2/company/${companyId}/members/${userId}/`, bundle),
  companyMissionCreate: (companyId, bundle)                               => sdk_api.companyIdMissionsPost(companyId, bundle).then(extract_data_from_response),
  companyMissionBuilder: (companyId, bundle)                              => axios.post(`v2/company/${companyId}/missions/mission_builder/`, bundle),
  companyMissionDetail: (companyId, missionId)                            => sdk_api.companyIdMissionsMissionIdGet(companyId, missionId).then(extract_data_from_response),
  companyMissionList: (companyId)                                         => sdk_api.companyIdMissionsGet(companyId).then(extract_data_from_response),
  companyMissionUpdate: (companyId, missionId, bundle)                    => sdk_api.companyIdMissionsMissionIdPatch(companyId, missionId, bundle).then(extract_data_from_response),
  companyMissionDelete: (companyId, missionId)                            => sdk_api.companyIdMissionsMissionIdDelete(companyId, missionId),
  companyMissionBudgetRecommendations(companyId, experienceLevel, compensationPeriod, options?: RawAxiosRequestConfig) {
    return sdk_api.companyMissionBudgetRecommendationsGet(companyId, experienceLevel, compensationPeriod, options)
      .then(extract_data_from_response)
  },
  companyShowdownSlotsCreate: (companyId, missionId, bundle)              => axios.post(`v2/company/${companyId}/missions/${missionId}/showdown-slots/`, bundle),
  companyShowdownSlotsList: (companyId, missionId, params)                => axios.get(`v2/company/${companyId}/missions/${missionId}/showdown-slots/`, { params }),
  companyMissionShortlistList(companyId, missionId) {
    return axios.get(`v2/company/${companyId}/missions/${missionId}/shortlists/`)
  },

  companyMissionShortlistFreelancerCreate(companyId, missionId, bundle) {
    return axios.post(`v2/company/${companyId}/missions/${missionId}/shortlist_freelancers/`, bundle)
  },

  companyMissionShortlistFreelancerUpdate(companyId, missionId, shortlistFreelancerId, bundle) {
    return axios.patch(`v2/company/${companyId}/missions/${missionId}/shortlist_freelancers/${shortlistFreelancerId}/`, bundle)
  },
  companyMissionShortlistFreelancerDetail(companyId, missionId, shortlistFreelancerId) {
    return axios.get(`v2/company/${companyId}/missions/${missionId}/shortlist_freelancers/${shortlistFreelancerId}/`)
  },

  companyMissionShortlistFreelancerDelete(companyId, missionId, shortlistFreelancerId, options?: RawAxiosRequestConfig) {
    return sdk_api.companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdDelete(
      companyId, missionId, shortlistFreelancerId, options,
    )
  },

  freelancerShortlistFreelancerUpdate(freelancerId, shortlistFreelancerId, bundle) {
    return axios.patch(`v2/freelancer/${freelancerId}/shortlist_freelancers/${shortlistFreelancerId}/`, bundle)
  },
  freelancerShortlistFreelancerDetails(freelancerId, shortlistFreelancerId) {
    return axios.get(`v2/freelancer/${freelancerId}/shortlist_freelancers/${shortlistFreelancerId}/`)
  },
  companyMissionApplicantsTableList(companyId, missionId) {
    return sdk_api.companyMissionJobApplicationListGet(companyId, missionId)
      .then(extract_data_from_response)
  },
  companyMissionShortlistedApplicantsTableList(companyId, missionId) {
    return sdk_api.companyShortlistFreelancerMissionDisplayListGet(companyId, missionId)
      .then(extract_data_from_response)
  },

  companyMissionDeclineApplicant(companyId, missionId, applicationId) {
    const action = CompanyMissionJobApplicationActionPutActionEnum.Decline

    return sdk_api.companyMissionJobApplicationActionPut(companyId, missionId, applicationId, action)
      .then(extract_data_from_response)
  },

  userGetNylasAuthUrl(params) {
    return axios.get("/v2/user/grant/nylas/get_auth_url/", { params })
  },
  userCreateNylasGrant(payload) {
    return axios.post("/v2/user/grant/nylas/", payload)
  },
  companyInterviewSettingsCreate(companyId, payload) {
    return axios.post(`v2/company/${companyId}/interview_settings/`, payload)
  },
  companyInterviewSettingsUpdate(companyId, interviewSettingsId, payload) {
    return axios.put(`v2/company/${companyId}/interview_settings/${interviewSettingsId}/`, payload)
  },
  companyInterviewSettingsList(companyId, params) {
    return axios.get(`v2/company/${companyId}/interview_settings/`, { params })
  },
  companyDisconnectCalendar(companyId, interviewSettingsId) {
    return axios.delete(`v2/company/${companyId}/interview_settings/${interviewSettingsId}/disconnect-calendar/`)
  },
  companyInvitesCreate(companyId, payload) {
    return axios.post(`v2/company/${companyId}/invites/`, payload)
  },
  companyReviewCreate: (companyId, bundle)                                => axios.post(`v2/company/${companyId}/reviews/`, bundle),
  companyReviewDetail: (companyId, reviewId)                              => axios.get(`v2/company/${companyId}/reviews/${reviewId}/`),
  companyReviewList: (companyId, page)                                    => axios.get(`v2/company/${companyId}/reviews/`, { params: { page } }),
  companyReviewUpdate: (companyId, reviewId, bundle)                      => axios.patch(`v2/company/${companyId}/reviews/${reviewId}/`, bundle),
  companyMissionShortlistCreate: (companyId, missionId, bundle)           => sdk_api.companyIdMissionsMissionIdShortlistsPost(companyId, missionId, bundle).then(extract_data_from_response),
  companyUpdate: (companyId, bundle)                                      => axios.patch(`v2/company/${companyId}/`, bundle),

  freelancerAvailability: ()                                              => axios.get("v2/freelancer/availability/"),
  freelancerAvailabilityById: (freelancerId)                              => axios.get(`v2/freelancer/availability/${freelancerId}/`),
  freelancerCompleteScreening: ()                                         => axios.post("v2/freelancer/complete-screening/"),
  freelancerDetail: ()                                                    => axios.get("v2/freelancer/"),
  freelancerDetailById: (freelancerId)                                    => axios.get(`v2/freelancer/${freelancerId}/`),
  freelancerExperienceCreate: (freelancerId, bundle)                      => axios.post(`v2/freelancer/${freelancerId}/experiences/`, bundle),
  freelancerExperienceDelete: (freelancerId, id)                          => axios.delete(`v2/freelancer/${freelancerId}/experiences/${id}/`),
  freelancerExperienceDetail: (freelancerId, id)                          => axios.get(`v2/freelancer/${freelancerId}/experiences/${id}/`),
  freelancerExperienceList: (freelancerId, page)                          => axios.get(`v2/freelancer/${freelancerId}/experiences/`, { params: { page } }),
  freelancerExperienceUpdate: (freelancerId, id, bundle)                  => axios.patch(`v2/freelancer/${freelancerId}/experiences/${id}/`, bundle),
  freelancerProjectCreate: (freelancerId, bundle)                         => axios.post(`v2/freelancer/${freelancerId}/projects/`, bundle),
  freelancerProjectDetail: (freelancerId, id)                             => axios.get(`v2/freelancer/${freelancerId}/projects/${id}/`),
  freelancerProjectDelete: (freelancerId, id)                             => axios.delete(`v2/freelancer/${freelancerId}/projects/${id}/`),
  freelancerProjectList: (freelancerId, page)                             => axios.get(`v2/freelancer/${freelancerId}/projects/`, { params: { page } }),
  freelancerProjectUpdate: (freelancerId, id, bundle)                     => axios.patch(`v2/freelancer/${freelancerId}/projects/${id}/`, bundle),
  freelancerRateRecommendations: ()                                       => sdk_api.freelancerRateRecommendationsGet().then(extract_data_from_response),
  freelancerReferenceCreate: (freelancerId, bundle)                       => axios.post(`v2/freelancer/${freelancerId}/references/`, bundle),
  freelancerReferenceDetail: (freelancerId, id)                           => axios.get(`v2/freelancer/${freelancerId}/references/${id}/`),
  freelancerReferenceList: (freelancerId, page)                           => axios.get(`v2/freelancer/${freelancerId}/references/`, { params: { page } }),
  freelancerReferenceUpdate: (freelancerId, id, bundle)                   => axios.patch(`v2/freelancer/${freelancerId}/references/${id}/`, bundle),
  freelancerReviewDetail: (freelancerId, id)                              => axios.get(`v2/freelancer/${freelancerId}/reviews/${id}/`),
  freelancerReviewList: (freelancerId, page)                              => axios.get(`v2/freelancer/${freelancerId}/reviews/`, { params: { page } }),
  freelancerReviewUpdate: (freelancerId, id, bundle)                      => axios.patch(`v2/freelancer/${freelancerId}/reviews/${id}/`, bundle),
  freelancerSharingCreate: (bundle)                                       => axios.post<any, FreelancerSharingCreate>("v2/freelancer/share/", bundle),
  freelancerSharingDetail: (freelancerId)                                 => sdk_api.freelancerShareIdGet(freelancerId).then(extract_data_from_response),
  freelancerUpdate: (bundle)                                              => axios.patch("v2/freelancer/", bundle),
  freelancerUnbalrog: (freelancerId)                                      => axios.delete(`v2/freelancer/${freelancerId}/`),
  freelancerJobBellowMarket: (freelancerId, jobId)                        => axios.patch(`v2/freelancer/${freelancerId}/jobs/job-bellow-market/${jobId}/`),

  genericJobList: (params)                                                 => axios.get("v2/jobs/", { params }),
  genericJobDetail: (id)                                                   => axios.get(`v2/jobs/${id}/`),
  publicJobInteractionCreate: (id, type)                                  => axios.post(`v2/jobs/${id}/interactions/`, { type }),
  publicJobStats: ()                                                      => axios.get("v2/jobs/stats/"),

  rates: (params)                                                         => axios.get<any, GetRates>("v2/insights/rates/", { params }),
  newRates: (params)                                                      => axios.get<any, GetNewRates>("v2/insights/new-rates/", { params }),
  geoPreferencesInsights: (params)                                        => axios.get<any, Record<string, number>>("v2/insights/geo-preferences/", { params }),
  roleInsights: (params)                                                  => axios.get("v2/insights/roles/", { params }),
  skillInsights: (params)                                                 => axios.get("v2/insights/skills/", { params }),
  hourlyRate: (tier)                                                      => axios.get(`v2/hourly-rate/${tier}/`),

  scopeOfWork: (companyId, hireId)                                        => axios.get(`v2/company/${companyId}/hires/${hireId}/scope-of-work/`),

  staffAppointmentDetail: (id)                                            => axios.get(`v2/staff/appointments/${id}/`),
  staffAppointmentList: (query, filters, page, sort)                      => axios.get("v2/staff/appointments/", { params: { q: query, ...filters, page, sort } }),
  staffAppointmentUpdate: (id, bundle)                                    => axios.patch(`v2/staff/appointments/${id}/`, bundle),
  staffCodeTestHistogram: ()                                              => axios.get("v2/staff/reports/codetests/"),
  staffChoreCreate: (bundle)                                              => axios.post("v2/staff/chores/", bundle),
  staffChoreUpdate: (id, bundle)                                          => axios.patch(`v2/staff/chores/${id}/`, bundle),
  staffChoreList: (query, filters, page, sort)                            => axios.get<any, GetStaffChoreList>("v2/staff/chores/", { params: { q: query, ...filters, page, sort } }),
  staffCompanyCreate: (bundle)                                            => axios.post("v2/staff/companies/", bundle),
  staffCompanyList: (params)                                              => axios.get("v2/staff/companies/", { params }),
  staffCompanyMerge: (id, bundle)                                         => axios.put(`v2/staff/companies/${id}/merge/`, bundle),
  staffFreelancerDetail: (id)                                             => axios.get<any, GetStaffFreelancerDetail>(`v2/staff/freelancers/${id}/`),
  staffFreelancerHireList: (id, page)                                     => axios.get(`v2/staff/freelancers/${id}/hires/`, { params: { page } }),
  staffFreelancerList: (params)                                           => axios.get("v2/staff/freelancers/", { params }),
  staffFreelancerListNew: (params, config = {})                           => axios.get("v2/staff/freelancers/new/", { params, ...config }),
  staffFreelancerMatchList: (id, page)                                    => axios.get(`v2/staff/freelancers/${id}/matches/`, { params: { page } }),
  staffFreelancerMessageList: (id)                                        => axios.get(`v2/staff/freelancers/${id}/messages/`),
  staffFreelancerScreeningDetail: (id)                                    => axios.get(`v2/staff/freelancers/${id}/screening/`),
  staffFreelancerSetApproval: (id, bundle)                                => axios.put(`v2/staff/freelancers/${id}/set-approval/`, bundle),
  staffFreelancerSetRating: (id, rating, reason)                          => axios.put(`v2/staff/freelancers/${id}/set-rating/`, { rating, reason }),
  staffFreelancerSetStatus: (id, status)                                  => axios.put(`v2/staff/freelancers/${id}/set-status/`, { status }),
  staffFreelancerUpdate: (id, bundle)                                     => axios.patch(`v2/staff/freelancers/${id}/`, bundle),
  staffHireDetail: (id)                                                   => axios.get<any, GetStaffHireDetail>(`v2/staff/hires/${id}/`),
  staffHireStartEngagement: (id)                                          => axios.put(`v2/staff/hires/${id}/start/`),
  staffHireStopEngagement: (id, bundle)                                   => axios.put(`v2/staff/hires/${id}/stop/`, bundle),
  staffHireList: (params)                                                 => axios.get("v2/staff/hires/", { params }),
  staffHireUpdate: (id, bundle)                                           => axios.patch<any, Hire>(`v2/staff/hires/${id}/`, bundle),
  staffHireScopeOfWorkList: (hireId)                                      => axios.get(`v2/staff/hires/${hireId}/scope-of-work/`),
  staffHireScopeOfWorkDetail: (hireId, scopeOfWorkId)                     => axios.get(`v2/staff/hires/${hireId}/scope-of-work/${scopeOfWorkId}/`),
  staffHireScopeOfWorkCreate: (hireId, bundle)                            => axios.post(`v2/staff/hires/${hireId}/scope-of-work/`, bundle),
  // staffInterviewCreate: (bundle)                                          => axios.post("v2/staff/interviews/", bundle),
  staffInterviewDetail: (id)                                              => axios.get(`v2/staff/interviews/${id}/`),
  staffInterviewList: (query, filters, page, sort)                        => axios.get("v2/staff/interviews/", { params: { q: query, ...filters, page, sort } }),
  staffInterviewOnlyUpdate: (id, bundle)                                  => axios.patch(`v2/staff/interviews/${id}/`, bundle),
  staffJobAutoMatches: (jobId, page)                                      => axios.get(`v2/staff/automatches/${jobId}/`, { params: { page } }),
  staffJobArchive: (id, archiveReason, archivalNote)                      => axios.put(`v2/staff/jobs/${id}/archive/`, { archive_reason: archiveReason, archival_note: archivalNote }),
  staffJobClose: (id)                                                     => axios.put(`v2/staff/jobs/${id}/close/`),
  staffJobDecline: (id, reason)                                           => axios.put(`v2/staff/jobs/${id}/decline/`, { reason }),
  staffJobDetail: (id)                                                    => axios.get<any, GetStaffJobDetal>(`v2/staff/jobs/${id}/`),
  staffJobFreelancerList: (jobId, params)                                 => axios.get(`v2/staff/jobs/${jobId}/freelancers/`, { params }),
  staffJobFreelancerSearchCreate: (jobId, bundle)                         => axios.post(`v2/staff/jobs/${jobId}/searches/`, bundle),
  staffJobFreelancerSearchDelete: (jobId, searchId)                       => axios.delete(`v2/staff/jobs/${jobId}/searches/${searchId}/`),
  staffJobFreelancerSearchDetail: (jobId, searchId)                       => axios.get(`v2/staff/jobs/${jobId}/searches/${searchId}/`),
  staffJobFreelancerSearchList: (jobId)                                   => axios.get(`v2/staff/jobs/${jobId}/searches/`),
  staffJobFreelancerSearchUpdate: (jobId, searchId, bundle)               => axios.patch(`v2/staff/jobs/${jobId}/searches/${searchId}/`, bundle),
  staffJobExtend: (id, newDate)                                           => axios.put(`v2/staff/jobs/${id}/extend/`, newDate),
  staffJobGenerateAutoMatches: (jobId)                                    => axios.put(`v2/staff/jobs/${jobId}/generate-automatches/`),
  staffJobList: (params)                                                  => axios.get<any, GetStaffJobList>("v2/staff/jobs/", { params }),
  staffJobPublish: (id)                                                   => axios.put(`v2/staff/jobs/${id}/publish/`),
  staffJobRecordingCreate: (jobId, bundle)                                => axios.post<any, Recording>(`v2/staff/jobs/${jobId}/recordings/`, bundle),
  staffJobRecordingDelete: (jobId, id)                                    => axios.delete(`v2/staff/jobs/${jobId}/recordings/${id}/`),
  staffJobRecordingList: (jobId)                                          => axios.get<any, { recordings: Recording[] }>(`v2/staff/jobs/${jobId}/recordings/`),
  staffJobReopen: (id, newDate)                                           => axios.put(`v2/staff/jobs/${id}/reopen/`, newDate),
  staffJobSendNotifications: (id)                                         => axios.put(`v2/staff/jobs/${id}/send-notifications/`),
  staffJobUpdate: (id, bundle)                                            => axios.patch(`v2/staff/jobs/${id}/`, bundle),
  staffJobViability: (id)                                                 => axios.put(`v2/staff/jobs/${id}/viability/`),
  staffJobUnarchive: (id)                                                 => axios.put(`v2/staff/jobs/${id}/unarchive/`),
  staffJobUnwithdraw: (id)                                                => axios.put(`v2/staff/jobs/${id}/unwithdraw/`),
  staffJobWithdraw: (id, reason)                                          => axios.put(`v2/staff/jobs/${id}/withdraw/`, { reason }),
  staffMarketingAttributionStats: (params)                                => axios.get("v2/staff/reports/marketing-attribution/", { params }),
  staffMessageList: ()                                                    => axios.get("v2/staff/messages/"),
  staffMessageSend: (bundle)                                              => axios.post("v2/staff/messages/", bundle),
  staffMetrics: ()                                                        => axios.get("v2/staff/reports/metrics/"),
  staffNoteCreate: (id, bundle)                                           => axios.post(`v2/staff/freelancers/${id}/notes/`, bundle),
  staffNoteList: (id)                                                     => axios.get(`v2/staff/freelancers/${id}/notes/`),
  staffReviewDetail: (id)                                                 => axios.get(`v2/staff/reviews/${id}/`),
  staffReviewList: (params)                                               => axios.get("v2/staff/reviews/", { params }),
  staffReviewUpdate: (id, bundle)                                         => axios.patch(`v2/staff/reviews/${id}/`, bundle),

  subscriptionsDetail: (hash)                                             => axios.get(`v2/subscriptions/${hash}/`),
  subscriptionsUpdate: (hash, bundle)                                     => axios.patch(`v2/subscriptions/${hash}/`, bundle),

  userAppointmentDetail: (id)                                             => axios.get(`v2/user/appointments/${id}/`),
  userAppointmentList: ()                                                 => axios.get("v2/user/appointments/"),
  userDetail: ()                                                          => axios.get("v2/user/"),
  userEvent: (type, data)                                                 => axios.post("v2/user/events/", { type, data }),
  userList: (params)                                                      => axios.get("v2/users/", { params }),
  userSlackConnect: (code)                                                => axios.patch("v2/user/subscriptions/slack/", { code }),
  userSubscriptionsDetail: ()                                             => axios.get("v2/user/subscriptions/"),
  userSubscriptionsUpdate: (bundle)                                       => axios.patch("v2/user/subscriptions/", bundle),
  userTelegramConnect: (telegramId)                                       => axios.patch("v2/user/subscriptions/telegram/", { telegramId }),
  userUpdate: (profile)                                                   => axios.patch("v2/user/", profile),
  userWorkstyle: ()                                                       => axios.get("v2/user/workstyle/"),

  workstyleAssessmentDetail: (id)                                         => axios.get(`v2/workstyles/assessments/${id}/`),
  workstyleAssessmentCreate: (bundle)                                     => axios.post("v2/workstyles/assessments/", bundle),
  workstyleAssessmentUpdate: (id, bundle)                                 => axios.patch(`v2/workstyles/assessments/${id}/`, bundle),

  /* Datasets */

  freeEmails: ()                                                          => sdk_datasets_api.freeEmailsGet().then(extract_data_from_response),
  geoPreferences: ()                                                      => sdk_datasets_api.geoPreferencesGet().then(extract_data_from_response),
  continents: ()                                                          => sdk_datasets_api.continentsGet().then(extract_data_from_response),
  countries: ()                                                           => sdk_datasets_api.countriesGet().then(extract_data_from_response),
  marketingAttributions: ()                                               => sdk_datasets_api.marketingAttributionsGet().then(extract_data_from_response),
  experienceLevels: ()                                                    => sdk_datasets_api.experienceLevelsGet().then(extract_data_from_response),
  pricingRegions: ()                                                      => axios.get("v2/pricing-regions/"),
  roles: (params)                                                         => sdk_datasets_api.rolesGet({ params }).then(extract_data_from_response),
  sectors: ()                                                             => sdk_datasets_api.sectorsGet().then(extract_data_from_response),
  skills: (params)                                                        => sdk_datasets_api.skillsGet({ params }).then(extract_data_from_response),
  users: ()                                                               => axios.get("v2/users/", { params: { isStaff: true } }),
  workstyles: ()                                                          => axios.get("v2/workstyles/"),

  /* Deals */

  freelancerApplicationCreate: (freelancerId, bundle)                     => axios.post(`v2/freelancer/${freelancerId}/applications/`, bundle),
  // freelancerApplicationDetail: (freelancerId, applicationId)              => axios.get(`v2/freelancer/${freelancerId}/applications/${applicationId}/`),
  freelancerApplicationList: (freelancerId, params)                       => axios.get<any, GetFreelancerApplicationList>(`v2/freelancer/${freelancerId}/applications/`, { params }),
  freelancerApplicationUpdate: (freelancerId, applicationId, bundle)      => axios.patch(`v2/freelancer/${freelancerId}/applications/${applicationId}/`, bundle),
  // freelancerApplicationWithdraw: (freelancerId, applicationId, bundle)    => axios.put(`v2/freelancer/${freelancerId}/applications/${applicationId}/withdraw/`, bundle),
  // freelancerHireDetail: (freelancerId, hireId)                            => axios.get(`v2/freelancer/${freelancerId}/hires/${hireId}/`),
  freelancerHireList: (freelancerId, params)                              => axios.get<any, GetFreelancerHireList>(`v2/freelancer/${freelancerId}/hires/`, { params }),
  // freelancerInterviewCancel: (freelancerId, interviewId, bundle)          => axios.put(`v2/freelancer/${freelancerId}/interviews/${interviewId}/cancel/`, bundle),
  // freelancerInterviewDetail: (freelancerId, interviewId)                  => axios.get(`v2/freelancer/${freelancerId}/interviews/${interviewId}/`),
  freelancerInterviewList: (freelancerId, params)                         => axios.get<any, GetFreelancerInterviewList>(`v2/freelancer/${freelancerId}/interviews/`, { params }),
  // freelancerInterviewReschedule: (freelancerId, interviewId, bundle)      => axios.put(`v2/freelancer/${freelancerId}/interviews/${interviewId}/reschedule/`, bundle),
  freelancerInterviewSchedule: (freelancerId, interviewId, bundle)        => axios.put(`v2/freelancer/${freelancerId}/interviews/${interviewId}/schedule/`, bundle),
  // freelancerInterviewUnschedule: (freelancerId, interviewId)              => axios.put(`v2/freelancer/${freelancerId}/interviews/${interviewId}/unschedule/`),
  // freelancerPresentationDetail: (freelancerId, presentationId)            => axios.get(`v2/freelancer/${freelancerId}/presentations/${presentationId}/`),
  freelancerPresentationList: (freelancerId, params)                      => axios.get<any, GetFreelancerPresentationList>(`v2/freelancer/${freelancerId}/presentations/`, { params }),
  // freelancerPresentationWithdraw: (freelancerId, presentationId, bundle)  => axios.put(`v2/freelancer/${freelancerId}/presentations/${presentationId}/withdraw/`, bundle),

  freelancerInvitesList(freelancerId, params) {
    return axios.get(`v2/freelancer/${freelancerId}/invites`, { params })
  },
  freelancerInvitesDetail(freelancerId, inviteId) {
    return axios.get(`v2/freelancer/${freelancerId}/invites/${inviteId}/`)
  },
  freelancerInvitesUpdate(freelancerId, inviteId, payload) {
    return axios.put(`v2/freelancer/${freelancerId}/invites/${inviteId}/`, payload)
  },
  freelancerMissionInterviewList(freelancerId, params) {
    return axios.get(`v2/freelancer/${freelancerId}/mission_interviews`, { params })
  },
  freelancerMissionInterviewUpdate(freelancerId, interviewId, payload) {
    return axios.put(`v2/freelancer/${freelancerId}/mission_interviews/${interviewId}/`, payload)
  },
  // companyDealDetail: (companyId, dealId)                                  => axios.get(`v2/company/${companyId}/deals/${dealId}/`),
  companyDealList: (companyId, params)                                    => axios.get(`v2/company/${companyId}/deals/`, { params }),
  // companyInterviewCancel: (companyId, interviewId, bundle)                => axios.put(`v2/company/${companyId}/interviews/${interviewId}/cancel/`, bundle),
  // companyInterviewDetail: (companyId, interviewId)                        => axios.get(`v2/company/${companyId}/interviews/${interviewId}/`),
  companyInterviewUpdate: (companyId, interviewId, bundle)                   => axios.patch(`v2/company/${companyId}/interviews/${interviewId}/`, bundle),
  companyInterviewList: (companyId, params)                               => axios.get(`v2/company/${companyId}/interviews/`, { params }),
  // companyInterviewReschedule: (companyId, interviewId, bundle)            => axios.put(`v2/company/${companyId}/interviews/${interviewId}/reschedule/`, bundle),
  companyInterviewSchedule: (companyId, interviewId, bundle)              => axios.put(`v2/company/${companyId}/interviews/${interviewId}/schedule/`, bundle),
  // companyInterviewUnschedule: (companyId, interviewId)                    => axios.put(`v2/company/${companyId}/interviews/${interviewId}/unschedule/`),
  companyPresentationDecline: (companyId, dealId, presentationId, bundle) => axios.put(`v2/company/${companyId}/deals/${dealId}/presentations/${presentationId}/decline/`, bundle),
  companyPresentationDetail: (companyId, dealId, presentationId)          => axios.get(`v2/company/${companyId}/deals/${dealId}/presentations/${presentationId}/`),
  companyPresentationList: (companyId, dealId, params)                    => axios.get(`v2/company/${companyId}/deals/${dealId}/presentations/`, { params }),
  // companyPresentationReset: (companyId, dealId, presentationId)           => axios.put(`v2/company/${companyId}/deals/{dealId}/presentations/${presentationId}/reset/`),

  staffApplicationBulkDecline: (jobId, bundle)                               => axios.put(`v2/staff/jobs/${jobId}/applications/decline/`, bundle),
  // staffApplicationDecline: (jobId, applicationId, bundle)                 => axios.put(`v2/staff/jobs/{jobId}/applications/${applicationId}/decline/`, bundle),
  // staffApplicationDetail: (jobId, applicationId)                          => axios.get(`v2/staff/jobs/${jobId}/interviews/${applicationId}/`),
  // staffJobInterviewList: (jobId, params)                                   => axios.get(`v2/staff/jobs/${jobId}/interviews/`, { params }),
  // staffApplicationReset: (jobId, applicationId)                           => axios.put(`v2/staff/jobs/{jobId}/applications/${applicationId}/reset/`),
  // staffDealCreate: (bundle)                                               => axios.post(`v2/staff/deals/`, bundle),
  // staffDealDetail: (dealId)                                               => axios.get(`v2/staff/deals/${dealId}/`),
  // staffDealList: (params)                                                 => axios.get("v2/staff/deals/", { params }),
  // staffDealUpdate: (dealId, bundle)                                       => axios.patch(`v2/staff/deals/${dealId}/`, bundle),
  staffHireCreate: (bundle)                                                  => axios.post("v2/staff/hires/", bundle),
  staffInterviewCancel: (dealId, interviewId, bundle)                        => axios.put(`v2/staff/deals/${dealId}/interviews/${interviewId}/cancel/`, bundle),
  staffInterviewDelete: (dealId, interviewId)                                => axios.delete(`v2/staff/deals/${dealId}/interviews/${interviewId}/`),
  staffInterviewCreate: (dealId, bundle)                                     => axios.post<any, PostStaffInterviewCreate>(`v2/staff/deals/${dealId}/interviews/`, bundle),
  // staffInterviewDetail: (dealId, interviewId)                             => axios.get(`v2/staff/deals/${dealId}/interviews/${interviewId}/`),
  staffDealInterviewList: (dealId, params)                                   => axios.get<any, GetStaffDealInterviewList>(`v2/staff/deals/${dealId}/interviews/`, { params }),
  staffInterviewReschedule: (dealId, interviewId, bundle)                    => axios.put(`v2/staff/deals/${dealId}/interviews/${interviewId}/reschedule/`, bundle),
  // staffInterviewReview: (dealId, interviewId, bundle)                     => axios.put(`v2/staff/deals/${dealId}/interviews/${interviewId}/review/`, bundle),
  staffInterviewSchedule: (dealId, interviewId, bundle)                      => axios.put(`v2/staff/deals/${dealId}/interviews/${interviewId}/schedule/`, bundle),
  staffInterviewUnschedule: (dealId, interviewId)                            => axios.put(`v2/staff/deals/${dealId}/interviews/${interviewId}/unschedule/`),
  staffInterviewUpdate: (dealId, interviewId, bundle)                        => axios.patch(`v2/staff/deals/${dealId}/interviews/${interviewId}/`, bundle),
  staffInterviewReview: (dealId, interviewId, bundle)                        => axios.put(`v2/staff/deals/${dealId}/interviews/${interviewId}/review/`, bundle),
  // staffPresentationBulkDecline: (dealId, bundle)                          => axios.put(`v2/staff/deals/${dealId}/presentations/decline/`, bundle),
  staffPresentationCreate: (dealId, bundle)                                  => axios.post(`v2/staff/deals/${dealId}/presentations/`, bundle),
  // staffPresentationDelete: (dealId, presentationId)                       => axios.delete(`v2/staff/deals/${dealId}/presentations/${presentationId}/`),
  staffPresentationDecline: (dealId, presentationId, bundle)                 => axios.put(`v2/staff/deals/${dealId}/presentations/${presentationId}/decline/`, bundle),
  staffPresentationUpdate: (dealId, presentationId, bundle)                  => axios.patch(`v2/staff/deals/${dealId}/presentations/${presentationId}/`, bundle),
  staffPresentationDetail: (dealId, presentationId)                          => axios.get(`v2/staff/deals/${dealId}/presentations/${presentationId}/`),
  // staffPresentationDecline: (dealId, presentationId, bundle)              => axios.put(`v2/staff/deals/${dealId}/presentations/${presentationId}/decline/`, bundle),
  staffPresentationList: (dealId, params)                                    => axios.get<any, GetStaffPresentationList>(`v2/staff/deals/${dealId}/presentations/`, { params }),
  staffPresentationReset: (dealId, presentationId)                           => axios.put(`v2/staff/deals/${dealId}/presentations/${presentationId}/reset/`),
  // staffPresentationSendCancel: (dealId, presentationId)                   => axios.put(`v2/staff/deals/${dealId}/presentations/${presentationId}/send-cancel/`),
  staffPresentationSendLater: (dealId, presentationId)                       => axios.put(`v2/staff/deals/${dealId}/presentations/${presentationId}/send-later/`),
  staffPresentationSendNow: (dealId, presentationId)                         => axios.put(`v2/staff/deals/${dealId}/presentations/${presentationId}/send-now/`),
  // staffPresentationUpdate: (dealId, presentationId, bundle)               => axios.patch(`v2/staff/deals/${dealId}/presentations/${presentationId}/`, bundle),

  userInterviewDetail: (id)                                                  => axios.get(`v2/user/interviews/${id}/`),
  userInterviewList: (bundle = null)                                         => axios.get("v2/user/interviews/", bundle ? { params: { ...bundle } } : null),
  staffJobApplicationUpdate: (jobId, jobApplicationId, bundle)               => axios.patch(`v2/staff/jobs/${jobId}/applications/${jobApplicationId}/`, bundle),
  staffJobApplicationApprove: (jobId, jobApplicationId)                      => axios.put(`v2/staff/jobs/${jobId}/applications/${jobApplicationId}/approve/`),
  staffJobApplicationDecline: (jobId, jobApplicationId, bundle)              => axios.put(`v2/staff/jobs/${jobId}/applications/${jobApplicationId}/decline/`, bundle),
  staffJobApplicationsList: (jobId, bundle)                                  => axios.get<any, GetStaffJobApplicationsList>(`v2/staff/jobs/${jobId}/applications/`, { params: { ...bundle } }),
}
