export default {
  add: "fa fa-plus",
  admin: "fas fa-bolt",
  alert: "fa fa-exclamation",
  alertRound: "fa fa-exclamation-circle",
  americas: "fas fa-globe-americas",
  angleDown: "fas fa-angle-down",
  angleUp: "fas fa-angle-up",
  apply: "fa fa-hand-point-up",
  archive: "fas fa-archive",
  arrowLeft: "fa fa-long-arrow-left",
  arrowRight: "fa fa-long-arrow-right",
  averageIndicator: "fas fa-triangle",
  back: "fa fa-chevron-left",
  badge: "fas fa-certificate",
  ban: "fas fa-ban",
  bitbucket: "fab fa-bitbucket",
  blog: "fas fa-blog",
  calendar: "fas fa-calendar",
  calendarAlt: "fas fa-calendar-alt",
  calendarCheck: "fas fa-calendar-check",
  calendarDay: "fas fa-calendar-day",
  calendarDown: "fas fa-caret-down",
  calendarGrid: "fas fa-calendar-alt",
  calendarMinus: "fas fa-calendar-minus",
  calendarTimes: "fas fa-calendar-times",
  calendarUp: "fas fa-caret-up",
  camera: "fas fa-video",
  cancel: "fa fa-times",
  candidates: "fas fa-clipboard-user",
  card: "fas fa-image",
  check: "far fa-check",
  checked: "far fa-check-circle",
  clear: "fa fa-times",
  clipboard: "fas fa-clipboard",
  clock: "fas fa-clock",
  close: "fa fa-times",
  cog: "fas fa-cog",
  collapse: "fa fa-chevron-up",
  compass: "fas fa-compass",
  completed: "fa fa-check",
  compressArrowsAlt: "fas fa-compress-arrows-alt",
  contract: "fas fa-handshake",
  copy: "fa fa-copy",
  company: "fa fa-building",
  dashboard: "fa fa-tachometer-fast",
  dashboardJobOpen: "fa fa-lock-open",
  dashboardJobClose: "fa fa-lock",
  dashboardUpcomingDate: "fa fa-calendar-day",
  declineReasons: "fa fa-comment-minus",
  delete: "fa fa-trash",
  down: "fa fa-arrow-down",
  download: "fa fa-download",
  duration: "fas fa-calendar",
  edit: "fa fa-pencil-alt",
  ellipsis: "fas fa-ellipsis-h",
  email: "fa fa-envelope",
  expand: "fa fa-chevron-down",
  empty: "fad fa-empty-set",
  error: "fa fa-times-circle",
  externalLink: "fas fa-external-link-alt",
  fail: "fa fa-times",
  false: "fas fa-times-circle",
  fasttracked: "fa fa-user-plus",
  file: "fas fa-file-alt",
  sidePanel: "fas fa-columns",
  freelancer: "fa fa-user-cowboy",
  gear: "fa fa-gear",
  github: "fab fa-github",
  gitlab: "fab fa-gitlab",
  hat: "fas fa-hat-cowboy",
  hatCowboySide: "fas fa-hat-cowboy-side",
  headset: "fas fa-headset",
  heart: "fas fa-heart",
  hide: "fa fa-eye-slash",
  hires: "fa fa-starship-freighter",
  hourglassHalf: "fas fa-hourglass-half",
  hunter: "far fa-bullseye-arrow",
  hunterBulk: "fa fa-mail-bulk",
  income: "fa fa-hand-holding-usd",
  intercom: "fab fa-intercom",
  interview: "fa fa-alarm-clock",
  interviewGroupList: "fa fa-layer-group",
  interviewComments: "fa fa-comments",
  invoice: "fa fa-file-invoice-dollar",
  info: "fas fa-info-square",
  information: "fas fa-info-circle",
  job: "fa fa-briefcase",
  jobType: "fas fa-id-badge",
  launch: "fas fa-rocket-launch",
  left: "fa fa-arrow-left",
  lightbulb: "fas fa-lightbulb",
  lightBulbOn: "far fa-lightbulb-on",
  link: "far fa-link",
  linkedin: "fab fa-linkedin",
  list: "fas fa-list",
  listOrdered: "fas fa-list-ol",
  lock: "fas fa-lock",
  logout: "fas fa-sign-out-alt",
  mapMarker: "fas fa-map-marker-alt",
  markdown: "fab fa-markdown",
  meetup: "fab fa-meetup",
  member: "fas fa-id-card",
  menu: "fas fa-bars",
  money: "fas fa-money-check-alt",
  next: "fa fa-chevron-right",
  newspaper: "fas fa-newspaper",
  note: "fad fa-sticky-note",
  no: "fa fa-times",
  onboardingInterview: "fas fa-handshake",
  pdf: "fa fa-file-pdf",
  pendingApproval: "fa fa-user-clock",
  peopleList: "fas fa-poll-people",
  pin: "fas fa-thumbtack",
  phone: "fa fa-phone",
  play: "fa fa-play",
  point: "fa fa-hand-point-up",
  portfolio: "fa fa-images",
  potterNext: "far fa-long-arrow-right",
  preview: "fa fa-eye",
  previous: "fa fa-chevron-left",
  private: "fas fa-eye-slash",
  profile: "fas fa-id-badge",
  public: "fas fa-eye",
  question: "fa fa-question-circle",
  rated: "fa fa-star",
  refresh: "far fa-sync-alt",
  remove: "fal fa-times",
  report: "fa fa-user-chart",
  resume: "fa fa-file",
  revert: "fas fa-undo",
  right: "fa fa-arrow-right",
  rocket: "fas fa-rocket",
  screened: "fa fa-user-check",
  search: "fa fa-search",
  selectedRight: "fa fa-chevron-right",
  sliders: "fas fa-sliders-h",
  sort: "fa fa-sort",
  sortDown: "fa fa-sort-down",
  sortUp: "fa fa-sort-up",
  suitcase: "fas fa-suitcase",
  swap: "fas fa-exchange-alt",
  share: "fas fa-share",
  show: "fa fa-eye",
  slack: "fab fa-slack",
  spacer: "fa",
  spinner: "fa fa-spinner-third rotate",
  spinnerNoRotate: "fa fa-spinner-third",
  spotify: "fab fa-spotify",
  stackoverflow: "fab fa-stack-overflow",
  staff: "fas fa-hat-cowboy-side",
  staffApproved: "fa fa-check",
  staffDeclined: "fa fa-times",
  success: "fa fa-check",
  suggestion: "fal fa-circle",
  suggestionComplete: "fal fa-check-circle",
  talentReview: "fa fa-clipboard-list-check",
  telegram: "fab fa-telegram-plane",
  timesCircle: "fas fa-times-circle",
  true: "fas fa-check-circle",
  twitter: "fab fa-twitter",
  unchecked: "far fa-times-circle",
  unselected: "far fa-square-full",
  up: "fa fa-arrow-up",
  upload: "fas fa-upload",
  userMinus: "fas fa-user-minus",
  wand: "fas fa-magic",
  warning: "fa fa-warning",
  website: "fa fa-globe-americas",
  workstyle: "fa fa-flask",
  world: "fas fa-globe",
  yes: "fa fa-check",
  youtube: "fab fa-youtube",
  companyTalentRequestPerson: "fad fa-user-cowboy",
} as const
