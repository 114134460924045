import { useUserStore } from "@/store/user"

// typescript declarations for intercom stuff - keeping here for the time being for ease of reading
type IntercomFunction = {
  (method: string)
  (method: string, object: any)
}

interface IntercomSettings {
  app_id: string
  code_test_failed: boolean
  code_test_passed: boolean
  completeness_score: number
  count_applications: number
  count_experiences: number
  count_projects: number
  count_skills: number
  count_top_skills: number
  country: string
  freelancer_updated_at: number
  has_passed_screening: boolean
  hired: number
  is_engaged: boolean
  is_preferred_skill_match: boolean
  is_staff_approved: boolean
  profile_url: string
  rating: string
  screening_score: number
  user_hash: string
  user_id: number
  year_experience_started: number
}

declare global {
  interface Window {
    Intercom: IntercomFunction
    intercomSettings: IntercomSettings
  }
}

const Intercom = window.Intercom || (() => {})

export default {
  shutdown() {
    Intercom("shutdown")
  },

  syncProfile() {
    const userStore = useUserStore()
    const user = userStore.profile

    this.update({
      name: user.name,
      email: user.email,
      phone: user.phone,
      country: user.country,
      has_company: user.hasCompany,
      is_freelancer: user.isFreelancer,
      is_staff: user.isStaff,
      marketing_attribution: user.marketingAttribution,
    })
  },

  update(data: object) {
    Intercom("update", data)
  },
}
