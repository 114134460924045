import { onUnmounted } from "vue"

export const useDocumentEventListener = (event: string, listener: Function) => {
  document.addEventListener(event, listener)

  onUnmounted(() => {
    document.removeEventListener(event, listener)
  })
}

export const useWindowEventListener = (event: string, listener: Function) => {
  window.addEventListener(event, listener)

  onUnmounted(() => {
    window.removeEventListener(event, listener)
  })
}
