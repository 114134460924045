<template>
  <div class="w-full grow">
    <header
      v-if="heading || $slots.heading || $slots.actions"
      :class="classes.header || 'flex justify-between items-end mx-4 md:mx-0 mb-4 md:mb-8'"
    >
      <div v-if="heading || $slots.heading" :class="classes.heading">
        <slot v-if="$slots.heading" name="heading"/>
        <h1 v-else class="jumbo" v-text="heading"/>
        <slot name="heading.append"/>
      </div>

      <transition name="fade">
        <div v-if="$slots.actions" :class="classes.actions">
          <slot name="actions"/>
        </div>
      </transition>
    </header>

    <div v-if="$slots.tabs" class="md:flex justify-between items-end">
      <div class="flex overflow-x-auto" :class="classes.tabs" role="tablist">
        <slot name="tabs"/>
      </div>
    </div>
    <transition
      enter-active-class="transform ease-out duration-500 transition"
      enter-class="translate-y-2 opacity-0 sm:translate-y-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition ease-in duration-100"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
      mode="out-in"
    >
      <div v-if="loading" key="loader">
        <slot v-if="$slots.loading" name="loading"/>
        <component :is="skeletons[skeleton] || skeleton" v-else-if="skeleton"/>
        <loader v-else/>
      </div>

      <div v-else key="content">
        <slot/>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import ListLoader from "@/components/skeletons/ListLoader.vue"
import Loader from "@/components/Loader.vue"
import type { ClassesProp } from "@/models/ClassesProp"

withDefaults(defineProps<{
  heading?: string
  classes?: ClassesProp
  loading?: boolean | number
  skeleton?: any
  tag?: string
}>(), {
  classes: () => ({} as ClassesProp),
  tag: "div",
})

const skeletons = {
  list: ListLoader,
}
</script>
