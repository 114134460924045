/* eslint-disable import/no-unused-modules, import/namespace */

import * as Sentry from "@sentry/vue"
import { initialize } from "launchdarkly-js-client-sdk"
import { readonly, ref } from "vue"
import { settings } from "@/config"
import type { LDClient, LDContext, LDFlagValue } from "launchdarkly-js-client-sdk"

const anonymousContext = {
  anonymous: true,
  kind: "user",
} as const

const clientId = settings.LAUNCHDARKLY_CLIENT_ID ?? null

const ldReadyRef = ref(false)
const ldReady = readonly(ldReadyRef)

let ldClient: LDClient = null

function initLaunchDarkly() {
  if (clientId) {
    try {
      ldClient = initialize(clientId, anonymousContext, { streaming: true })

      ldClient.on("ready", () => {
        ldReadyRef.value = true
      })
    } catch (e) {
      Sentry.captureException(e)
    }
  }
}

// NOTE: This is mainly used for testing purposes.
async function closeClient() {
  if (ldClient) {
    await ldClient.close()

    ldClient = null
  }
}

async function setContext(context: LDContext) {
  if (ldClient) {
    await ldClient.identify(context)
  }
}

function getFeatureFlag<T>(key: string, defaultValue: T): LDFlagValue {
  if (ldClient) {
    return ldClient.variation(key, defaultValue)
  }

  return defaultValue as LDFlagValue
}

export {
  anonymousContext,
  closeClient,
  getFeatureFlag,
  initLaunchDarkly,
  ldClient,
  ldReady,
  setContext,
}
