/* eslint-disable import/no-unused-modules */
import {
  email,
  helpers,
  maxLength,
  maxValue,
  minLength,
  minValue,
  numeric,
  required,
  sameAs,
  url,
} from "@vuelidate/validators"

import { trim } from "lodash-es"

const minValueFromCommas = (param) => (value) => !value || parseInt(value.replaceAll(",", "")) >= parseInt(helpers.unwrap(param))
const titlecase = (param) => (value) => value.length < param || !!trim(value).match(/^[A-Z].*[a-z]/)
const isTrue = (value) => value === true

const withMessage = helpers.withMessage
const req = helpers.req

// eslint-disable-next-line import/no-unused-modules
export {
  required,
  email,
  url,
  minValue,
  maxValue,
  minLength,
  maxLength,
  numeric,
  sameAs,
  minValueFromCommas,
  titlecase,
  withMessage,
  req,
  isTrue,
}
