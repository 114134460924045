import "@/scss/index.scss"
import "@/validation"
import "reflect-metadata"
import App from "@/components/App.vue"
import { createApp } from "vue"
import { createPinia } from "pinia"
import { createSentryPiniaPlugin } from "@sentry/vue"
import { initLaunchDarkly } from "@/plugins/launchdarkly"
import { initSentry } from "@/plugins/sentry"
import { router } from "@/router"

const app = createApp(App)

initLaunchDarkly()
initSentry(app, router)

const pinia = createPinia()
pinia.use(createSentryPiniaPlugin())
app.use(pinia)

import GButton from "@/components/buttons/GButton.vue"
app.component("GButton", GButton)

import GClose from "@/components/buttons/GClose.vue"
app.component("GClose", GClose)

import Icon from "@/components/Icon.vue"
app.component("Icon", Icon)

import Page from "@/components/Page.vue"
app.component("Page", Page)

import "floating-vue/dist/style.css"
import { vTooltip } from "floating-vue"
app.directive("tooltip", vTooltip)

app.directive("focus", {
  mounted: (el, binding) => binding.value && el?.focus(),
})

import VueClickAway from "vue3-click-away"
app.use(VueClickAway)

import { MotionPlugin } from "@vueuse/motion"
app.use(MotionPlugin)

if (import.meta.env.DEV) {
  import("@/plugins/dump")
    .then(DumpPlugin => {
      app.use(DumpPlugin.default)
    })
}

app.use(router)

router.isReady().then(() => {
  app.mount("#app")
})

