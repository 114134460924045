import { getFeatureFlag, ldClient } from "@/plugins/launchdarkly"
import { onBeforeUnmount, readonly, ref } from "vue"
import type { LDFlagValue } from "launchdarkly-js-client-sdk"
import type { Ref } from "vue"

export function useFeatureFlag<T>(key: string, defaultValue: T) {
  const flagValue = ref(defaultValue)
  flagValue.value = getFeatureFlag(key, defaultValue)

  function updateFlagValue(newValue: LDFlagValue) {
    flagValue.value = newValue
  }

  if (ldClient) {
    ldClient.on(`change:${key}`, updateFlagValue)

    onBeforeUnmount(() => {
      ldClient.off(`change:${key}`, updateFlagValue)
    })
  }

  return readonly(flagValue) as Readonly<Ref<T>>
}
