// Generated by Django
export abstract class BaseFreelancer {
  public static readonly BANNED = 0

  public static readonly NO_FACTOR = 1

  public static readonly LEGACY = 10

  public static readonly STATUS_CHOICES = [
    [0, "Shadowbanned"],
    [1, "No Factor"],
    [10, "Legacy - Vetted Pre-App"],
  ] as const

  public static readonly APPLICANT = 0

  public static readonly INITIATE = 1

  public static readonly MEMBER = 2

  public static readonly MASTER = 3

  public static readonly RATING_UNVERIFIED = 0

  public static readonly RATING_UNRANKED = 10

  public static readonly RATING_UNHIRABLE = 20

  public static readonly RATING_AVERAGE = 30

  public static readonly RATING_HIGH = 40

  public static readonly RATING_EXCEPTIONAL = 50

  public static readonly RATING_CHOICES = [
    [50, "Excellent"],
    [40, "High"],
    [30, "Adequate"],
    [20, "Not a good fit"],
    [10, "Unratable"],
    [0, "Not Rated"],
  ] as const

  public static readonly CODEBASE_NEW = "NEW"

  public static readonly CODEBASE_EXISTING = "EXISTING"

  public static readonly CODEBASE_BOTH = "BOTH"

  public static readonly CODEBASE_PREFERENCE_CHOICES = [
    ["NEW", "New projects"],
    ["EXISTING", "Existing projects"],
    ["BOTH", "Both"],
  ] as const

  public static readonly COMMUNICATION_STRONG = "STRONG"

  public static readonly COMMUNICATION_ADEQUATE = "ADEQUATE"

  public static readonly COMMUNICATION_WEAK = "WEAK"

  public static readonly COMMUNICATION_PROFICIENCY_CHOICES = [
    ["STRONG", "Strong"],
    ["ADEQUATE", "Adequate"],
    ["WEAK", "Weak"],
  ] as const

  public static readonly DEGRADE_WARNING_ONE_DAYS = 30

  public static readonly DEGRADE_WARNING_TWO_DAYS = 37

  public static readonly DEGRADE_WARNING_THREE_DAYS = 45

  public static readonly ENGLISH_BASIC = "BASIC"

  public static readonly ENGLISH_FLUENT = "FLUENT"

  public static readonly ENGLISH_NATIVE = "NATIVE"

  public static readonly ENGLISH_PROFICIENCY_CHOICES = [
    ["BASIC", "Basic"],
    ["FLUENT", "Fluent"],
    ["NATIVE", "Native"],
  ] as const

  public static readonly PING_FREQUENCY_CHOICES = [
    ["1_WEEK", "1 week"],
    ["2_WEEKS", "2 weeks"],
    ["1_MONTH", "1 month"],
    ["2_MONTHS", "2 months"],
  ] as const

  public static readonly SEEKING_ACTIVE = 1

  public static readonly SEEKING_PASSIVE = 2

  public static readonly SEEKING_NOT = 0

  public static readonly SEEKING_ACTIVE_LABEL = "Ready to interview"

  public static readonly SEEKING_PASSIVE_LABEL = "Open to offers"

  public static readonly SEEKING_NOT_LABEL = "Unavailable for jobs"

  public static readonly SEEKING_STATUS_CHOICES = [
    [1, "Ready to interview"],
    [2, "Open to offers"],
    [0, "Unavailable for jobs"],
  ] as const

  public static readonly SEEKING_STATUS_DESCRIPTION_CHOICES = [
    [1, "You're actively looking for new remote work and are ready to interview in the next 30 days."],
    [2, "You're not actively looking for new remote work, but you are interested in hearing about new opportunities."],
    [0, "You're not looking for new work at the moment."],
  ] as const

  public static readonly WORK_HOUR_CHOICES = [
    [1, "1 am"],
    [2, "2 am"],
    [3, "3 am"],
    [4, "4 am"],
    [5, "5 am"],
    [6, "6 am"],
    [7, "7 am"],
    [8, "8 am"],
    [9, "9 am"],
    [10, "10 am"],
    [11, "11 am"],
    [12, "12 pm"],
    [13, "1 pm"],
    [14, "2 pm"],
    [15, "3 pm"],
    [16, "4 pm"],
    [17, "5 pm"],
    [18, "6 pm"],
    [19, "7 pm"],
    [20, "8 pm"],
    [21, "9 pm"],
    [22, "10 pm"],
    [23, "11 pm"],
    [24, "12 am"],
  ] as const

  public static readonly YEAR_CHOICES = [
    [0, "Less Than a Year"],
    [1, "1-3 Years"],
    [2, "4-9 Years"],
    [3, "10-14 Years"],
    [4, "15-19 Years"],
    [5, "20+ Years"],
  ] as const

  public static readonly BALROG_MAX_THRESHOLD = 50

  public static readonly BALROG_DAYS = 365

  public static readonly FASTTRACK_MIN_THRESHOLD = 60

  public static readonly VIDEO_FILE_MAX_SIZE = 157286400

  public static readonly CAPACITY_CHOICES = [
    [10, "10 hrs/wk"],
    [20, "20 hrs/wk"],
    [30, "30 hrs/wk"],
    [40, "40 hrs/wk"],
  ] as const
}
