<template>
  <div v-if="staging" data-test="staging-modal-banner" class="bg-blue-800 p-2 text-center fixed top-0 right-16 z-[1000]">
    STAGING
  </div>
  <div v-if="staging" data-test="staging-banner" class="bg-blue-800 p-2 text-center z-[1000]">
    {{ staging }}
  </div>

  <router-view/>
</template>

<script setup lang="ts">
const staging = (window as any).GuildSettings?.STAGING
</script>
