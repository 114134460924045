<template>
  <div class="flex h-full print:bg-white">
    <div id="siteWrapper" class="flex flex-col justify-items-center w-full" :data-test-page="routeName">
      <header class="z-50 print:hidden">
        <slot name="header-content"/>
      </header>

      <main class="flex flex-col grow py-4 print:bg-white print:py-0">
        <slot name="alerts"/>
        <slot/>
      </main>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useRoute } from "vue-router"

const route = useRoute()

const routeName = computed(() => {
  return route.name ?? ""
})
</script>
