import * as Sentry from "@sentry/vue"
import { settings } from "@/config"

export function initSentry(app, router) {
  if (settings.SENTRY_ENABLED) {
    Sentry.init({
      app,
      dsn: settings.SENTRY_VUE_DSN,
      release: settings.RELEASE,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.captureConsoleIntegration({
          levels: ["error"],
        }),
      ],
      tracePropagationTargets: ["localhost", "app.gun.io", "gun.io", /^\//],
      tracesSampleRate: 0.2,
      // Capture Replay for 10% of all sessions, plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    })
  }
}
