import * as Sentry from "@sentry/vue"
import { ref } from "vue"
import { uniqueId } from "lodash-es"

interface NotificationOptions {
  type?: "error" | "success" | "info"
  duration?: number
  noAutoDismiss?: boolean
  secondaryMessage?: string
}

interface Notification {
  id: string
  message: string
  show: boolean
  options: NotificationOptions
}

const notifications = ref([] as Notification[])

export const useNotify = () => {
  const notify = (message: string, options?: NotificationOptions) => {
    const notification = ref({
      id: uniqueId(),
      message,
      options: {
        type: "success" as "success" | "error" | "info", // this seems to be a vue 2.7 quirk.  Shouldn't need the as
        duration: 3000,
        ...(options ?? {}),
      },
      show: true,
    })
    notifications.value.push(notification.value)

    if (notification.value.options.duration >= 0) {
      setTimeout(() => {
        notification.value.show = false
      }, notification.value.options.duration)
    }
  }

  const notifyError = (message, error: any, options?: NotificationOptions) => {
    try {
      // eslint-disable-next-line import/namespace
      Sentry.captureException(error)
    } catch (e) {
      console.error("Could not capture for sentry")
    }

    notify(message, {
      type: "error",
      secondaryMessage: error?.response?.data?.message,
      ...options,
    })
  }

  const clearAll = () => {
    notifications.value = []
  }

  return {
    clearAll,
    notify,
    notifyError,

    notifications,
  }
}
