// Generated by Django
export abstract class BaseChore {
  public static readonly DUE_AFTER_DAYS = 2

  public static readonly INTERVIEW_REQUEST = "INTERVIEW_REQUEST"

  public static readonly MISCELLANEOUS = "MISCELLANEOUS"

  public static readonly SEND_FEEDBACK = "FEEDBACK"

  public static readonly CATEGORY_CHOICES = [
    ["INTERVIEW_REQUEST", "Interview request"],
    ["FEEDBACK", "Send feedback"],
    ["MISCELLANEOUS", "Miscellaneous"],
  ] as const

  public static readonly STATUS_TODO = "TODO"

  public static readonly STATUS_IN_PROGRESS = "IN_PROGRESS"

  public static readonly STATUS_DONE = "DONE"

  public static readonly STATUS_CHOICES = [
    ["TODO", "Todo"],
    ["IN_PROGRESS", "In progress"],
    ["DONE", "Done"],
  ] as const
}
