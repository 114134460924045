import * as Sentry from "@sentry/browser"
import axiosPackage from "axios"
import qs from "qs"
import { settings } from "@/config"
import { some } from "lodash-es"
import { useUserStore } from "@/store/user"

// eslint-disable-next-line import/no-unused-modules
export const paramsSerializer = (params) => {
  return qs.stringify(params, { arrayFormat: "repeat", skipNulls: true })
}

const axios = axiosPackage.create({
  baseURL: "/api/",
  extractData: true,
  paramsSerializer,
  xsrfHeaderName: "X-CSRFToken",
  xsrfCookieName: "csrftoken",
})

axios.interceptors.request.use(request => {
  if (some(request.data, item => item instanceof Blob || item instanceof File)) {
    request.headers["Content-Type"] = "multipart/form-data"

    const form = new FormData()

    for (const [key, value] of Object.entries(request.data)) {
      form.append(key, value, value?.name)
    }

    request.data = form
  }

  return request
})

axios.interceptors.response.use(
  response => {
    const version = response.headers && response.headers["x-guild-version"]

    /* c8 ignore next 4 */
    if (!settings.DEBUG && settings.RELEASE !== version) {
      const userStore = useUserStore()
      userStore.showNewVersion(version)
    }

    if (response.config.extractData) {
      return response.data
    }

    return response
  },
  error => {
    try {
      // eslint-disable-next-line import/namespace
      Sentry.withScope((scope) => {
        scope.setExtra("msg", error?.response?.data?.message)
        // eslint-disable-next-line import/namespace
        Sentry.captureException(error)
      })
    } catch (e) {
      /* c8 ignore next 2 */
      // swallow
    }

    return Promise.reject(error)
  },
)

export default axios
