<template>
  <div
    :id="menuItemId"
    class="bg-base border-black z-50 relative px-4 font-medium text-white cursor-pointer ring-inset select-none group"
    :aria-controls="dropdownId"
    :aria-expanded="active"
    :aria-haspopup="true"
    :aria-label="text"
    :data-test="dataId"
    tabindex="0"
    @click="emit('show')"
    @mouseover="emit('show')"
    @mouseleave="emit('hide')"
    @keyup.enter="emit('toggle')"
  >
    <div class="flex h-12 pt-0.5 items-center shrink-0">
      <slot/>

      <icon
        name="expand"
        class="mt-1 ml-2 group-hover:text-green"
        :class="{ 'text-green': hasActiveChild }"
        size="xs"
      />
    </div>

    <div
      :id="dropdownId"
      class="nav-dropdown-list flex-col min-w-48 select-none whitespace-nowrap"
      :aria-labelledby="menuItemId"
      :aria-hidden="!active"
      :class="{ flex: active, hidden: !active }"
      @mouseleave="emit('hide')"
    >
      <nav-item
        v-for="item in items"
        :key="item.text"
        class="border-2 border-b-0 last:border-b-2"
        :href="item.href"
        :to="item.to"
      >
        <icon :name="item.icon" class="w-5 mr-4" fixed-width/>
        {{ item.text }}
      </nav-item>
    </div>
  </div>
</template>

<script setup lang="ts">
import NavItem from "@/components/NavItem.vue"
import { computed } from "vue"
import { useRoute } from "vue-router"

const props = defineProps<{
  id: string
  items: any[]
  text?: string
  active?: boolean
}>()

const emit = defineEmits(["show", "hide", "toggle"])

const route = useRoute()

const dataId = `${props.id}-nav`
const dropdownId = `nav-dropdown-${props.id}`
const menuItemId = `nav-menu-item-${props.id}`

const hasActiveChild = computed(() => {
  return !!(route.name && props.items.some(val => val.to?.name === route.name))
})
</script>

<style lang="scss" scoped>
  .nav-dropdown-list {
    position: absolute;
    top: 48px;
    left: -2px;
  }
</style>
