<template>
  <span
    v-show="show"
    :style="{ height, width: computedWidth }"
    class="skeleton-loader bg-gray-700 inline-block relative align-middle cursor-progress overflow-hidden"
  />
</template>

<script setup lang="ts">
import { computed, ref } from "vue"

const props = withDefaults(defineProps<{
  maxWidth?: number
  minWidth?: number
  height?: string
  delay?: number
  width?: string
}>(), {
  maxWidth: 100,
  minWidth: 80,
  height: "1em",
  delay: 0,
})

const show = ref(false)

const computedWidth = computed(() => {
  // Either use the given fixed width or
  // a random width between the given min
  // and max values.
  return props.width || `${Math.floor((Math.random() * (props.maxWidth - props.minWidth)) + props.minWidth)}%`
})

setTimeout(() => {
  show.value = true
}, props.delay)

</script>

<style lang="scss" scoped>
  .skeleton-loader {
    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(
        90deg,
        rgba(#030f1c, 0) 0,
        rgba(#030f1c, 0.1) 20%,
        rgba(#030f1c, 0.4) 60%,
        rgba(#030f1c, 0)
      );
      transform: translateX(-100%);
      animation: shimmer 3s infinite;
    }
  }
</style>
