import Intercom from "@/intercom"

import routes from "@/router/routes"
import { createRouter, createWebHistory } from "vue-router"
import { getFeatureFlag } from "@/plugins/launchdarkly"
import { redirectUser } from "@/router/guards"
import { useUserStore } from "@/store/user"

export const router = createRouter({
  history: createWebHistory(),
  routes: routes as any,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
      }
    }

    if (savedPosition) {
      return savedPosition
    }

    if (to.path != from.path) {
      return { x: 0, y: 0 }
    }
  },
})

const redirectOrContinue = (to, next, redirect, params = null) => {
  if (to.name !== redirect) {
    params = params || {}
    redirect = { ...{ name: redirect }, ...params }
    next(redirect)
  } else {
    next()
  }
}

router.beforeEach(async (to, from, next) => {
  // NOTE: This ensures we have up-to-date user data and
  // feature flags for determining route permissions.
  const userStore = useUserStore()
  await userStore.userGet()

  // TODO: Do we really need this?
  document.body.setAttribute("tabindex", "-1")
  document.body.focus()
  document.body.removeAttribute("tabindex")

  if (to.meta?.featureFlag) {
    const flagName = to.meta.featureFlag as string
    const flagValue = getFeatureFlag(flagName, false)

    // NOTE: If the flagValue is false, we assume that
    // the user does not have access to this route.
    if (flagValue === false) {
      return next({ path: "/" })
    }
  }

  if (userStore.isAuthenticated) {
    const { freelancer, isFreelancer, isStaff, company } = userStore.profile

    const redirectRouteName = redirectUser(to, userStore)

    if (redirectRouteName) {
      return next({
        name: redirectRouteName,
        params: {
          companyId: userStore.myCompanyId,
        },
      },
      )
    }

    if (to.matched.some(route => route.name === "login" || route.name === "sign-up")) {
      // redirect auth screen when logged in
      next({ name: "redirect" })
    } else if (to.matched.some(route => route.meta.staff) && !isStaff) {
      // send non-staffers to dashboard
      next({ name: "redirect" })
    } else if (to.matched.some(route => route.meta.freelancer) && !isStaff && !isFreelancer) {
      // send non-freelancers to dashboard
      next({ name: "redirect" })
    } else if (to.matched.some(route => route.meta.company) && !isStaff && !company) {
      // send non-companies to dashboard
      next({ name: "redirect" })
    } else if (to.matched.some(route => route.name === "auth-jobs-page")) {
      if ( isFreelancer && (freelancer?.isStaffApproved === null && freelancer?.completenessScore < 100) ) {
        next({ name: "redirect" })
      } else if (freelancer?.isStaffApproved === false) {
        redirectOrContinue(to, next, "freelancer-not-approved" )
      } else {
        next()
      }
    } else if (company != null) {
      // an old concept, but if company has declined they can't go through...could remove maybe in the future?  But for now, any one who has declined, keep showing what they have always seen
      if (company.isOnboardingCompleted && company.hasDeclined) {
        redirectOrContinue(to, next, "company-balrog")
      } else {
        next()
      }
    } else if (isFreelancer) {
      if (freelancer.isStaffApproved === false ) {
        redirectOrContinue(to, next, "freelancer-not-approved" )
      } else if (freelancer.hasPassedScreening === null) {
        redirectOrContinue(to, next, "join-us" )
      } else if (freelancer.hasPassedScreening === false) {
        if (freelancer.isBalrogified) {
          redirectOrContinue(to, next, "freelancer-offer-not-extended")
        } else {
          redirectOrContinue(to, next, "freelancer-pending-offer-review")
        }
      } else if (!isStaff) {
        if (freelancer.isSeekingSetRecently === false) {
          redirectOrContinue(to, next, "availability-wall", { query: { next: to.fullPath } })
        } else {
          next()
        }
      } else {
        next()
      }
    } else {
      // all checks passed, move along
      next()
    }
  } else if (to.matched.some(route => route.meta.public)) {
    // public - next
    next()
  } else {
    // unauthed - log in
    next({
      name: "login",
      query: {
        next: to.fullPath,
      },
    })
  }
})

router.afterEach((to, from) => {
  if (to.query.gclid && to.query.gclid !== localStorage.getItem("gclid")) {
    localStorage.setItem("gclid", to.query.gclid)
  }

  // I don't want the double hit when coming to the app initially.  update is taken care of in applicationLayout.vue
  if (from?.name === null) {
    return
  }

  // trying here for now rather then a watch on the $route in App.vue
  Intercom.update(null)
})
