<template>
  <i :class="[icon, classes]" v-bind="meta"/>
</template>

<script setup lang="ts">
import icons from "@/icons"
import { camelCase } from "lodash-es"
import { computed } from "vue"

const props = defineProps<{
  name: keyof typeof icons
  color?: string
  size?: string
  ariaLabel?: string
  fixedWidth?: boolean
  loading?: boolean
}>()

const classes = computed(() => {
  return {
    "fa-fw": props.fixedWidth,
    "inline-block": true,
    [`text-${props.color}`]: !!props.color,
    [`text-${props.size}`]: !!props.size,
  }
})

const icon = computed(() => {
  return props.loading ? icons.spinner : icons[camelCase(props.name)] || props.name
})

const meta = computed(() => {
  return {
    "aria-hidden": !props.ariaLabel,
    "aria-label": props.ariaLabel,
  }
})
</script>
