<template>
  <component
    :is="is"
    ref="rootElement"
    :aria-disabled="disabled"
    :aria-label="active && label ? `${label} (current)` : label"
    :aria-selected="active"
    :class="['btn', variant, link || [{ icon, thin }, size], { active, disabled, loading }]"
    :title="title"
    v-bind="{ 'a': { href }, 'button': { disabled, type }, 'router-link': { to } }[is]"
  >
    <slot v-if="!icon"/>
    <icon
      v-if="loading"
      fixed-width
      :name="spinner"
      :class="{ [link ? 'ml-1' : 'ml-3']: !icon }"
      :size="size"
    />
    <icon v-else-if="icon" fixed-width :name="icon" :size="size"/>
    <span v-if="active && !label" class="sr-only">(current)</span>
  </component>
</template>

<script setup lang="ts">
import Icon from "@/components/Icon.vue"
import { computed, ref } from "vue"

const rootElement = ref(null)

defineExpose({
  rootElement,
})

const props = withDefaults(defineProps<{
  type?: string
  variant?: string
  size?: string
  active?: boolean
  ariaLabel?: string
  disabled?: boolean
  loading?: boolean | number
  href?: string
  icon?: string
  spinner?: string
  thin?: boolean
  title?: string
  to?: string | object
}>(), {
  size: null,
  spinner: "spinner",
  type: "button",
  variant: "primary",
})

const active = computed(() => {
  return props.active || null
})

const anchor = computed(() => {
  return !!(props.href || props.to)
})

const disabled = computed(() => {
  return props.disabled || props.loading ? true : null
})

const is = computed(() => {
  if (anchor.value && !disabled.value) {
    return props.href ? "a" : "router-link"
  }

  return "button"
})

const label = computed(() => {
  return props.ariaLabel || props.title
})

const link = computed(() => {
  return props.variant === "action" || props.variant === "link"
})

const size = computed(() => {
  return props.size ?? (props.icon ? "md" : "lg")
})
</script>
