<template>
  <base-layout class="application-layout print:bg-white">
    <template #alerts>
      <alert v-if="emailNotVerified" variant="secondary" class="text-center container">
        Your email address needs to be verified.
        <span v-if="!emailSent">Check your email, or</span>
        <a
          v-if="!emailSent"
          href="#"
          class="text-white underline hover:text-gray-200"
          @click.prevent="resendEmailConfirmation"
        >
          Resend it now
        </a>
        <span v-else><i class="fa fa-paper-plane text-gray-800 ml-4"/> Email sent!</span>
      </alert>
      <div v-if="cannotDeliverEmail" class="container mb-6">
        <div class="bg-orange/75 px-4 py-2">
          <div class="font-semibold text-lg">
            <icon
              name="warning"
              fixed-width
              color="white"
              class="mr-2"
              size="lg"
              title="Email deliverability issues detected"
            />
            We are unable to deliver emails to your email address at {{ emailAddress }}.
          </div>  In order to receive communication from us, please contact <a class="text-white font-semibold hover:text-white" href="mailto:talent@gun.io">talent@gun.io</a> to help us troubleshoot.
        </div>
      </div>
    </template>

    <template #header-content>
      <app-nav/>

      <div class="justify-between hidden md:flex print:hidden">
        <div>
          <!-- multiple company goes here -->
        </div>
        <div class="flex items-center text-sm">
          <g-button
            v-if="userStore.profile.isFreelancer"
            class="
              w-full px-4 py-2 font-medium text-white ring-inset
              border-b-2 border-l-2 border-black
              hover:text-green hover:no-underline
              focus-visible:text-green focus-visible:no-underline
            "
            title="Current seeking status"
            variant="link"
            @click="highlightProfileStatus"
          >
            Status:
            <span
              v-if="userStore.profile.freelancer.seekingStatus != null"
              :class="[
                'font-normal group-hover:text-green group-focus-visible:text-green',
                { 'text-orange': userStore.profile.freelancer.seekingStatus === Freelancer.SEEKING_NOT && !userStore.isStaff },
              ]"
              v-text="t('freelancer.seekingStatus')[userStore.profile.freelancer.seekingStatus] || NOT_SET"
            />
          </g-button>

          <div class="border-b-2 border-x-2 border-black">
            <div class="flex min-w-64 divide-x-2 divide-black text-center">
              <router-link
                class="
                  w-full px-4 py-2 font-medium text-white ring-inset
                  hover:bg-green hover:text-black hover:no-underline
                  focus-visible:text-black focus-visible:bg-green
                "
                data-test="settings-link"
                :to="{ name: 'settings-page' }"
              >
                Settings
              </router-link>

              <a
                href="https://gun.io/faq"
                target="_blank"
                class="
                  w-full px-4 py-2 font-medium text-white ring-inset
                  hover:bg-green hover:text-black hover:no-underline
                  focus-visible:text-black focus-visible:bg-green
                "
                data-test="help-link"
                v-text="'Help'"
              />

              <g-button
                class="
                  w-full px-4 py-2 font-medium text-white ring-inset
                  hover:bg-green hover:text-black hover:no-underline
                  focus-visible:text-black focus-visible:bg-green
                "
                data-test="logout-link"
                variant="link"
                @click="logout"
              >
                Logout
              </g-button>
            </div>
          </div>
        </div>
      </div>
    </template>

    <router-view :key="route.path"/>

    <footer class="flex space-x-10 px-20 py-8 items-center justify-center print:hidden">
      <a v-if="userStore.isStaff" href="/emails/" class="text-gray-500 hover:text-blue">
        Emails
      </a>
      <router-link v-if="userStore.isStaff" class="text-gray-500 hover:text-blue" :to="{ name: 'styleguide' }">
        Styleguide
      </router-link>
      <a href="https://gun.io/user-agreement/" class="text-gray-500 hover:text-blue" target="_blank">
        Terms
      </a>
      <a href="https://gun.io/privacy-policy/" class="text-gray-500 hover:text-blue" target="_blank">
        Privacy
      </a>
      <a class="hidden md:inline text-gray-500 hover:text-blue" href="javascript:var KICKASS_SITE_KEY='5e9890678b9fe0.04598507';var el = document.createElement('script'); el.src = '//hi.kickassapp.com/kickass-mysite.js';document.body.appendChild(el);void(0);">
        Destroy
      </a>
    </footer>
    <transition name="fade">
      <div v-if="showReleaseWarning" class="bg-gray-600 flex fixed bottom-4 left-4 z-[150]">
        <g-button variant="action" class="block px-3 py-2 text-white ring-inset" @click="reloadWindow">
          A new version is available:
          <span class="text-green">Reload page</span>
        </g-button>

        <g-button
          class="p-2 text-white ring-inset"
          icon="close"
          variant="action"
          @click="closeNewReleaseWarning"
        />
      </div>
    </transition>
    <notification/>
  </base-layout>
</template>

<script setup lang="ts">
// Alert is there, for some reason it thinks it isn't
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import * as Sentry from "@sentry/browser"
import Alert from "@/components/Alert.vue"
import Api from "@/api"
import AppNav from "@/components/AppNav.vue"
import BaseLayout from "@/layouts/BaseLayout.vue"
import GButton from "@/components/buttons/GButton.vue"
import Intercom from "@/intercom"
import LayoutAnimations from "@/animations/layoutAnimations"
import Notification from "@/components/Notification.vue"
import { Freelancer } from "@/models/Freelancer"
import { FullStory } from "@fullstory/browser"
import { NOT_SET } from "@/constants"
import { computed, nextTick, onMounted, ref, watch } from "vue"
import { useI18n } from "@/composables/useI18n"
import { useRoute, useRouter } from "vue-router"
import { useUserStore } from "@/store/user"

const route = useRoute()
const router = useRouter()
const { t } = useI18n()
const userStore = useUserStore()

const emailSent = ref(false)
const showMobileNav = ref(false)
const emailAddress = computed(() => {
  return userStore.profile.email
})

const emailNotVerified = computed(() => {
  return !userStore.profile.isEmailVerified
})
const cannotDeliverEmail = computed(() => {
  return userStore.profile?.isEmailDeliverable === false
})
const showReleaseWarning = computed(() => {
  return userStore.showReleaseWarning
})

const identifyUserInFullStory = () => {
  const profileUUID = userStore.profile?.id

  if (!profileUUID) {
    throw Error("No profile uuid")
  }
  const email = emailAddress.value || ""
  FullStory("setIdentity", {
    uid: profileUUID,
    properties: {
      email,
    },
  })
}
watch(route, () => {
  showMobileNav.value = false
})

onMounted(() => {
  Intercom.syncProfile()

  try {
    identifyUserInFullStory()
  } catch (e) {
    console.error("Error identifying user for fullstory")
  }

  try {
    // eslint-disable-next-line import/namespace
    Sentry.setUser({ email: userStore.profile?.email })
  } catch (e) {
    console.error("Could not set sentry properly")
  }
})

const resendEmailConfirmation = () => {
  Api.emailResendConfirmation().then(() => emailSent.value = true)
}

const reloadWindow = () => {
  location.reload()
}

const closeNewReleaseWarning = () => {
  userStore.showNewVersion(false)
}

const logout = () => {
  userStore.authLogout()
}

const highlightProfileStatus = async () => {
  if (window.location.pathname !== "/app/freelancer/") {
    await router.push({ name: "freelancer-profile" })
  }

  nextTick(() => {
    LayoutAnimations.scrollTo("searchStatus", true)
  })
}

</script>
