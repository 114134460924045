import Api from "@/api"
import { Chore } from "@/models/Chore"
import { computed, ref } from "vue"
import { omit } from "lodash-es"
import { useNotify } from "@/composables/useNotify"
import { useUserStore } from "@/store/user"
import type { ChoreDetail } from "@/models/Chore"

const chores = ref([] as ChoreDetail[])

export const useChores = () => {
  const userStore = useUserStore()
  const loading = ref(false)
  const saving = ref(false)
  const pages = ref(1)
  const { notify, notifyError } = useNotify()

  const getChores = async (filters, page = 1, sort = null) => {
    loading.value = true

    try {
      const response: any = await Api.staffChoreList("", filters, page, sort)
      chores.value = response.chores
      pages.value = response.pagination.pages
    } catch (e) {
      notifyError("Could not load tasks", e)
    } finally {
      loading.value = false
    }
  }

  const updateChore = async (id: string, chore: any) => {
    try {
      saving.value = true
      const response: any = await Api.staffChoreUpdate(id, chore)

      const c = chores.value.findIndex(x => x.id === response.id)

      if (c >= 0) {
        chores.value[c] = response
      }

      return true
    } catch (e) {
      notifyError("Unable to update", e)
    } finally {
      saving.value = false
    }
  }

  const createTask = async (newTask: any, autoAdd?: boolean) => {
    saving.value = true

    if(newTask?.dueAt == null) {
      newTask = omit(newTask, ["dueAt"])
    }

    try {
      const response: any = await Api.staffChoreCreate({
        ...newTask,
      })

      notify("Successfully added")

      if (autoAdd) {
        chores.value.push(response)
      }

      return true
    } catch (e) {
      notifyError("Could not add tasks", e)
    } finally {
      saving.value = false
    }
  }

  const myActiveTodos = computed(() => {
    return chores.value.filter(x => x.status != Chore.STATUS_DONE && x.assignees.map(a => a.id).includes(userStore.profile?.id))
  })

  const myNumberOfTodos = computed(() => {
    return myActiveTodos.value.length
  })

  const myNumberPastDue = computed(() => {
    const today = Date.now()

    return myActiveTodos.value.filter(x => Date.parse(x.dueAt) < today).length
  })

  return {
    chores,
    loading,
    myNumberPastDue,
    myNumberOfTodos,
    saving,
    pages,

    createTask,
    getChores,
    updateChore,
  }
}
