<template>
  <div class="py-4 px-6 mb-4" :class="classes">
    <div v-if="title" :class="titleClasses">
      <icon name="warning"/>
      <div class="my-2">
        {{ title }}
      </div>
    </div>
    <div class="text-sm">
      <slot/>
    </div>
    <ul v-if="items.length" class="m-0 mt-2">
      <li v-for="item in items" :key="item" class="list-disc ml-10">
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"

const props = withDefaults(defineProps<{
  variant?: string
  items?: any[]
  title?: string
}>(), {
  variant: "primary",
  items: () => [],
})

const classes = computed(() => {
  if (props.variant === "danger") {
    return "bg-orange text-white bg-opacity-10"
  }

  if (props.variant === "secondary") {
    return "bg-1 text-white"
  }

  return "bg-blue-300"
})

const titleClasses = computed(() => {
  if (props.variant === "danger") {
    return "text-orange"
  }

  return "text-white"
})
</script>
