/* tslint:disable */
/* eslint-disable */
/**
 * Gun.io Guild
 * Public API for the Gun.io Guild platform
 *
 * The version of the OpenAPI document: 0.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios from "axios"
import type { AxiosInstance, AxiosPromise, RawAxiosRequestConfig } from "axios"
import type { Configuration } from "../configuration"
/*
 * Some imports not used depending on template conditions
 * @ts-ignore
 */
import { DUMMY_BASE_URL, assertParamExists, createRequestFunction, serializeDataIfNeeded, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, toPathString } from "../common"
// @ts-ignore
import { BASE_PATH, BaseAPI, COLLECTION_FORMATS, RequiredError, operationServerMap } from "../base"
import type { RequestArgs } from "../base"
// @ts-ignore
import type { AuthInviteCreateUserUserSerializer } from "../models"
// @ts-ignore
import type { AuthLoginLoginUserSerializer } from "../models"
// @ts-ignore
import type { CompanyFreelancerDetail } from "../models"
// @ts-ignore
import type { CompanyIdCompanyDetailCompanySerializer } from "../models"
// @ts-ignore
import type { CompanyIdDealsDealIdDealDetailDealSerializer } from "../models"
// @ts-ignore
import type { CompanyIdDealsDealListDealSerializer } from "../models"
// @ts-ignore
import type { CompanyIdHiresHireIdHireDetailHireSerializer } from "../models"
// @ts-ignore
import type { CompanyIdHiresHireIdScopeOfWorkScopeOfWorkDetailScopeOfWorkSerializer } from "../models"
// @ts-ignore
import type { CompanyIdHiresHireListHireSerializer } from "../models"
// @ts-ignore
import type { CompanyIdInterviewsInterviewIdActionInterviewDetailInterviewSerializer } from "../models"
// @ts-ignore
import type { CompanyIdInterviewsInterviewIdInterviewDetailInterviewSerializer } from "../models"
// @ts-ignore
import type { CompanyIdInterviewsInterviewListInterviewSerializer } from "../models"
// @ts-ignore
import type { CompanyIdJobsFromMissionJobCreateFromMissionCompanyJobSerializer } from "../models"
// @ts-ignore
import type { CompanyIdJobsJobIdArchiveJobArchiveCompanyJobSerializer } from "../models"
// @ts-ignore
import type { CompanyIdJobsJobIdJobDetailCompanyJobSerializer } from "../models"
// @ts-ignore
import type { CompanyIdJobsJobIdReviewJobReadyForReviewCompanyJobSerializer } from "../models"
// @ts-ignore
import type { CompanyIdJobsJobListCompanyJobSerializer } from "../models"
// @ts-ignore
import type { CompanyIdMembersMemberListCompanyMemberSerializer } from "../models"
// @ts-ignore
import type { CompanyIdPublicCompanyDetailCompanySerializer } from "../models"
// @ts-ignore
import type { CompanyIdReviewsReviewIdReviewDetailCompanyReviewSerializer } from "../models"
// @ts-ignore
import type { CompanyIdReviewsReviewListCompanyReviewSerializer } from "../models"
// @ts-ignore
import type { CompanyInterviewSettings } from "../models"
// @ts-ignore
import type { CompanyInvite } from "../models"
// @ts-ignore
import type { CompanyMission } from "../models"
// @ts-ignore
import type { CompanyMissionBudgetRecommendations } from "../models"
// @ts-ignore
import type { CompanyMissionInterview } from "../models"
// @ts-ignore
import type { CompanyMissionInterviewDefaultAvailability } from "../models"
// @ts-ignore
import type { CompanyMissionJobApplicationAction } from "../models"
// @ts-ignore
import type { CompanyMissionJobApplicationDetail } from "../models"
// @ts-ignore
import type { CompanyMissionJobApplicationList } from "../models"
// @ts-ignore
import type { CompanyMissionList } from "../models"
// @ts-ignore
import type { CompanyShowdownSlots } from "../models"
// @ts-ignore
import type { FactoriesApplicationsJobApplicationCreateJobApplicationSerializer } from "../models"
// @ts-ignore
import type { FactoriesCompaniesCompanyCreateStaffCompanySerializer } from "../models"
// @ts-ignore
import type { FactoriesDealsDealCreateDealSerializer } from "../models"
// @ts-ignore
import type { FactoriesFreelancersFreelancerCreateStaffFreelancerSerializer } from "../models"
// @ts-ignore
import type { FactoriesJobsJobCreateStaffJobSerializer } from "../models"
// @ts-ignore
import type { FactoriesMembersMemberCreateCompanyMemberSerializer } from "../models"
// @ts-ignore
import type { FactoriesPresentationsPresentationCreatePresentationSerializer } from "../models"
// @ts-ignore
import type { FactoriesUsersUserCreateUserSerializer } from "../models"
// @ts-ignore
import type { Freelancer } from "../models"
// @ts-ignore
import type { FreelancerCompleteScreeningCompleteScreeningFreelancerSerializer } from "../models"
// @ts-ignore
import type { FreelancerDetail } from "../models"
// @ts-ignore
import type { FreelancerIdApplicationsApplicationIdActionApplicationDetailJobApplicationSerializer } from "../models"
// @ts-ignore
import type { FreelancerIdApplicationsApplicationIdApplicationDetailJobApplicationSerializer } from "../models"
// @ts-ignore
import type { FreelancerIdApplicationsApplicationListJobApplicationSerializer } from "../models"
// @ts-ignore
import type { FreelancerIdExperiencesExperienceIdExperienceDetailExperienceSerializer } from "../models"
// @ts-ignore
import type { FreelancerIdExperiencesExperienceListExperienceSerializer } from "../models"
// @ts-ignore
import type { FreelancerIdHiresHireIdHireDetailHireSerializer } from "../models"
// @ts-ignore
import type { FreelancerIdHiresHireListHireSerializer } from "../models"
// @ts-ignore
import type { FreelancerIdInterviewsInterviewIdActionInterviewDetailInterviewSerializer } from "../models"
// @ts-ignore
import type { FreelancerIdInterviewsInterviewIdInterviewDetailInterviewSerializer } from "../models"
// @ts-ignore
import type { FreelancerIdInterviewsInterviewListInterviewSerializer } from "../models"
// @ts-ignore
import type { FreelancerIdPresentationsPresentationIdActionPresentationDetailPresentationSerializer } from "../models"
// @ts-ignore
import type { FreelancerIdPresentationsPresentationIdPresentationDetailPresentationSerializer } from "../models"
// @ts-ignore
import type { FreelancerIdPresentationsPresentationListPresentationSerializer } from "../models"
// @ts-ignore
import type { FreelancerIdProjectsProjectIdProjectDetailProjectSerializer } from "../models"
// @ts-ignore
import type { FreelancerIdProjectsProjectListProjectSerializer } from "../models"
// @ts-ignore
import type { FreelancerIdReferencesReferenceIdReferenceDetailReferenceSerializer } from "../models"
// @ts-ignore
import type { FreelancerIdReferencesReferenceListReferenceSerializer } from "../models"
// @ts-ignore
import type { FreelancerIdReviewsReviewIdReviewDetailFreelancerReviewSerializer } from "../models"
// @ts-ignore
import type { FreelancerIdReviewsReviewListFreelancerReviewSerializer } from "../models"
// @ts-ignore
import type { FreelancerInvite } from "../models"
// @ts-ignore
import type { FreelancerMissionInterview } from "../models"
// @ts-ignore
import type { FreelancerSharingCreate } from "../models"
// @ts-ignore
import type { FreelancerSharingDetail } from "../models"
// @ts-ignore
import type { HourlyRateTierHourlyRateDetailHourlyRateSerializer } from "../models"
// @ts-ignore
import type { JobsIdJobDetailJobSerializer } from "../models"
// @ts-ignore
import type { JobsJobListJobSerializer } from "../models"
// @ts-ignore
import type { MissionShortlist } from "../models"
// @ts-ignore
import type { PresentationDetail } from "../models"
// @ts-ignore
import type { PresentationListEntry } from "../models"
// @ts-ignore
import type { RateSuggestions } from "../models"
// @ts-ignore
import type { ShortlistFreelancer } from "../models"
// @ts-ignore
import type { ShortlistFreelancerMissionDisplay } from "../models"
// @ts-ignore
import type { StaffApplicationsApplicationListJobApplicationSerializer } from "../models"
// @ts-ignore
import type { StaffApplicationsIdApplicationDetailJobApplicationSerializer } from "../models"
// @ts-ignore
import type { StaffAppointmentsAppointmentListStaffAppointmentSerializer } from "../models"
// @ts-ignore
import type { StaffAppointmentsIdAppointmentDetailStaffAppointmentSerializer } from "../models"
// @ts-ignore
import type { StaffAppointmentsIdRecordingsAppointmentRecordingListRecordingSerializer } from "../models"
// @ts-ignore
import type { StaffAppointmentsIdRecordingsRecordingIdAppointmentRecordingDetailRecordingSerializer } from "../models"
// @ts-ignore
import type { StaffChoresChoreListChoreSerializer } from "../models"
// @ts-ignore
import type { StaffChoresIdChoreDetailChoreSerializer } from "../models"
// @ts-ignore
import type { StaffCompaniesCompanyListStaffCompanySerializer } from "../models"
// @ts-ignore
import type { StaffCompaniesIdActionCompanyDetailStaffCompanySerializer } from "../models"
// @ts-ignore
import type { StaffCompaniesIdCompanyDetailStaffCompanySerializer } from "../models"
// @ts-ignore
import type { StaffDealsDealListDealSerializer } from "../models"
// @ts-ignore
import type { StaffDealsIdActionDealDetailDealSerializer } from "../models"
// @ts-ignore
import type { StaffDealsIdDealDetailDealSerializer } from "../models"
// @ts-ignore
import type { StaffDealsIdInterviewsDealInterviewListInterviewSerializer } from "../models"
// @ts-ignore
import type { StaffDealsIdInterviewsInterviewIdActionDealInterviewDetailInterviewSerializer } from "../models"
// @ts-ignore
import type { StaffDealsIdInterviewsInterviewIdDealInterviewDetailInterviewSerializer } from "../models"
// @ts-ignore
import type { StaffDealsIdPresentationsDeclinePresentationBulkDeclinePresentationSerializer } from "../models"
// @ts-ignore
import type { StaffDealsIdPresentationsPresentationIdActionPresentationDetailPresentationSerializer } from "../models"
// @ts-ignore
import type { StaffDealsIdPresentationsPresentationIdPresentationDetailPresentationSerializer } from "../models"
// @ts-ignore
import type { StaffDealsIdPresentationsPresentationListPresentationSerializer } from "../models"
// @ts-ignore
import type { StaffFreelancersFreelancerListStaffFreelancerSerializer } from "../models"
// @ts-ignore
import type { StaffFreelancersIdActionFreelancerDetailStaffFreelancerSerializer } from "../models"
// @ts-ignore
import type { StaffFreelancersIdFreelancerDetailStaffFreelancerSerializer } from "../models"
// @ts-ignore
import type { StaffFreelancersIdHiresFreelancerHireListHireSerializer } from "../models"
// @ts-ignore
import type { StaffFreelancersIdMatchesFreelancerMatchListStaffJobSerializer } from "../models"
// @ts-ignore
import type { StaffFreelancersIdMessagesFreelancerMessageListMessageSerializer } from "../models"
// @ts-ignore
import type { StaffFreelancersIdNotesFreelancerNoteListNoteSerializer } from "../models"
// @ts-ignore
import type { StaffFreelancersIdRecordingsFreelancerRecordingListRecordingSerializer } from "../models"
// @ts-ignore
import type { StaffFreelancersIdRecordingsRecordingIdFreelancerRecordingDetailRecordingSerializer } from "../models"
// @ts-ignore
import type { StaffFreelancersIdScreeningFreelancerScreeningDebuggerStaffFreelancerSerializer } from "../models"
// @ts-ignore
import type { StaffHiresHireListHireSerializer } from "../models"
// @ts-ignore
import type { StaffHiresIdActionHireDetailHireSerializer } from "../models"
// @ts-ignore
import type { StaffHiresIdHireDetailHireSerializer } from "../models"
// @ts-ignore
import type { StaffHiresIdScopeOfWorkScopeOfWorkIdScopeOfWorkDetailScopeOfWorkSerializer } from "../models"
// @ts-ignore
import type { StaffHiresIdScopeOfWorkScopeOfWorkListScopeOfWorkSerializer } from "../models"
// @ts-ignore
import type { StaffInterviewsIdActionInterviewDetailInterviewSerializer } from "../models"
// @ts-ignore
import type { StaffInterviewsIdInterviewDetailInterviewSerializer } from "../models"
// @ts-ignore
import type { StaffInterviewsInterviewListInterviewSerializer } from "../models"
// @ts-ignore
import type { StaffJobsIdActionJobDetailStaffJobSerializer } from "../models"
// @ts-ignore
import type { StaffJobsIdApplicationsApplicationIdActionJobApplicationDetailJobApplicationSerializer } from "../models"
// @ts-ignore
import type { StaffJobsIdApplicationsApplicationIdJobApplicationDetailJobApplicationSerializer } from "../models"
// @ts-ignore
import type { StaffJobsIdApplicationsDeclineJobApplicationBulkDeclineJobApplicationSerializer } from "../models"
// @ts-ignore
import type { StaffJobsIdApplicationsJobApplicationListJobApplicationSerializer } from "../models"
// @ts-ignore
import type { StaffJobsIdFreelancersFreelancerIdJobFreelancerDetailJobFreelancerSerializer } from "../models"
// @ts-ignore
import type { StaffJobsIdFreelancersJobFreelancerListJobFreelancerSerializer } from "../models"
// @ts-ignore
import type { StaffJobsIdJobDetailStaffJobSerializer } from "../models"
// @ts-ignore
import type { StaffJobsIdRecordingsJobRecordingListRecordingSerializer } from "../models"
// @ts-ignore
import type { StaffJobsIdRecordingsRecordingIdJobRecordingDetailRecordingSerializer } from "../models"
// @ts-ignore
import type { StaffJobsIdSearchesJobFreelancerSearchListJobFreelancerSearchSerializer } from "../models"
// @ts-ignore
import type { StaffJobsIdSearchesSearchIdJobFreelancerSearchDetailJobFreelancerSearchSerializer } from "../models"
// @ts-ignore
import type { StaffJobsJobListStaffJobSerializer } from "../models"
// @ts-ignore
import type { StaffMessagesMessageListMessageSerializer } from "../models"
// @ts-ignore
import type { StaffReportsMetricsMetricListMetricSerializer } from "../models"
// @ts-ignore
import type { StaffReviewsIdReviewDetailStaffReviewSerializer } from "../models"
// @ts-ignore
import type { StaffReviewsReviewListStaffReviewSerializer } from "../models"
// @ts-ignore
import type { SubscriptionsHashSubscriptionsSubscriptionSerializer } from "../models"
// @ts-ignore
import type { UserAppointmentsAppointmentListAppointmentSerializer } from "../models"
// @ts-ignore
import type { UserAppointmentsIdAppointmentDetailAppointmentSerializer } from "../models"
// @ts-ignore
import type { UserCreateCompanyCreateCompanyCompanySerializer } from "../models"
// @ts-ignore
import type { UserCreateFreelancerCreateFreelancerFreelancerSerializer } from "../models"
// @ts-ignore
import type { UserGrantNylasNylasGrantNylasGrantSerializer } from "../models"
// @ts-ignore
import type { UserInterviewsIdInterviewDetailInterviewSerializer } from "../models"
// @ts-ignore
import type { UserInterviewsInterviewListInterviewSerializer } from "../models"
// @ts-ignore
import type { UserSubscriptionsSlackSlackSubscriptionSlackSubscriptionSerializer } from "../models"
// @ts-ignore
import type { UserSubscriptionsSubscriptionsSubscriptionSerializer } from "../models"
// @ts-ignore
import type { UserSubscriptionsTelegramTelegramSubscriptionTelegramSubscriptionSerializer } from "../models"
// @ts-ignore
import type { UserUserDetailUserSerializer } from "../models"
// @ts-ignore
import type { UserWorkstyleWorkStyleAssessmentSelfWorkStyleAssessmentSerializer } from "../models"
// @ts-ignore
import type { UsersUserListProfileSerializer } from "../models"
// @ts-ignore
import type { WorkstylesAssessmentsIdWorkStyleAssessmentDetailWorkStyleAssessmentSerializer } from "../models"
// @ts-ignore
import type { WorkstylesAssessmentsWorkStyleAssessmentListWorkStyleAssessmentSerializer } from "../models"
// @ts-ignore
import type { WorkstylesWorkStyleListWorkStyleSerializer } from "../models"

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authInviteGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/auth/invite/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authInviteOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/auth/invite/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authInvitePost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/auth/invite/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authLoginGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/auth/login/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authLoginOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/auth/login/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authLoginPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/auth/login/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} dealId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdDealsDealIdGet: async (id: string, dealId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdDealsDealIdGet", "id", id)
      // verify required parameter 'dealId' is not null or undefined
      assertParamExists("companyIdDealsDealIdGet", "dealId", dealId)
      const localVarPath = "/company/{id}/deals/{deal_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"deal_id"}}`, encodeURIComponent(String(dealId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} dealId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdDealsDealIdOptions: async (id: string, dealId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdDealsDealIdOptions", "id", id)
      // verify required parameter 'dealId' is not null or undefined
      assertParamExists("companyIdDealsDealIdOptions", "dealId", dealId)
      const localVarPath = "/company/{id}/deals/{deal_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"deal_id"}}`, encodeURIComponent(String(dealId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} dealId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdDealsDealIdPresentationsGet: async (id: string, dealId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdDealsDealIdPresentationsGet", "id", id)
      // verify required parameter 'dealId' is not null or undefined
      assertParamExists("companyIdDealsDealIdPresentationsGet", "dealId", dealId)
      const localVarPath = "/company/{id}/deals/{deal_id}/presentations/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"deal_id"}}`, encodeURIComponent(String(dealId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} dealId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdDealsDealIdPresentationsOptions: async (id: string, dealId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdDealsDealIdPresentationsOptions", "id", id)
      // verify required parameter 'dealId' is not null or undefined
      assertParamExists("companyIdDealsDealIdPresentationsOptions", "dealId", dealId)
      const localVarPath = "/company/{id}/deals/{deal_id}/presentations/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"deal_id"}}`, encodeURIComponent(String(dealId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} dealId
     * @param {string} presentationId
     * @param {CompanyIdDealsDealIdPresentationsPresentationIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdDealsDealIdPresentationsPresentationIdActionGet: async (id: string, dealId: string, presentationId: string, action: CompanyIdDealsDealIdPresentationsPresentationIdActionGetActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdDealsDealIdPresentationsPresentationIdActionGet", "id", id)
      // verify required parameter 'dealId' is not null or undefined
      assertParamExists("companyIdDealsDealIdPresentationsPresentationIdActionGet", "dealId", dealId)
      // verify required parameter 'presentationId' is not null or undefined
      assertParamExists("companyIdDealsDealIdPresentationsPresentationIdActionGet", "presentationId", presentationId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("companyIdDealsDealIdPresentationsPresentationIdActionGet", "action", action)
      const localVarPath = "/company/{id}/deals/{deal_id}/presentations/{presentation_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"deal_id"}}`, encodeURIComponent(String(dealId)))
        .replace(`{${"presentation_id"}}`, encodeURIComponent(String(presentationId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} dealId
     * @param {string} presentationId
     * @param {CompanyIdDealsDealIdPresentationsPresentationIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdDealsDealIdPresentationsPresentationIdActionOptions: async (id: string, dealId: string, presentationId: string, action: CompanyIdDealsDealIdPresentationsPresentationIdActionOptionsActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdDealsDealIdPresentationsPresentationIdActionOptions", "id", id)
      // verify required parameter 'dealId' is not null or undefined
      assertParamExists("companyIdDealsDealIdPresentationsPresentationIdActionOptions", "dealId", dealId)
      // verify required parameter 'presentationId' is not null or undefined
      assertParamExists("companyIdDealsDealIdPresentationsPresentationIdActionOptions", "presentationId", presentationId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("companyIdDealsDealIdPresentationsPresentationIdActionOptions", "action", action)
      const localVarPath = "/company/{id}/deals/{deal_id}/presentations/{presentation_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"deal_id"}}`, encodeURIComponent(String(dealId)))
        .replace(`{${"presentation_id"}}`, encodeURIComponent(String(presentationId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} dealId
     * @param {string} presentationId
     * @param {CompanyIdDealsDealIdPresentationsPresentationIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdDealsDealIdPresentationsPresentationIdActionPut: async (id: string, dealId: string, presentationId: string, action: CompanyIdDealsDealIdPresentationsPresentationIdActionPutActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdDealsDealIdPresentationsPresentationIdActionPut", "id", id)
      // verify required parameter 'dealId' is not null or undefined
      assertParamExists("companyIdDealsDealIdPresentationsPresentationIdActionPut", "dealId", dealId)
      // verify required parameter 'presentationId' is not null or undefined
      assertParamExists("companyIdDealsDealIdPresentationsPresentationIdActionPut", "presentationId", presentationId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("companyIdDealsDealIdPresentationsPresentationIdActionPut", "action", action)
      const localVarPath = "/company/{id}/deals/{deal_id}/presentations/{presentation_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"deal_id"}}`, encodeURIComponent(String(dealId)))
        .replace(`{${"presentation_id"}}`, encodeURIComponent(String(presentationId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} dealId
     * @param {string} presentationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdDealsDealIdPresentationsPresentationIdGet: async (id: string, dealId: string, presentationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdDealsDealIdPresentationsPresentationIdGet", "id", id)
      // verify required parameter 'dealId' is not null or undefined
      assertParamExists("companyIdDealsDealIdPresentationsPresentationIdGet", "dealId", dealId)
      // verify required parameter 'presentationId' is not null or undefined
      assertParamExists("companyIdDealsDealIdPresentationsPresentationIdGet", "presentationId", presentationId)
      const localVarPath = "/company/{id}/deals/{deal_id}/presentations/{presentation_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"deal_id"}}`, encodeURIComponent(String(dealId)))
        .replace(`{${"presentation_id"}}`, encodeURIComponent(String(presentationId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} dealId
     * @param {string} presentationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdDealsDealIdPresentationsPresentationIdOptions: async (id: string, dealId: string, presentationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdDealsDealIdPresentationsPresentationIdOptions", "id", id)
      // verify required parameter 'dealId' is not null or undefined
      assertParamExists("companyIdDealsDealIdPresentationsPresentationIdOptions", "dealId", dealId)
      // verify required parameter 'presentationId' is not null or undefined
      assertParamExists("companyIdDealsDealIdPresentationsPresentationIdOptions", "presentationId", presentationId)
      const localVarPath = "/company/{id}/deals/{deal_id}/presentations/{presentation_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"deal_id"}}`, encodeURIComponent(String(dealId)))
        .replace(`{${"presentation_id"}}`, encodeURIComponent(String(presentationId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} dealId
     * @param {string} presentationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdDealsDealIdPresentationsPresentationIdPut: async (id: string, dealId: string, presentationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdDealsDealIdPresentationsPresentationIdPut", "id", id)
      // verify required parameter 'dealId' is not null or undefined
      assertParamExists("companyIdDealsDealIdPresentationsPresentationIdPut", "dealId", dealId)
      // verify required parameter 'presentationId' is not null or undefined
      assertParamExists("companyIdDealsDealIdPresentationsPresentationIdPut", "presentationId", presentationId)
      const localVarPath = "/company/{id}/deals/{deal_id}/presentations/{presentation_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"deal_id"}}`, encodeURIComponent(String(dealId)))
        .replace(`{${"presentation_id"}}`, encodeURIComponent(String(presentationId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdDealsGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdDealsGet", "id", id)
      const localVarPath = "/company/{id}/deals/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdDealsOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdDealsOptions", "id", id)
      const localVarPath = "/company/{id}/deals/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} freelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdFreelancersFreelancerIdGet: async (id: string, freelancerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdFreelancersFreelancerIdGet", "id", id)
      // verify required parameter 'freelancerId' is not null or undefined
      assertParamExists("companyIdFreelancersFreelancerIdGet", "freelancerId", freelancerId)
      const localVarPath = "/company/{id}/freelancers/{freelancer_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"freelancer_id"}}`, encodeURIComponent(String(freelancerId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} freelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdFreelancersFreelancerIdOptions: async (id: string, freelancerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdFreelancersFreelancerIdOptions", "id", id)
      // verify required parameter 'freelancerId' is not null or undefined
      assertParamExists("companyIdFreelancersFreelancerIdOptions", "freelancerId", freelancerId)
      const localVarPath = "/company/{id}/freelancers/{freelancer_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"freelancer_id"}}`, encodeURIComponent(String(freelancerId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdGet", "id", id)
      const localVarPath = "/company/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdHiresGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdHiresGet", "id", id)
      const localVarPath = "/company/{id}/hires/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} hireId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdHiresHireIdGet: async (id: string, hireId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdHiresHireIdGet", "id", id)
      // verify required parameter 'hireId' is not null or undefined
      assertParamExists("companyIdHiresHireIdGet", "hireId", hireId)
      const localVarPath = "/company/{id}/hires/{hire_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"hire_id"}}`, encodeURIComponent(String(hireId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} hireId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdHiresHireIdOptions: async (id: string, hireId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdHiresHireIdOptions", "id", id)
      // verify required parameter 'hireId' is not null or undefined
      assertParamExists("companyIdHiresHireIdOptions", "hireId", hireId)
      const localVarPath = "/company/{id}/hires/{hire_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"hire_id"}}`, encodeURIComponent(String(hireId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} hireId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdHiresHireIdPatch: async (id: string, hireId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdHiresHireIdPatch", "id", id)
      // verify required parameter 'hireId' is not null or undefined
      assertParamExists("companyIdHiresHireIdPatch", "hireId", hireId)
      const localVarPath = "/company/{id}/hires/{hire_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"hire_id"}}`, encodeURIComponent(String(hireId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} hireId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdHiresHireIdPut: async (id: string, hireId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdHiresHireIdPut", "id", id)
      // verify required parameter 'hireId' is not null or undefined
      assertParamExists("companyIdHiresHireIdPut", "hireId", hireId)
      const localVarPath = "/company/{id}/hires/{hire_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"hire_id"}}`, encodeURIComponent(String(hireId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} hireId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdHiresHireIdScopeOfWorkGet: async (id: string, hireId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdHiresHireIdScopeOfWorkGet", "id", id)
      // verify required parameter 'hireId' is not null or undefined
      assertParamExists("companyIdHiresHireIdScopeOfWorkGet", "hireId", hireId)
      const localVarPath = "/company/{id}/hires/{hire_id}/scope-of-work/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"hire_id"}}`, encodeURIComponent(String(hireId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} hireId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdHiresHireIdScopeOfWorkOptions: async (id: string, hireId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdHiresHireIdScopeOfWorkOptions", "id", id)
      // verify required parameter 'hireId' is not null or undefined
      assertParamExists("companyIdHiresHireIdScopeOfWorkOptions", "hireId", hireId)
      const localVarPath = "/company/{id}/hires/{hire_id}/scope-of-work/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"hire_id"}}`, encodeURIComponent(String(hireId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdHiresOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdHiresOptions", "id", id)
      const localVarPath = "/company/{id}/hires/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewSettingsGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdInterviewSettingsGet", "id", id)
      const localVarPath = "/company/{id}/interview_settings/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewSettingsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewSettingsInterviewSettingsIdDelete: async (id: string, interviewSettingsId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdInterviewSettingsInterviewSettingsIdDelete", "id", id)
      // verify required parameter 'interviewSettingsId' is not null or undefined
      assertParamExists("companyIdInterviewSettingsInterviewSettingsIdDelete", "interviewSettingsId", interviewSettingsId)
      const localVarPath = "/company/{id}/interview_settings/{interview_settings_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"interview_settings_id"}}`, encodeURIComponent(String(interviewSettingsId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewSettingsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewSettingsInterviewSettingsIdGet: async (id: string, interviewSettingsId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdInterviewSettingsInterviewSettingsIdGet", "id", id)
      // verify required parameter 'interviewSettingsId' is not null or undefined
      assertParamExists("companyIdInterviewSettingsInterviewSettingsIdGet", "interviewSettingsId", interviewSettingsId)
      const localVarPath = "/company/{id}/interview_settings/{interview_settings_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"interview_settings_id"}}`, encodeURIComponent(String(interviewSettingsId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewSettingsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewSettingsInterviewSettingsIdOptions: async (id: string, interviewSettingsId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdInterviewSettingsInterviewSettingsIdOptions", "id", id)
      // verify required parameter 'interviewSettingsId' is not null or undefined
      assertParamExists("companyIdInterviewSettingsInterviewSettingsIdOptions", "interviewSettingsId", interviewSettingsId)
      const localVarPath = "/company/{id}/interview_settings/{interview_settings_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"interview_settings_id"}}`, encodeURIComponent(String(interviewSettingsId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewSettingsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewSettingsInterviewSettingsIdPatch: async (id: string, interviewSettingsId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdInterviewSettingsInterviewSettingsIdPatch", "id", id)
      // verify required parameter 'interviewSettingsId' is not null or undefined
      assertParamExists("companyIdInterviewSettingsInterviewSettingsIdPatch", "interviewSettingsId", interviewSettingsId)
      const localVarPath = "/company/{id}/interview_settings/{interview_settings_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"interview_settings_id"}}`, encodeURIComponent(String(interviewSettingsId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewSettingsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewSettingsInterviewSettingsIdPut: async (id: string, interviewSettingsId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdInterviewSettingsInterviewSettingsIdPut", "id", id)
      // verify required parameter 'interviewSettingsId' is not null or undefined
      assertParamExists("companyIdInterviewSettingsInterviewSettingsIdPut", "interviewSettingsId", interviewSettingsId)
      const localVarPath = "/company/{id}/interview_settings/{interview_settings_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"interview_settings_id"}}`, encodeURIComponent(String(interviewSettingsId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewSettingsOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdInterviewSettingsOptions", "id", id)
      const localVarPath = "/company/{id}/interview_settings/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewSettingsPost: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdInterviewSettingsPost", "id", id)
      const localVarPath = "/company/{id}/interview_settings/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewsGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdInterviewsGet", "id", id)
      const localVarPath = "/company/{id}/interviews/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {CompanyIdInterviewsInterviewIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewsInterviewIdActionGet: async (id: string, interviewId: string, action: CompanyIdInterviewsInterviewIdActionGetActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdInterviewsInterviewIdActionGet", "id", id)
      // verify required parameter 'interviewId' is not null or undefined
      assertParamExists("companyIdInterviewsInterviewIdActionGet", "interviewId", interviewId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("companyIdInterviewsInterviewIdActionGet", "action", action)
      const localVarPath = "/company/{id}/interviews/{interview_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"interview_id"}}`, encodeURIComponent(String(interviewId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {CompanyIdInterviewsInterviewIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewsInterviewIdActionOptions: async (id: string, interviewId: string, action: CompanyIdInterviewsInterviewIdActionOptionsActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdInterviewsInterviewIdActionOptions", "id", id)
      // verify required parameter 'interviewId' is not null or undefined
      assertParamExists("companyIdInterviewsInterviewIdActionOptions", "interviewId", interviewId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("companyIdInterviewsInterviewIdActionOptions", "action", action)
      const localVarPath = "/company/{id}/interviews/{interview_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"interview_id"}}`, encodeURIComponent(String(interviewId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {CompanyIdInterviewsInterviewIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewsInterviewIdActionPut: async (id: string, interviewId: string, action: CompanyIdInterviewsInterviewIdActionPutActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdInterviewsInterviewIdActionPut", "id", id)
      // verify required parameter 'interviewId' is not null or undefined
      assertParamExists("companyIdInterviewsInterviewIdActionPut", "interviewId", interviewId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("companyIdInterviewsInterviewIdActionPut", "action", action)
      const localVarPath = "/company/{id}/interviews/{interview_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"interview_id"}}`, encodeURIComponent(String(interviewId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewsInterviewIdGet: async (id: string, interviewId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdInterviewsInterviewIdGet", "id", id)
      // verify required parameter 'interviewId' is not null or undefined
      assertParamExists("companyIdInterviewsInterviewIdGet", "interviewId", interviewId)
      const localVarPath = "/company/{id}/interviews/{interview_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"interview_id"}}`, encodeURIComponent(String(interviewId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewsInterviewIdOptions: async (id: string, interviewId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdInterviewsInterviewIdOptions", "id", id)
      // verify required parameter 'interviewId' is not null or undefined
      assertParamExists("companyIdInterviewsInterviewIdOptions", "interviewId", interviewId)
      const localVarPath = "/company/{id}/interviews/{interview_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"interview_id"}}`, encodeURIComponent(String(interviewId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewsInterviewIdPut: async (id: string, interviewId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdInterviewsInterviewIdPut", "id", id)
      // verify required parameter 'interviewId' is not null or undefined
      assertParamExists("companyIdInterviewsInterviewIdPut", "interviewId", interviewId)
      const localVarPath = "/company/{id}/interviews/{interview_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"interview_id"}}`, encodeURIComponent(String(interviewId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewsOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdInterviewsOptions", "id", id)
      const localVarPath = "/company/{id}/interviews/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewsPost: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdInterviewsPost", "id", id)
      const localVarPath = "/company/{id}/interviews/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInvitesGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdInvitesGet", "id", id)
      const localVarPath = "/company/{id}/invites/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} inviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInvitesInviteIdDelete: async (id: string, inviteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdInvitesInviteIdDelete", "id", id)
      // verify required parameter 'inviteId' is not null or undefined
      assertParamExists("companyIdInvitesInviteIdDelete", "inviteId", inviteId)
      const localVarPath = "/company/{id}/invites/{invite_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"invite_id"}}`, encodeURIComponent(String(inviteId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} inviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInvitesInviteIdGet: async (id: string, inviteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdInvitesInviteIdGet", "id", id)
      // verify required parameter 'inviteId' is not null or undefined
      assertParamExists("companyIdInvitesInviteIdGet", "inviteId", inviteId)
      const localVarPath = "/company/{id}/invites/{invite_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"invite_id"}}`, encodeURIComponent(String(inviteId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} inviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInvitesInviteIdOptions: async (id: string, inviteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdInvitesInviteIdOptions", "id", id)
      // verify required parameter 'inviteId' is not null or undefined
      assertParamExists("companyIdInvitesInviteIdOptions", "inviteId", inviteId)
      const localVarPath = "/company/{id}/invites/{invite_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"invite_id"}}`, encodeURIComponent(String(inviteId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} inviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInvitesInviteIdPatch: async (id: string, inviteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdInvitesInviteIdPatch", "id", id)
      // verify required parameter 'inviteId' is not null or undefined
      assertParamExists("companyIdInvitesInviteIdPatch", "inviteId", inviteId)
      const localVarPath = "/company/{id}/invites/{invite_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"invite_id"}}`, encodeURIComponent(String(inviteId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} inviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInvitesInviteIdPut: async (id: string, inviteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdInvitesInviteIdPut", "id", id)
      // verify required parameter 'inviteId' is not null or undefined
      assertParamExists("companyIdInvitesInviteIdPut", "inviteId", inviteId)
      const localVarPath = "/company/{id}/invites/{invite_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"invite_id"}}`, encodeURIComponent(String(inviteId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInvitesOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdInvitesOptions", "id", id)
      const localVarPath = "/company/{id}/invites/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInvitesPost: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdInvitesPost", "id", id)
      const localVarPath = "/company/{id}/invites/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdJobsFromMissionOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdJobsFromMissionOptions", "id", id)
      const localVarPath = "/company/{id}/jobs/from_mission/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdJobsFromMissionPost: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdJobsFromMissionPost", "id", id)
      const localVarPath = "/company/{id}/jobs/from_mission/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdJobsGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdJobsGet", "id", id)
      const localVarPath = "/company/{id}/jobs/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdJobsJobIdArchiveGet: async (id: string, jobId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdJobsJobIdArchiveGet", "id", id)
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists("companyIdJobsJobIdArchiveGet", "jobId", jobId)
      const localVarPath = "/company/{id}/jobs/{job_id}/archive/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"job_id"}}`, encodeURIComponent(String(jobId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdJobsJobIdArchiveOptions: async (id: string, jobId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdJobsJobIdArchiveOptions", "id", id)
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists("companyIdJobsJobIdArchiveOptions", "jobId", jobId)
      const localVarPath = "/company/{id}/jobs/{job_id}/archive/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"job_id"}}`, encodeURIComponent(String(jobId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdJobsJobIdArchivePost: async (id: string, jobId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdJobsJobIdArchivePost", "id", id)
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists("companyIdJobsJobIdArchivePost", "jobId", jobId)
      const localVarPath = "/company/{id}/jobs/{job_id}/archive/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"job_id"}}`, encodeURIComponent(String(jobId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdJobsJobIdDelete: async (id: string, jobId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdJobsJobIdDelete", "id", id)
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists("companyIdJobsJobIdDelete", "jobId", jobId)
      const localVarPath = "/company/{id}/jobs/{job_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"job_id"}}`, encodeURIComponent(String(jobId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdJobsJobIdGet: async (id: string, jobId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdJobsJobIdGet", "id", id)
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists("companyIdJobsJobIdGet", "jobId", jobId)
      const localVarPath = "/company/{id}/jobs/{job_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"job_id"}}`, encodeURIComponent(String(jobId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdJobsJobIdOptions: async (id: string, jobId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdJobsJobIdOptions", "id", id)
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists("companyIdJobsJobIdOptions", "jobId", jobId)
      const localVarPath = "/company/{id}/jobs/{job_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"job_id"}}`, encodeURIComponent(String(jobId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdJobsJobIdPatch: async (id: string, jobId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdJobsJobIdPatch", "id", id)
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists("companyIdJobsJobIdPatch", "jobId", jobId)
      const localVarPath = "/company/{id}/jobs/{job_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"job_id"}}`, encodeURIComponent(String(jobId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdJobsJobIdPut: async (id: string, jobId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdJobsJobIdPut", "id", id)
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists("companyIdJobsJobIdPut", "jobId", jobId)
      const localVarPath = "/company/{id}/jobs/{job_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"job_id"}}`, encodeURIComponent(String(jobId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdJobsJobIdReviewGet: async (id: string, jobId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdJobsJobIdReviewGet", "id", id)
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists("companyIdJobsJobIdReviewGet", "jobId", jobId)
      const localVarPath = "/company/{id}/jobs/{job_id}/review/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"job_id"}}`, encodeURIComponent(String(jobId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdJobsJobIdReviewOptions: async (id: string, jobId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdJobsJobIdReviewOptions", "id", id)
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists("companyIdJobsJobIdReviewOptions", "jobId", jobId)
      const localVarPath = "/company/{id}/jobs/{job_id}/review/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"job_id"}}`, encodeURIComponent(String(jobId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdJobsJobIdReviewPost: async (id: string, jobId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdJobsJobIdReviewPost", "id", id)
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists("companyIdJobsJobIdReviewPost", "jobId", jobId)
      const localVarPath = "/company/{id}/jobs/{job_id}/review/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"job_id"}}`, encodeURIComponent(String(jobId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdJobsOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdJobsOptions", "id", id)
      const localVarPath = "/company/{id}/jobs/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdJobsPost: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdJobsPost", "id", id)
      const localVarPath = "/company/{id}/jobs/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMembersGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMembersGet", "id", id)
      const localVarPath = "/company/{id}/members/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMembersOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMembersOptions", "id", id)
      const localVarPath = "/company/{id}/members/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMembersPost: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMembersPost", "id", id)
      const localVarPath = "/company/{id}/members/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionInterviewsGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionInterviewsGet", "id", id)
      const localVarPath = "/company/{id}/mission_interviews/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionInterviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionInterviewsMissionInterviewIdDelete: async (id: string, missionInterviewId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionInterviewsMissionInterviewIdDelete", "id", id)
      // verify required parameter 'missionInterviewId' is not null or undefined
      assertParamExists("companyIdMissionInterviewsMissionInterviewIdDelete", "missionInterviewId", missionInterviewId)
      const localVarPath = "/company/{id}/mission_interviews/{mission_interview_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_interview_id"}}`, encodeURIComponent(String(missionInterviewId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionInterviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionInterviewsMissionInterviewIdGet: async (id: string, missionInterviewId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionInterviewsMissionInterviewIdGet", "id", id)
      // verify required parameter 'missionInterviewId' is not null or undefined
      assertParamExists("companyIdMissionInterviewsMissionInterviewIdGet", "missionInterviewId", missionInterviewId)
      const localVarPath = "/company/{id}/mission_interviews/{mission_interview_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_interview_id"}}`, encodeURIComponent(String(missionInterviewId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionInterviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionInterviewsMissionInterviewIdOptions: async (id: string, missionInterviewId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionInterviewsMissionInterviewIdOptions", "id", id)
      // verify required parameter 'missionInterviewId' is not null or undefined
      assertParamExists("companyIdMissionInterviewsMissionInterviewIdOptions", "missionInterviewId", missionInterviewId)
      const localVarPath = "/company/{id}/mission_interviews/{mission_interview_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_interview_id"}}`, encodeURIComponent(String(missionInterviewId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionInterviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionInterviewsMissionInterviewIdPatch: async (id: string, missionInterviewId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionInterviewsMissionInterviewIdPatch", "id", id)
      // verify required parameter 'missionInterviewId' is not null or undefined
      assertParamExists("companyIdMissionInterviewsMissionInterviewIdPatch", "missionInterviewId", missionInterviewId)
      const localVarPath = "/company/{id}/mission_interviews/{mission_interview_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_interview_id"}}`, encodeURIComponent(String(missionInterviewId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionInterviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionInterviewsMissionInterviewIdPut: async (id: string, missionInterviewId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionInterviewsMissionInterviewIdPut", "id", id)
      // verify required parameter 'missionInterviewId' is not null or undefined
      assertParamExists("companyIdMissionInterviewsMissionInterviewIdPut", "missionInterviewId", missionInterviewId)
      const localVarPath = "/company/{id}/mission_interviews/{mission_interview_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_interview_id"}}`, encodeURIComponent(String(missionInterviewId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionInterviewsOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionInterviewsOptions", "id", id)
      const localVarPath = "/company/{id}/mission_interviews/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionInterviewsPost: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionInterviewsPost", "id", id)
      const localVarPath = "/company/{id}/mission_interviews/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsGet", "id", id)
      const localVarPath = "/company/{id}/missions/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionBuilderOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionBuilderOptions", "id", id)
      const localVarPath = "/company/{id}/missions/mission_builder/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionBuilderPost: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionBuilderPost", "id", id)
      const localVarPath = "/company/{id}/missions/mission_builder/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdDelete: async (id: string, missionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdDelete", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdDelete", "missionId", missionId)
      const localVarPath = "/company/{id}/missions/{mission_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdGet: async (id: string, missionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdGet", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdGet", "missionId", missionId)
      const localVarPath = "/company/{id}/missions/{mission_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityGet: async (id: string, missionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityGet", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityGet", "missionId", missionId)
      const localVarPath = "/company/{id}/missions/{mission_id}/mission-interview-default-availability/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} missionInterviewDefaultAvailabilityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdGet: async (id: string, missionId: string, missionInterviewDefaultAvailabilityId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdGet", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdGet", "missionId", missionId)
      // verify required parameter 'missionInterviewDefaultAvailabilityId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdGet", "missionInterviewDefaultAvailabilityId", missionInterviewDefaultAvailabilityId)
      const localVarPath = "/company/{id}/missions/{mission_id}/mission-interview-default-availability/{mission_interview_default_availability_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
        .replace(`{${"mission_interview_default_availability_id"}}`, encodeURIComponent(String(missionInterviewDefaultAvailabilityId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} missionInterviewDefaultAvailabilityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdOptions: async (id: string, missionId: string, missionInterviewDefaultAvailabilityId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdOptions", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdOptions", "missionId", missionId)
      // verify required parameter 'missionInterviewDefaultAvailabilityId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdOptions", "missionInterviewDefaultAvailabilityId", missionInterviewDefaultAvailabilityId)
      const localVarPath = "/company/{id}/missions/{mission_id}/mission-interview-default-availability/{mission_interview_default_availability_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
        .replace(`{${"mission_interview_default_availability_id"}}`, encodeURIComponent(String(missionInterviewDefaultAvailabilityId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} missionInterviewDefaultAvailabilityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdPatch: async (id: string, missionId: string, missionInterviewDefaultAvailabilityId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdPatch", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdPatch", "missionId", missionId)
      // verify required parameter 'missionInterviewDefaultAvailabilityId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdPatch", "missionInterviewDefaultAvailabilityId", missionInterviewDefaultAvailabilityId)
      const localVarPath = "/company/{id}/missions/{mission_id}/mission-interview-default-availability/{mission_interview_default_availability_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
        .replace(`{${"mission_interview_default_availability_id"}}`, encodeURIComponent(String(missionInterviewDefaultAvailabilityId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} missionInterviewDefaultAvailabilityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdPut: async (id: string, missionId: string, missionInterviewDefaultAvailabilityId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdPut", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdPut", "missionId", missionId)
      // verify required parameter 'missionInterviewDefaultAvailabilityId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdPut", "missionInterviewDefaultAvailabilityId", missionInterviewDefaultAvailabilityId)
      const localVarPath = "/company/{id}/missions/{mission_id}/mission-interview-default-availability/{mission_interview_default_availability_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
        .replace(`{${"mission_interview_default_availability_id"}}`, encodeURIComponent(String(missionInterviewDefaultAvailabilityId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityOptions: async (id: string, missionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityOptions", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityOptions", "missionId", missionId)
      const localVarPath = "/company/{id}/missions/{mission_id}/mission-interview-default-availability/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityPost: async (id: string, missionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityPost", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityPost", "missionId", missionId)
      const localVarPath = "/company/{id}/missions/{mission_id}/mission-interview-default-availability/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdOptions: async (id: string, missionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdOptions", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdOptions", "missionId", missionId)
      const localVarPath = "/company/{id}/missions/{mission_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdPatch: async (id: string, missionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdPatch", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdPatch", "missionId", missionId)
      const localVarPath = "/company/{id}/missions/{mission_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdPut: async (id: string, missionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdPut", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdPut", "missionId", missionId)
      const localVarPath = "/company/{id}/missions/{mission_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistFreelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdDelete: async (id: string, missionId: string, shortlistFreelancerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdDelete", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdDelete", "missionId", missionId)
      // verify required parameter 'shortlistFreelancerId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdDelete", "shortlistFreelancerId", shortlistFreelancerId)
      const localVarPath = "/company/{id}/missions/{mission_id}/shortlist_freelancers/{shortlist_freelancer_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
        .replace(`{${"shortlist_freelancer_id"}}`, encodeURIComponent(String(shortlistFreelancerId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistFreelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdGet: async (id: string, missionId: string, shortlistFreelancerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdGet", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdGet", "missionId", missionId)
      // verify required parameter 'shortlistFreelancerId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdGet", "shortlistFreelancerId", shortlistFreelancerId)
      const localVarPath = "/company/{id}/missions/{mission_id}/shortlist_freelancers/{shortlist_freelancer_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
        .replace(`{${"shortlist_freelancer_id"}}`, encodeURIComponent(String(shortlistFreelancerId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistFreelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdOptions: async (id: string, missionId: string, shortlistFreelancerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdOptions", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdOptions", "missionId", missionId)
      // verify required parameter 'shortlistFreelancerId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdOptions", "shortlistFreelancerId", shortlistFreelancerId)
      const localVarPath = "/company/{id}/missions/{mission_id}/shortlist_freelancers/{shortlist_freelancer_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
        .replace(`{${"shortlist_freelancer_id"}}`, encodeURIComponent(String(shortlistFreelancerId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistFreelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdPatch: async (id: string, missionId: string, shortlistFreelancerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdPatch", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdPatch", "missionId", missionId)
      // verify required parameter 'shortlistFreelancerId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdPatch", "shortlistFreelancerId", shortlistFreelancerId)
      const localVarPath = "/company/{id}/missions/{mission_id}/shortlist_freelancers/{shortlist_freelancer_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
        .replace(`{${"shortlist_freelancer_id"}}`, encodeURIComponent(String(shortlistFreelancerId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistFreelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdPut: async (id: string, missionId: string, shortlistFreelancerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdPut", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdPut", "missionId", missionId)
      // verify required parameter 'shortlistFreelancerId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdPut", "shortlistFreelancerId", shortlistFreelancerId)
      const localVarPath = "/company/{id}/missions/{mission_id}/shortlist_freelancers/{shortlist_freelancer_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
        .replace(`{${"shortlist_freelancer_id"}}`, encodeURIComponent(String(shortlistFreelancerId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistsGet: async (id: string, missionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsGet", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsGet", "missionId", missionId)
      const localVarPath = "/company/{id}/missions/{mission_id}/shortlists/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistsOptions: async (id: string, missionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsOptions", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsOptions", "missionId", missionId)
      const localVarPath = "/company/{id}/missions/{mission_id}/shortlists/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistsPost: async (id: string, missionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsPost", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsPost", "missionId", missionId)
      const localVarPath = "/company/{id}/missions/{mission_id}/shortlists/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistId
     * @param {string} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistsShortlistIdActionDelete: async (id: string, missionId: string, shortlistId: string, action: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdActionDelete", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdActionDelete", "missionId", missionId)
      // verify required parameter 'shortlistId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdActionDelete", "shortlistId", shortlistId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdActionDelete", "action", action)
      const localVarPath = "/company/{id}/missions/{mission_id}/shortlists/{shortlist_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
        .replace(`{${"shortlist_id"}}`, encodeURIComponent(String(shortlistId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistId
     * @param {string} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistsShortlistIdActionGet: async (id: string, missionId: string, shortlistId: string, action: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdActionGet", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdActionGet", "missionId", missionId)
      // verify required parameter 'shortlistId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdActionGet", "shortlistId", shortlistId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdActionGet", "action", action)
      const localVarPath = "/company/{id}/missions/{mission_id}/shortlists/{shortlist_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
        .replace(`{${"shortlist_id"}}`, encodeURIComponent(String(shortlistId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistId
     * @param {string} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistsShortlistIdActionOptions: async (id: string, missionId: string, shortlistId: string, action: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdActionOptions", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdActionOptions", "missionId", missionId)
      // verify required parameter 'shortlistId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdActionOptions", "shortlistId", shortlistId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdActionOptions", "action", action)
      const localVarPath = "/company/{id}/missions/{mission_id}/shortlists/{shortlist_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
        .replace(`{${"shortlist_id"}}`, encodeURIComponent(String(shortlistId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistId
     * @param {string} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistsShortlistIdActionPatch: async (id: string, missionId: string, shortlistId: string, action: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdActionPatch", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdActionPatch", "missionId", missionId)
      // verify required parameter 'shortlistId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdActionPatch", "shortlistId", shortlistId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdActionPatch", "action", action)
      const localVarPath = "/company/{id}/missions/{mission_id}/shortlists/{shortlist_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
        .replace(`{${"shortlist_id"}}`, encodeURIComponent(String(shortlistId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistId
     * @param {string} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistsShortlistIdActionPut: async (id: string, missionId: string, shortlistId: string, action: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdActionPut", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdActionPut", "missionId", missionId)
      // verify required parameter 'shortlistId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdActionPut", "shortlistId", shortlistId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdActionPut", "action", action)
      const localVarPath = "/company/{id}/missions/{mission_id}/shortlists/{shortlist_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
        .replace(`{${"shortlist_id"}}`, encodeURIComponent(String(shortlistId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistsShortlistIdDelete: async (id: string, missionId: string, shortlistId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdDelete", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdDelete", "missionId", missionId)
      // verify required parameter 'shortlistId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdDelete", "shortlistId", shortlistId)
      const localVarPath = "/company/{id}/missions/{mission_id}/shortlists/{shortlist_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
        .replace(`{${"shortlist_id"}}`, encodeURIComponent(String(shortlistId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistsShortlistIdGet: async (id: string, missionId: string, shortlistId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdGet", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdGet", "missionId", missionId)
      // verify required parameter 'shortlistId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdGet", "shortlistId", shortlistId)
      const localVarPath = "/company/{id}/missions/{mission_id}/shortlists/{shortlist_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
        .replace(`{${"shortlist_id"}}`, encodeURIComponent(String(shortlistId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistsShortlistIdOptions: async (id: string, missionId: string, shortlistId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdOptions", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdOptions", "missionId", missionId)
      // verify required parameter 'shortlistId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdOptions", "shortlistId", shortlistId)
      const localVarPath = "/company/{id}/missions/{mission_id}/shortlists/{shortlist_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
        .replace(`{${"shortlist_id"}}`, encodeURIComponent(String(shortlistId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistsShortlistIdPatch: async (id: string, missionId: string, shortlistId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdPatch", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdPatch", "missionId", missionId)
      // verify required parameter 'shortlistId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdPatch", "shortlistId", shortlistId)
      const localVarPath = "/company/{id}/missions/{mission_id}/shortlists/{shortlist_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
        .replace(`{${"shortlist_id"}}`, encodeURIComponent(String(shortlistId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistsShortlistIdPut: async (id: string, missionId: string, shortlistId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdPut", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdPut", "missionId", missionId)
      // verify required parameter 'shortlistId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShortlistsShortlistIdPut", "shortlistId", shortlistId)
      const localVarPath = "/company/{id}/missions/{mission_id}/shortlists/{shortlist_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
        .replace(`{${"shortlist_id"}}`, encodeURIComponent(String(shortlistId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShowdownSlotsGet: async (id: string, missionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShowdownSlotsGet", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShowdownSlotsGet", "missionId", missionId)
      const localVarPath = "/company/{id}/missions/{mission_id}/showdown-slots/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShowdownSlotsOptions: async (id: string, missionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShowdownSlotsOptions", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShowdownSlotsOptions", "missionId", missionId)
      const localVarPath = "/company/{id}/missions/{mission_id}/showdown-slots/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShowdownSlotsPost: async (id: string, missionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShowdownSlotsPost", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShowdownSlotsPost", "missionId", missionId)
      const localVarPath = "/company/{id}/missions/{mission_id}/showdown-slots/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} showdownSlotsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShowdownSlotsShowdownSlotsIdGet: async (id: string, missionId: string, showdownSlotsId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShowdownSlotsShowdownSlotsIdGet", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShowdownSlotsShowdownSlotsIdGet", "missionId", missionId)
      // verify required parameter 'showdownSlotsId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShowdownSlotsShowdownSlotsIdGet", "showdownSlotsId", showdownSlotsId)
      const localVarPath = "/company/{id}/missions/{mission_id}/showdown-slots/{showdown_slots_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
        .replace(`{${"showdown_slots_id"}}`, encodeURIComponent(String(showdownSlotsId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} showdownSlotsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShowdownSlotsShowdownSlotsIdOptions: async (id: string, missionId: string, showdownSlotsId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShowdownSlotsShowdownSlotsIdOptions", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShowdownSlotsShowdownSlotsIdOptions", "missionId", missionId)
      // verify required parameter 'showdownSlotsId' is not null or undefined
      assertParamExists("companyIdMissionsMissionIdShowdownSlotsShowdownSlotsIdOptions", "showdownSlotsId", showdownSlotsId)
      const localVarPath = "/company/{id}/missions/{mission_id}/showdown-slots/{showdown_slots_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
        .replace(`{${"showdown_slots_id"}}`, encodeURIComponent(String(showdownSlotsId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsOptions", "id", id)
      const localVarPath = "/company/{id}/missions/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsPost: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdMissionsPost", "id", id)
      const localVarPath = "/company/{id}/missions/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdOptions", "id", id)
      const localVarPath = "/company/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdPatch: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdPatch", "id", id)
      const localVarPath = "/company/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdPublicGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdPublicGet", "id", id)
      const localVarPath = "/company/{id}/public/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdPublicOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdPublicOptions", "id", id)
      const localVarPath = "/company/{id}/public/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdPut: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdPut", "id", id)
      const localVarPath = "/company/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdReviewsGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdReviewsGet", "id", id)
      const localVarPath = "/company/{id}/reviews/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdReviewsOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdReviewsOptions", "id", id)
      const localVarPath = "/company/{id}/reviews/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdReviewsPost: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdReviewsPost", "id", id)
      const localVarPath = "/company/{id}/reviews/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} reviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdReviewsReviewIdGet: async (id: string, reviewId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdReviewsReviewIdGet", "id", id)
      // verify required parameter 'reviewId' is not null or undefined
      assertParamExists("companyIdReviewsReviewIdGet", "reviewId", reviewId)
      const localVarPath = "/company/{id}/reviews/{review_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"review_id"}}`, encodeURIComponent(String(reviewId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} reviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdReviewsReviewIdOptions: async (id: string, reviewId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdReviewsReviewIdOptions", "id", id)
      // verify required parameter 'reviewId' is not null or undefined
      assertParamExists("companyIdReviewsReviewIdOptions", "reviewId", reviewId)
      const localVarPath = "/company/{id}/reviews/{review_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"review_id"}}`, encodeURIComponent(String(reviewId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} reviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdReviewsReviewIdPatch: async (id: string, reviewId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdReviewsReviewIdPatch", "id", id)
      // verify required parameter 'reviewId' is not null or undefined
      assertParamExists("companyIdReviewsReviewIdPatch", "reviewId", reviewId)
      const localVarPath = "/company/{id}/reviews/{review_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"review_id"}}`, encodeURIComponent(String(reviewId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} reviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdReviewsReviewIdPut: async (id: string, reviewId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyIdReviewsReviewIdPut", "id", id)
      // verify required parameter 'reviewId' is not null or undefined
      assertParamExists("companyIdReviewsReviewIdPut", "reviewId", reviewId)
      const localVarPath = "/company/{id}/reviews/{review_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"review_id"}}`, encodeURIComponent(String(reviewId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} experienceLevel The experience level PK being considered
     * @param {CompanyMissionBudgetRecommendationsGetCompensationPeriodEnum} compensationPeriod The compensation period being considered
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyMissionBudgetRecommendationsGet: async (id: string, experienceLevel: number, compensationPeriod: CompanyMissionBudgetRecommendationsGetCompensationPeriodEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyMissionBudgetRecommendationsGet", "id", id)
      // verify required parameter 'experienceLevel' is not null or undefined
      assertParamExists("companyMissionBudgetRecommendationsGet", "experienceLevel", experienceLevel)
      // verify required parameter 'compensationPeriod' is not null or undefined
      assertParamExists("companyMissionBudgetRecommendationsGet", "compensationPeriod", compensationPeriod)
      const localVarPath = "/company/{id}/mission_budget_recommendations/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (experienceLevel !== undefined) {
        localVarQueryParameter.experience_level = experienceLevel
      }

      if (compensationPeriod !== undefined) {
        localVarQueryParameter.compensation_period = compensationPeriod
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyMissionBudgetRecommendationsOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyMissionBudgetRecommendationsOptions", "id", id)
      const localVarPath = "/company/{id}/mission_budget_recommendations/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} applicationId
     * @param {CompanyMissionJobApplicationActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyMissionJobApplicationActionOptions: async (id: string, missionId: string, applicationId: string, action: CompanyMissionJobApplicationActionOptionsActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyMissionJobApplicationActionOptions", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyMissionJobApplicationActionOptions", "missionId", missionId)
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists("companyMissionJobApplicationActionOptions", "applicationId", applicationId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("companyMissionJobApplicationActionOptions", "action", action)
      const localVarPath = "/company/{id}/missions/{mission_id}/applicants/{application_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
        .replace(`{${"application_id"}}`, encodeURIComponent(String(applicationId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} applicationId
     * @param {CompanyMissionJobApplicationActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyMissionJobApplicationActionPut: async (id: string, missionId: string, applicationId: string, action: CompanyMissionJobApplicationActionPutActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyMissionJobApplicationActionPut", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyMissionJobApplicationActionPut", "missionId", missionId)
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists("companyMissionJobApplicationActionPut", "applicationId", applicationId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("companyMissionJobApplicationActionPut", "action", action)
      const localVarPath = "/company/{id}/missions/{mission_id}/applicants/{application_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
        .replace(`{${"application_id"}}`, encodeURIComponent(String(applicationId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} applicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyMissionJobApplicationDetailGet: async (id: string, missionId: string, applicationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyMissionJobApplicationDetailGet", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyMissionJobApplicationDetailGet", "missionId", missionId)
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists("companyMissionJobApplicationDetailGet", "applicationId", applicationId)
      const localVarPath = "/company/{id}/missions/{mission_id}/applicants/{application_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
        .replace(`{${"application_id"}}`, encodeURIComponent(String(applicationId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} applicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyMissionJobApplicationDetailOptions: async (id: string, missionId: string, applicationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyMissionJobApplicationDetailOptions", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyMissionJobApplicationDetailOptions", "missionId", missionId)
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists("companyMissionJobApplicationDetailOptions", "applicationId", applicationId)
      const localVarPath = "/company/{id}/missions/{mission_id}/applicants/{application_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
        .replace(`{${"application_id"}}`, encodeURIComponent(String(applicationId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyMissionJobApplicationListGet: async (id: string, missionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyMissionJobApplicationListGet", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyMissionJobApplicationListGet", "missionId", missionId)
      const localVarPath = "/company/{id}/missions/{mission_id}/applicants/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyMissionJobApplicationListOptions: async (id: string, missionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyMissionJobApplicationListOptions", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyMissionJobApplicationListOptions", "missionId", missionId)
      const localVarPath = "/company/{id}/missions/{mission_id}/applicants/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyShortlistFreelancerMissionDisplayListGet: async (id: string, missionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyShortlistFreelancerMissionDisplayListGet", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyShortlistFreelancerMissionDisplayListGet", "missionId", missionId)
      const localVarPath = "/company/{id}/missions/{mission_id}/shortlist_freelancers/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyShortlistFreelancerMissionDisplayListOptions: async (id: string, missionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyShortlistFreelancerMissionDisplayListOptions", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyShortlistFreelancerMissionDisplayListOptions", "missionId", missionId)
      const localVarPath = "/company/{id}/missions/{mission_id}/shortlist_freelancers/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyShortlistFreelancerMissionDisplayListPost: async (id: string, missionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("companyShortlistFreelancerMissionDisplayListPost", "id", id)
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists("companyShortlistFreelancerMissionDisplayListPost", "missionId", missionId)
      const localVarPath = "/company/{id}/missions/{mission_id}/shortlist_freelancers/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_id"}}`, encodeURIComponent(String(missionId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    factoriesApplicationsOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/factories/applications/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    factoriesApplicationsPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/factories/applications/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    factoriesCompaniesOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/factories/companies/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    factoriesCompaniesPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/factories/companies/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    factoriesDealsOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/factories/deals/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    factoriesDealsPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/factories/deals/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    factoriesFreelancersOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/factories/freelancers/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    factoriesFreelancersPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/factories/freelancers/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    factoriesJobsOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/factories/jobs/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    factoriesJobsPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/factories/jobs/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    factoriesMembersOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/factories/members/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    factoriesMembersPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/factories/members/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    factoriesPresentationsOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/factories/presentations/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    factoriesPresentationsPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/factories/presentations/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    factoriesUsersOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/factories/users/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    factoriesUsersPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/factories/users/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerCompleteScreeningGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/freelancer/complete-screening/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerCompleteScreeningOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/freelancer/complete-screening/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerCompleteScreeningPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/freelancer/complete-screening/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/freelancer/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {FreelancerIdApplicationsApplicationIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdApplicationsApplicationIdActionGet: async (id: string, applicationId: string, action: FreelancerIdApplicationsApplicationIdActionGetActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdApplicationsApplicationIdActionGet", "id", id)
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists("freelancerIdApplicationsApplicationIdActionGet", "applicationId", applicationId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("freelancerIdApplicationsApplicationIdActionGet", "action", action)
      const localVarPath = "/freelancer/{id}/applications/{application_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"application_id"}}`, encodeURIComponent(String(applicationId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {FreelancerIdApplicationsApplicationIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdApplicationsApplicationIdActionOptions: async (id: string, applicationId: string, action: FreelancerIdApplicationsApplicationIdActionOptionsActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdApplicationsApplicationIdActionOptions", "id", id)
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists("freelancerIdApplicationsApplicationIdActionOptions", "applicationId", applicationId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("freelancerIdApplicationsApplicationIdActionOptions", "action", action)
      const localVarPath = "/freelancer/{id}/applications/{application_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"application_id"}}`, encodeURIComponent(String(applicationId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {FreelancerIdApplicationsApplicationIdActionPatchActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdApplicationsApplicationIdActionPatch: async (id: string, applicationId: string, action: FreelancerIdApplicationsApplicationIdActionPatchActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdApplicationsApplicationIdActionPatch", "id", id)
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists("freelancerIdApplicationsApplicationIdActionPatch", "applicationId", applicationId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("freelancerIdApplicationsApplicationIdActionPatch", "action", action)
      const localVarPath = "/freelancer/{id}/applications/{application_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"application_id"}}`, encodeURIComponent(String(applicationId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {FreelancerIdApplicationsApplicationIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdApplicationsApplicationIdActionPut: async (id: string, applicationId: string, action: FreelancerIdApplicationsApplicationIdActionPutActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdApplicationsApplicationIdActionPut", "id", id)
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists("freelancerIdApplicationsApplicationIdActionPut", "applicationId", applicationId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("freelancerIdApplicationsApplicationIdActionPut", "action", action)
      const localVarPath = "/freelancer/{id}/applications/{application_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"application_id"}}`, encodeURIComponent(String(applicationId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdApplicationsApplicationIdGet: async (id: string, applicationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdApplicationsApplicationIdGet", "id", id)
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists("freelancerIdApplicationsApplicationIdGet", "applicationId", applicationId)
      const localVarPath = "/freelancer/{id}/applications/{application_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"application_id"}}`, encodeURIComponent(String(applicationId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdApplicationsApplicationIdOptions: async (id: string, applicationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdApplicationsApplicationIdOptions", "id", id)
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists("freelancerIdApplicationsApplicationIdOptions", "applicationId", applicationId)
      const localVarPath = "/freelancer/{id}/applications/{application_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"application_id"}}`, encodeURIComponent(String(applicationId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdApplicationsApplicationIdPatch: async (id: string, applicationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdApplicationsApplicationIdPatch", "id", id)
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists("freelancerIdApplicationsApplicationIdPatch", "applicationId", applicationId)
      const localVarPath = "/freelancer/{id}/applications/{application_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"application_id"}}`, encodeURIComponent(String(applicationId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdApplicationsApplicationIdPut: async (id: string, applicationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdApplicationsApplicationIdPut", "id", id)
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists("freelancerIdApplicationsApplicationIdPut", "applicationId", applicationId)
      const localVarPath = "/freelancer/{id}/applications/{application_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"application_id"}}`, encodeURIComponent(String(applicationId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdApplicationsGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdApplicationsGet", "id", id)
      const localVarPath = "/freelancer/{id}/applications/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdApplicationsOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdApplicationsOptions", "id", id)
      const localVarPath = "/freelancer/{id}/applications/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdApplicationsPost: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdApplicationsPost", "id", id)
      const localVarPath = "/freelancer/{id}/applications/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdDelete", "id", id)
      const localVarPath = "/freelancer/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} experienceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdExperiencesExperienceIdDelete: async (id: string, experienceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdExperiencesExperienceIdDelete", "id", id)
      // verify required parameter 'experienceId' is not null or undefined
      assertParamExists("freelancerIdExperiencesExperienceIdDelete", "experienceId", experienceId)
      const localVarPath = "/freelancer/{id}/experiences/{experience_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"experience_id"}}`, encodeURIComponent(String(experienceId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} experienceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdExperiencesExperienceIdGet: async (id: string, experienceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdExperiencesExperienceIdGet", "id", id)
      // verify required parameter 'experienceId' is not null or undefined
      assertParamExists("freelancerIdExperiencesExperienceIdGet", "experienceId", experienceId)
      const localVarPath = "/freelancer/{id}/experiences/{experience_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"experience_id"}}`, encodeURIComponent(String(experienceId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} experienceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdExperiencesExperienceIdOptions: async (id: string, experienceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdExperiencesExperienceIdOptions", "id", id)
      // verify required parameter 'experienceId' is not null or undefined
      assertParamExists("freelancerIdExperiencesExperienceIdOptions", "experienceId", experienceId)
      const localVarPath = "/freelancer/{id}/experiences/{experience_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"experience_id"}}`, encodeURIComponent(String(experienceId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} experienceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdExperiencesExperienceIdPatch: async (id: string, experienceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdExperiencesExperienceIdPatch", "id", id)
      // verify required parameter 'experienceId' is not null or undefined
      assertParamExists("freelancerIdExperiencesExperienceIdPatch", "experienceId", experienceId)
      const localVarPath = "/freelancer/{id}/experiences/{experience_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"experience_id"}}`, encodeURIComponent(String(experienceId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} experienceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdExperiencesExperienceIdPut: async (id: string, experienceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdExperiencesExperienceIdPut", "id", id)
      // verify required parameter 'experienceId' is not null or undefined
      assertParamExists("freelancerIdExperiencesExperienceIdPut", "experienceId", experienceId)
      const localVarPath = "/freelancer/{id}/experiences/{experience_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"experience_id"}}`, encodeURIComponent(String(experienceId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdExperiencesGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdExperiencesGet", "id", id)
      const localVarPath = "/freelancer/{id}/experiences/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdExperiencesOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdExperiencesOptions", "id", id)
      const localVarPath = "/freelancer/{id}/experiences/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdExperiencesPost: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdExperiencesPost", "id", id)
      const localVarPath = "/freelancer/{id}/experiences/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdGet", "id", id)
      const localVarPath = "/freelancer/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdHiresGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdHiresGet", "id", id)
      const localVarPath = "/freelancer/{id}/hires/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} hireId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdHiresHireIdGet: async (id: string, hireId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdHiresHireIdGet", "id", id)
      // verify required parameter 'hireId' is not null or undefined
      assertParamExists("freelancerIdHiresHireIdGet", "hireId", hireId)
      const localVarPath = "/freelancer/{id}/hires/{hire_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"hire_id"}}`, encodeURIComponent(String(hireId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} hireId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdHiresHireIdOptions: async (id: string, hireId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdHiresHireIdOptions", "id", id)
      // verify required parameter 'hireId' is not null or undefined
      assertParamExists("freelancerIdHiresHireIdOptions", "hireId", hireId)
      const localVarPath = "/freelancer/{id}/hires/{hire_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"hire_id"}}`, encodeURIComponent(String(hireId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdHiresOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdHiresOptions", "id", id)
      const localVarPath = "/freelancer/{id}/hires/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdInterviewsGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdInterviewsGet", "id", id)
      const localVarPath = "/freelancer/{id}/interviews/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {FreelancerIdInterviewsInterviewIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdInterviewsInterviewIdActionGet: async (id: string, interviewId: string, action: FreelancerIdInterviewsInterviewIdActionGetActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdInterviewsInterviewIdActionGet", "id", id)
      // verify required parameter 'interviewId' is not null or undefined
      assertParamExists("freelancerIdInterviewsInterviewIdActionGet", "interviewId", interviewId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("freelancerIdInterviewsInterviewIdActionGet", "action", action)
      const localVarPath = "/freelancer/{id}/interviews/{interview_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"interview_id"}}`, encodeURIComponent(String(interviewId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {FreelancerIdInterviewsInterviewIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdInterviewsInterviewIdActionOptions: async (id: string, interviewId: string, action: FreelancerIdInterviewsInterviewIdActionOptionsActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdInterviewsInterviewIdActionOptions", "id", id)
      // verify required parameter 'interviewId' is not null or undefined
      assertParamExists("freelancerIdInterviewsInterviewIdActionOptions", "interviewId", interviewId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("freelancerIdInterviewsInterviewIdActionOptions", "action", action)
      const localVarPath = "/freelancer/{id}/interviews/{interview_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"interview_id"}}`, encodeURIComponent(String(interviewId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {FreelancerIdInterviewsInterviewIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdInterviewsInterviewIdActionPut: async (id: string, interviewId: string, action: FreelancerIdInterviewsInterviewIdActionPutActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdInterviewsInterviewIdActionPut", "id", id)
      // verify required parameter 'interviewId' is not null or undefined
      assertParamExists("freelancerIdInterviewsInterviewIdActionPut", "interviewId", interviewId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("freelancerIdInterviewsInterviewIdActionPut", "action", action)
      const localVarPath = "/freelancer/{id}/interviews/{interview_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"interview_id"}}`, encodeURIComponent(String(interviewId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdInterviewsInterviewIdGet: async (id: string, interviewId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdInterviewsInterviewIdGet", "id", id)
      // verify required parameter 'interviewId' is not null or undefined
      assertParamExists("freelancerIdInterviewsInterviewIdGet", "interviewId", interviewId)
      const localVarPath = "/freelancer/{id}/interviews/{interview_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"interview_id"}}`, encodeURIComponent(String(interviewId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdInterviewsInterviewIdOptions: async (id: string, interviewId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdInterviewsInterviewIdOptions", "id", id)
      // verify required parameter 'interviewId' is not null or undefined
      assertParamExists("freelancerIdInterviewsInterviewIdOptions", "interviewId", interviewId)
      const localVarPath = "/freelancer/{id}/interviews/{interview_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"interview_id"}}`, encodeURIComponent(String(interviewId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdInterviewsInterviewIdPut: async (id: string, interviewId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdInterviewsInterviewIdPut", "id", id)
      // verify required parameter 'interviewId' is not null or undefined
      assertParamExists("freelancerIdInterviewsInterviewIdPut", "interviewId", interviewId)
      const localVarPath = "/freelancer/{id}/interviews/{interview_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"interview_id"}}`, encodeURIComponent(String(interviewId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdInterviewsOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdInterviewsOptions", "id", id)
      const localVarPath = "/freelancer/{id}/interviews/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdInterviewsPost: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdInterviewsPost", "id", id)
      const localVarPath = "/freelancer/{id}/interviews/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdInvitesGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdInvitesGet", "id", id)
      const localVarPath = "/freelancer/{id}/invites/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} inviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdInvitesInviteIdDelete: async (id: string, inviteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdInvitesInviteIdDelete", "id", id)
      // verify required parameter 'inviteId' is not null or undefined
      assertParamExists("freelancerIdInvitesInviteIdDelete", "inviteId", inviteId)
      const localVarPath = "/freelancer/{id}/invites/{invite_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"invite_id"}}`, encodeURIComponent(String(inviteId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} inviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdInvitesInviteIdGet: async (id: string, inviteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdInvitesInviteIdGet", "id", id)
      // verify required parameter 'inviteId' is not null or undefined
      assertParamExists("freelancerIdInvitesInviteIdGet", "inviteId", inviteId)
      const localVarPath = "/freelancer/{id}/invites/{invite_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"invite_id"}}`, encodeURIComponent(String(inviteId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} inviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdInvitesInviteIdOptions: async (id: string, inviteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdInvitesInviteIdOptions", "id", id)
      // verify required parameter 'inviteId' is not null or undefined
      assertParamExists("freelancerIdInvitesInviteIdOptions", "inviteId", inviteId)
      const localVarPath = "/freelancer/{id}/invites/{invite_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"invite_id"}}`, encodeURIComponent(String(inviteId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdInvitesOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdInvitesOptions", "id", id)
      const localVarPath = "/freelancer/{id}/invites/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdMissionInterviewsGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdMissionInterviewsGet", "id", id)
      const localVarPath = "/freelancer/{id}/mission_interviews/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionInterviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdMissionInterviewsMissionInterviewIdDelete: async (id: string, missionInterviewId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdMissionInterviewsMissionInterviewIdDelete", "id", id)
      // verify required parameter 'missionInterviewId' is not null or undefined
      assertParamExists("freelancerIdMissionInterviewsMissionInterviewIdDelete", "missionInterviewId", missionInterviewId)
      const localVarPath = "/freelancer/{id}/mission_interviews/{mission_interview_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_interview_id"}}`, encodeURIComponent(String(missionInterviewId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionInterviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdMissionInterviewsMissionInterviewIdGet: async (id: string, missionInterviewId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdMissionInterviewsMissionInterviewIdGet", "id", id)
      // verify required parameter 'missionInterviewId' is not null or undefined
      assertParamExists("freelancerIdMissionInterviewsMissionInterviewIdGet", "missionInterviewId", missionInterviewId)
      const localVarPath = "/freelancer/{id}/mission_interviews/{mission_interview_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_interview_id"}}`, encodeURIComponent(String(missionInterviewId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionInterviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdMissionInterviewsMissionInterviewIdOptions: async (id: string, missionInterviewId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdMissionInterviewsMissionInterviewIdOptions", "id", id)
      // verify required parameter 'missionInterviewId' is not null or undefined
      assertParamExists("freelancerIdMissionInterviewsMissionInterviewIdOptions", "missionInterviewId", missionInterviewId)
      const localVarPath = "/freelancer/{id}/mission_interviews/{mission_interview_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_interview_id"}}`, encodeURIComponent(String(missionInterviewId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionInterviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdMissionInterviewsMissionInterviewIdPatch: async (id: string, missionInterviewId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdMissionInterviewsMissionInterviewIdPatch", "id", id)
      // verify required parameter 'missionInterviewId' is not null or undefined
      assertParamExists("freelancerIdMissionInterviewsMissionInterviewIdPatch", "missionInterviewId", missionInterviewId)
      const localVarPath = "/freelancer/{id}/mission_interviews/{mission_interview_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_interview_id"}}`, encodeURIComponent(String(missionInterviewId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} missionInterviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdMissionInterviewsMissionInterviewIdPut: async (id: string, missionInterviewId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdMissionInterviewsMissionInterviewIdPut", "id", id)
      // verify required parameter 'missionInterviewId' is not null or undefined
      assertParamExists("freelancerIdMissionInterviewsMissionInterviewIdPut", "missionInterviewId", missionInterviewId)
      const localVarPath = "/freelancer/{id}/mission_interviews/{mission_interview_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"mission_interview_id"}}`, encodeURIComponent(String(missionInterviewId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdMissionInterviewsOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdMissionInterviewsOptions", "id", id)
      const localVarPath = "/freelancer/{id}/mission_interviews/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdMissionInterviewsPost: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdMissionInterviewsPost", "id", id)
      const localVarPath = "/freelancer/{id}/mission_interviews/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdOptions", "id", id)
      const localVarPath = "/freelancer/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdPatch: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdPatch", "id", id)
      const localVarPath = "/freelancer/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdPresentationsGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdPresentationsGet", "id", id)
      const localVarPath = "/freelancer/{id}/presentations/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdPresentationsOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdPresentationsOptions", "id", id)
      const localVarPath = "/freelancer/{id}/presentations/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {FreelancerIdPresentationsPresentationIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdPresentationsPresentationIdActionGet: async (id: string, presentationId: string, action: FreelancerIdPresentationsPresentationIdActionGetActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdPresentationsPresentationIdActionGet", "id", id)
      // verify required parameter 'presentationId' is not null or undefined
      assertParamExists("freelancerIdPresentationsPresentationIdActionGet", "presentationId", presentationId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("freelancerIdPresentationsPresentationIdActionGet", "action", action)
      const localVarPath = "/freelancer/{id}/presentations/{presentation_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"presentation_id"}}`, encodeURIComponent(String(presentationId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {FreelancerIdPresentationsPresentationIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdPresentationsPresentationIdActionOptions: async (id: string, presentationId: string, action: FreelancerIdPresentationsPresentationIdActionOptionsActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdPresentationsPresentationIdActionOptions", "id", id)
      // verify required parameter 'presentationId' is not null or undefined
      assertParamExists("freelancerIdPresentationsPresentationIdActionOptions", "presentationId", presentationId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("freelancerIdPresentationsPresentationIdActionOptions", "action", action)
      const localVarPath = "/freelancer/{id}/presentations/{presentation_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"presentation_id"}}`, encodeURIComponent(String(presentationId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {FreelancerIdPresentationsPresentationIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdPresentationsPresentationIdActionPut: async (id: string, presentationId: string, action: FreelancerIdPresentationsPresentationIdActionPutActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdPresentationsPresentationIdActionPut", "id", id)
      // verify required parameter 'presentationId' is not null or undefined
      assertParamExists("freelancerIdPresentationsPresentationIdActionPut", "presentationId", presentationId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("freelancerIdPresentationsPresentationIdActionPut", "action", action)
      const localVarPath = "/freelancer/{id}/presentations/{presentation_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"presentation_id"}}`, encodeURIComponent(String(presentationId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdPresentationsPresentationIdGet: async (id: string, presentationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdPresentationsPresentationIdGet", "id", id)
      // verify required parameter 'presentationId' is not null or undefined
      assertParamExists("freelancerIdPresentationsPresentationIdGet", "presentationId", presentationId)
      const localVarPath = "/freelancer/{id}/presentations/{presentation_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"presentation_id"}}`, encodeURIComponent(String(presentationId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdPresentationsPresentationIdOptions: async (id: string, presentationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdPresentationsPresentationIdOptions", "id", id)
      // verify required parameter 'presentationId' is not null or undefined
      assertParamExists("freelancerIdPresentationsPresentationIdOptions", "presentationId", presentationId)
      const localVarPath = "/freelancer/{id}/presentations/{presentation_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"presentation_id"}}`, encodeURIComponent(String(presentationId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdPresentationsPresentationIdPut: async (id: string, presentationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdPresentationsPresentationIdPut", "id", id)
      // verify required parameter 'presentationId' is not null or undefined
      assertParamExists("freelancerIdPresentationsPresentationIdPut", "presentationId", presentationId)
      const localVarPath = "/freelancer/{id}/presentations/{presentation_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"presentation_id"}}`, encodeURIComponent(String(presentationId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdProjectsGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdProjectsGet", "id", id)
      const localVarPath = "/freelancer/{id}/projects/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdProjectsOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdProjectsOptions", "id", id)
      const localVarPath = "/freelancer/{id}/projects/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdProjectsPost: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdProjectsPost", "id", id)
      const localVarPath = "/freelancer/{id}/projects/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdProjectsProjectIdDelete: async (id: string, projectId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdProjectsProjectIdDelete", "id", id)
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("freelancerIdProjectsProjectIdDelete", "projectId", projectId)
      const localVarPath = "/freelancer/{id}/projects/{project_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdProjectsProjectIdGet: async (id: string, projectId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdProjectsProjectIdGet", "id", id)
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("freelancerIdProjectsProjectIdGet", "projectId", projectId)
      const localVarPath = "/freelancer/{id}/projects/{project_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdProjectsProjectIdOptions: async (id: string, projectId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdProjectsProjectIdOptions", "id", id)
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("freelancerIdProjectsProjectIdOptions", "projectId", projectId)
      const localVarPath = "/freelancer/{id}/projects/{project_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdProjectsProjectIdPatch: async (id: string, projectId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdProjectsProjectIdPatch", "id", id)
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("freelancerIdProjectsProjectIdPatch", "projectId", projectId)
      const localVarPath = "/freelancer/{id}/projects/{project_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdProjectsProjectIdPut: async (id: string, projectId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdProjectsProjectIdPut", "id", id)
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("freelancerIdProjectsProjectIdPut", "projectId", projectId)
      const localVarPath = "/freelancer/{id}/projects/{project_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdPut: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdPut", "id", id)
      const localVarPath = "/freelancer/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdReferencesGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdReferencesGet", "id", id)
      const localVarPath = "/freelancer/{id}/references/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdReferencesOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdReferencesOptions", "id", id)
      const localVarPath = "/freelancer/{id}/references/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdReferencesPost: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdReferencesPost", "id", id)
      const localVarPath = "/freelancer/{id}/references/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} referenceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdReferencesReferenceIdDelete: async (id: string, referenceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdReferencesReferenceIdDelete", "id", id)
      // verify required parameter 'referenceId' is not null or undefined
      assertParamExists("freelancerIdReferencesReferenceIdDelete", "referenceId", referenceId)
      const localVarPath = "/freelancer/{id}/references/{reference_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"reference_id"}}`, encodeURIComponent(String(referenceId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} referenceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdReferencesReferenceIdGet: async (id: string, referenceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdReferencesReferenceIdGet", "id", id)
      // verify required parameter 'referenceId' is not null or undefined
      assertParamExists("freelancerIdReferencesReferenceIdGet", "referenceId", referenceId)
      const localVarPath = "/freelancer/{id}/references/{reference_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"reference_id"}}`, encodeURIComponent(String(referenceId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} referenceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdReferencesReferenceIdOptions: async (id: string, referenceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdReferencesReferenceIdOptions", "id", id)
      // verify required parameter 'referenceId' is not null or undefined
      assertParamExists("freelancerIdReferencesReferenceIdOptions", "referenceId", referenceId)
      const localVarPath = "/freelancer/{id}/references/{reference_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"reference_id"}}`, encodeURIComponent(String(referenceId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} referenceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdReferencesReferenceIdPatch: async (id: string, referenceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdReferencesReferenceIdPatch", "id", id)
      // verify required parameter 'referenceId' is not null or undefined
      assertParamExists("freelancerIdReferencesReferenceIdPatch", "referenceId", referenceId)
      const localVarPath = "/freelancer/{id}/references/{reference_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"reference_id"}}`, encodeURIComponent(String(referenceId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} referenceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdReferencesReferenceIdPut: async (id: string, referenceId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdReferencesReferenceIdPut", "id", id)
      // verify required parameter 'referenceId' is not null or undefined
      assertParamExists("freelancerIdReferencesReferenceIdPut", "referenceId", referenceId)
      const localVarPath = "/freelancer/{id}/references/{reference_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"reference_id"}}`, encodeURIComponent(String(referenceId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdReviewsGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdReviewsGet", "id", id)
      const localVarPath = "/freelancer/{id}/reviews/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdReviewsOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdReviewsOptions", "id", id)
      const localVarPath = "/freelancer/{id}/reviews/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} reviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdReviewsReviewIdGet: async (id: string, reviewId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdReviewsReviewIdGet", "id", id)
      // verify required parameter 'reviewId' is not null or undefined
      assertParamExists("freelancerIdReviewsReviewIdGet", "reviewId", reviewId)
      const localVarPath = "/freelancer/{id}/reviews/{review_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"review_id"}}`, encodeURIComponent(String(reviewId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} reviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdReviewsReviewIdOptions: async (id: string, reviewId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdReviewsReviewIdOptions", "id", id)
      // verify required parameter 'reviewId' is not null or undefined
      assertParamExists("freelancerIdReviewsReviewIdOptions", "reviewId", reviewId)
      const localVarPath = "/freelancer/{id}/reviews/{review_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"review_id"}}`, encodeURIComponent(String(reviewId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} reviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdReviewsReviewIdPatch: async (id: string, reviewId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdReviewsReviewIdPatch", "id", id)
      // verify required parameter 'reviewId' is not null or undefined
      assertParamExists("freelancerIdReviewsReviewIdPatch", "reviewId", reviewId)
      const localVarPath = "/freelancer/{id}/reviews/{review_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"review_id"}}`, encodeURIComponent(String(reviewId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} reviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdReviewsReviewIdPut: async (id: string, reviewId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdReviewsReviewIdPut", "id", id)
      // verify required parameter 'reviewId' is not null or undefined
      assertParamExists("freelancerIdReviewsReviewIdPut", "reviewId", reviewId)
      const localVarPath = "/freelancer/{id}/reviews/{review_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"review_id"}}`, encodeURIComponent(String(reviewId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} shortlistFreelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdShortlistFreelancersShortlistFreelancerIdGet: async (id: string, shortlistFreelancerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdShortlistFreelancersShortlistFreelancerIdGet", "id", id)
      // verify required parameter 'shortlistFreelancerId' is not null or undefined
      assertParamExists("freelancerIdShortlistFreelancersShortlistFreelancerIdGet", "shortlistFreelancerId", shortlistFreelancerId)
      const localVarPath = "/freelancer/{id}/shortlist_freelancers/{shortlist_freelancer_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"shortlist_freelancer_id"}}`, encodeURIComponent(String(shortlistFreelancerId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} shortlistFreelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdShortlistFreelancersShortlistFreelancerIdOptions: async (id: string, shortlistFreelancerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdShortlistFreelancersShortlistFreelancerIdOptions", "id", id)
      // verify required parameter 'shortlistFreelancerId' is not null or undefined
      assertParamExists("freelancerIdShortlistFreelancersShortlistFreelancerIdOptions", "shortlistFreelancerId", shortlistFreelancerId)
      const localVarPath = "/freelancer/{id}/shortlist_freelancers/{shortlist_freelancer_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"shortlist_freelancer_id"}}`, encodeURIComponent(String(shortlistFreelancerId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} shortlistFreelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdShortlistFreelancersShortlistFreelancerIdPatch: async (id: string, shortlistFreelancerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdShortlistFreelancersShortlistFreelancerIdPatch", "id", id)
      // verify required parameter 'shortlistFreelancerId' is not null or undefined
      assertParamExists("freelancerIdShortlistFreelancersShortlistFreelancerIdPatch", "shortlistFreelancerId", shortlistFreelancerId)
      const localVarPath = "/freelancer/{id}/shortlist_freelancers/{shortlist_freelancer_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"shortlist_freelancer_id"}}`, encodeURIComponent(String(shortlistFreelancerId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} shortlistFreelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdShortlistFreelancersShortlistFreelancerIdPut: async (id: string, shortlistFreelancerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerIdShortlistFreelancersShortlistFreelancerIdPut", "id", id)
      // verify required parameter 'shortlistFreelancerId' is not null or undefined
      assertParamExists("freelancerIdShortlistFreelancersShortlistFreelancerIdPut", "shortlistFreelancerId", shortlistFreelancerId)
      const localVarPath = "/freelancer/{id}/shortlist_freelancers/{shortlist_freelancer_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"shortlist_freelancer_id"}}`, encodeURIComponent(String(shortlistFreelancerId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/freelancer/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerPatch: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/freelancer/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerPut: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/freelancer/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerRateRecommendationsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/freelancer/rate_recommendations/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerRateRecommendationsOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/freelancer/rate_recommendations/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerShareIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerShareIdGet", "id", id)
      const localVarPath = "/freelancer/share/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerShareIdOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("freelancerShareIdOptions", "id", id)
      const localVarPath = "/freelancer/share/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerShareOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/freelancer/share/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerSharePost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/freelancer/share/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} tier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    hourlyRateTierGet: async (tier: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'tier' is not null or undefined
      assertParamExists("hourlyRateTierGet", "tier", tier)
      const localVarPath = "/hourly-rate/{tier}/"
        .replace(`{${"tier"}}`, encodeURIComponent(String(tier)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} tier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    hourlyRateTierOptions: async (tier: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'tier' is not null or undefined
      assertParamExists("hourlyRateTierOptions", "tier", tier)
      const localVarPath = "/hourly-rate/{tier}/"
        .replace(`{${"tier"}}`, encodeURIComponent(String(tier)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/jobs/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobsIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("jobsIdGet", "id", id)
      const localVarPath = "/jobs/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobsIdOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("jobsIdOptions", "id", id)
      const localVarPath = "/jobs/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobsOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/jobs/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffApplicationsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/staff/applications/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffApplicationsIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffApplicationsIdGet", "id", id)
      const localVarPath = "/staff/applications/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffApplicationsIdOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffApplicationsIdOptions", "id", id)
      const localVarPath = "/staff/applications/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffApplicationsIdPatch: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffApplicationsIdPatch", "id", id)
      const localVarPath = "/staff/applications/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffApplicationsIdPut: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffApplicationsIdPut", "id", id)
      const localVarPath = "/staff/applications/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffApplicationsOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/staff/applications/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffAppointmentsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/staff/appointments/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffAppointmentsIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffAppointmentsIdGet", "id", id)
      const localVarPath = "/staff/appointments/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffAppointmentsIdOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffAppointmentsIdOptions", "id", id)
      const localVarPath = "/staff/appointments/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffAppointmentsIdPatch: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffAppointmentsIdPatch", "id", id)
      const localVarPath = "/staff/appointments/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffAppointmentsIdPut: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffAppointmentsIdPut", "id", id)
      const localVarPath = "/staff/appointments/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffAppointmentsIdRecordingsGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffAppointmentsIdRecordingsGet", "id", id)
      const localVarPath = "/staff/appointments/{id}/recordings/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffAppointmentsIdRecordingsOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffAppointmentsIdRecordingsOptions", "id", id)
      const localVarPath = "/staff/appointments/{id}/recordings/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffAppointmentsIdRecordingsPost: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffAppointmentsIdRecordingsPost", "id", id)
      const localVarPath = "/staff/appointments/{id}/recordings/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffAppointmentsIdRecordingsRecordingIdDelete: async (id: string, recordingId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffAppointmentsIdRecordingsRecordingIdDelete", "id", id)
      // verify required parameter 'recordingId' is not null or undefined
      assertParamExists("staffAppointmentsIdRecordingsRecordingIdDelete", "recordingId", recordingId)
      const localVarPath = "/staff/appointments/{id}/recordings/{recording_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"recording_id"}}`, encodeURIComponent(String(recordingId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffAppointmentsIdRecordingsRecordingIdGet: async (id: string, recordingId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffAppointmentsIdRecordingsRecordingIdGet", "id", id)
      // verify required parameter 'recordingId' is not null or undefined
      assertParamExists("staffAppointmentsIdRecordingsRecordingIdGet", "recordingId", recordingId)
      const localVarPath = "/staff/appointments/{id}/recordings/{recording_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"recording_id"}}`, encodeURIComponent(String(recordingId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffAppointmentsIdRecordingsRecordingIdOptions: async (id: string, recordingId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffAppointmentsIdRecordingsRecordingIdOptions", "id", id)
      // verify required parameter 'recordingId' is not null or undefined
      assertParamExists("staffAppointmentsIdRecordingsRecordingIdOptions", "recordingId", recordingId)
      const localVarPath = "/staff/appointments/{id}/recordings/{recording_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"recording_id"}}`, encodeURIComponent(String(recordingId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffAppointmentsIdRecordingsRecordingIdPatch: async (id: string, recordingId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffAppointmentsIdRecordingsRecordingIdPatch", "id", id)
      // verify required parameter 'recordingId' is not null or undefined
      assertParamExists("staffAppointmentsIdRecordingsRecordingIdPatch", "recordingId", recordingId)
      const localVarPath = "/staff/appointments/{id}/recordings/{recording_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"recording_id"}}`, encodeURIComponent(String(recordingId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffAppointmentsIdRecordingsRecordingIdPut: async (id: string, recordingId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffAppointmentsIdRecordingsRecordingIdPut", "id", id)
      // verify required parameter 'recordingId' is not null or undefined
      assertParamExists("staffAppointmentsIdRecordingsRecordingIdPut", "recordingId", recordingId)
      const localVarPath = "/staff/appointments/{id}/recordings/{recording_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"recording_id"}}`, encodeURIComponent(String(recordingId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffAppointmentsOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/staff/appointments/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffChoresGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/staff/chores/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffChoresIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffChoresIdGet", "id", id)
      const localVarPath = "/staff/chores/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffChoresIdOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffChoresIdOptions", "id", id)
      const localVarPath = "/staff/chores/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffChoresIdPatch: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffChoresIdPatch", "id", id)
      const localVarPath = "/staff/chores/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffChoresIdPut: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffChoresIdPut", "id", id)
      const localVarPath = "/staff/chores/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffChoresOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/staff/chores/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffChoresPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/staff/chores/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffCompaniesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/staff/companies/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {StaffCompaniesIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffCompaniesIdActionGet: async (id: string, action: StaffCompaniesIdActionGetActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffCompaniesIdActionGet", "id", id)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffCompaniesIdActionGet", "action", action)
      const localVarPath = "/staff/companies/{id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {StaffCompaniesIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffCompaniesIdActionOptions: async (id: string, action: StaffCompaniesIdActionOptionsActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffCompaniesIdActionOptions", "id", id)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffCompaniesIdActionOptions", "action", action)
      const localVarPath = "/staff/companies/{id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {StaffCompaniesIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffCompaniesIdActionPut: async (id: string, action: StaffCompaniesIdActionPutActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffCompaniesIdActionPut", "id", id)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffCompaniesIdActionPut", "action", action)
      const localVarPath = "/staff/companies/{id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffCompaniesIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffCompaniesIdGet", "id", id)
      const localVarPath = "/staff/companies/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffCompaniesIdOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffCompaniesIdOptions", "id", id)
      const localVarPath = "/staff/companies/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffCompaniesIdPut: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffCompaniesIdPut", "id", id)
      const localVarPath = "/staff/companies/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffCompaniesOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/staff/companies/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffCompaniesPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/staff/companies/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/staff/deals/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdActionGet: async (id: string, action: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdActionGet", "id", id)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffDealsIdActionGet", "action", action)
      const localVarPath = "/staff/deals/{id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdActionOptions: async (id: string, action: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdActionOptions", "id", id)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffDealsIdActionOptions", "action", action)
      const localVarPath = "/staff/deals/{id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdActionPatch: async (id: string, action: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdActionPatch", "id", id)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffDealsIdActionPatch", "action", action)
      const localVarPath = "/staff/deals/{id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdActionPut: async (id: string, action: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdActionPut", "id", id)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffDealsIdActionPut", "action", action)
      const localVarPath = "/staff/deals/{id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdGet", "id", id)
      const localVarPath = "/staff/deals/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdInterviewsGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdInterviewsGet", "id", id)
      const localVarPath = "/staff/deals/{id}/interviews/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {StaffDealsIdInterviewsInterviewIdActionDeleteActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdInterviewsInterviewIdActionDelete: async (id: string, interviewId: string, action: StaffDealsIdInterviewsInterviewIdActionDeleteActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdInterviewsInterviewIdActionDelete", "id", id)
      // verify required parameter 'interviewId' is not null or undefined
      assertParamExists("staffDealsIdInterviewsInterviewIdActionDelete", "interviewId", interviewId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffDealsIdInterviewsInterviewIdActionDelete", "action", action)
      const localVarPath = "/staff/deals/{id}/interviews/{interview_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"interview_id"}}`, encodeURIComponent(String(interviewId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {StaffDealsIdInterviewsInterviewIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdInterviewsInterviewIdActionGet: async (id: string, interviewId: string, action: StaffDealsIdInterviewsInterviewIdActionGetActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdInterviewsInterviewIdActionGet", "id", id)
      // verify required parameter 'interviewId' is not null or undefined
      assertParamExists("staffDealsIdInterviewsInterviewIdActionGet", "interviewId", interviewId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffDealsIdInterviewsInterviewIdActionGet", "action", action)
      const localVarPath = "/staff/deals/{id}/interviews/{interview_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"interview_id"}}`, encodeURIComponent(String(interviewId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {StaffDealsIdInterviewsInterviewIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdInterviewsInterviewIdActionOptions: async (id: string, interviewId: string, action: StaffDealsIdInterviewsInterviewIdActionOptionsActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdInterviewsInterviewIdActionOptions", "id", id)
      // verify required parameter 'interviewId' is not null or undefined
      assertParamExists("staffDealsIdInterviewsInterviewIdActionOptions", "interviewId", interviewId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffDealsIdInterviewsInterviewIdActionOptions", "action", action)
      const localVarPath = "/staff/deals/{id}/interviews/{interview_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"interview_id"}}`, encodeURIComponent(String(interviewId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {StaffDealsIdInterviewsInterviewIdActionPatchActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdInterviewsInterviewIdActionPatch: async (id: string, interviewId: string, action: StaffDealsIdInterviewsInterviewIdActionPatchActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdInterviewsInterviewIdActionPatch", "id", id)
      // verify required parameter 'interviewId' is not null or undefined
      assertParamExists("staffDealsIdInterviewsInterviewIdActionPatch", "interviewId", interviewId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffDealsIdInterviewsInterviewIdActionPatch", "action", action)
      const localVarPath = "/staff/deals/{id}/interviews/{interview_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"interview_id"}}`, encodeURIComponent(String(interviewId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {StaffDealsIdInterviewsInterviewIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdInterviewsInterviewIdActionPut: async (id: string, interviewId: string, action: StaffDealsIdInterviewsInterviewIdActionPutActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdInterviewsInterviewIdActionPut", "id", id)
      // verify required parameter 'interviewId' is not null or undefined
      assertParamExists("staffDealsIdInterviewsInterviewIdActionPut", "interviewId", interviewId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffDealsIdInterviewsInterviewIdActionPut", "action", action)
      const localVarPath = "/staff/deals/{id}/interviews/{interview_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"interview_id"}}`, encodeURIComponent(String(interviewId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdInterviewsInterviewIdDelete: async (id: string, interviewId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdInterviewsInterviewIdDelete", "id", id)
      // verify required parameter 'interviewId' is not null or undefined
      assertParamExists("staffDealsIdInterviewsInterviewIdDelete", "interviewId", interviewId)
      const localVarPath = "/staff/deals/{id}/interviews/{interview_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"interview_id"}}`, encodeURIComponent(String(interviewId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdInterviewsInterviewIdGet: async (id: string, interviewId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdInterviewsInterviewIdGet", "id", id)
      // verify required parameter 'interviewId' is not null or undefined
      assertParamExists("staffDealsIdInterviewsInterviewIdGet", "interviewId", interviewId)
      const localVarPath = "/staff/deals/{id}/interviews/{interview_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"interview_id"}}`, encodeURIComponent(String(interviewId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdInterviewsInterviewIdOptions: async (id: string, interviewId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdInterviewsInterviewIdOptions", "id", id)
      // verify required parameter 'interviewId' is not null or undefined
      assertParamExists("staffDealsIdInterviewsInterviewIdOptions", "interviewId", interviewId)
      const localVarPath = "/staff/deals/{id}/interviews/{interview_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"interview_id"}}`, encodeURIComponent(String(interviewId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdInterviewsInterviewIdPatch: async (id: string, interviewId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdInterviewsInterviewIdPatch", "id", id)
      // verify required parameter 'interviewId' is not null or undefined
      assertParamExists("staffDealsIdInterviewsInterviewIdPatch", "interviewId", interviewId)
      const localVarPath = "/staff/deals/{id}/interviews/{interview_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"interview_id"}}`, encodeURIComponent(String(interviewId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdInterviewsInterviewIdPut: async (id: string, interviewId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdInterviewsInterviewIdPut", "id", id)
      // verify required parameter 'interviewId' is not null or undefined
      assertParamExists("staffDealsIdInterviewsInterviewIdPut", "interviewId", interviewId)
      const localVarPath = "/staff/deals/{id}/interviews/{interview_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"interview_id"}}`, encodeURIComponent(String(interviewId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdInterviewsOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdInterviewsOptions", "id", id)
      const localVarPath = "/staff/deals/{id}/interviews/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdInterviewsPost: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdInterviewsPost", "id", id)
      const localVarPath = "/staff/deals/{id}/interviews/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdOptions", "id", id)
      const localVarPath = "/staff/deals/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPatch: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdPatch", "id", id)
      const localVarPath = "/staff/deals/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPresentationsDeclineOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdPresentationsDeclineOptions", "id", id)
      const localVarPath = "/staff/deals/{id}/presentations/decline/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPresentationsDeclinePatch: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdPresentationsDeclinePatch", "id", id)
      const localVarPath = "/staff/deals/{id}/presentations/decline/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPresentationsDeclinePut: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdPresentationsDeclinePut", "id", id)
      const localVarPath = "/staff/deals/{id}/presentations/decline/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPresentationsGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdPresentationsGet", "id", id)
      const localVarPath = "/staff/deals/{id}/presentations/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPresentationsOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdPresentationsOptions", "id", id)
      const localVarPath = "/staff/deals/{id}/presentations/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPresentationsPost: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdPresentationsPost", "id", id)
      const localVarPath = "/staff/deals/{id}/presentations/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {StaffDealsIdPresentationsPresentationIdActionDeleteActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPresentationsPresentationIdActionDelete: async (id: string, presentationId: string, action: StaffDealsIdPresentationsPresentationIdActionDeleteActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdPresentationsPresentationIdActionDelete", "id", id)
      // verify required parameter 'presentationId' is not null or undefined
      assertParamExists("staffDealsIdPresentationsPresentationIdActionDelete", "presentationId", presentationId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffDealsIdPresentationsPresentationIdActionDelete", "action", action)
      const localVarPath = "/staff/deals/{id}/presentations/{presentation_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"presentation_id"}}`, encodeURIComponent(String(presentationId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {StaffDealsIdPresentationsPresentationIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPresentationsPresentationIdActionGet: async (id: string, presentationId: string, action: StaffDealsIdPresentationsPresentationIdActionGetActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdPresentationsPresentationIdActionGet", "id", id)
      // verify required parameter 'presentationId' is not null or undefined
      assertParamExists("staffDealsIdPresentationsPresentationIdActionGet", "presentationId", presentationId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffDealsIdPresentationsPresentationIdActionGet", "action", action)
      const localVarPath = "/staff/deals/{id}/presentations/{presentation_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"presentation_id"}}`, encodeURIComponent(String(presentationId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {StaffDealsIdPresentationsPresentationIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPresentationsPresentationIdActionOptions: async (id: string, presentationId: string, action: StaffDealsIdPresentationsPresentationIdActionOptionsActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdPresentationsPresentationIdActionOptions", "id", id)
      // verify required parameter 'presentationId' is not null or undefined
      assertParamExists("staffDealsIdPresentationsPresentationIdActionOptions", "presentationId", presentationId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffDealsIdPresentationsPresentationIdActionOptions", "action", action)
      const localVarPath = "/staff/deals/{id}/presentations/{presentation_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"presentation_id"}}`, encodeURIComponent(String(presentationId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {StaffDealsIdPresentationsPresentationIdActionPatchActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPresentationsPresentationIdActionPatch: async (id: string, presentationId: string, action: StaffDealsIdPresentationsPresentationIdActionPatchActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdPresentationsPresentationIdActionPatch", "id", id)
      // verify required parameter 'presentationId' is not null or undefined
      assertParamExists("staffDealsIdPresentationsPresentationIdActionPatch", "presentationId", presentationId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffDealsIdPresentationsPresentationIdActionPatch", "action", action)
      const localVarPath = "/staff/deals/{id}/presentations/{presentation_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"presentation_id"}}`, encodeURIComponent(String(presentationId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {StaffDealsIdPresentationsPresentationIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPresentationsPresentationIdActionPut: async (id: string, presentationId: string, action: StaffDealsIdPresentationsPresentationIdActionPutActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdPresentationsPresentationIdActionPut", "id", id)
      // verify required parameter 'presentationId' is not null or undefined
      assertParamExists("staffDealsIdPresentationsPresentationIdActionPut", "presentationId", presentationId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffDealsIdPresentationsPresentationIdActionPut", "action", action)
      const localVarPath = "/staff/deals/{id}/presentations/{presentation_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"presentation_id"}}`, encodeURIComponent(String(presentationId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPresentationsPresentationIdDelete: async (id: string, presentationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdPresentationsPresentationIdDelete", "id", id)
      // verify required parameter 'presentationId' is not null or undefined
      assertParamExists("staffDealsIdPresentationsPresentationIdDelete", "presentationId", presentationId)
      const localVarPath = "/staff/deals/{id}/presentations/{presentation_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"presentation_id"}}`, encodeURIComponent(String(presentationId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPresentationsPresentationIdGet: async (id: string, presentationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdPresentationsPresentationIdGet", "id", id)
      // verify required parameter 'presentationId' is not null or undefined
      assertParamExists("staffDealsIdPresentationsPresentationIdGet", "presentationId", presentationId)
      const localVarPath = "/staff/deals/{id}/presentations/{presentation_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"presentation_id"}}`, encodeURIComponent(String(presentationId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPresentationsPresentationIdOptions: async (id: string, presentationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdPresentationsPresentationIdOptions", "id", id)
      // verify required parameter 'presentationId' is not null or undefined
      assertParamExists("staffDealsIdPresentationsPresentationIdOptions", "presentationId", presentationId)
      const localVarPath = "/staff/deals/{id}/presentations/{presentation_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"presentation_id"}}`, encodeURIComponent(String(presentationId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPresentationsPresentationIdPatch: async (id: string, presentationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdPresentationsPresentationIdPatch", "id", id)
      // verify required parameter 'presentationId' is not null or undefined
      assertParamExists("staffDealsIdPresentationsPresentationIdPatch", "presentationId", presentationId)
      const localVarPath = "/staff/deals/{id}/presentations/{presentation_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"presentation_id"}}`, encodeURIComponent(String(presentationId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPresentationsPresentationIdPut: async (id: string, presentationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdPresentationsPresentationIdPut", "id", id)
      // verify required parameter 'presentationId' is not null or undefined
      assertParamExists("staffDealsIdPresentationsPresentationIdPut", "presentationId", presentationId)
      const localVarPath = "/staff/deals/{id}/presentations/{presentation_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"presentation_id"}}`, encodeURIComponent(String(presentationId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPut: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffDealsIdPut", "id", id)
      const localVarPath = "/staff/deals/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/staff/deals/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/staff/deals/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/staff/freelancers/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {StaffFreelancersIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdActionGet: async (id: string, action: StaffFreelancersIdActionGetActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffFreelancersIdActionGet", "id", id)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffFreelancersIdActionGet", "action", action)
      const localVarPath = "/staff/freelancers/{id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {StaffFreelancersIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdActionOptions: async (id: string, action: StaffFreelancersIdActionOptionsActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffFreelancersIdActionOptions", "id", id)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffFreelancersIdActionOptions", "action", action)
      const localVarPath = "/staff/freelancers/{id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {StaffFreelancersIdActionPatchActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdActionPatch: async (id: string, action: StaffFreelancersIdActionPatchActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffFreelancersIdActionPatch", "id", id)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffFreelancersIdActionPatch", "action", action)
      const localVarPath = "/staff/freelancers/{id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {StaffFreelancersIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdActionPut: async (id: string, action: StaffFreelancersIdActionPutActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffFreelancersIdActionPut", "id", id)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffFreelancersIdActionPut", "action", action)
      const localVarPath = "/staff/freelancers/{id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffFreelancersIdGet", "id", id)
      const localVarPath = "/staff/freelancers/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdHiresGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffFreelancersIdHiresGet", "id", id)
      const localVarPath = "/staff/freelancers/{id}/hires/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdHiresOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffFreelancersIdHiresOptions", "id", id)
      const localVarPath = "/staff/freelancers/{id}/hires/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdHiresPost: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffFreelancersIdHiresPost", "id", id)
      const localVarPath = "/staff/freelancers/{id}/hires/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdMatchesGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffFreelancersIdMatchesGet", "id", id)
      const localVarPath = "/staff/freelancers/{id}/matches/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdMatchesOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffFreelancersIdMatchesOptions", "id", id)
      const localVarPath = "/staff/freelancers/{id}/matches/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdMessagesGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffFreelancersIdMessagesGet", "id", id)
      const localVarPath = "/staff/freelancers/{id}/messages/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdMessagesOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffFreelancersIdMessagesOptions", "id", id)
      const localVarPath = "/staff/freelancers/{id}/messages/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdNotesGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffFreelancersIdNotesGet", "id", id)
      const localVarPath = "/staff/freelancers/{id}/notes/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdNotesOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffFreelancersIdNotesOptions", "id", id)
      const localVarPath = "/staff/freelancers/{id}/notes/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdNotesPost: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffFreelancersIdNotesPost", "id", id)
      const localVarPath = "/staff/freelancers/{id}/notes/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffFreelancersIdOptions", "id", id)
      const localVarPath = "/staff/freelancers/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdPatch: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffFreelancersIdPatch", "id", id)
      const localVarPath = "/staff/freelancers/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdPut: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffFreelancersIdPut", "id", id)
      const localVarPath = "/staff/freelancers/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdRecordingsGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffFreelancersIdRecordingsGet", "id", id)
      const localVarPath = "/staff/freelancers/{id}/recordings/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdRecordingsOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffFreelancersIdRecordingsOptions", "id", id)
      const localVarPath = "/staff/freelancers/{id}/recordings/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdRecordingsPost: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffFreelancersIdRecordingsPost", "id", id)
      const localVarPath = "/staff/freelancers/{id}/recordings/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdRecordingsRecordingIdDelete: async (id: string, recordingId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffFreelancersIdRecordingsRecordingIdDelete", "id", id)
      // verify required parameter 'recordingId' is not null or undefined
      assertParamExists("staffFreelancersIdRecordingsRecordingIdDelete", "recordingId", recordingId)
      const localVarPath = "/staff/freelancers/{id}/recordings/{recording_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"recording_id"}}`, encodeURIComponent(String(recordingId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdRecordingsRecordingIdGet: async (id: string, recordingId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffFreelancersIdRecordingsRecordingIdGet", "id", id)
      // verify required parameter 'recordingId' is not null or undefined
      assertParamExists("staffFreelancersIdRecordingsRecordingIdGet", "recordingId", recordingId)
      const localVarPath = "/staff/freelancers/{id}/recordings/{recording_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"recording_id"}}`, encodeURIComponent(String(recordingId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdRecordingsRecordingIdOptions: async (id: string, recordingId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffFreelancersIdRecordingsRecordingIdOptions", "id", id)
      // verify required parameter 'recordingId' is not null or undefined
      assertParamExists("staffFreelancersIdRecordingsRecordingIdOptions", "recordingId", recordingId)
      const localVarPath = "/staff/freelancers/{id}/recordings/{recording_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"recording_id"}}`, encodeURIComponent(String(recordingId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdRecordingsRecordingIdPatch: async (id: string, recordingId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffFreelancersIdRecordingsRecordingIdPatch", "id", id)
      // verify required parameter 'recordingId' is not null or undefined
      assertParamExists("staffFreelancersIdRecordingsRecordingIdPatch", "recordingId", recordingId)
      const localVarPath = "/staff/freelancers/{id}/recordings/{recording_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"recording_id"}}`, encodeURIComponent(String(recordingId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdRecordingsRecordingIdPut: async (id: string, recordingId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffFreelancersIdRecordingsRecordingIdPut", "id", id)
      // verify required parameter 'recordingId' is not null or undefined
      assertParamExists("staffFreelancersIdRecordingsRecordingIdPut", "recordingId", recordingId)
      const localVarPath = "/staff/freelancers/{id}/recordings/{recording_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"recording_id"}}`, encodeURIComponent(String(recordingId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdScreeningGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffFreelancersIdScreeningGet", "id", id)
      const localVarPath = "/staff/freelancers/{id}/screening/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdScreeningOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffFreelancersIdScreeningOptions", "id", id)
      const localVarPath = "/staff/freelancers/{id}/screening/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/staff/freelancers/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffHiresGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/staff/hires/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {StaffHiresIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffHiresIdActionGet: async (id: number, action: StaffHiresIdActionGetActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffHiresIdActionGet", "id", id)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffHiresIdActionGet", "action", action)
      const localVarPath = "/staff/hires/{id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {StaffHiresIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffHiresIdActionOptions: async (id: number, action: StaffHiresIdActionOptionsActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffHiresIdActionOptions", "id", id)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffHiresIdActionOptions", "action", action)
      const localVarPath = "/staff/hires/{id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {StaffHiresIdActionPatchActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffHiresIdActionPatch: async (id: number, action: StaffHiresIdActionPatchActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffHiresIdActionPatch", "id", id)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffHiresIdActionPatch", "action", action)
      const localVarPath = "/staff/hires/{id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {StaffHiresIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffHiresIdActionPut: async (id: number, action: StaffHiresIdActionPutActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffHiresIdActionPut", "id", id)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffHiresIdActionPut", "action", action)
      const localVarPath = "/staff/hires/{id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffHiresIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffHiresIdGet", "id", id)
      const localVarPath = "/staff/hires/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffHiresIdOptions: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffHiresIdOptions", "id", id)
      const localVarPath = "/staff/hires/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffHiresIdPatch: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffHiresIdPatch", "id", id)
      const localVarPath = "/staff/hires/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffHiresIdPut: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffHiresIdPut", "id", id)
      const localVarPath = "/staff/hires/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffHiresIdScopeOfWorkGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffHiresIdScopeOfWorkGet", "id", id)
      const localVarPath = "/staff/hires/{id}/scope-of-work/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffHiresIdScopeOfWorkOptions: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffHiresIdScopeOfWorkOptions", "id", id)
      const localVarPath = "/staff/hires/{id}/scope-of-work/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffHiresIdScopeOfWorkPost: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffHiresIdScopeOfWorkPost", "id", id)
      const localVarPath = "/staff/hires/{id}/scope-of-work/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {string} scopeOfWorkId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffHiresIdScopeOfWorkScopeOfWorkIdGet: async (id: number, scopeOfWorkId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffHiresIdScopeOfWorkScopeOfWorkIdGet", "id", id)
      // verify required parameter 'scopeOfWorkId' is not null or undefined
      assertParamExists("staffHiresIdScopeOfWorkScopeOfWorkIdGet", "scopeOfWorkId", scopeOfWorkId)
      const localVarPath = "/staff/hires/{id}/scope-of-work/{scope_of_work_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"scope_of_work_id"}}`, encodeURIComponent(String(scopeOfWorkId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {string} scopeOfWorkId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffHiresIdScopeOfWorkScopeOfWorkIdOptions: async (id: number, scopeOfWorkId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffHiresIdScopeOfWorkScopeOfWorkIdOptions", "id", id)
      // verify required parameter 'scopeOfWorkId' is not null or undefined
      assertParamExists("staffHiresIdScopeOfWorkScopeOfWorkIdOptions", "scopeOfWorkId", scopeOfWorkId)
      const localVarPath = "/staff/hires/{id}/scope-of-work/{scope_of_work_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"scope_of_work_id"}}`, encodeURIComponent(String(scopeOfWorkId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffHiresOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/staff/hires/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffHiresPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/staff/hires/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffInterviewsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/staff/interviews/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {StaffInterviewsIdActionDeleteActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffInterviewsIdActionDelete: async (id: string, action: StaffInterviewsIdActionDeleteActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffInterviewsIdActionDelete", "id", id)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffInterviewsIdActionDelete", "action", action)
      const localVarPath = "/staff/interviews/{id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {StaffInterviewsIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffInterviewsIdActionGet: async (id: string, action: StaffInterviewsIdActionGetActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffInterviewsIdActionGet", "id", id)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffInterviewsIdActionGet", "action", action)
      const localVarPath = "/staff/interviews/{id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {StaffInterviewsIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffInterviewsIdActionOptions: async (id: string, action: StaffInterviewsIdActionOptionsActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffInterviewsIdActionOptions", "id", id)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffInterviewsIdActionOptions", "action", action)
      const localVarPath = "/staff/interviews/{id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {StaffInterviewsIdActionPatchActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffInterviewsIdActionPatch: async (id: string, action: StaffInterviewsIdActionPatchActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffInterviewsIdActionPatch", "id", id)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffInterviewsIdActionPatch", "action", action)
      const localVarPath = "/staff/interviews/{id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {StaffInterviewsIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffInterviewsIdActionPut: async (id: string, action: StaffInterviewsIdActionPutActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffInterviewsIdActionPut", "id", id)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffInterviewsIdActionPut", "action", action)
      const localVarPath = "/staff/interviews/{id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffInterviewsIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffInterviewsIdDelete", "id", id)
      const localVarPath = "/staff/interviews/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffInterviewsIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffInterviewsIdGet", "id", id)
      const localVarPath = "/staff/interviews/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffInterviewsIdOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffInterviewsIdOptions", "id", id)
      const localVarPath = "/staff/interviews/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffInterviewsIdPatch: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffInterviewsIdPatch", "id", id)
      const localVarPath = "/staff/interviews/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffInterviewsIdPut: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffInterviewsIdPut", "id", id)
      const localVarPath = "/staff/interviews/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffInterviewsOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/staff/interviews/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffInterviewsPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/staff/interviews/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/staff/jobs/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {StaffJobsIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdActionGet: async (id: string, action: StaffJobsIdActionGetActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdActionGet", "id", id)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffJobsIdActionGet", "action", action)
      const localVarPath = "/staff/jobs/{id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {StaffJobsIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdActionOptions: async (id: string, action: StaffJobsIdActionOptionsActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdActionOptions", "id", id)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffJobsIdActionOptions", "action", action)
      const localVarPath = "/staff/jobs/{id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {StaffJobsIdActionPatchActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdActionPatch: async (id: string, action: StaffJobsIdActionPatchActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdActionPatch", "id", id)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffJobsIdActionPatch", "action", action)
      const localVarPath = "/staff/jobs/{id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {StaffJobsIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdActionPut: async (id: string, action: StaffJobsIdActionPutActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdActionPut", "id", id)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffJobsIdActionPut", "action", action)
      const localVarPath = "/staff/jobs/{id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {StaffJobsIdApplicationsApplicationIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdApplicationsApplicationIdActionGet: async (id: string, applicationId: string, action: StaffJobsIdApplicationsApplicationIdActionGetActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdApplicationsApplicationIdActionGet", "id", id)
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists("staffJobsIdApplicationsApplicationIdActionGet", "applicationId", applicationId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffJobsIdApplicationsApplicationIdActionGet", "action", action)
      const localVarPath = "/staff/jobs/{id}/applications/{application_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"application_id"}}`, encodeURIComponent(String(applicationId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {StaffJobsIdApplicationsApplicationIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdApplicationsApplicationIdActionOptions: async (id: string, applicationId: string, action: StaffJobsIdApplicationsApplicationIdActionOptionsActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdApplicationsApplicationIdActionOptions", "id", id)
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists("staffJobsIdApplicationsApplicationIdActionOptions", "applicationId", applicationId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffJobsIdApplicationsApplicationIdActionOptions", "action", action)
      const localVarPath = "/staff/jobs/{id}/applications/{application_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"application_id"}}`, encodeURIComponent(String(applicationId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {StaffJobsIdApplicationsApplicationIdActionPatchActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdApplicationsApplicationIdActionPatch: async (id: string, applicationId: string, action: StaffJobsIdApplicationsApplicationIdActionPatchActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdApplicationsApplicationIdActionPatch", "id", id)
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists("staffJobsIdApplicationsApplicationIdActionPatch", "applicationId", applicationId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffJobsIdApplicationsApplicationIdActionPatch", "action", action)
      const localVarPath = "/staff/jobs/{id}/applications/{application_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"application_id"}}`, encodeURIComponent(String(applicationId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {StaffJobsIdApplicationsApplicationIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdApplicationsApplicationIdActionPut: async (id: string, applicationId: string, action: StaffJobsIdApplicationsApplicationIdActionPutActionEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdApplicationsApplicationIdActionPut", "id", id)
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists("staffJobsIdApplicationsApplicationIdActionPut", "applicationId", applicationId)
      // verify required parameter 'action' is not null or undefined
      assertParamExists("staffJobsIdApplicationsApplicationIdActionPut", "action", action)
      const localVarPath = "/staff/jobs/{id}/applications/{application_id}/{action}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"application_id"}}`, encodeURIComponent(String(applicationId)))
        .replace(`{${"action"}}`, encodeURIComponent(String(action)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdApplicationsApplicationIdGet: async (id: string, applicationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdApplicationsApplicationIdGet", "id", id)
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists("staffJobsIdApplicationsApplicationIdGet", "applicationId", applicationId)
      const localVarPath = "/staff/jobs/{id}/applications/{application_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"application_id"}}`, encodeURIComponent(String(applicationId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdApplicationsApplicationIdOptions: async (id: string, applicationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdApplicationsApplicationIdOptions", "id", id)
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists("staffJobsIdApplicationsApplicationIdOptions", "applicationId", applicationId)
      const localVarPath = "/staff/jobs/{id}/applications/{application_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"application_id"}}`, encodeURIComponent(String(applicationId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdApplicationsApplicationIdPatch: async (id: string, applicationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdApplicationsApplicationIdPatch", "id", id)
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists("staffJobsIdApplicationsApplicationIdPatch", "applicationId", applicationId)
      const localVarPath = "/staff/jobs/{id}/applications/{application_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"application_id"}}`, encodeURIComponent(String(applicationId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdApplicationsApplicationIdPut: async (id: string, applicationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdApplicationsApplicationIdPut", "id", id)
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists("staffJobsIdApplicationsApplicationIdPut", "applicationId", applicationId)
      const localVarPath = "/staff/jobs/{id}/applications/{application_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"application_id"}}`, encodeURIComponent(String(applicationId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdApplicationsDeclineOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdApplicationsDeclineOptions", "id", id)
      const localVarPath = "/staff/jobs/{id}/applications/decline/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdApplicationsDeclinePatch: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdApplicationsDeclinePatch", "id", id)
      const localVarPath = "/staff/jobs/{id}/applications/decline/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdApplicationsDeclinePut: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdApplicationsDeclinePut", "id", id)
      const localVarPath = "/staff/jobs/{id}/applications/decline/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdApplicationsGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdApplicationsGet", "id", id)
      const localVarPath = "/staff/jobs/{id}/applications/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdApplicationsOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdApplicationsOptions", "id", id)
      const localVarPath = "/staff/jobs/{id}/applications/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} freelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdFreelancersFreelancerIdGet: async (id: string, freelancerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdFreelancersFreelancerIdGet", "id", id)
      // verify required parameter 'freelancerId' is not null or undefined
      assertParamExists("staffJobsIdFreelancersFreelancerIdGet", "freelancerId", freelancerId)
      const localVarPath = "/staff/jobs/{id}/freelancers/{freelancer_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"freelancer_id"}}`, encodeURIComponent(String(freelancerId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {string} freelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdFreelancersFreelancerIdOptions: async (id: string, freelancerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdFreelancersFreelancerIdOptions", "id", id)
      // verify required parameter 'freelancerId' is not null or undefined
      assertParamExists("staffJobsIdFreelancersFreelancerIdOptions", "freelancerId", freelancerId)
      const localVarPath = "/staff/jobs/{id}/freelancers/{freelancer_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"freelancer_id"}}`, encodeURIComponent(String(freelancerId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdFreelancersGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdFreelancersGet", "id", id)
      const localVarPath = "/staff/jobs/{id}/freelancers/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdFreelancersOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdFreelancersOptions", "id", id)
      const localVarPath = "/staff/jobs/{id}/freelancers/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdGet", "id", id)
      const localVarPath = "/staff/jobs/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdOptions", "id", id)
      const localVarPath = "/staff/jobs/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdPatch: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdPatch", "id", id)
      const localVarPath = "/staff/jobs/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdPut: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdPut", "id", id)
      const localVarPath = "/staff/jobs/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdRecordingsGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdRecordingsGet", "id", id)
      const localVarPath = "/staff/jobs/{id}/recordings/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdRecordingsOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdRecordingsOptions", "id", id)
      const localVarPath = "/staff/jobs/{id}/recordings/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdRecordingsPost: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdRecordingsPost", "id", id)
      const localVarPath = "/staff/jobs/{id}/recordings/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdRecordingsRecordingIdDelete: async (id: string, recordingId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdRecordingsRecordingIdDelete", "id", id)
      // verify required parameter 'recordingId' is not null or undefined
      assertParamExists("staffJobsIdRecordingsRecordingIdDelete", "recordingId", recordingId)
      const localVarPath = "/staff/jobs/{id}/recordings/{recording_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"recording_id"}}`, encodeURIComponent(String(recordingId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdRecordingsRecordingIdGet: async (id: string, recordingId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdRecordingsRecordingIdGet", "id", id)
      // verify required parameter 'recordingId' is not null or undefined
      assertParamExists("staffJobsIdRecordingsRecordingIdGet", "recordingId", recordingId)
      const localVarPath = "/staff/jobs/{id}/recordings/{recording_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"recording_id"}}`, encodeURIComponent(String(recordingId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdRecordingsRecordingIdOptions: async (id: string, recordingId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdRecordingsRecordingIdOptions", "id", id)
      // verify required parameter 'recordingId' is not null or undefined
      assertParamExists("staffJobsIdRecordingsRecordingIdOptions", "recordingId", recordingId)
      const localVarPath = "/staff/jobs/{id}/recordings/{recording_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"recording_id"}}`, encodeURIComponent(String(recordingId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdRecordingsRecordingIdPatch: async (id: string, recordingId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdRecordingsRecordingIdPatch", "id", id)
      // verify required parameter 'recordingId' is not null or undefined
      assertParamExists("staffJobsIdRecordingsRecordingIdPatch", "recordingId", recordingId)
      const localVarPath = "/staff/jobs/{id}/recordings/{recording_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"recording_id"}}`, encodeURIComponent(String(recordingId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdRecordingsRecordingIdPut: async (id: string, recordingId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdRecordingsRecordingIdPut", "id", id)
      // verify required parameter 'recordingId' is not null or undefined
      assertParamExists("staffJobsIdRecordingsRecordingIdPut", "recordingId", recordingId)
      const localVarPath = "/staff/jobs/{id}/recordings/{recording_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"recording_id"}}`, encodeURIComponent(String(recordingId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdSearchesGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdSearchesGet", "id", id)
      const localVarPath = "/staff/jobs/{id}/searches/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdSearchesOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdSearchesOptions", "id", id)
      const localVarPath = "/staff/jobs/{id}/searches/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdSearchesPost: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdSearchesPost", "id", id)
      const localVarPath = "/staff/jobs/{id}/searches/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} searchId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdSearchesSearchIdDelete: async (id: string, searchId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdSearchesSearchIdDelete", "id", id)
      // verify required parameter 'searchId' is not null or undefined
      assertParamExists("staffJobsIdSearchesSearchIdDelete", "searchId", searchId)
      const localVarPath = "/staff/jobs/{id}/searches/{search_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"search_id"}}`, encodeURIComponent(String(searchId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} searchId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdSearchesSearchIdGet: async (id: string, searchId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdSearchesSearchIdGet", "id", id)
      // verify required parameter 'searchId' is not null or undefined
      assertParamExists("staffJobsIdSearchesSearchIdGet", "searchId", searchId)
      const localVarPath = "/staff/jobs/{id}/searches/{search_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"search_id"}}`, encodeURIComponent(String(searchId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} searchId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdSearchesSearchIdOptions: async (id: string, searchId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdSearchesSearchIdOptions", "id", id)
      // verify required parameter 'searchId' is not null or undefined
      assertParamExists("staffJobsIdSearchesSearchIdOptions", "searchId", searchId)
      const localVarPath = "/staff/jobs/{id}/searches/{search_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"search_id"}}`, encodeURIComponent(String(searchId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} searchId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdSearchesSearchIdPatch: async (id: string, searchId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdSearchesSearchIdPatch", "id", id)
      // verify required parameter 'searchId' is not null or undefined
      assertParamExists("staffJobsIdSearchesSearchIdPatch", "searchId", searchId)
      const localVarPath = "/staff/jobs/{id}/searches/{search_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"search_id"}}`, encodeURIComponent(String(searchId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {number} searchId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdSearchesSearchIdPut: async (id: string, searchId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffJobsIdSearchesSearchIdPut", "id", id)
      // verify required parameter 'searchId' is not null or undefined
      assertParamExists("staffJobsIdSearchesSearchIdPut", "searchId", searchId)
      const localVarPath = "/staff/jobs/{id}/searches/{search_id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
        .replace(`{${"search_id"}}`, encodeURIComponent(String(searchId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/staff/jobs/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffMessagesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/staff/messages/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffMessagesOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/staff/messages/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffMessagesPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/staff/messages/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffReportsMetricsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/staff/reports/metrics/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffReportsMetricsOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/staff/reports/metrics/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffReviewsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/staff/reviews/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffReviewsIdGet: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffReviewsIdGet", "id", id)
      const localVarPath = "/staff/reviews/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffReviewsIdOptions: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffReviewsIdOptions", "id", id)
      const localVarPath = "/staff/reviews/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffReviewsIdPatch: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffReviewsIdPatch", "id", id)
      const localVarPath = "/staff/reviews/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffReviewsIdPut: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("staffReviewsIdPut", "id", id)
      const localVarPath = "/staff/reviews/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffReviewsOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/staff/reviews/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscriptionsHashGet: async (hash: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'hash' is not null or undefined
      assertParamExists("subscriptionsHashGet", "hash", hash)
      const localVarPath = "/subscriptions/{hash}/"
        .replace(`{${"hash"}}`, encodeURIComponent(String(hash)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscriptionsHashOptions: async (hash: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'hash' is not null or undefined
      assertParamExists("subscriptionsHashOptions", "hash", hash)
      const localVarPath = "/subscriptions/{hash}/"
        .replace(`{${"hash"}}`, encodeURIComponent(String(hash)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscriptionsHashPatch: async (hash: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'hash' is not null or undefined
      assertParamExists("subscriptionsHashPatch", "hash", hash)
      const localVarPath = "/subscriptions/{hash}/"
        .replace(`{${"hash"}}`, encodeURIComponent(String(hash)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscriptionsHashPut: async (hash: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'hash' is not null or undefined
      assertParamExists("subscriptionsHashPut", "hash", hash)
      const localVarPath = "/subscriptions/{hash}/"
        .replace(`{${"hash"}}`, encodeURIComponent(String(hash)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userAppointmentsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/user/appointments/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userAppointmentsIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("userAppointmentsIdGet", "id", id)
      const localVarPath = "/user/appointments/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userAppointmentsIdOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("userAppointmentsIdOptions", "id", id)
      const localVarPath = "/user/appointments/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userAppointmentsOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/user/appointments/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userCreateCompanyOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/user/create/company/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userCreateCompanyPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/user/create/company/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userCreateFreelancerOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/user/create/freelancer/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userCreateFreelancerPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/user/create/freelancer/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/user/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userGrantNylasDelete: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/user/grant/nylas/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userGrantNylasGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/user/grant/nylas/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userGrantNylasOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/user/grant/nylas/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userGrantNylasPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/user/grant/nylas/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userInterviewsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/user/interviews/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userInterviewsIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("userInterviewsIdGet", "id", id)
      const localVarPath = "/user/interviews/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userInterviewsIdOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("userInterviewsIdOptions", "id", id)
      const localVarPath = "/user/interviews/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userInterviewsOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/user/interviews/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/user/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userPatch: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/user/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userPut: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/user/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userSubscriptionsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/user/subscriptions/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userSubscriptionsOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/user/subscriptions/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userSubscriptionsPatch: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/user/subscriptions/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userSubscriptionsPut: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/user/subscriptions/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userSubscriptionsSlackOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/user/subscriptions/slack/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userSubscriptionsSlackPatch: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/user/subscriptions/slack/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userSubscriptionsSlackPut: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/user/subscriptions/slack/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userSubscriptionsTelegramOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/user/subscriptions/telegram/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userSubscriptionsTelegramPatch: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/user/subscriptions/telegram/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userSubscriptionsTelegramPut: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/user/subscriptions/telegram/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userWorkstyleGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/user/workstyle/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userWorkstyleOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/user/workstyle/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/users/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/users/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workstylesAssessmentsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/workstyles/assessments/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workstylesAssessmentsIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("workstylesAssessmentsIdGet", "id", id)
      const localVarPath = "/workstyles/assessments/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workstylesAssessmentsIdOptions: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("workstylesAssessmentsIdOptions", "id", id)
      const localVarPath = "/workstyles/assessments/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workstylesAssessmentsIdPatch: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("workstylesAssessmentsIdPatch", "id", id)
      const localVarPath = "/workstyles/assessments/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workstylesAssessmentsIdPut: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("workstylesAssessmentsIdPut", "id", id)
      const localVarPath = "/workstyles/assessments/{id}/"
        .replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workstylesAssessmentsOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/workstyles/assessments/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workstylesAssessmentsPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/workstyles/assessments/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workstylesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/workstyles/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workstylesOptions: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = "/workstyles/"
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions

      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "OPTIONS", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)

  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authInviteGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthInviteCreateUserUserSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authInviteGet(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.authInviteGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authInviteOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthInviteCreateUserUserSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authInviteOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.authInviteOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authInvitePost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthInviteCreateUserUserSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authInvitePost(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.authInvitePost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authLoginGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthLoginLoginUserSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authLoginGet(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.authLoginGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authLoginOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthLoginLoginUserSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authLoginOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.authLoginOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authLoginPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthLoginLoginUserSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authLoginPost(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.authLoginPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} dealId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdDealsDealIdGet(id: string, dealId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdDealsDealIdDealDetailDealSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdDealsDealIdGet(id, dealId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdDealsDealIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} dealId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdDealsDealIdOptions(id: string, dealId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdDealsDealIdDealDetailDealSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdDealsDealIdOptions(id, dealId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdDealsDealIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} dealId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdDealsDealIdPresentationsGet(id: string, dealId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PresentationListEntry>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdDealsDealIdPresentationsGet(id, dealId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdDealsDealIdPresentationsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} dealId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdDealsDealIdPresentationsOptions(id: string, dealId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PresentationListEntry>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdDealsDealIdPresentationsOptions(id, dealId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdDealsDealIdPresentationsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} dealId
     * @param {string} presentationId
     * @param {CompanyIdDealsDealIdPresentationsPresentationIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdDealsDealIdPresentationsPresentationIdActionGet(id: string, dealId: string, presentationId: string, action: CompanyIdDealsDealIdPresentationsPresentationIdActionGetActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PresentationDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdDealsDealIdPresentationsPresentationIdActionGet(id, dealId, presentationId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdDealsDealIdPresentationsPresentationIdActionGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} dealId
     * @param {string} presentationId
     * @param {CompanyIdDealsDealIdPresentationsPresentationIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdDealsDealIdPresentationsPresentationIdActionOptions(id: string, dealId: string, presentationId: string, action: CompanyIdDealsDealIdPresentationsPresentationIdActionOptionsActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PresentationDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdDealsDealIdPresentationsPresentationIdActionOptions(id, dealId, presentationId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdDealsDealIdPresentationsPresentationIdActionOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} dealId
     * @param {string} presentationId
     * @param {CompanyIdDealsDealIdPresentationsPresentationIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdDealsDealIdPresentationsPresentationIdActionPut(id: string, dealId: string, presentationId: string, action: CompanyIdDealsDealIdPresentationsPresentationIdActionPutActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PresentationDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdDealsDealIdPresentationsPresentationIdActionPut(id, dealId, presentationId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdDealsDealIdPresentationsPresentationIdActionPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} dealId
     * @param {string} presentationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdDealsDealIdPresentationsPresentationIdGet(id: string, dealId: string, presentationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PresentationDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdDealsDealIdPresentationsPresentationIdGet(id, dealId, presentationId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdDealsDealIdPresentationsPresentationIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} dealId
     * @param {string} presentationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdDealsDealIdPresentationsPresentationIdOptions(id: string, dealId: string, presentationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PresentationDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdDealsDealIdPresentationsPresentationIdOptions(id, dealId, presentationId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdDealsDealIdPresentationsPresentationIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} dealId
     * @param {string} presentationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdDealsDealIdPresentationsPresentationIdPut(id: string, dealId: string, presentationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PresentationDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdDealsDealIdPresentationsPresentationIdPut(id, dealId, presentationId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdDealsDealIdPresentationsPresentationIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdDealsGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdDealsDealListDealSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdDealsGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdDealsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdDealsOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdDealsDealListDealSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdDealsOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdDealsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} freelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdFreelancersFreelancerIdGet(id: string, freelancerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyFreelancerDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdFreelancersFreelancerIdGet(id, freelancerId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdFreelancersFreelancerIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} freelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdFreelancersFreelancerIdOptions(id: string, freelancerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyFreelancerDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdFreelancersFreelancerIdOptions(id, freelancerId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdFreelancersFreelancerIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdCompanyDetailCompanySerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdHiresGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdHiresHireListHireSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdHiresGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdHiresGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} hireId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdHiresHireIdGet(id: string, hireId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdHiresHireIdHireDetailHireSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdHiresHireIdGet(id, hireId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdHiresHireIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} hireId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdHiresHireIdOptions(id: string, hireId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdHiresHireIdHireDetailHireSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdHiresHireIdOptions(id, hireId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdHiresHireIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} hireId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdHiresHireIdPatch(id: string, hireId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdHiresHireIdHireDetailHireSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdHiresHireIdPatch(id, hireId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdHiresHireIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} hireId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdHiresHireIdPut(id: string, hireId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdHiresHireIdHireDetailHireSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdHiresHireIdPut(id, hireId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdHiresHireIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} hireId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdHiresHireIdScopeOfWorkGet(id: string, hireId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdHiresHireIdScopeOfWorkScopeOfWorkDetailScopeOfWorkSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdHiresHireIdScopeOfWorkGet(id, hireId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdHiresHireIdScopeOfWorkGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} hireId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdHiresHireIdScopeOfWorkOptions(id: string, hireId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdHiresHireIdScopeOfWorkScopeOfWorkDetailScopeOfWorkSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdHiresHireIdScopeOfWorkOptions(id, hireId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdHiresHireIdScopeOfWorkOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdHiresOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdHiresHireListHireSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdHiresOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdHiresOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdInterviewSettingsGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyInterviewSettings>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdInterviewSettingsGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdInterviewSettingsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewSettingsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdInterviewSettingsInterviewSettingsIdDelete(id: string, interviewSettingsId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyInterviewSettings>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdInterviewSettingsInterviewSettingsIdDelete(id, interviewSettingsId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdInterviewSettingsInterviewSettingsIdDelete"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewSettingsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdInterviewSettingsInterviewSettingsIdGet(id: string, interviewSettingsId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyInterviewSettings>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdInterviewSettingsInterviewSettingsIdGet(id, interviewSettingsId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdInterviewSettingsInterviewSettingsIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewSettingsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdInterviewSettingsInterviewSettingsIdOptions(id: string, interviewSettingsId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyInterviewSettings>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdInterviewSettingsInterviewSettingsIdOptions(id, interviewSettingsId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdInterviewSettingsInterviewSettingsIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewSettingsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdInterviewSettingsInterviewSettingsIdPatch(id: string, interviewSettingsId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyInterviewSettings>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdInterviewSettingsInterviewSettingsIdPatch(id, interviewSettingsId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdInterviewSettingsInterviewSettingsIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewSettingsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdInterviewSettingsInterviewSettingsIdPut(id: string, interviewSettingsId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyInterviewSettings>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdInterviewSettingsInterviewSettingsIdPut(id, interviewSettingsId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdInterviewSettingsInterviewSettingsIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdInterviewSettingsOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyInterviewSettings>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdInterviewSettingsOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdInterviewSettingsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdInterviewSettingsPost(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyInterviewSettings>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdInterviewSettingsPost(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdInterviewSettingsPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdInterviewsGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdInterviewsInterviewListInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdInterviewsGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdInterviewsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {CompanyIdInterviewsInterviewIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdInterviewsInterviewIdActionGet(id: string, interviewId: string, action: CompanyIdInterviewsInterviewIdActionGetActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdInterviewsInterviewIdActionInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdInterviewsInterviewIdActionGet(id, interviewId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdInterviewsInterviewIdActionGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {CompanyIdInterviewsInterviewIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdInterviewsInterviewIdActionOptions(id: string, interviewId: string, action: CompanyIdInterviewsInterviewIdActionOptionsActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdInterviewsInterviewIdActionInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdInterviewsInterviewIdActionOptions(id, interviewId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdInterviewsInterviewIdActionOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {CompanyIdInterviewsInterviewIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdInterviewsInterviewIdActionPut(id: string, interviewId: string, action: CompanyIdInterviewsInterviewIdActionPutActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdInterviewsInterviewIdActionInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdInterviewsInterviewIdActionPut(id, interviewId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdInterviewsInterviewIdActionPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdInterviewsInterviewIdGet(id: string, interviewId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdInterviewsInterviewIdInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdInterviewsInterviewIdGet(id, interviewId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdInterviewsInterviewIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdInterviewsInterviewIdOptions(id: string, interviewId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdInterviewsInterviewIdInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdInterviewsInterviewIdOptions(id, interviewId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdInterviewsInterviewIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdInterviewsInterviewIdPut(id: string, interviewId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdInterviewsInterviewIdInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdInterviewsInterviewIdPut(id, interviewId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdInterviewsInterviewIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdInterviewsOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdInterviewsInterviewListInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdInterviewsOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdInterviewsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdInterviewsPost(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdInterviewsInterviewListInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdInterviewsPost(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdInterviewsPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdInvitesGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyInvite>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdInvitesGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdInvitesGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} inviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdInvitesInviteIdDelete(id: string, inviteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyInvite>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdInvitesInviteIdDelete(id, inviteId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdInvitesInviteIdDelete"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} inviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdInvitesInviteIdGet(id: string, inviteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyInvite>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdInvitesInviteIdGet(id, inviteId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdInvitesInviteIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} inviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdInvitesInviteIdOptions(id: string, inviteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyInvite>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdInvitesInviteIdOptions(id, inviteId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdInvitesInviteIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} inviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdInvitesInviteIdPatch(id: string, inviteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyInvite>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdInvitesInviteIdPatch(id, inviteId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdInvitesInviteIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} inviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdInvitesInviteIdPut(id: string, inviteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyInvite>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdInvitesInviteIdPut(id, inviteId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdInvitesInviteIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdInvitesOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyInvite>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdInvitesOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdInvitesOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdInvitesPost(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyInvite>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdInvitesPost(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdInvitesPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdJobsFromMissionOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdJobsFromMissionJobCreateFromMissionCompanyJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdJobsFromMissionOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdJobsFromMissionOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdJobsFromMissionPost(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdJobsFromMissionJobCreateFromMissionCompanyJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdJobsFromMissionPost(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdJobsFromMissionPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdJobsGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdJobsJobListCompanyJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdJobsGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdJobsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdJobsJobIdArchiveGet(id: string, jobId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdJobsJobIdArchiveJobArchiveCompanyJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdJobsJobIdArchiveGet(id, jobId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdJobsJobIdArchiveGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdJobsJobIdArchiveOptions(id: string, jobId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdJobsJobIdArchiveJobArchiveCompanyJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdJobsJobIdArchiveOptions(id, jobId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdJobsJobIdArchiveOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdJobsJobIdArchivePost(id: string, jobId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdJobsJobIdArchiveJobArchiveCompanyJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdJobsJobIdArchivePost(id, jobId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdJobsJobIdArchivePost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdJobsJobIdDelete(id: string, jobId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdJobsJobIdJobDetailCompanyJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdJobsJobIdDelete(id, jobId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdJobsJobIdDelete"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdJobsJobIdGet(id: string, jobId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdJobsJobIdJobDetailCompanyJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdJobsJobIdGet(id, jobId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdJobsJobIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdJobsJobIdOptions(id: string, jobId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdJobsJobIdJobDetailCompanyJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdJobsJobIdOptions(id, jobId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdJobsJobIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdJobsJobIdPatch(id: string, jobId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdJobsJobIdJobDetailCompanyJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdJobsJobIdPatch(id, jobId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdJobsJobIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdJobsJobIdPut(id: string, jobId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdJobsJobIdJobDetailCompanyJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdJobsJobIdPut(id, jobId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdJobsJobIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdJobsJobIdReviewGet(id: string, jobId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdJobsJobIdReviewJobReadyForReviewCompanyJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdJobsJobIdReviewGet(id, jobId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdJobsJobIdReviewGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdJobsJobIdReviewOptions(id: string, jobId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdJobsJobIdReviewJobReadyForReviewCompanyJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdJobsJobIdReviewOptions(id, jobId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdJobsJobIdReviewOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdJobsJobIdReviewPost(id: string, jobId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdJobsJobIdReviewJobReadyForReviewCompanyJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdJobsJobIdReviewPost(id, jobId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdJobsJobIdReviewPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdJobsOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdJobsJobListCompanyJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdJobsOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdJobsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdJobsPost(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdJobsJobListCompanyJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdJobsPost(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdJobsPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMembersGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdMembersMemberListCompanyMemberSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMembersGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMembersGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMembersOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdMembersMemberListCompanyMemberSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMembersOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMembersOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMembersPost(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdMembersMemberListCompanyMemberSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMembersPost(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMembersPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionInterviewsGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMissionInterview>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionInterviewsGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionInterviewsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionInterviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionInterviewsMissionInterviewIdDelete(id: string, missionInterviewId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMissionInterview>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionInterviewsMissionInterviewIdDelete(id, missionInterviewId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionInterviewsMissionInterviewIdDelete"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionInterviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionInterviewsMissionInterviewIdGet(id: string, missionInterviewId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMissionInterview>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionInterviewsMissionInterviewIdGet(id, missionInterviewId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionInterviewsMissionInterviewIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionInterviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionInterviewsMissionInterviewIdOptions(id: string, missionInterviewId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMissionInterview>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionInterviewsMissionInterviewIdOptions(id, missionInterviewId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionInterviewsMissionInterviewIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionInterviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionInterviewsMissionInterviewIdPatch(id: string, missionInterviewId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMissionInterview>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionInterviewsMissionInterviewIdPatch(id, missionInterviewId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionInterviewsMissionInterviewIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionInterviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionInterviewsMissionInterviewIdPut(id: string, missionInterviewId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMissionInterview>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionInterviewsMissionInterviewIdPut(id, missionInterviewId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionInterviewsMissionInterviewIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionInterviewsOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMissionInterview>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionInterviewsOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionInterviewsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionInterviewsPost(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMissionInterview>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionInterviewsPost(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionInterviewsPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMissionList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionBuilderOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMission>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionBuilderOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionBuilderOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionBuilderPost(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMission>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionBuilderPost(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionBuilderPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdDelete(id: string, missionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMission>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdDelete(id, missionId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdDelete"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdGet(id: string, missionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMission>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdGet(id, missionId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityGet(id: string, missionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMissionInterviewDefaultAvailability>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityGet(id, missionId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} missionInterviewDefaultAvailabilityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdGet(id: string, missionId: string, missionInterviewDefaultAvailabilityId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMissionInterviewDefaultAvailability>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdGet(id, missionId, missionInterviewDefaultAvailabilityId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} missionInterviewDefaultAvailabilityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdOptions(id: string, missionId: string, missionInterviewDefaultAvailabilityId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMissionInterviewDefaultAvailability>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdOptions(id, missionId, missionInterviewDefaultAvailabilityId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} missionInterviewDefaultAvailabilityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdPatch(id: string, missionId: string, missionInterviewDefaultAvailabilityId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMissionInterviewDefaultAvailability>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdPatch(id, missionId, missionInterviewDefaultAvailabilityId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} missionInterviewDefaultAvailabilityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdPut(id: string, missionId: string, missionInterviewDefaultAvailabilityId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMissionInterviewDefaultAvailability>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdPut(id, missionId, missionInterviewDefaultAvailabilityId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityOptions(id: string, missionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMissionInterviewDefaultAvailability>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityOptions(id, missionId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityPost(id: string, missionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMissionInterviewDefaultAvailability>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityPost(id, missionId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdOptions(id: string, missionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMission>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdOptions(id, missionId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdPatch(id: string, missionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMission>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdPatch(id, missionId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdPut(id: string, missionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMission>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdPut(id, missionId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistFreelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdDelete(id: string, missionId: string, shortlistFreelancerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShortlistFreelancer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdDelete(id, missionId, shortlistFreelancerId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdDelete"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistFreelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdGet(id: string, missionId: string, shortlistFreelancerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShortlistFreelancer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdGet(id, missionId, shortlistFreelancerId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistFreelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdOptions(id: string, missionId: string, shortlistFreelancerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShortlistFreelancer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdOptions(id, missionId, shortlistFreelancerId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistFreelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdPatch(id: string, missionId: string, shortlistFreelancerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShortlistFreelancer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdPatch(id, missionId, shortlistFreelancerId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistFreelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdPut(id: string, missionId: string, shortlistFreelancerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShortlistFreelancer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdPut(id, missionId, shortlistFreelancerId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdShortlistsGet(id: string, missionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionShortlist>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdShortlistsGet(id, missionId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdShortlistsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdShortlistsOptions(id: string, missionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionShortlist>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdShortlistsOptions(id, missionId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdShortlistsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdShortlistsPost(id: string, missionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionShortlist>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdShortlistsPost(id, missionId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdShortlistsPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistId
     * @param {string} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdShortlistsShortlistIdActionDelete(id: string, missionId: string, shortlistId: string, action: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionShortlist>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdShortlistsShortlistIdActionDelete(id, missionId, shortlistId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdShortlistsShortlistIdActionDelete"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistId
     * @param {string} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdShortlistsShortlistIdActionGet(id: string, missionId: string, shortlistId: string, action: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionShortlist>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdShortlistsShortlistIdActionGet(id, missionId, shortlistId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdShortlistsShortlistIdActionGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistId
     * @param {string} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdShortlistsShortlistIdActionOptions(id: string, missionId: string, shortlistId: string, action: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionShortlist>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdShortlistsShortlistIdActionOptions(id, missionId, shortlistId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdShortlistsShortlistIdActionOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistId
     * @param {string} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdShortlistsShortlistIdActionPatch(id: string, missionId: string, shortlistId: string, action: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionShortlist>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdShortlistsShortlistIdActionPatch(id, missionId, shortlistId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdShortlistsShortlistIdActionPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistId
     * @param {string} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdShortlistsShortlistIdActionPut(id: string, missionId: string, shortlistId: string, action: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionShortlist>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdShortlistsShortlistIdActionPut(id, missionId, shortlistId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdShortlistsShortlistIdActionPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdShortlistsShortlistIdDelete(id: string, missionId: string, shortlistId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionShortlist>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdShortlistsShortlistIdDelete(id, missionId, shortlistId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdShortlistsShortlistIdDelete"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdShortlistsShortlistIdGet(id: string, missionId: string, shortlistId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionShortlist>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdShortlistsShortlistIdGet(id, missionId, shortlistId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdShortlistsShortlistIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdShortlistsShortlistIdOptions(id: string, missionId: string, shortlistId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionShortlist>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdShortlistsShortlistIdOptions(id, missionId, shortlistId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdShortlistsShortlistIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdShortlistsShortlistIdPatch(id: string, missionId: string, shortlistId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionShortlist>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdShortlistsShortlistIdPatch(id, missionId, shortlistId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdShortlistsShortlistIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdShortlistsShortlistIdPut(id: string, missionId: string, shortlistId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionShortlist>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdShortlistsShortlistIdPut(id, missionId, shortlistId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdShortlistsShortlistIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdShowdownSlotsGet(id: string, missionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyShowdownSlots>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdShowdownSlotsGet(id, missionId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdShowdownSlotsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdShowdownSlotsOptions(id: string, missionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyShowdownSlots>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdShowdownSlotsOptions(id, missionId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdShowdownSlotsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdShowdownSlotsPost(id: string, missionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyShowdownSlots>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdShowdownSlotsPost(id, missionId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdShowdownSlotsPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} showdownSlotsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdShowdownSlotsShowdownSlotsIdGet(id: string, missionId: string, showdownSlotsId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyShowdownSlots>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdShowdownSlotsShowdownSlotsIdGet(id, missionId, showdownSlotsId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdShowdownSlotsShowdownSlotsIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} showdownSlotsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsMissionIdShowdownSlotsShowdownSlotsIdOptions(id: string, missionId: string, showdownSlotsId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyShowdownSlots>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsMissionIdShowdownSlotsShowdownSlotsIdOptions(id, missionId, showdownSlotsId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsMissionIdShowdownSlotsShowdownSlotsIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMissionList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdMissionsPost(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMissionList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdMissionsPost(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdMissionsPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdCompanyDetailCompanySerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdPatch(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdCompanyDetailCompanySerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdPatch(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdPublicGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdPublicCompanyDetailCompanySerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdPublicGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdPublicGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdPublicOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdPublicCompanyDetailCompanySerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdPublicOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdPublicOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdPut(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdCompanyDetailCompanySerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdPut(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdReviewsGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdReviewsReviewListCompanyReviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdReviewsGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdReviewsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdReviewsOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdReviewsReviewListCompanyReviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdReviewsOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdReviewsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdReviewsPost(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdReviewsReviewListCompanyReviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdReviewsPost(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdReviewsPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} reviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdReviewsReviewIdGet(id: string, reviewId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdReviewsReviewIdReviewDetailCompanyReviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdReviewsReviewIdGet(id, reviewId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdReviewsReviewIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} reviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdReviewsReviewIdOptions(id: string, reviewId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdReviewsReviewIdReviewDetailCompanyReviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdReviewsReviewIdOptions(id, reviewId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdReviewsReviewIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} reviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdReviewsReviewIdPatch(id: string, reviewId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdReviewsReviewIdReviewDetailCompanyReviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdReviewsReviewIdPatch(id, reviewId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdReviewsReviewIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} reviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyIdReviewsReviewIdPut(id: string, reviewId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIdReviewsReviewIdReviewDetailCompanyReviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyIdReviewsReviewIdPut(id, reviewId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyIdReviewsReviewIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} experienceLevel The experience level PK being considered
     * @param {CompanyMissionBudgetRecommendationsGetCompensationPeriodEnum} compensationPeriod The compensation period being considered
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyMissionBudgetRecommendationsGet(id: string, experienceLevel: number, compensationPeriod: CompanyMissionBudgetRecommendationsGetCompensationPeriodEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMissionBudgetRecommendations>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyMissionBudgetRecommendationsGet(id, experienceLevel, compensationPeriod, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyMissionBudgetRecommendationsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyMissionBudgetRecommendationsOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMissionBudgetRecommendations>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyMissionBudgetRecommendationsOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyMissionBudgetRecommendationsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} applicationId
     * @param {CompanyMissionJobApplicationActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyMissionJobApplicationActionOptions(id: string, missionId: string, applicationId: string, action: CompanyMissionJobApplicationActionOptionsActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMissionJobApplicationAction>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyMissionJobApplicationActionOptions(id, missionId, applicationId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyMissionJobApplicationActionOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} applicationId
     * @param {CompanyMissionJobApplicationActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyMissionJobApplicationActionPut(id: string, missionId: string, applicationId: string, action: CompanyMissionJobApplicationActionPutActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMissionJobApplicationAction>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyMissionJobApplicationActionPut(id, missionId, applicationId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyMissionJobApplicationActionPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} applicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyMissionJobApplicationDetailGet(id: string, missionId: string, applicationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMissionJobApplicationDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyMissionJobApplicationDetailGet(id, missionId, applicationId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyMissionJobApplicationDetailGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} applicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyMissionJobApplicationDetailOptions(id: string, missionId: string, applicationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMissionJobApplicationDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyMissionJobApplicationDetailOptions(id, missionId, applicationId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyMissionJobApplicationDetailOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyMissionJobApplicationListGet(id: string, missionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMissionJobApplicationList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyMissionJobApplicationListGet(id, missionId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyMissionJobApplicationListGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyMissionJobApplicationListOptions(id: string, missionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMissionJobApplicationList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyMissionJobApplicationListOptions(id, missionId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyMissionJobApplicationListOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyShortlistFreelancerMissionDisplayListGet(id: string, missionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShortlistFreelancerMissionDisplay>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyShortlistFreelancerMissionDisplayListGet(id, missionId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyShortlistFreelancerMissionDisplayListGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyShortlistFreelancerMissionDisplayListOptions(id: string, missionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShortlistFreelancerMissionDisplay>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyShortlistFreelancerMissionDisplayListOptions(id, missionId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyShortlistFreelancerMissionDisplayListOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyShortlistFreelancerMissionDisplayListPost(id: string, missionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShortlistFreelancerMissionDisplay>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyShortlistFreelancerMissionDisplayListPost(id, missionId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.companyShortlistFreelancerMissionDisplayListPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async factoriesApplicationsOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactoriesApplicationsJobApplicationCreateJobApplicationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.factoriesApplicationsOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.factoriesApplicationsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async factoriesApplicationsPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactoriesApplicationsJobApplicationCreateJobApplicationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.factoriesApplicationsPost(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.factoriesApplicationsPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async factoriesCompaniesOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactoriesCompaniesCompanyCreateStaffCompanySerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.factoriesCompaniesOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.factoriesCompaniesOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async factoriesCompaniesPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactoriesCompaniesCompanyCreateStaffCompanySerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.factoriesCompaniesPost(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.factoriesCompaniesPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async factoriesDealsOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactoriesDealsDealCreateDealSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.factoriesDealsOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.factoriesDealsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async factoriesDealsPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactoriesDealsDealCreateDealSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.factoriesDealsPost(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.factoriesDealsPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async factoriesFreelancersOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactoriesFreelancersFreelancerCreateStaffFreelancerSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.factoriesFreelancersOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.factoriesFreelancersOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async factoriesFreelancersPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactoriesFreelancersFreelancerCreateStaffFreelancerSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.factoriesFreelancersPost(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.factoriesFreelancersPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async factoriesJobsOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactoriesJobsJobCreateStaffJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.factoriesJobsOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.factoriesJobsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async factoriesJobsPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactoriesJobsJobCreateStaffJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.factoriesJobsPost(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.factoriesJobsPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async factoriesMembersOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactoriesMembersMemberCreateCompanyMemberSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.factoriesMembersOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.factoriesMembersOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async factoriesMembersPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactoriesMembersMemberCreateCompanyMemberSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.factoriesMembersPost(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.factoriesMembersPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async factoriesPresentationsOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactoriesPresentationsPresentationCreatePresentationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.factoriesPresentationsOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.factoriesPresentationsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async factoriesPresentationsPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactoriesPresentationsPresentationCreatePresentationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.factoriesPresentationsPost(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.factoriesPresentationsPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async factoriesUsersOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactoriesUsersUserCreateUserSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.factoriesUsersOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.factoriesUsersOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async factoriesUsersPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactoriesUsersUserCreateUserSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.factoriesUsersPost(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.factoriesUsersPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerCompleteScreeningGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerCompleteScreeningCompleteScreeningFreelancerSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerCompleteScreeningGet(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerCompleteScreeningGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerCompleteScreeningOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerCompleteScreeningCompleteScreeningFreelancerSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerCompleteScreeningOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerCompleteScreeningOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerCompleteScreeningPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerCompleteScreeningCompleteScreeningFreelancerSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerCompleteScreeningPost(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerCompleteScreeningPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Freelancer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerGet(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {FreelancerIdApplicationsApplicationIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdApplicationsApplicationIdActionGet(id: string, applicationId: string, action: FreelancerIdApplicationsApplicationIdActionGetActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdApplicationsApplicationIdActionApplicationDetailJobApplicationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdApplicationsApplicationIdActionGet(id, applicationId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdApplicationsApplicationIdActionGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {FreelancerIdApplicationsApplicationIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdApplicationsApplicationIdActionOptions(id: string, applicationId: string, action: FreelancerIdApplicationsApplicationIdActionOptionsActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdApplicationsApplicationIdActionApplicationDetailJobApplicationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdApplicationsApplicationIdActionOptions(id, applicationId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdApplicationsApplicationIdActionOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {FreelancerIdApplicationsApplicationIdActionPatchActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdApplicationsApplicationIdActionPatch(id: string, applicationId: string, action: FreelancerIdApplicationsApplicationIdActionPatchActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdApplicationsApplicationIdActionApplicationDetailJobApplicationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdApplicationsApplicationIdActionPatch(id, applicationId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdApplicationsApplicationIdActionPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {FreelancerIdApplicationsApplicationIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdApplicationsApplicationIdActionPut(id: string, applicationId: string, action: FreelancerIdApplicationsApplicationIdActionPutActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdApplicationsApplicationIdActionApplicationDetailJobApplicationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdApplicationsApplicationIdActionPut(id, applicationId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdApplicationsApplicationIdActionPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdApplicationsApplicationIdGet(id: string, applicationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdApplicationsApplicationIdApplicationDetailJobApplicationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdApplicationsApplicationIdGet(id, applicationId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdApplicationsApplicationIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdApplicationsApplicationIdOptions(id: string, applicationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdApplicationsApplicationIdApplicationDetailJobApplicationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdApplicationsApplicationIdOptions(id, applicationId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdApplicationsApplicationIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdApplicationsApplicationIdPatch(id: string, applicationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdApplicationsApplicationIdApplicationDetailJobApplicationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdApplicationsApplicationIdPatch(id, applicationId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdApplicationsApplicationIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdApplicationsApplicationIdPut(id: string, applicationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdApplicationsApplicationIdApplicationDetailJobApplicationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdApplicationsApplicationIdPut(id, applicationId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdApplicationsApplicationIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdApplicationsGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdApplicationsApplicationListJobApplicationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdApplicationsGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdApplicationsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdApplicationsOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdApplicationsApplicationListJobApplicationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdApplicationsOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdApplicationsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdApplicationsPost(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdApplicationsApplicationListJobApplicationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdApplicationsPost(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdApplicationsPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdDelete(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdDelete"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} experienceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdExperiencesExperienceIdDelete(id: string, experienceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdExperiencesExperienceIdExperienceDetailExperienceSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdExperiencesExperienceIdDelete(id, experienceId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdExperiencesExperienceIdDelete"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} experienceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdExperiencesExperienceIdGet(id: string, experienceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdExperiencesExperienceIdExperienceDetailExperienceSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdExperiencesExperienceIdGet(id, experienceId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdExperiencesExperienceIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} experienceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdExperiencesExperienceIdOptions(id: string, experienceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdExperiencesExperienceIdExperienceDetailExperienceSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdExperiencesExperienceIdOptions(id, experienceId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdExperiencesExperienceIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} experienceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdExperiencesExperienceIdPatch(id: string, experienceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdExperiencesExperienceIdExperienceDetailExperienceSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdExperiencesExperienceIdPatch(id, experienceId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdExperiencesExperienceIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} experienceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdExperiencesExperienceIdPut(id: string, experienceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdExperiencesExperienceIdExperienceDetailExperienceSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdExperiencesExperienceIdPut(id, experienceId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdExperiencesExperienceIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdExperiencesGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdExperiencesExperienceListExperienceSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdExperiencesGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdExperiencesGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdExperiencesOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdExperiencesExperienceListExperienceSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdExperiencesOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdExperiencesOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdExperiencesPost(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdExperiencesExperienceListExperienceSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdExperiencesPost(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdExperiencesPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdHiresGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdHiresHireListHireSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdHiresGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdHiresGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} hireId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdHiresHireIdGet(id: string, hireId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdHiresHireIdHireDetailHireSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdHiresHireIdGet(id, hireId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdHiresHireIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} hireId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdHiresHireIdOptions(id: string, hireId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdHiresHireIdHireDetailHireSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdHiresHireIdOptions(id, hireId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdHiresHireIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdHiresOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdHiresHireListHireSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdHiresOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdHiresOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdInterviewsGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdInterviewsInterviewListInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdInterviewsGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdInterviewsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {FreelancerIdInterviewsInterviewIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdInterviewsInterviewIdActionGet(id: string, interviewId: string, action: FreelancerIdInterviewsInterviewIdActionGetActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdInterviewsInterviewIdActionInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdInterviewsInterviewIdActionGet(id, interviewId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdInterviewsInterviewIdActionGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {FreelancerIdInterviewsInterviewIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdInterviewsInterviewIdActionOptions(id: string, interviewId: string, action: FreelancerIdInterviewsInterviewIdActionOptionsActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdInterviewsInterviewIdActionInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdInterviewsInterviewIdActionOptions(id, interviewId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdInterviewsInterviewIdActionOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {FreelancerIdInterviewsInterviewIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdInterviewsInterviewIdActionPut(id: string, interviewId: string, action: FreelancerIdInterviewsInterviewIdActionPutActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdInterviewsInterviewIdActionInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdInterviewsInterviewIdActionPut(id, interviewId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdInterviewsInterviewIdActionPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdInterviewsInterviewIdGet(id: string, interviewId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdInterviewsInterviewIdInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdInterviewsInterviewIdGet(id, interviewId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdInterviewsInterviewIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdInterviewsInterviewIdOptions(id: string, interviewId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdInterviewsInterviewIdInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdInterviewsInterviewIdOptions(id, interviewId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdInterviewsInterviewIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdInterviewsInterviewIdPut(id: string, interviewId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdInterviewsInterviewIdInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdInterviewsInterviewIdPut(id, interviewId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdInterviewsInterviewIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdInterviewsOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdInterviewsInterviewListInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdInterviewsOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdInterviewsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdInterviewsPost(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdInterviewsInterviewListInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdInterviewsPost(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdInterviewsPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdInvitesGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerInvite>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdInvitesGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdInvitesGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} inviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdInvitesInviteIdDelete(id: string, inviteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerInvite>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdInvitesInviteIdDelete(id, inviteId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdInvitesInviteIdDelete"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} inviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdInvitesInviteIdGet(id: string, inviteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerInvite>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdInvitesInviteIdGet(id, inviteId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdInvitesInviteIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} inviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdInvitesInviteIdOptions(id: string, inviteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerInvite>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdInvitesInviteIdOptions(id, inviteId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdInvitesInviteIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdInvitesOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerInvite>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdInvitesOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdInvitesOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdMissionInterviewsGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerMissionInterview>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdMissionInterviewsGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdMissionInterviewsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionInterviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdMissionInterviewsMissionInterviewIdDelete(id: string, missionInterviewId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerMissionInterview>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdMissionInterviewsMissionInterviewIdDelete(id, missionInterviewId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdMissionInterviewsMissionInterviewIdDelete"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionInterviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdMissionInterviewsMissionInterviewIdGet(id: string, missionInterviewId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerMissionInterview>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdMissionInterviewsMissionInterviewIdGet(id, missionInterviewId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdMissionInterviewsMissionInterviewIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionInterviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdMissionInterviewsMissionInterviewIdOptions(id: string, missionInterviewId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerMissionInterview>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdMissionInterviewsMissionInterviewIdOptions(id, missionInterviewId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdMissionInterviewsMissionInterviewIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionInterviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdMissionInterviewsMissionInterviewIdPatch(id: string, missionInterviewId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerMissionInterview>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdMissionInterviewsMissionInterviewIdPatch(id, missionInterviewId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdMissionInterviewsMissionInterviewIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} missionInterviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdMissionInterviewsMissionInterviewIdPut(id: string, missionInterviewId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerMissionInterview>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdMissionInterviewsMissionInterviewIdPut(id, missionInterviewId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdMissionInterviewsMissionInterviewIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdMissionInterviewsOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerMissionInterview>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdMissionInterviewsOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdMissionInterviewsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdMissionInterviewsPost(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerMissionInterview>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdMissionInterviewsPost(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdMissionInterviewsPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdPatch(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdPatch(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdPresentationsGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdPresentationsPresentationListPresentationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdPresentationsGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdPresentationsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdPresentationsOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdPresentationsPresentationListPresentationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdPresentationsOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdPresentationsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {FreelancerIdPresentationsPresentationIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdPresentationsPresentationIdActionGet(id: string, presentationId: string, action: FreelancerIdPresentationsPresentationIdActionGetActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdPresentationsPresentationIdActionPresentationDetailPresentationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdPresentationsPresentationIdActionGet(id, presentationId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdPresentationsPresentationIdActionGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {FreelancerIdPresentationsPresentationIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdPresentationsPresentationIdActionOptions(id: string, presentationId: string, action: FreelancerIdPresentationsPresentationIdActionOptionsActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdPresentationsPresentationIdActionPresentationDetailPresentationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdPresentationsPresentationIdActionOptions(id, presentationId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdPresentationsPresentationIdActionOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {FreelancerIdPresentationsPresentationIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdPresentationsPresentationIdActionPut(id: string, presentationId: string, action: FreelancerIdPresentationsPresentationIdActionPutActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdPresentationsPresentationIdActionPresentationDetailPresentationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdPresentationsPresentationIdActionPut(id, presentationId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdPresentationsPresentationIdActionPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdPresentationsPresentationIdGet(id: string, presentationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdPresentationsPresentationIdPresentationDetailPresentationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdPresentationsPresentationIdGet(id, presentationId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdPresentationsPresentationIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdPresentationsPresentationIdOptions(id: string, presentationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdPresentationsPresentationIdPresentationDetailPresentationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdPresentationsPresentationIdOptions(id, presentationId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdPresentationsPresentationIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdPresentationsPresentationIdPut(id: string, presentationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdPresentationsPresentationIdPresentationDetailPresentationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdPresentationsPresentationIdPut(id, presentationId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdPresentationsPresentationIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdProjectsGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdProjectsProjectListProjectSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdProjectsGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdProjectsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdProjectsOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdProjectsProjectListProjectSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdProjectsOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdProjectsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdProjectsPost(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdProjectsProjectListProjectSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdProjectsPost(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdProjectsPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdProjectsProjectIdDelete(id: string, projectId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdProjectsProjectIdProjectDetailProjectSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdProjectsProjectIdDelete(id, projectId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdProjectsProjectIdDelete"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdProjectsProjectIdGet(id: string, projectId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdProjectsProjectIdProjectDetailProjectSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdProjectsProjectIdGet(id, projectId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdProjectsProjectIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdProjectsProjectIdOptions(id: string, projectId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdProjectsProjectIdProjectDetailProjectSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdProjectsProjectIdOptions(id, projectId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdProjectsProjectIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdProjectsProjectIdPatch(id: string, projectId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdProjectsProjectIdProjectDetailProjectSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdProjectsProjectIdPatch(id, projectId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdProjectsProjectIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdProjectsProjectIdPut(id: string, projectId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdProjectsProjectIdProjectDetailProjectSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdProjectsProjectIdPut(id, projectId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdProjectsProjectIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdPut(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdPut(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdReferencesGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdReferencesReferenceListReferenceSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdReferencesGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdReferencesGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdReferencesOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdReferencesReferenceListReferenceSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdReferencesOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdReferencesOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdReferencesPost(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdReferencesReferenceListReferenceSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdReferencesPost(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdReferencesPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} referenceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdReferencesReferenceIdDelete(id: string, referenceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdReferencesReferenceIdReferenceDetailReferenceSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdReferencesReferenceIdDelete(id, referenceId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdReferencesReferenceIdDelete"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} referenceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdReferencesReferenceIdGet(id: string, referenceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdReferencesReferenceIdReferenceDetailReferenceSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdReferencesReferenceIdGet(id, referenceId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdReferencesReferenceIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} referenceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdReferencesReferenceIdOptions(id: string, referenceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdReferencesReferenceIdReferenceDetailReferenceSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdReferencesReferenceIdOptions(id, referenceId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdReferencesReferenceIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} referenceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdReferencesReferenceIdPatch(id: string, referenceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdReferencesReferenceIdReferenceDetailReferenceSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdReferencesReferenceIdPatch(id, referenceId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdReferencesReferenceIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} referenceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdReferencesReferenceIdPut(id: string, referenceId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdReferencesReferenceIdReferenceDetailReferenceSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdReferencesReferenceIdPut(id, referenceId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdReferencesReferenceIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdReviewsGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdReviewsReviewListFreelancerReviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdReviewsGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdReviewsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdReviewsOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdReviewsReviewListFreelancerReviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdReviewsOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdReviewsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} reviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdReviewsReviewIdGet(id: string, reviewId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdReviewsReviewIdReviewDetailFreelancerReviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdReviewsReviewIdGet(id, reviewId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdReviewsReviewIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} reviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdReviewsReviewIdOptions(id: string, reviewId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdReviewsReviewIdReviewDetailFreelancerReviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdReviewsReviewIdOptions(id, reviewId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdReviewsReviewIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} reviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdReviewsReviewIdPatch(id: string, reviewId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdReviewsReviewIdReviewDetailFreelancerReviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdReviewsReviewIdPatch(id, reviewId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdReviewsReviewIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} reviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdReviewsReviewIdPut(id: string, reviewId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerIdReviewsReviewIdReviewDetailFreelancerReviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdReviewsReviewIdPut(id, reviewId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdReviewsReviewIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} shortlistFreelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdShortlistFreelancersShortlistFreelancerIdGet(id: string, shortlistFreelancerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShortlistFreelancer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdShortlistFreelancersShortlistFreelancerIdGet(id, shortlistFreelancerId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdShortlistFreelancersShortlistFreelancerIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} shortlistFreelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdShortlistFreelancersShortlistFreelancerIdOptions(id: string, shortlistFreelancerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShortlistFreelancer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdShortlistFreelancersShortlistFreelancerIdOptions(id, shortlistFreelancerId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdShortlistFreelancersShortlistFreelancerIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} shortlistFreelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdShortlistFreelancersShortlistFreelancerIdPatch(id: string, shortlistFreelancerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShortlistFreelancer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdShortlistFreelancersShortlistFreelancerIdPatch(id, shortlistFreelancerId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdShortlistFreelancersShortlistFreelancerIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} shortlistFreelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerIdShortlistFreelancersShortlistFreelancerIdPut(id: string, shortlistFreelancerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShortlistFreelancer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerIdShortlistFreelancersShortlistFreelancerIdPut(id, shortlistFreelancerId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerIdShortlistFreelancersShortlistFreelancerIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Freelancer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerPatch(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Freelancer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerPatch(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerPut(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Freelancer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerPut(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerRateRecommendationsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RateSuggestions>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerRateRecommendationsGet(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerRateRecommendationsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerRateRecommendationsOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RateSuggestions>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerRateRecommendationsOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerRateRecommendationsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerShareIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerSharingDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerShareIdGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerShareIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerShareIdOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerSharingDetail>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerShareIdOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerShareIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerShareOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerSharingCreate>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerShareOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerShareOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freelancerSharePost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreelancerSharingCreate>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freelancerSharePost(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.freelancerSharePost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} tier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async hourlyRateTierGet(tier: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HourlyRateTierHourlyRateDetailHourlyRateSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.hourlyRateTierGet(tier, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.hourlyRateTierGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} tier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async hourlyRateTierOptions(tier: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HourlyRateTierHourlyRateDetailHourlyRateSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.hourlyRateTierOptions(tier, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.hourlyRateTierOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobsJobListJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobsGet(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.jobsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobsIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobsIdJobDetailJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobsIdGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.jobsIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobsIdOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobsIdJobDetailJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobsIdOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.jobsIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async jobsOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobsJobListJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.jobsOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.jobsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffApplicationsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffApplicationsApplicationListJobApplicationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffApplicationsGet(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffApplicationsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffApplicationsIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffApplicationsIdApplicationDetailJobApplicationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffApplicationsIdGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffApplicationsIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffApplicationsIdOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffApplicationsIdApplicationDetailJobApplicationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffApplicationsIdOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffApplicationsIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffApplicationsIdPatch(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffApplicationsIdApplicationDetailJobApplicationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffApplicationsIdPatch(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffApplicationsIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffApplicationsIdPut(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffApplicationsIdApplicationDetailJobApplicationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffApplicationsIdPut(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffApplicationsIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffApplicationsOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffApplicationsApplicationListJobApplicationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffApplicationsOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffApplicationsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffAppointmentsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffAppointmentsAppointmentListStaffAppointmentSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffAppointmentsGet(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffAppointmentsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffAppointmentsIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffAppointmentsIdAppointmentDetailStaffAppointmentSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffAppointmentsIdGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffAppointmentsIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffAppointmentsIdOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffAppointmentsIdAppointmentDetailStaffAppointmentSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffAppointmentsIdOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffAppointmentsIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffAppointmentsIdPatch(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffAppointmentsIdAppointmentDetailStaffAppointmentSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffAppointmentsIdPatch(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffAppointmentsIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffAppointmentsIdPut(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffAppointmentsIdAppointmentDetailStaffAppointmentSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffAppointmentsIdPut(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffAppointmentsIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffAppointmentsIdRecordingsGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffAppointmentsIdRecordingsAppointmentRecordingListRecordingSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffAppointmentsIdRecordingsGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffAppointmentsIdRecordingsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffAppointmentsIdRecordingsOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffAppointmentsIdRecordingsAppointmentRecordingListRecordingSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffAppointmentsIdRecordingsOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffAppointmentsIdRecordingsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffAppointmentsIdRecordingsPost(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffAppointmentsIdRecordingsAppointmentRecordingListRecordingSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffAppointmentsIdRecordingsPost(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffAppointmentsIdRecordingsPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffAppointmentsIdRecordingsRecordingIdDelete(id: string, recordingId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffAppointmentsIdRecordingsRecordingIdAppointmentRecordingDetailRecordingSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffAppointmentsIdRecordingsRecordingIdDelete(id, recordingId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffAppointmentsIdRecordingsRecordingIdDelete"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffAppointmentsIdRecordingsRecordingIdGet(id: string, recordingId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffAppointmentsIdRecordingsRecordingIdAppointmentRecordingDetailRecordingSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffAppointmentsIdRecordingsRecordingIdGet(id, recordingId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffAppointmentsIdRecordingsRecordingIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffAppointmentsIdRecordingsRecordingIdOptions(id: string, recordingId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffAppointmentsIdRecordingsRecordingIdAppointmentRecordingDetailRecordingSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffAppointmentsIdRecordingsRecordingIdOptions(id, recordingId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffAppointmentsIdRecordingsRecordingIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffAppointmentsIdRecordingsRecordingIdPatch(id: string, recordingId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffAppointmentsIdRecordingsRecordingIdAppointmentRecordingDetailRecordingSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffAppointmentsIdRecordingsRecordingIdPatch(id, recordingId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffAppointmentsIdRecordingsRecordingIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffAppointmentsIdRecordingsRecordingIdPut(id: string, recordingId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffAppointmentsIdRecordingsRecordingIdAppointmentRecordingDetailRecordingSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffAppointmentsIdRecordingsRecordingIdPut(id, recordingId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffAppointmentsIdRecordingsRecordingIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffAppointmentsOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffAppointmentsAppointmentListStaffAppointmentSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffAppointmentsOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffAppointmentsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffChoresGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffChoresChoreListChoreSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffChoresGet(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffChoresGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffChoresIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffChoresIdChoreDetailChoreSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffChoresIdGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffChoresIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffChoresIdOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffChoresIdChoreDetailChoreSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffChoresIdOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffChoresIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffChoresIdPatch(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffChoresIdChoreDetailChoreSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffChoresIdPatch(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffChoresIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffChoresIdPut(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffChoresIdChoreDetailChoreSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffChoresIdPut(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffChoresIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffChoresOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffChoresChoreListChoreSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffChoresOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffChoresOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffChoresPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffChoresChoreListChoreSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffChoresPost(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffChoresPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffCompaniesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffCompaniesCompanyListStaffCompanySerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffCompaniesGet(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffCompaniesGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {StaffCompaniesIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffCompaniesIdActionGet(id: string, action: StaffCompaniesIdActionGetActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffCompaniesIdActionCompanyDetailStaffCompanySerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffCompaniesIdActionGet(id, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffCompaniesIdActionGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {StaffCompaniesIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffCompaniesIdActionOptions(id: string, action: StaffCompaniesIdActionOptionsActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffCompaniesIdActionCompanyDetailStaffCompanySerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffCompaniesIdActionOptions(id, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffCompaniesIdActionOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {StaffCompaniesIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffCompaniesIdActionPut(id: string, action: StaffCompaniesIdActionPutActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffCompaniesIdActionCompanyDetailStaffCompanySerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffCompaniesIdActionPut(id, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffCompaniesIdActionPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffCompaniesIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffCompaniesIdCompanyDetailStaffCompanySerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffCompaniesIdGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffCompaniesIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffCompaniesIdOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffCompaniesIdCompanyDetailStaffCompanySerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffCompaniesIdOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffCompaniesIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffCompaniesIdPut(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffCompaniesIdCompanyDetailStaffCompanySerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffCompaniesIdPut(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffCompaniesIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffCompaniesOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffCompaniesCompanyListStaffCompanySerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffCompaniesOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffCompaniesOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffCompaniesPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffCompaniesCompanyListStaffCompanySerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffCompaniesPost(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffCompaniesPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsDealListDealSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsGet(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdActionGet(id: string, action: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdActionDealDetailDealSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdActionGet(id, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdActionGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdActionOptions(id: string, action: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdActionDealDetailDealSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdActionOptions(id, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdActionOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdActionPatch(id: string, action: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdActionDealDetailDealSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdActionPatch(id, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdActionPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdActionPut(id: string, action: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdActionDealDetailDealSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdActionPut(id, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdActionPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdDealDetailDealSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdInterviewsGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdInterviewsDealInterviewListInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdInterviewsGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdInterviewsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {StaffDealsIdInterviewsInterviewIdActionDeleteActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdInterviewsInterviewIdActionDelete(id: string, interviewId: string, action: StaffDealsIdInterviewsInterviewIdActionDeleteActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdInterviewsInterviewIdActionDealInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdInterviewsInterviewIdActionDelete(id, interviewId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdInterviewsInterviewIdActionDelete"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {StaffDealsIdInterviewsInterviewIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdInterviewsInterviewIdActionGet(id: string, interviewId: string, action: StaffDealsIdInterviewsInterviewIdActionGetActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdInterviewsInterviewIdActionDealInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdInterviewsInterviewIdActionGet(id, interviewId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdInterviewsInterviewIdActionGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {StaffDealsIdInterviewsInterviewIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdInterviewsInterviewIdActionOptions(id: string, interviewId: string, action: StaffDealsIdInterviewsInterviewIdActionOptionsActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdInterviewsInterviewIdActionDealInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdInterviewsInterviewIdActionOptions(id, interviewId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdInterviewsInterviewIdActionOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {StaffDealsIdInterviewsInterviewIdActionPatchActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdInterviewsInterviewIdActionPatch(id: string, interviewId: string, action: StaffDealsIdInterviewsInterviewIdActionPatchActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdInterviewsInterviewIdActionDealInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdInterviewsInterviewIdActionPatch(id, interviewId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdInterviewsInterviewIdActionPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {StaffDealsIdInterviewsInterviewIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdInterviewsInterviewIdActionPut(id: string, interviewId: string, action: StaffDealsIdInterviewsInterviewIdActionPutActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdInterviewsInterviewIdActionDealInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdInterviewsInterviewIdActionPut(id, interviewId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdInterviewsInterviewIdActionPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdInterviewsInterviewIdDelete(id: string, interviewId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdInterviewsInterviewIdDealInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdInterviewsInterviewIdDelete(id, interviewId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdInterviewsInterviewIdDelete"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdInterviewsInterviewIdGet(id: string, interviewId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdInterviewsInterviewIdDealInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdInterviewsInterviewIdGet(id, interviewId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdInterviewsInterviewIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdInterviewsInterviewIdOptions(id: string, interviewId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdInterviewsInterviewIdDealInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdInterviewsInterviewIdOptions(id, interviewId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdInterviewsInterviewIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdInterviewsInterviewIdPatch(id: string, interviewId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdInterviewsInterviewIdDealInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdInterviewsInterviewIdPatch(id, interviewId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdInterviewsInterviewIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdInterviewsInterviewIdPut(id: string, interviewId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdInterviewsInterviewIdDealInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdInterviewsInterviewIdPut(id, interviewId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdInterviewsInterviewIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdInterviewsOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdInterviewsDealInterviewListInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdInterviewsOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdInterviewsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdInterviewsPost(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdInterviewsDealInterviewListInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdInterviewsPost(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdInterviewsPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdDealDetailDealSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdPatch(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdDealDetailDealSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdPatch(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdPresentationsDeclineOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdPresentationsDeclinePresentationBulkDeclinePresentationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdPresentationsDeclineOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdPresentationsDeclineOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdPresentationsDeclinePatch(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdPresentationsDeclinePresentationBulkDeclinePresentationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdPresentationsDeclinePatch(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdPresentationsDeclinePatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdPresentationsDeclinePut(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdPresentationsDeclinePresentationBulkDeclinePresentationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdPresentationsDeclinePut(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdPresentationsDeclinePut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdPresentationsGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdPresentationsPresentationListPresentationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdPresentationsGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdPresentationsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdPresentationsOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdPresentationsPresentationListPresentationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdPresentationsOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdPresentationsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdPresentationsPost(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdPresentationsPresentationListPresentationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdPresentationsPost(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdPresentationsPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {StaffDealsIdPresentationsPresentationIdActionDeleteActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdPresentationsPresentationIdActionDelete(id: string, presentationId: string, action: StaffDealsIdPresentationsPresentationIdActionDeleteActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdPresentationsPresentationIdActionPresentationDetailPresentationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdPresentationsPresentationIdActionDelete(id, presentationId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdPresentationsPresentationIdActionDelete"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {StaffDealsIdPresentationsPresentationIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdPresentationsPresentationIdActionGet(id: string, presentationId: string, action: StaffDealsIdPresentationsPresentationIdActionGetActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdPresentationsPresentationIdActionPresentationDetailPresentationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdPresentationsPresentationIdActionGet(id, presentationId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdPresentationsPresentationIdActionGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {StaffDealsIdPresentationsPresentationIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdPresentationsPresentationIdActionOptions(id: string, presentationId: string, action: StaffDealsIdPresentationsPresentationIdActionOptionsActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdPresentationsPresentationIdActionPresentationDetailPresentationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdPresentationsPresentationIdActionOptions(id, presentationId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdPresentationsPresentationIdActionOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {StaffDealsIdPresentationsPresentationIdActionPatchActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdPresentationsPresentationIdActionPatch(id: string, presentationId: string, action: StaffDealsIdPresentationsPresentationIdActionPatchActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdPresentationsPresentationIdActionPresentationDetailPresentationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdPresentationsPresentationIdActionPatch(id, presentationId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdPresentationsPresentationIdActionPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {StaffDealsIdPresentationsPresentationIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdPresentationsPresentationIdActionPut(id: string, presentationId: string, action: StaffDealsIdPresentationsPresentationIdActionPutActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdPresentationsPresentationIdActionPresentationDetailPresentationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdPresentationsPresentationIdActionPut(id, presentationId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdPresentationsPresentationIdActionPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdPresentationsPresentationIdDelete(id: string, presentationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdPresentationsPresentationIdPresentationDetailPresentationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdPresentationsPresentationIdDelete(id, presentationId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdPresentationsPresentationIdDelete"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdPresentationsPresentationIdGet(id: string, presentationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdPresentationsPresentationIdPresentationDetailPresentationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdPresentationsPresentationIdGet(id, presentationId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdPresentationsPresentationIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdPresentationsPresentationIdOptions(id: string, presentationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdPresentationsPresentationIdPresentationDetailPresentationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdPresentationsPresentationIdOptions(id, presentationId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdPresentationsPresentationIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdPresentationsPresentationIdPatch(id: string, presentationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdPresentationsPresentationIdPresentationDetailPresentationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdPresentationsPresentationIdPatch(id, presentationId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdPresentationsPresentationIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdPresentationsPresentationIdPut(id: string, presentationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdPresentationsPresentationIdPresentationDetailPresentationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdPresentationsPresentationIdPut(id, presentationId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdPresentationsPresentationIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsIdPut(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsIdDealDetailDealSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsIdPut(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsDealListDealSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffDealsPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffDealsDealListDealSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffDealsPost(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffDealsPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffFreelancersGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffFreelancersFreelancerListStaffFreelancerSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffFreelancersGet(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffFreelancersGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {StaffFreelancersIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffFreelancersIdActionGet(id: string, action: StaffFreelancersIdActionGetActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffFreelancersIdActionFreelancerDetailStaffFreelancerSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffFreelancersIdActionGet(id, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffFreelancersIdActionGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {StaffFreelancersIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffFreelancersIdActionOptions(id: string, action: StaffFreelancersIdActionOptionsActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffFreelancersIdActionFreelancerDetailStaffFreelancerSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffFreelancersIdActionOptions(id, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffFreelancersIdActionOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {StaffFreelancersIdActionPatchActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffFreelancersIdActionPatch(id: string, action: StaffFreelancersIdActionPatchActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffFreelancersIdActionFreelancerDetailStaffFreelancerSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffFreelancersIdActionPatch(id, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffFreelancersIdActionPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {StaffFreelancersIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffFreelancersIdActionPut(id: string, action: StaffFreelancersIdActionPutActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffFreelancersIdActionFreelancerDetailStaffFreelancerSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffFreelancersIdActionPut(id, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffFreelancersIdActionPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffFreelancersIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffFreelancersIdFreelancerDetailStaffFreelancerSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffFreelancersIdGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffFreelancersIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffFreelancersIdHiresGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffFreelancersIdHiresFreelancerHireListHireSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffFreelancersIdHiresGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffFreelancersIdHiresGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffFreelancersIdHiresOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffFreelancersIdHiresFreelancerHireListHireSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffFreelancersIdHiresOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffFreelancersIdHiresOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffFreelancersIdHiresPost(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffFreelancersIdHiresFreelancerHireListHireSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffFreelancersIdHiresPost(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffFreelancersIdHiresPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffFreelancersIdMatchesGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffFreelancersIdMatchesFreelancerMatchListStaffJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffFreelancersIdMatchesGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffFreelancersIdMatchesGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffFreelancersIdMatchesOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffFreelancersIdMatchesFreelancerMatchListStaffJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffFreelancersIdMatchesOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffFreelancersIdMatchesOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffFreelancersIdMessagesGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffFreelancersIdMessagesFreelancerMessageListMessageSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffFreelancersIdMessagesGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffFreelancersIdMessagesGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffFreelancersIdMessagesOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffFreelancersIdMessagesFreelancerMessageListMessageSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffFreelancersIdMessagesOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffFreelancersIdMessagesOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffFreelancersIdNotesGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffFreelancersIdNotesFreelancerNoteListNoteSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffFreelancersIdNotesGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffFreelancersIdNotesGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffFreelancersIdNotesOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffFreelancersIdNotesFreelancerNoteListNoteSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffFreelancersIdNotesOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffFreelancersIdNotesOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffFreelancersIdNotesPost(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffFreelancersIdNotesFreelancerNoteListNoteSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffFreelancersIdNotesPost(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffFreelancersIdNotesPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffFreelancersIdOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffFreelancersIdFreelancerDetailStaffFreelancerSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffFreelancersIdOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffFreelancersIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffFreelancersIdPatch(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffFreelancersIdFreelancerDetailStaffFreelancerSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffFreelancersIdPatch(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffFreelancersIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffFreelancersIdPut(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffFreelancersIdFreelancerDetailStaffFreelancerSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffFreelancersIdPut(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffFreelancersIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffFreelancersIdRecordingsGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffFreelancersIdRecordingsFreelancerRecordingListRecordingSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffFreelancersIdRecordingsGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffFreelancersIdRecordingsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffFreelancersIdRecordingsOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffFreelancersIdRecordingsFreelancerRecordingListRecordingSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffFreelancersIdRecordingsOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffFreelancersIdRecordingsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffFreelancersIdRecordingsPost(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffFreelancersIdRecordingsFreelancerRecordingListRecordingSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffFreelancersIdRecordingsPost(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffFreelancersIdRecordingsPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffFreelancersIdRecordingsRecordingIdDelete(id: string, recordingId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffFreelancersIdRecordingsRecordingIdFreelancerRecordingDetailRecordingSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffFreelancersIdRecordingsRecordingIdDelete(id, recordingId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffFreelancersIdRecordingsRecordingIdDelete"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffFreelancersIdRecordingsRecordingIdGet(id: string, recordingId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffFreelancersIdRecordingsRecordingIdFreelancerRecordingDetailRecordingSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffFreelancersIdRecordingsRecordingIdGet(id, recordingId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffFreelancersIdRecordingsRecordingIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffFreelancersIdRecordingsRecordingIdOptions(id: string, recordingId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffFreelancersIdRecordingsRecordingIdFreelancerRecordingDetailRecordingSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffFreelancersIdRecordingsRecordingIdOptions(id, recordingId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffFreelancersIdRecordingsRecordingIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffFreelancersIdRecordingsRecordingIdPatch(id: string, recordingId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffFreelancersIdRecordingsRecordingIdFreelancerRecordingDetailRecordingSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffFreelancersIdRecordingsRecordingIdPatch(id, recordingId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffFreelancersIdRecordingsRecordingIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffFreelancersIdRecordingsRecordingIdPut(id: string, recordingId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffFreelancersIdRecordingsRecordingIdFreelancerRecordingDetailRecordingSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffFreelancersIdRecordingsRecordingIdPut(id, recordingId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffFreelancersIdRecordingsRecordingIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffFreelancersIdScreeningGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffFreelancersIdScreeningFreelancerScreeningDebuggerStaffFreelancerSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffFreelancersIdScreeningGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffFreelancersIdScreeningGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffFreelancersIdScreeningOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffFreelancersIdScreeningFreelancerScreeningDebuggerStaffFreelancerSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffFreelancersIdScreeningOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffFreelancersIdScreeningOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffFreelancersOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffFreelancersFreelancerListStaffFreelancerSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffFreelancersOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffFreelancersOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffHiresGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffHiresHireListHireSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffHiresGet(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffHiresGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {StaffHiresIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffHiresIdActionGet(id: number, action: StaffHiresIdActionGetActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffHiresIdActionHireDetailHireSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffHiresIdActionGet(id, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffHiresIdActionGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {StaffHiresIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffHiresIdActionOptions(id: number, action: StaffHiresIdActionOptionsActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffHiresIdActionHireDetailHireSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffHiresIdActionOptions(id, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffHiresIdActionOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {StaffHiresIdActionPatchActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffHiresIdActionPatch(id: number, action: StaffHiresIdActionPatchActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffHiresIdActionHireDetailHireSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffHiresIdActionPatch(id, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffHiresIdActionPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {StaffHiresIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffHiresIdActionPut(id: number, action: StaffHiresIdActionPutActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffHiresIdActionHireDetailHireSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffHiresIdActionPut(id, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffHiresIdActionPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffHiresIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffHiresIdHireDetailHireSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffHiresIdGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffHiresIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffHiresIdOptions(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffHiresIdHireDetailHireSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffHiresIdOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffHiresIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffHiresIdPatch(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffHiresIdHireDetailHireSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffHiresIdPatch(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffHiresIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffHiresIdPut(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffHiresIdHireDetailHireSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffHiresIdPut(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffHiresIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffHiresIdScopeOfWorkGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffHiresIdScopeOfWorkScopeOfWorkListScopeOfWorkSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffHiresIdScopeOfWorkGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffHiresIdScopeOfWorkGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffHiresIdScopeOfWorkOptions(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffHiresIdScopeOfWorkScopeOfWorkListScopeOfWorkSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffHiresIdScopeOfWorkOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffHiresIdScopeOfWorkOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffHiresIdScopeOfWorkPost(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffHiresIdScopeOfWorkScopeOfWorkListScopeOfWorkSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffHiresIdScopeOfWorkPost(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffHiresIdScopeOfWorkPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {string} scopeOfWorkId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffHiresIdScopeOfWorkScopeOfWorkIdGet(id: number, scopeOfWorkId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffHiresIdScopeOfWorkScopeOfWorkIdScopeOfWorkDetailScopeOfWorkSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffHiresIdScopeOfWorkScopeOfWorkIdGet(id, scopeOfWorkId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffHiresIdScopeOfWorkScopeOfWorkIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {string} scopeOfWorkId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffHiresIdScopeOfWorkScopeOfWorkIdOptions(id: number, scopeOfWorkId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffHiresIdScopeOfWorkScopeOfWorkIdScopeOfWorkDetailScopeOfWorkSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffHiresIdScopeOfWorkScopeOfWorkIdOptions(id, scopeOfWorkId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffHiresIdScopeOfWorkScopeOfWorkIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffHiresOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffHiresHireListHireSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffHiresOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffHiresOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffHiresPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffHiresHireListHireSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffHiresPost(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffHiresPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffInterviewsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffInterviewsInterviewListInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffInterviewsGet(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffInterviewsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {StaffInterviewsIdActionDeleteActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffInterviewsIdActionDelete(id: string, action: StaffInterviewsIdActionDeleteActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffInterviewsIdActionInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffInterviewsIdActionDelete(id, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffInterviewsIdActionDelete"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {StaffInterviewsIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffInterviewsIdActionGet(id: string, action: StaffInterviewsIdActionGetActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffInterviewsIdActionInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffInterviewsIdActionGet(id, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffInterviewsIdActionGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {StaffInterviewsIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffInterviewsIdActionOptions(id: string, action: StaffInterviewsIdActionOptionsActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffInterviewsIdActionInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffInterviewsIdActionOptions(id, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffInterviewsIdActionOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {StaffInterviewsIdActionPatchActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffInterviewsIdActionPatch(id: string, action: StaffInterviewsIdActionPatchActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffInterviewsIdActionInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffInterviewsIdActionPatch(id, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffInterviewsIdActionPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {StaffInterviewsIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffInterviewsIdActionPut(id: string, action: StaffInterviewsIdActionPutActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffInterviewsIdActionInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffInterviewsIdActionPut(id, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffInterviewsIdActionPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffInterviewsIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffInterviewsIdInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffInterviewsIdDelete(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffInterviewsIdDelete"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffInterviewsIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffInterviewsIdInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffInterviewsIdGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffInterviewsIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffInterviewsIdOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffInterviewsIdInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffInterviewsIdOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffInterviewsIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffInterviewsIdPatch(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffInterviewsIdInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffInterviewsIdPatch(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffInterviewsIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffInterviewsIdPut(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffInterviewsIdInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffInterviewsIdPut(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffInterviewsIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffInterviewsOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffInterviewsInterviewListInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffInterviewsOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffInterviewsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffInterviewsPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffInterviewsInterviewListInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffInterviewsPost(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffInterviewsPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsJobListStaffJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsGet(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {StaffJobsIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdActionGet(id: string, action: StaffJobsIdActionGetActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdActionJobDetailStaffJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdActionGet(id, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdActionGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {StaffJobsIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdActionOptions(id: string, action: StaffJobsIdActionOptionsActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdActionJobDetailStaffJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdActionOptions(id, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdActionOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {StaffJobsIdActionPatchActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdActionPatch(id: string, action: StaffJobsIdActionPatchActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdActionJobDetailStaffJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdActionPatch(id, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdActionPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {StaffJobsIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdActionPut(id: string, action: StaffJobsIdActionPutActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdActionJobDetailStaffJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdActionPut(id, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdActionPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {StaffJobsIdApplicationsApplicationIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdApplicationsApplicationIdActionGet(id: string, applicationId: string, action: StaffJobsIdApplicationsApplicationIdActionGetActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdApplicationsApplicationIdActionJobApplicationDetailJobApplicationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdApplicationsApplicationIdActionGet(id, applicationId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdApplicationsApplicationIdActionGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {StaffJobsIdApplicationsApplicationIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdApplicationsApplicationIdActionOptions(id: string, applicationId: string, action: StaffJobsIdApplicationsApplicationIdActionOptionsActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdApplicationsApplicationIdActionJobApplicationDetailJobApplicationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdApplicationsApplicationIdActionOptions(id, applicationId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdApplicationsApplicationIdActionOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {StaffJobsIdApplicationsApplicationIdActionPatchActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdApplicationsApplicationIdActionPatch(id: string, applicationId: string, action: StaffJobsIdApplicationsApplicationIdActionPatchActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdApplicationsApplicationIdActionJobApplicationDetailJobApplicationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdApplicationsApplicationIdActionPatch(id, applicationId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdApplicationsApplicationIdActionPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {StaffJobsIdApplicationsApplicationIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdApplicationsApplicationIdActionPut(id: string, applicationId: string, action: StaffJobsIdApplicationsApplicationIdActionPutActionEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdApplicationsApplicationIdActionJobApplicationDetailJobApplicationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdApplicationsApplicationIdActionPut(id, applicationId, action, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdApplicationsApplicationIdActionPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdApplicationsApplicationIdGet(id: string, applicationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdApplicationsApplicationIdJobApplicationDetailJobApplicationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdApplicationsApplicationIdGet(id, applicationId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdApplicationsApplicationIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdApplicationsApplicationIdOptions(id: string, applicationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdApplicationsApplicationIdJobApplicationDetailJobApplicationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdApplicationsApplicationIdOptions(id, applicationId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdApplicationsApplicationIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdApplicationsApplicationIdPatch(id: string, applicationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdApplicationsApplicationIdJobApplicationDetailJobApplicationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdApplicationsApplicationIdPatch(id, applicationId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdApplicationsApplicationIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdApplicationsApplicationIdPut(id: string, applicationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdApplicationsApplicationIdJobApplicationDetailJobApplicationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdApplicationsApplicationIdPut(id, applicationId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdApplicationsApplicationIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdApplicationsDeclineOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdApplicationsDeclineJobApplicationBulkDeclineJobApplicationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdApplicationsDeclineOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdApplicationsDeclineOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdApplicationsDeclinePatch(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdApplicationsDeclineJobApplicationBulkDeclineJobApplicationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdApplicationsDeclinePatch(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdApplicationsDeclinePatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdApplicationsDeclinePut(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdApplicationsDeclineJobApplicationBulkDeclineJobApplicationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdApplicationsDeclinePut(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdApplicationsDeclinePut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdApplicationsGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdApplicationsJobApplicationListJobApplicationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdApplicationsGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdApplicationsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdApplicationsOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdApplicationsJobApplicationListJobApplicationSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdApplicationsOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdApplicationsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} freelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdFreelancersFreelancerIdGet(id: string, freelancerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdFreelancersFreelancerIdJobFreelancerDetailJobFreelancerSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdFreelancersFreelancerIdGet(id, freelancerId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdFreelancersFreelancerIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {string} freelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdFreelancersFreelancerIdOptions(id: string, freelancerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdFreelancersFreelancerIdJobFreelancerDetailJobFreelancerSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdFreelancersFreelancerIdOptions(id, freelancerId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdFreelancersFreelancerIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdFreelancersGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdFreelancersJobFreelancerListJobFreelancerSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdFreelancersGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdFreelancersGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdFreelancersOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdFreelancersJobFreelancerListJobFreelancerSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdFreelancersOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdFreelancersOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdJobDetailStaffJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdJobDetailStaffJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdPatch(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdJobDetailStaffJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdPatch(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdPut(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdJobDetailStaffJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdPut(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdRecordingsGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdRecordingsJobRecordingListRecordingSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdRecordingsGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdRecordingsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdRecordingsOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdRecordingsJobRecordingListRecordingSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdRecordingsOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdRecordingsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdRecordingsPost(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdRecordingsJobRecordingListRecordingSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdRecordingsPost(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdRecordingsPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdRecordingsRecordingIdDelete(id: string, recordingId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdRecordingsRecordingIdJobRecordingDetailRecordingSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdRecordingsRecordingIdDelete(id, recordingId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdRecordingsRecordingIdDelete"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdRecordingsRecordingIdGet(id: string, recordingId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdRecordingsRecordingIdJobRecordingDetailRecordingSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdRecordingsRecordingIdGet(id, recordingId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdRecordingsRecordingIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdRecordingsRecordingIdOptions(id: string, recordingId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdRecordingsRecordingIdJobRecordingDetailRecordingSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdRecordingsRecordingIdOptions(id, recordingId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdRecordingsRecordingIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdRecordingsRecordingIdPatch(id: string, recordingId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdRecordingsRecordingIdJobRecordingDetailRecordingSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdRecordingsRecordingIdPatch(id, recordingId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdRecordingsRecordingIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdRecordingsRecordingIdPut(id: string, recordingId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdRecordingsRecordingIdJobRecordingDetailRecordingSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdRecordingsRecordingIdPut(id, recordingId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdRecordingsRecordingIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdSearchesGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdSearchesJobFreelancerSearchListJobFreelancerSearchSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdSearchesGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdSearchesGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdSearchesOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdSearchesJobFreelancerSearchListJobFreelancerSearchSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdSearchesOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdSearchesOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdSearchesPost(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdSearchesJobFreelancerSearchListJobFreelancerSearchSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdSearchesPost(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdSearchesPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} searchId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdSearchesSearchIdDelete(id: string, searchId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdSearchesSearchIdJobFreelancerSearchDetailJobFreelancerSearchSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdSearchesSearchIdDelete(id, searchId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdSearchesSearchIdDelete"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} searchId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdSearchesSearchIdGet(id: string, searchId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdSearchesSearchIdJobFreelancerSearchDetailJobFreelancerSearchSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdSearchesSearchIdGet(id, searchId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdSearchesSearchIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} searchId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdSearchesSearchIdOptions(id: string, searchId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdSearchesSearchIdJobFreelancerSearchDetailJobFreelancerSearchSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdSearchesSearchIdOptions(id, searchId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdSearchesSearchIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} searchId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdSearchesSearchIdPatch(id: string, searchId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdSearchesSearchIdJobFreelancerSearchDetailJobFreelancerSearchSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdSearchesSearchIdPatch(id, searchId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdSearchesSearchIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {number} searchId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsIdSearchesSearchIdPut(id: string, searchId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsIdSearchesSearchIdJobFreelancerSearchDetailJobFreelancerSearchSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsIdSearchesSearchIdPut(id, searchId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsIdSearchesSearchIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffJobsOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffJobsJobListStaffJobSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffJobsOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffJobsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffMessagesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffMessagesMessageListMessageSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffMessagesGet(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffMessagesGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffMessagesOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffMessagesMessageListMessageSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffMessagesOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffMessagesOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffMessagesPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffMessagesMessageListMessageSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffMessagesPost(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffMessagesPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffReportsMetricsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffReportsMetricsMetricListMetricSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffReportsMetricsGet(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffReportsMetricsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffReportsMetricsOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffReportsMetricsMetricListMetricSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffReportsMetricsOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffReportsMetricsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffReviewsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffReviewsReviewListStaffReviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffReviewsGet(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffReviewsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffReviewsIdGet(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffReviewsIdReviewDetailStaffReviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffReviewsIdGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffReviewsIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffReviewsIdOptions(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffReviewsIdReviewDetailStaffReviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffReviewsIdOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffReviewsIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffReviewsIdPatch(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffReviewsIdReviewDetailStaffReviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffReviewsIdPatch(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffReviewsIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffReviewsIdPut(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffReviewsIdReviewDetailStaffReviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffReviewsIdPut(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffReviewsIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async staffReviewsOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffReviewsReviewListStaffReviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.staffReviewsOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.staffReviewsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async subscriptionsHashGet(hash: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionsHashSubscriptionsSubscriptionSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionsHashGet(hash, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.subscriptionsHashGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async subscriptionsHashOptions(hash: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionsHashSubscriptionsSubscriptionSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionsHashOptions(hash, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.subscriptionsHashOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async subscriptionsHashPatch(hash: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionsHashSubscriptionsSubscriptionSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionsHashPatch(hash, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.subscriptionsHashPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async subscriptionsHashPut(hash: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionsHashSubscriptionsSubscriptionSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionsHashPut(hash, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.subscriptionsHashPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userAppointmentsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAppointmentsAppointmentListAppointmentSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userAppointmentsGet(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.userAppointmentsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userAppointmentsIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAppointmentsIdAppointmentDetailAppointmentSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userAppointmentsIdGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.userAppointmentsIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userAppointmentsIdOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAppointmentsIdAppointmentDetailAppointmentSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userAppointmentsIdOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.userAppointmentsIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userAppointmentsOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAppointmentsAppointmentListAppointmentSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userAppointmentsOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.userAppointmentsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userCreateCompanyOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCreateCompanyCreateCompanyCompanySerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userCreateCompanyOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.userCreateCompanyOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userCreateCompanyPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCreateCompanyCreateCompanyCompanySerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userCreateCompanyPost(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.userCreateCompanyPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userCreateFreelancerOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCreateFreelancerCreateFreelancerFreelancerSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userCreateFreelancerOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.userCreateFreelancerOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userCreateFreelancerPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCreateFreelancerCreateFreelancerFreelancerSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userCreateFreelancerPost(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.userCreateFreelancerPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserDetailUserSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userGet(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.userGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userGrantNylasDelete(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGrantNylasNylasGrantNylasGrantSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userGrantNylasDelete(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.userGrantNylasDelete"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userGrantNylasGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGrantNylasNylasGrantNylasGrantSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userGrantNylasGet(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.userGrantNylasGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userGrantNylasOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGrantNylasNylasGrantNylasGrantSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userGrantNylasOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.userGrantNylasOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userGrantNylasPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGrantNylasNylasGrantNylasGrantSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userGrantNylasPost(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.userGrantNylasPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userInterviewsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInterviewsInterviewListInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userInterviewsGet(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.userInterviewsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userInterviewsIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInterviewsIdInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userInterviewsIdGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.userInterviewsIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userInterviewsIdOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInterviewsIdInterviewDetailInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userInterviewsIdOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.userInterviewsIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userInterviewsOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInterviewsInterviewListInterviewSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userInterviewsOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.userInterviewsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserDetailUserSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.userOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userPatch(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserDetailUserSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userPatch(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.userPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userPut(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserDetailUserSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userPut(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.userPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userSubscriptionsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSubscriptionsSubscriptionsSubscriptionSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userSubscriptionsGet(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.userSubscriptionsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userSubscriptionsOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSubscriptionsSubscriptionsSubscriptionSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userSubscriptionsOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.userSubscriptionsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userSubscriptionsPatch(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSubscriptionsSubscriptionsSubscriptionSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userSubscriptionsPatch(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.userSubscriptionsPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userSubscriptionsPut(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSubscriptionsSubscriptionsSubscriptionSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userSubscriptionsPut(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.userSubscriptionsPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userSubscriptionsSlackOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSubscriptionsSlackSlackSubscriptionSlackSubscriptionSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userSubscriptionsSlackOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.userSubscriptionsSlackOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userSubscriptionsSlackPatch(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSubscriptionsSlackSlackSubscriptionSlackSubscriptionSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userSubscriptionsSlackPatch(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.userSubscriptionsSlackPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userSubscriptionsSlackPut(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSubscriptionsSlackSlackSubscriptionSlackSubscriptionSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userSubscriptionsSlackPut(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.userSubscriptionsSlackPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userSubscriptionsTelegramOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSubscriptionsTelegramTelegramSubscriptionTelegramSubscriptionSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userSubscriptionsTelegramOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.userSubscriptionsTelegramOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userSubscriptionsTelegramPatch(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSubscriptionsTelegramTelegramSubscriptionTelegramSubscriptionSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userSubscriptionsTelegramPatch(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.userSubscriptionsTelegramPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userSubscriptionsTelegramPut(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSubscriptionsTelegramTelegramSubscriptionTelegramSubscriptionSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userSubscriptionsTelegramPut(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.userSubscriptionsTelegramPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userWorkstyleGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserWorkstyleWorkStyleAssessmentSelfWorkStyleAssessmentSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userWorkstyleGet(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.userWorkstyleGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userWorkstyleOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserWorkstyleWorkStyleAssessmentSelfWorkStyleAssessmentSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userWorkstyleOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.userWorkstyleOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersUserListProfileSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersGet(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.usersGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersUserListProfileSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.usersOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workstylesAssessmentsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkstylesAssessmentsWorkStyleAssessmentListWorkStyleAssessmentSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workstylesAssessmentsGet(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.workstylesAssessmentsGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workstylesAssessmentsIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkstylesAssessmentsIdWorkStyleAssessmentDetailWorkStyleAssessmentSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workstylesAssessmentsIdGet(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.workstylesAssessmentsIdGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workstylesAssessmentsIdOptions(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkstylesAssessmentsIdWorkStyleAssessmentDetailWorkStyleAssessmentSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workstylesAssessmentsIdOptions(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.workstylesAssessmentsIdOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workstylesAssessmentsIdPatch(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkstylesAssessmentsIdWorkStyleAssessmentDetailWorkStyleAssessmentSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workstylesAssessmentsIdPatch(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.workstylesAssessmentsIdPatch"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workstylesAssessmentsIdPut(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkstylesAssessmentsIdWorkStyleAssessmentDetailWorkStyleAssessmentSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workstylesAssessmentsIdPut(id, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.workstylesAssessmentsIdPut"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workstylesAssessmentsOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkstylesAssessmentsWorkStyleAssessmentListWorkStyleAssessmentSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workstylesAssessmentsOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.workstylesAssessmentsOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workstylesAssessmentsPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkstylesAssessmentsWorkStyleAssessmentListWorkStyleAssessmentSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workstylesAssessmentsPost(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.workstylesAssessmentsPost"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workstylesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkstylesWorkStyleListWorkStyleSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workstylesGet(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.workstylesGet"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async workstylesOptions(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkstylesWorkStyleListWorkStyleSerializer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.workstylesOptions(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap["DefaultApi.workstylesOptions"]?.[localVarOperationServerIndex]?.url

      return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath)
    },
  }
}

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DefaultApiFp(configuration)

  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authInviteGet(options?: any): AxiosPromise<AuthInviteCreateUserUserSerializer> {
      return localVarFp.authInviteGet(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authInviteOptions(options?: any): AxiosPromise<AuthInviteCreateUserUserSerializer> {
      return localVarFp.authInviteOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authInvitePost(options?: any): AxiosPromise<AuthInviteCreateUserUserSerializer> {
      return localVarFp.authInvitePost(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authLoginGet(options?: any): AxiosPromise<AuthLoginLoginUserSerializer> {
      return localVarFp.authLoginGet(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authLoginOptions(options?: any): AxiosPromise<AuthLoginLoginUserSerializer> {
      return localVarFp.authLoginOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authLoginPost(options?: any): AxiosPromise<AuthLoginLoginUserSerializer> {
      return localVarFp.authLoginPost(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} dealId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdDealsDealIdGet(id: string, dealId: string, options?: any): AxiosPromise<CompanyIdDealsDealIdDealDetailDealSerializer> {
      return localVarFp.companyIdDealsDealIdGet(id, dealId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} dealId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdDealsDealIdOptions(id: string, dealId: string, options?: any): AxiosPromise<CompanyIdDealsDealIdDealDetailDealSerializer> {
      return localVarFp.companyIdDealsDealIdOptions(id, dealId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} dealId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdDealsDealIdPresentationsGet(id: string, dealId: string, options?: any): AxiosPromise<PresentationListEntry> {
      return localVarFp.companyIdDealsDealIdPresentationsGet(id, dealId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} dealId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdDealsDealIdPresentationsOptions(id: string, dealId: string, options?: any): AxiosPromise<PresentationListEntry> {
      return localVarFp.companyIdDealsDealIdPresentationsOptions(id, dealId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} dealId
     * @param {string} presentationId
     * @param {CompanyIdDealsDealIdPresentationsPresentationIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdDealsDealIdPresentationsPresentationIdActionGet(id: string, dealId: string, presentationId: string, action: CompanyIdDealsDealIdPresentationsPresentationIdActionGetActionEnum, options?: any): AxiosPromise<PresentationDetail> {
      return localVarFp.companyIdDealsDealIdPresentationsPresentationIdActionGet(id, dealId, presentationId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} dealId
     * @param {string} presentationId
     * @param {CompanyIdDealsDealIdPresentationsPresentationIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdDealsDealIdPresentationsPresentationIdActionOptions(id: string, dealId: string, presentationId: string, action: CompanyIdDealsDealIdPresentationsPresentationIdActionOptionsActionEnum, options?: any): AxiosPromise<PresentationDetail> {
      return localVarFp.companyIdDealsDealIdPresentationsPresentationIdActionOptions(id, dealId, presentationId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} dealId
     * @param {string} presentationId
     * @param {CompanyIdDealsDealIdPresentationsPresentationIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdDealsDealIdPresentationsPresentationIdActionPut(id: string, dealId: string, presentationId: string, action: CompanyIdDealsDealIdPresentationsPresentationIdActionPutActionEnum, options?: any): AxiosPromise<PresentationDetail> {
      return localVarFp.companyIdDealsDealIdPresentationsPresentationIdActionPut(id, dealId, presentationId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} dealId
     * @param {string} presentationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdDealsDealIdPresentationsPresentationIdGet(id: string, dealId: string, presentationId: string, options?: any): AxiosPromise<PresentationDetail> {
      return localVarFp.companyIdDealsDealIdPresentationsPresentationIdGet(id, dealId, presentationId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} dealId
     * @param {string} presentationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdDealsDealIdPresentationsPresentationIdOptions(id: string, dealId: string, presentationId: string, options?: any): AxiosPromise<PresentationDetail> {
      return localVarFp.companyIdDealsDealIdPresentationsPresentationIdOptions(id, dealId, presentationId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} dealId
     * @param {string} presentationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdDealsDealIdPresentationsPresentationIdPut(id: string, dealId: string, presentationId: string, options?: any): AxiosPromise<PresentationDetail> {
      return localVarFp.companyIdDealsDealIdPresentationsPresentationIdPut(id, dealId, presentationId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdDealsGet(id: string, options?: any): AxiosPromise<CompanyIdDealsDealListDealSerializer> {
      return localVarFp.companyIdDealsGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdDealsOptions(id: string, options?: any): AxiosPromise<CompanyIdDealsDealListDealSerializer> {
      return localVarFp.companyIdDealsOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} freelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdFreelancersFreelancerIdGet(id: string, freelancerId: string, options?: any): AxiosPromise<CompanyFreelancerDetail> {
      return localVarFp.companyIdFreelancersFreelancerIdGet(id, freelancerId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} freelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdFreelancersFreelancerIdOptions(id: string, freelancerId: string, options?: any): AxiosPromise<CompanyFreelancerDetail> {
      return localVarFp.companyIdFreelancersFreelancerIdOptions(id, freelancerId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdGet(id: string, options?: any): AxiosPromise<CompanyIdCompanyDetailCompanySerializer> {
      return localVarFp.companyIdGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdHiresGet(id: string, options?: any): AxiosPromise<CompanyIdHiresHireListHireSerializer> {
      return localVarFp.companyIdHiresGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} hireId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdHiresHireIdGet(id: string, hireId: number, options?: any): AxiosPromise<CompanyIdHiresHireIdHireDetailHireSerializer> {
      return localVarFp.companyIdHiresHireIdGet(id, hireId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} hireId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdHiresHireIdOptions(id: string, hireId: number, options?: any): AxiosPromise<CompanyIdHiresHireIdHireDetailHireSerializer> {
      return localVarFp.companyIdHiresHireIdOptions(id, hireId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} hireId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdHiresHireIdPatch(id: string, hireId: number, options?: any): AxiosPromise<CompanyIdHiresHireIdHireDetailHireSerializer> {
      return localVarFp.companyIdHiresHireIdPatch(id, hireId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} hireId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdHiresHireIdPut(id: string, hireId: number, options?: any): AxiosPromise<CompanyIdHiresHireIdHireDetailHireSerializer> {
      return localVarFp.companyIdHiresHireIdPut(id, hireId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} hireId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdHiresHireIdScopeOfWorkGet(id: string, hireId: number, options?: any): AxiosPromise<CompanyIdHiresHireIdScopeOfWorkScopeOfWorkDetailScopeOfWorkSerializer> {
      return localVarFp.companyIdHiresHireIdScopeOfWorkGet(id, hireId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} hireId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdHiresHireIdScopeOfWorkOptions(id: string, hireId: number, options?: any): AxiosPromise<CompanyIdHiresHireIdScopeOfWorkScopeOfWorkDetailScopeOfWorkSerializer> {
      return localVarFp.companyIdHiresHireIdScopeOfWorkOptions(id, hireId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdHiresOptions(id: string, options?: any): AxiosPromise<CompanyIdHiresHireListHireSerializer> {
      return localVarFp.companyIdHiresOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewSettingsGet(id: string, options?: any): AxiosPromise<CompanyInterviewSettings> {
      return localVarFp.companyIdInterviewSettingsGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewSettingsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewSettingsInterviewSettingsIdDelete(id: string, interviewSettingsId: string, options?: any): AxiosPromise<CompanyInterviewSettings> {
      return localVarFp.companyIdInterviewSettingsInterviewSettingsIdDelete(id, interviewSettingsId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewSettingsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewSettingsInterviewSettingsIdGet(id: string, interviewSettingsId: string, options?: any): AxiosPromise<CompanyInterviewSettings> {
      return localVarFp.companyIdInterviewSettingsInterviewSettingsIdGet(id, interviewSettingsId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewSettingsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewSettingsInterviewSettingsIdOptions(id: string, interviewSettingsId: string, options?: any): AxiosPromise<CompanyInterviewSettings> {
      return localVarFp.companyIdInterviewSettingsInterviewSettingsIdOptions(id, interviewSettingsId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewSettingsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewSettingsInterviewSettingsIdPatch(id: string, interviewSettingsId: string, options?: any): AxiosPromise<CompanyInterviewSettings> {
      return localVarFp.companyIdInterviewSettingsInterviewSettingsIdPatch(id, interviewSettingsId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewSettingsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewSettingsInterviewSettingsIdPut(id: string, interviewSettingsId: string, options?: any): AxiosPromise<CompanyInterviewSettings> {
      return localVarFp.companyIdInterviewSettingsInterviewSettingsIdPut(id, interviewSettingsId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewSettingsOptions(id: string, options?: any): AxiosPromise<CompanyInterviewSettings> {
      return localVarFp.companyIdInterviewSettingsOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewSettingsPost(id: string, options?: any): AxiosPromise<CompanyInterviewSettings> {
      return localVarFp.companyIdInterviewSettingsPost(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewsGet(id: string, options?: any): AxiosPromise<CompanyIdInterviewsInterviewListInterviewSerializer> {
      return localVarFp.companyIdInterviewsGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {CompanyIdInterviewsInterviewIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewsInterviewIdActionGet(id: string, interviewId: string, action: CompanyIdInterviewsInterviewIdActionGetActionEnum, options?: any): AxiosPromise<CompanyIdInterviewsInterviewIdActionInterviewDetailInterviewSerializer> {
      return localVarFp.companyIdInterviewsInterviewIdActionGet(id, interviewId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {CompanyIdInterviewsInterviewIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewsInterviewIdActionOptions(id: string, interviewId: string, action: CompanyIdInterviewsInterviewIdActionOptionsActionEnum, options?: any): AxiosPromise<CompanyIdInterviewsInterviewIdActionInterviewDetailInterviewSerializer> {
      return localVarFp.companyIdInterviewsInterviewIdActionOptions(id, interviewId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {CompanyIdInterviewsInterviewIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewsInterviewIdActionPut(id: string, interviewId: string, action: CompanyIdInterviewsInterviewIdActionPutActionEnum, options?: any): AxiosPromise<CompanyIdInterviewsInterviewIdActionInterviewDetailInterviewSerializer> {
      return localVarFp.companyIdInterviewsInterviewIdActionPut(id, interviewId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewsInterviewIdGet(id: string, interviewId: string, options?: any): AxiosPromise<CompanyIdInterviewsInterviewIdInterviewDetailInterviewSerializer> {
      return localVarFp.companyIdInterviewsInterviewIdGet(id, interviewId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewsInterviewIdOptions(id: string, interviewId: string, options?: any): AxiosPromise<CompanyIdInterviewsInterviewIdInterviewDetailInterviewSerializer> {
      return localVarFp.companyIdInterviewsInterviewIdOptions(id, interviewId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewsInterviewIdPut(id: string, interviewId: string, options?: any): AxiosPromise<CompanyIdInterviewsInterviewIdInterviewDetailInterviewSerializer> {
      return localVarFp.companyIdInterviewsInterviewIdPut(id, interviewId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewsOptions(id: string, options?: any): AxiosPromise<CompanyIdInterviewsInterviewListInterviewSerializer> {
      return localVarFp.companyIdInterviewsOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInterviewsPost(id: string, options?: any): AxiosPromise<CompanyIdInterviewsInterviewListInterviewSerializer> {
      return localVarFp.companyIdInterviewsPost(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInvitesGet(id: string, options?: any): AxiosPromise<CompanyInvite> {
      return localVarFp.companyIdInvitesGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} inviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInvitesInviteIdDelete(id: string, inviteId: string, options?: any): AxiosPromise<CompanyInvite> {
      return localVarFp.companyIdInvitesInviteIdDelete(id, inviteId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} inviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInvitesInviteIdGet(id: string, inviteId: string, options?: any): AxiosPromise<CompanyInvite> {
      return localVarFp.companyIdInvitesInviteIdGet(id, inviteId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} inviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInvitesInviteIdOptions(id: string, inviteId: string, options?: any): AxiosPromise<CompanyInvite> {
      return localVarFp.companyIdInvitesInviteIdOptions(id, inviteId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} inviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInvitesInviteIdPatch(id: string, inviteId: string, options?: any): AxiosPromise<CompanyInvite> {
      return localVarFp.companyIdInvitesInviteIdPatch(id, inviteId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} inviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInvitesInviteIdPut(id: string, inviteId: string, options?: any): AxiosPromise<CompanyInvite> {
      return localVarFp.companyIdInvitesInviteIdPut(id, inviteId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInvitesOptions(id: string, options?: any): AxiosPromise<CompanyInvite> {
      return localVarFp.companyIdInvitesOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdInvitesPost(id: string, options?: any): AxiosPromise<CompanyInvite> {
      return localVarFp.companyIdInvitesPost(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdJobsFromMissionOptions(id: string, options?: any): AxiosPromise<CompanyIdJobsFromMissionJobCreateFromMissionCompanyJobSerializer> {
      return localVarFp.companyIdJobsFromMissionOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdJobsFromMissionPost(id: string, options?: any): AxiosPromise<CompanyIdJobsFromMissionJobCreateFromMissionCompanyJobSerializer> {
      return localVarFp.companyIdJobsFromMissionPost(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdJobsGet(id: string, options?: any): AxiosPromise<CompanyIdJobsJobListCompanyJobSerializer> {
      return localVarFp.companyIdJobsGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdJobsJobIdArchiveGet(id: string, jobId: string, options?: any): AxiosPromise<CompanyIdJobsJobIdArchiveJobArchiveCompanyJobSerializer> {
      return localVarFp.companyIdJobsJobIdArchiveGet(id, jobId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdJobsJobIdArchiveOptions(id: string, jobId: string, options?: any): AxiosPromise<CompanyIdJobsJobIdArchiveJobArchiveCompanyJobSerializer> {
      return localVarFp.companyIdJobsJobIdArchiveOptions(id, jobId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdJobsJobIdArchivePost(id: string, jobId: string, options?: any): AxiosPromise<CompanyIdJobsJobIdArchiveJobArchiveCompanyJobSerializer> {
      return localVarFp.companyIdJobsJobIdArchivePost(id, jobId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdJobsJobIdDelete(id: string, jobId: string, options?: any): AxiosPromise<CompanyIdJobsJobIdJobDetailCompanyJobSerializer> {
      return localVarFp.companyIdJobsJobIdDelete(id, jobId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdJobsJobIdGet(id: string, jobId: string, options?: any): AxiosPromise<CompanyIdJobsJobIdJobDetailCompanyJobSerializer> {
      return localVarFp.companyIdJobsJobIdGet(id, jobId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdJobsJobIdOptions(id: string, jobId: string, options?: any): AxiosPromise<CompanyIdJobsJobIdJobDetailCompanyJobSerializer> {
      return localVarFp.companyIdJobsJobIdOptions(id, jobId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdJobsJobIdPatch(id: string, jobId: string, options?: any): AxiosPromise<CompanyIdJobsJobIdJobDetailCompanyJobSerializer> {
      return localVarFp.companyIdJobsJobIdPatch(id, jobId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdJobsJobIdPut(id: string, jobId: string, options?: any): AxiosPromise<CompanyIdJobsJobIdJobDetailCompanyJobSerializer> {
      return localVarFp.companyIdJobsJobIdPut(id, jobId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdJobsJobIdReviewGet(id: string, jobId: string, options?: any): AxiosPromise<CompanyIdJobsJobIdReviewJobReadyForReviewCompanyJobSerializer> {
      return localVarFp.companyIdJobsJobIdReviewGet(id, jobId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdJobsJobIdReviewOptions(id: string, jobId: string, options?: any): AxiosPromise<CompanyIdJobsJobIdReviewJobReadyForReviewCompanyJobSerializer> {
      return localVarFp.companyIdJobsJobIdReviewOptions(id, jobId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdJobsJobIdReviewPost(id: string, jobId: string, options?: any): AxiosPromise<CompanyIdJobsJobIdReviewJobReadyForReviewCompanyJobSerializer> {
      return localVarFp.companyIdJobsJobIdReviewPost(id, jobId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdJobsOptions(id: string, options?: any): AxiosPromise<CompanyIdJobsJobListCompanyJobSerializer> {
      return localVarFp.companyIdJobsOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdJobsPost(id: string, options?: any): AxiosPromise<CompanyIdJobsJobListCompanyJobSerializer> {
      return localVarFp.companyIdJobsPost(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMembersGet(id: string, options?: any): AxiosPromise<CompanyIdMembersMemberListCompanyMemberSerializer> {
      return localVarFp.companyIdMembersGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMembersOptions(id: string, options?: any): AxiosPromise<CompanyIdMembersMemberListCompanyMemberSerializer> {
      return localVarFp.companyIdMembersOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMembersPost(id: string, options?: any): AxiosPromise<CompanyIdMembersMemberListCompanyMemberSerializer> {
      return localVarFp.companyIdMembersPost(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionInterviewsGet(id: string, options?: any): AxiosPromise<CompanyMissionInterview> {
      return localVarFp.companyIdMissionInterviewsGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionInterviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionInterviewsMissionInterviewIdDelete(id: string, missionInterviewId: string, options?: any): AxiosPromise<CompanyMissionInterview> {
      return localVarFp.companyIdMissionInterviewsMissionInterviewIdDelete(id, missionInterviewId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionInterviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionInterviewsMissionInterviewIdGet(id: string, missionInterviewId: string, options?: any): AxiosPromise<CompanyMissionInterview> {
      return localVarFp.companyIdMissionInterviewsMissionInterviewIdGet(id, missionInterviewId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionInterviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionInterviewsMissionInterviewIdOptions(id: string, missionInterviewId: string, options?: any): AxiosPromise<CompanyMissionInterview> {
      return localVarFp.companyIdMissionInterviewsMissionInterviewIdOptions(id, missionInterviewId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionInterviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionInterviewsMissionInterviewIdPatch(id: string, missionInterviewId: string, options?: any): AxiosPromise<CompanyMissionInterview> {
      return localVarFp.companyIdMissionInterviewsMissionInterviewIdPatch(id, missionInterviewId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionInterviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionInterviewsMissionInterviewIdPut(id: string, missionInterviewId: string, options?: any): AxiosPromise<CompanyMissionInterview> {
      return localVarFp.companyIdMissionInterviewsMissionInterviewIdPut(id, missionInterviewId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionInterviewsOptions(id: string, options?: any): AxiosPromise<CompanyMissionInterview> {
      return localVarFp.companyIdMissionInterviewsOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionInterviewsPost(id: string, options?: any): AxiosPromise<CompanyMissionInterview> {
      return localVarFp.companyIdMissionInterviewsPost(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsGet(id: string, options?: any): AxiosPromise<CompanyMissionList> {
      return localVarFp.companyIdMissionsGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionBuilderOptions(id: string, options?: any): AxiosPromise<CompanyMission> {
      return localVarFp.companyIdMissionsMissionBuilderOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionBuilderPost(id: string, options?: any): AxiosPromise<CompanyMission> {
      return localVarFp.companyIdMissionsMissionBuilderPost(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdDelete(id: string, missionId: string, options?: any): AxiosPromise<CompanyMission> {
      return localVarFp.companyIdMissionsMissionIdDelete(id, missionId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdGet(id: string, missionId: string, options?: any): AxiosPromise<CompanyMission> {
      return localVarFp.companyIdMissionsMissionIdGet(id, missionId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityGet(id: string, missionId: string, options?: any): AxiosPromise<CompanyMissionInterviewDefaultAvailability> {
      return localVarFp.companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityGet(id, missionId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} missionInterviewDefaultAvailabilityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdGet(id: string, missionId: string, missionInterviewDefaultAvailabilityId: string, options?: any): AxiosPromise<CompanyMissionInterviewDefaultAvailability> {
      return localVarFp.companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdGet(id, missionId, missionInterviewDefaultAvailabilityId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} missionInterviewDefaultAvailabilityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdOptions(id: string, missionId: string, missionInterviewDefaultAvailabilityId: string, options?: any): AxiosPromise<CompanyMissionInterviewDefaultAvailability> {
      return localVarFp.companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdOptions(id, missionId, missionInterviewDefaultAvailabilityId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} missionInterviewDefaultAvailabilityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdPatch(id: string, missionId: string, missionInterviewDefaultAvailabilityId: string, options?: any): AxiosPromise<CompanyMissionInterviewDefaultAvailability> {
      return localVarFp.companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdPatch(id, missionId, missionInterviewDefaultAvailabilityId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} missionInterviewDefaultAvailabilityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdPut(id: string, missionId: string, missionInterviewDefaultAvailabilityId: string, options?: any): AxiosPromise<CompanyMissionInterviewDefaultAvailability> {
      return localVarFp.companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdPut(id, missionId, missionInterviewDefaultAvailabilityId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityOptions(id: string, missionId: string, options?: any): AxiosPromise<CompanyMissionInterviewDefaultAvailability> {
      return localVarFp.companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityOptions(id, missionId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityPost(id: string, missionId: string, options?: any): AxiosPromise<CompanyMissionInterviewDefaultAvailability> {
      return localVarFp.companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityPost(id, missionId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdOptions(id: string, missionId: string, options?: any): AxiosPromise<CompanyMission> {
      return localVarFp.companyIdMissionsMissionIdOptions(id, missionId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdPatch(id: string, missionId: string, options?: any): AxiosPromise<CompanyMission> {
      return localVarFp.companyIdMissionsMissionIdPatch(id, missionId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdPut(id: string, missionId: string, options?: any): AxiosPromise<CompanyMission> {
      return localVarFp.companyIdMissionsMissionIdPut(id, missionId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistFreelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdDelete(id: string, missionId: string, shortlistFreelancerId: string, options?: any): AxiosPromise<ShortlistFreelancer> {
      return localVarFp.companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdDelete(id, missionId, shortlistFreelancerId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistFreelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdGet(id: string, missionId: string, shortlistFreelancerId: string, options?: any): AxiosPromise<ShortlistFreelancer> {
      return localVarFp.companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdGet(id, missionId, shortlistFreelancerId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistFreelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdOptions(id: string, missionId: string, shortlistFreelancerId: string, options?: any): AxiosPromise<ShortlistFreelancer> {
      return localVarFp.companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdOptions(id, missionId, shortlistFreelancerId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistFreelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdPatch(id: string, missionId: string, shortlistFreelancerId: string, options?: any): AxiosPromise<ShortlistFreelancer> {
      return localVarFp.companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdPatch(id, missionId, shortlistFreelancerId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistFreelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdPut(id: string, missionId: string, shortlistFreelancerId: string, options?: any): AxiosPromise<ShortlistFreelancer> {
      return localVarFp.companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdPut(id, missionId, shortlistFreelancerId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistsGet(id: string, missionId: string, options?: any): AxiosPromise<MissionShortlist> {
      return localVarFp.companyIdMissionsMissionIdShortlistsGet(id, missionId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistsOptions(id: string, missionId: string, options?: any): AxiosPromise<MissionShortlist> {
      return localVarFp.companyIdMissionsMissionIdShortlistsOptions(id, missionId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistsPost(id: string, missionId: string, options?: any): AxiosPromise<MissionShortlist> {
      return localVarFp.companyIdMissionsMissionIdShortlistsPost(id, missionId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistId
     * @param {string} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistsShortlistIdActionDelete(id: string, missionId: string, shortlistId: string, action: string, options?: any): AxiosPromise<MissionShortlist> {
      return localVarFp.companyIdMissionsMissionIdShortlistsShortlistIdActionDelete(id, missionId, shortlistId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistId
     * @param {string} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistsShortlistIdActionGet(id: string, missionId: string, shortlistId: string, action: string, options?: any): AxiosPromise<MissionShortlist> {
      return localVarFp.companyIdMissionsMissionIdShortlistsShortlistIdActionGet(id, missionId, shortlistId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistId
     * @param {string} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistsShortlistIdActionOptions(id: string, missionId: string, shortlistId: string, action: string, options?: any): AxiosPromise<MissionShortlist> {
      return localVarFp.companyIdMissionsMissionIdShortlistsShortlistIdActionOptions(id, missionId, shortlistId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistId
     * @param {string} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistsShortlistIdActionPatch(id: string, missionId: string, shortlistId: string, action: string, options?: any): AxiosPromise<MissionShortlist> {
      return localVarFp.companyIdMissionsMissionIdShortlistsShortlistIdActionPatch(id, missionId, shortlistId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistId
     * @param {string} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistsShortlistIdActionPut(id: string, missionId: string, shortlistId: string, action: string, options?: any): AxiosPromise<MissionShortlist> {
      return localVarFp.companyIdMissionsMissionIdShortlistsShortlistIdActionPut(id, missionId, shortlistId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistsShortlistIdDelete(id: string, missionId: string, shortlistId: string, options?: any): AxiosPromise<MissionShortlist> {
      return localVarFp.companyIdMissionsMissionIdShortlistsShortlistIdDelete(id, missionId, shortlistId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistsShortlistIdGet(id: string, missionId: string, shortlistId: string, options?: any): AxiosPromise<MissionShortlist> {
      return localVarFp.companyIdMissionsMissionIdShortlistsShortlistIdGet(id, missionId, shortlistId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistsShortlistIdOptions(id: string, missionId: string, shortlistId: string, options?: any): AxiosPromise<MissionShortlist> {
      return localVarFp.companyIdMissionsMissionIdShortlistsShortlistIdOptions(id, missionId, shortlistId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistsShortlistIdPatch(id: string, missionId: string, shortlistId: string, options?: any): AxiosPromise<MissionShortlist> {
      return localVarFp.companyIdMissionsMissionIdShortlistsShortlistIdPatch(id, missionId, shortlistId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} shortlistId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShortlistsShortlistIdPut(id: string, missionId: string, shortlistId: string, options?: any): AxiosPromise<MissionShortlist> {
      return localVarFp.companyIdMissionsMissionIdShortlistsShortlistIdPut(id, missionId, shortlistId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShowdownSlotsGet(id: string, missionId: string, options?: any): AxiosPromise<CompanyShowdownSlots> {
      return localVarFp.companyIdMissionsMissionIdShowdownSlotsGet(id, missionId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShowdownSlotsOptions(id: string, missionId: string, options?: any): AxiosPromise<CompanyShowdownSlots> {
      return localVarFp.companyIdMissionsMissionIdShowdownSlotsOptions(id, missionId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShowdownSlotsPost(id: string, missionId: string, options?: any): AxiosPromise<CompanyShowdownSlots> {
      return localVarFp.companyIdMissionsMissionIdShowdownSlotsPost(id, missionId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} showdownSlotsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShowdownSlotsShowdownSlotsIdGet(id: string, missionId: string, showdownSlotsId: string, options?: any): AxiosPromise<CompanyShowdownSlots> {
      return localVarFp.companyIdMissionsMissionIdShowdownSlotsShowdownSlotsIdGet(id, missionId, showdownSlotsId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} showdownSlotsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsMissionIdShowdownSlotsShowdownSlotsIdOptions(id: string, missionId: string, showdownSlotsId: string, options?: any): AxiosPromise<CompanyShowdownSlots> {
      return localVarFp.companyIdMissionsMissionIdShowdownSlotsShowdownSlotsIdOptions(id, missionId, showdownSlotsId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsOptions(id: string, options?: any): AxiosPromise<CompanyMissionList> {
      return localVarFp.companyIdMissionsOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdMissionsPost(id: string, options?: any): AxiosPromise<CompanyMissionList> {
      return localVarFp.companyIdMissionsPost(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdOptions(id: string, options?: any): AxiosPromise<CompanyIdCompanyDetailCompanySerializer> {
      return localVarFp.companyIdOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdPatch(id: string, options?: any): AxiosPromise<CompanyIdCompanyDetailCompanySerializer> {
      return localVarFp.companyIdPatch(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdPublicGet(id: string, options?: any): AxiosPromise<CompanyIdPublicCompanyDetailCompanySerializer> {
      return localVarFp.companyIdPublicGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdPublicOptions(id: string, options?: any): AxiosPromise<CompanyIdPublicCompanyDetailCompanySerializer> {
      return localVarFp.companyIdPublicOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdPut(id: string, options?: any): AxiosPromise<CompanyIdCompanyDetailCompanySerializer> {
      return localVarFp.companyIdPut(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdReviewsGet(id: string, options?: any): AxiosPromise<CompanyIdReviewsReviewListCompanyReviewSerializer> {
      return localVarFp.companyIdReviewsGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdReviewsOptions(id: string, options?: any): AxiosPromise<CompanyIdReviewsReviewListCompanyReviewSerializer> {
      return localVarFp.companyIdReviewsOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdReviewsPost(id: string, options?: any): AxiosPromise<CompanyIdReviewsReviewListCompanyReviewSerializer> {
      return localVarFp.companyIdReviewsPost(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} reviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdReviewsReviewIdGet(id: string, reviewId: number, options?: any): AxiosPromise<CompanyIdReviewsReviewIdReviewDetailCompanyReviewSerializer> {
      return localVarFp.companyIdReviewsReviewIdGet(id, reviewId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} reviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdReviewsReviewIdOptions(id: string, reviewId: number, options?: any): AxiosPromise<CompanyIdReviewsReviewIdReviewDetailCompanyReviewSerializer> {
      return localVarFp.companyIdReviewsReviewIdOptions(id, reviewId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} reviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdReviewsReviewIdPatch(id: string, reviewId: number, options?: any): AxiosPromise<CompanyIdReviewsReviewIdReviewDetailCompanyReviewSerializer> {
      return localVarFp.companyIdReviewsReviewIdPatch(id, reviewId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} reviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyIdReviewsReviewIdPut(id: string, reviewId: number, options?: any): AxiosPromise<CompanyIdReviewsReviewIdReviewDetailCompanyReviewSerializer> {
      return localVarFp.companyIdReviewsReviewIdPut(id, reviewId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} experienceLevel The experience level PK being considered
     * @param {CompanyMissionBudgetRecommendationsGetCompensationPeriodEnum} compensationPeriod The compensation period being considered
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyMissionBudgetRecommendationsGet(id: string, experienceLevel: number, compensationPeriod: CompanyMissionBudgetRecommendationsGetCompensationPeriodEnum, options?: any): AxiosPromise<CompanyMissionBudgetRecommendations> {
      return localVarFp.companyMissionBudgetRecommendationsGet(id, experienceLevel, compensationPeriod, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyMissionBudgetRecommendationsOptions(id: string, options?: any): AxiosPromise<CompanyMissionBudgetRecommendations> {
      return localVarFp.companyMissionBudgetRecommendationsOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} applicationId
     * @param {CompanyMissionJobApplicationActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyMissionJobApplicationActionOptions(id: string, missionId: string, applicationId: string, action: CompanyMissionJobApplicationActionOptionsActionEnum, options?: any): AxiosPromise<CompanyMissionJobApplicationAction> {
      return localVarFp.companyMissionJobApplicationActionOptions(id, missionId, applicationId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} applicationId
     * @param {CompanyMissionJobApplicationActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyMissionJobApplicationActionPut(id: string, missionId: string, applicationId: string, action: CompanyMissionJobApplicationActionPutActionEnum, options?: any): AxiosPromise<CompanyMissionJobApplicationAction> {
      return localVarFp.companyMissionJobApplicationActionPut(id, missionId, applicationId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} applicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyMissionJobApplicationDetailGet(id: string, missionId: string, applicationId: string, options?: any): AxiosPromise<CompanyMissionJobApplicationDetail> {
      return localVarFp.companyMissionJobApplicationDetailGet(id, missionId, applicationId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {string} applicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyMissionJobApplicationDetailOptions(id: string, missionId: string, applicationId: string, options?: any): AxiosPromise<CompanyMissionJobApplicationDetail> {
      return localVarFp.companyMissionJobApplicationDetailOptions(id, missionId, applicationId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyMissionJobApplicationListGet(id: string, missionId: string, options?: any): AxiosPromise<CompanyMissionJobApplicationList> {
      return localVarFp.companyMissionJobApplicationListGet(id, missionId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyMissionJobApplicationListOptions(id: string, missionId: string, options?: any): AxiosPromise<CompanyMissionJobApplicationList> {
      return localVarFp.companyMissionJobApplicationListOptions(id, missionId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyShortlistFreelancerMissionDisplayListGet(id: string, missionId: string, options?: any): AxiosPromise<ShortlistFreelancerMissionDisplay> {
      return localVarFp.companyShortlistFreelancerMissionDisplayListGet(id, missionId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyShortlistFreelancerMissionDisplayListOptions(id: string, missionId: string, options?: any): AxiosPromise<ShortlistFreelancerMissionDisplay> {
      return localVarFp.companyShortlistFreelancerMissionDisplayListOptions(id, missionId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyShortlistFreelancerMissionDisplayListPost(id: string, missionId: string, options?: any): AxiosPromise<ShortlistFreelancerMissionDisplay> {
      return localVarFp.companyShortlistFreelancerMissionDisplayListPost(id, missionId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    factoriesApplicationsOptions(options?: any): AxiosPromise<FactoriesApplicationsJobApplicationCreateJobApplicationSerializer> {
      return localVarFp.factoriesApplicationsOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    factoriesApplicationsPost(options?: any): AxiosPromise<FactoriesApplicationsJobApplicationCreateJobApplicationSerializer> {
      return localVarFp.factoriesApplicationsPost(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    factoriesCompaniesOptions(options?: any): AxiosPromise<FactoriesCompaniesCompanyCreateStaffCompanySerializer> {
      return localVarFp.factoriesCompaniesOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    factoriesCompaniesPost(options?: any): AxiosPromise<FactoriesCompaniesCompanyCreateStaffCompanySerializer> {
      return localVarFp.factoriesCompaniesPost(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    factoriesDealsOptions(options?: any): AxiosPromise<FactoriesDealsDealCreateDealSerializer> {
      return localVarFp.factoriesDealsOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    factoriesDealsPost(options?: any): AxiosPromise<FactoriesDealsDealCreateDealSerializer> {
      return localVarFp.factoriesDealsPost(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    factoriesFreelancersOptions(options?: any): AxiosPromise<FactoriesFreelancersFreelancerCreateStaffFreelancerSerializer> {
      return localVarFp.factoriesFreelancersOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    factoriesFreelancersPost(options?: any): AxiosPromise<FactoriesFreelancersFreelancerCreateStaffFreelancerSerializer> {
      return localVarFp.factoriesFreelancersPost(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    factoriesJobsOptions(options?: any): AxiosPromise<FactoriesJobsJobCreateStaffJobSerializer> {
      return localVarFp.factoriesJobsOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    factoriesJobsPost(options?: any): AxiosPromise<FactoriesJobsJobCreateStaffJobSerializer> {
      return localVarFp.factoriesJobsPost(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    factoriesMembersOptions(options?: any): AxiosPromise<FactoriesMembersMemberCreateCompanyMemberSerializer> {
      return localVarFp.factoriesMembersOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    factoriesMembersPost(options?: any): AxiosPromise<FactoriesMembersMemberCreateCompanyMemberSerializer> {
      return localVarFp.factoriesMembersPost(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    factoriesPresentationsOptions(options?: any): AxiosPromise<FactoriesPresentationsPresentationCreatePresentationSerializer> {
      return localVarFp.factoriesPresentationsOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    factoriesPresentationsPost(options?: any): AxiosPromise<FactoriesPresentationsPresentationCreatePresentationSerializer> {
      return localVarFp.factoriesPresentationsPost(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    factoriesUsersOptions(options?: any): AxiosPromise<FactoriesUsersUserCreateUserSerializer> {
      return localVarFp.factoriesUsersOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    factoriesUsersPost(options?: any): AxiosPromise<FactoriesUsersUserCreateUserSerializer> {
      return localVarFp.factoriesUsersPost(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerCompleteScreeningGet(options?: any): AxiosPromise<FreelancerCompleteScreeningCompleteScreeningFreelancerSerializer> {
      return localVarFp.freelancerCompleteScreeningGet(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerCompleteScreeningOptions(options?: any): AxiosPromise<FreelancerCompleteScreeningCompleteScreeningFreelancerSerializer> {
      return localVarFp.freelancerCompleteScreeningOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerCompleteScreeningPost(options?: any): AxiosPromise<FreelancerCompleteScreeningCompleteScreeningFreelancerSerializer> {
      return localVarFp.freelancerCompleteScreeningPost(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerGet(options?: any): AxiosPromise<Freelancer> {
      return localVarFp.freelancerGet(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {FreelancerIdApplicationsApplicationIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdApplicationsApplicationIdActionGet(id: string, applicationId: string, action: FreelancerIdApplicationsApplicationIdActionGetActionEnum, options?: any): AxiosPromise<FreelancerIdApplicationsApplicationIdActionApplicationDetailJobApplicationSerializer> {
      return localVarFp.freelancerIdApplicationsApplicationIdActionGet(id, applicationId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {FreelancerIdApplicationsApplicationIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdApplicationsApplicationIdActionOptions(id: string, applicationId: string, action: FreelancerIdApplicationsApplicationIdActionOptionsActionEnum, options?: any): AxiosPromise<FreelancerIdApplicationsApplicationIdActionApplicationDetailJobApplicationSerializer> {
      return localVarFp.freelancerIdApplicationsApplicationIdActionOptions(id, applicationId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {FreelancerIdApplicationsApplicationIdActionPatchActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdApplicationsApplicationIdActionPatch(id: string, applicationId: string, action: FreelancerIdApplicationsApplicationIdActionPatchActionEnum, options?: any): AxiosPromise<FreelancerIdApplicationsApplicationIdActionApplicationDetailJobApplicationSerializer> {
      return localVarFp.freelancerIdApplicationsApplicationIdActionPatch(id, applicationId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {FreelancerIdApplicationsApplicationIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdApplicationsApplicationIdActionPut(id: string, applicationId: string, action: FreelancerIdApplicationsApplicationIdActionPutActionEnum, options?: any): AxiosPromise<FreelancerIdApplicationsApplicationIdActionApplicationDetailJobApplicationSerializer> {
      return localVarFp.freelancerIdApplicationsApplicationIdActionPut(id, applicationId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdApplicationsApplicationIdGet(id: string, applicationId: string, options?: any): AxiosPromise<FreelancerIdApplicationsApplicationIdApplicationDetailJobApplicationSerializer> {
      return localVarFp.freelancerIdApplicationsApplicationIdGet(id, applicationId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdApplicationsApplicationIdOptions(id: string, applicationId: string, options?: any): AxiosPromise<FreelancerIdApplicationsApplicationIdApplicationDetailJobApplicationSerializer> {
      return localVarFp.freelancerIdApplicationsApplicationIdOptions(id, applicationId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdApplicationsApplicationIdPatch(id: string, applicationId: string, options?: any): AxiosPromise<FreelancerIdApplicationsApplicationIdApplicationDetailJobApplicationSerializer> {
      return localVarFp.freelancerIdApplicationsApplicationIdPatch(id, applicationId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdApplicationsApplicationIdPut(id: string, applicationId: string, options?: any): AxiosPromise<FreelancerIdApplicationsApplicationIdApplicationDetailJobApplicationSerializer> {
      return localVarFp.freelancerIdApplicationsApplicationIdPut(id, applicationId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdApplicationsGet(id: string, options?: any): AxiosPromise<FreelancerIdApplicationsApplicationListJobApplicationSerializer> {
      return localVarFp.freelancerIdApplicationsGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdApplicationsOptions(id: string, options?: any): AxiosPromise<FreelancerIdApplicationsApplicationListJobApplicationSerializer> {
      return localVarFp.freelancerIdApplicationsOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdApplicationsPost(id: string, options?: any): AxiosPromise<FreelancerIdApplicationsApplicationListJobApplicationSerializer> {
      return localVarFp.freelancerIdApplicationsPost(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdDelete(id: string, options?: any): AxiosPromise<FreelancerDetail> {
      return localVarFp.freelancerIdDelete(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} experienceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdExperiencesExperienceIdDelete(id: string, experienceId: number, options?: any): AxiosPromise<FreelancerIdExperiencesExperienceIdExperienceDetailExperienceSerializer> {
      return localVarFp.freelancerIdExperiencesExperienceIdDelete(id, experienceId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} experienceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdExperiencesExperienceIdGet(id: string, experienceId: number, options?: any): AxiosPromise<FreelancerIdExperiencesExperienceIdExperienceDetailExperienceSerializer> {
      return localVarFp.freelancerIdExperiencesExperienceIdGet(id, experienceId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} experienceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdExperiencesExperienceIdOptions(id: string, experienceId: number, options?: any): AxiosPromise<FreelancerIdExperiencesExperienceIdExperienceDetailExperienceSerializer> {
      return localVarFp.freelancerIdExperiencesExperienceIdOptions(id, experienceId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} experienceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdExperiencesExperienceIdPatch(id: string, experienceId: number, options?: any): AxiosPromise<FreelancerIdExperiencesExperienceIdExperienceDetailExperienceSerializer> {
      return localVarFp.freelancerIdExperiencesExperienceIdPatch(id, experienceId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} experienceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdExperiencesExperienceIdPut(id: string, experienceId: number, options?: any): AxiosPromise<FreelancerIdExperiencesExperienceIdExperienceDetailExperienceSerializer> {
      return localVarFp.freelancerIdExperiencesExperienceIdPut(id, experienceId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdExperiencesGet(id: string, options?: any): AxiosPromise<FreelancerIdExperiencesExperienceListExperienceSerializer> {
      return localVarFp.freelancerIdExperiencesGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdExperiencesOptions(id: string, options?: any): AxiosPromise<FreelancerIdExperiencesExperienceListExperienceSerializer> {
      return localVarFp.freelancerIdExperiencesOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdExperiencesPost(id: string, options?: any): AxiosPromise<FreelancerIdExperiencesExperienceListExperienceSerializer> {
      return localVarFp.freelancerIdExperiencesPost(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdGet(id: string, options?: any): AxiosPromise<FreelancerDetail> {
      return localVarFp.freelancerIdGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdHiresGet(id: string, options?: any): AxiosPromise<FreelancerIdHiresHireListHireSerializer> {
      return localVarFp.freelancerIdHiresGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} hireId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdHiresHireIdGet(id: string, hireId: number, options?: any): AxiosPromise<FreelancerIdHiresHireIdHireDetailHireSerializer> {
      return localVarFp.freelancerIdHiresHireIdGet(id, hireId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} hireId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdHiresHireIdOptions(id: string, hireId: number, options?: any): AxiosPromise<FreelancerIdHiresHireIdHireDetailHireSerializer> {
      return localVarFp.freelancerIdHiresHireIdOptions(id, hireId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdHiresOptions(id: string, options?: any): AxiosPromise<FreelancerIdHiresHireListHireSerializer> {
      return localVarFp.freelancerIdHiresOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdInterviewsGet(id: string, options?: any): AxiosPromise<FreelancerIdInterviewsInterviewListInterviewSerializer> {
      return localVarFp.freelancerIdInterviewsGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {FreelancerIdInterviewsInterviewIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdInterviewsInterviewIdActionGet(id: string, interviewId: string, action: FreelancerIdInterviewsInterviewIdActionGetActionEnum, options?: any): AxiosPromise<FreelancerIdInterviewsInterviewIdActionInterviewDetailInterviewSerializer> {
      return localVarFp.freelancerIdInterviewsInterviewIdActionGet(id, interviewId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {FreelancerIdInterviewsInterviewIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdInterviewsInterviewIdActionOptions(id: string, interviewId: string, action: FreelancerIdInterviewsInterviewIdActionOptionsActionEnum, options?: any): AxiosPromise<FreelancerIdInterviewsInterviewIdActionInterviewDetailInterviewSerializer> {
      return localVarFp.freelancerIdInterviewsInterviewIdActionOptions(id, interviewId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {FreelancerIdInterviewsInterviewIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdInterviewsInterviewIdActionPut(id: string, interviewId: string, action: FreelancerIdInterviewsInterviewIdActionPutActionEnum, options?: any): AxiosPromise<FreelancerIdInterviewsInterviewIdActionInterviewDetailInterviewSerializer> {
      return localVarFp.freelancerIdInterviewsInterviewIdActionPut(id, interviewId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdInterviewsInterviewIdGet(id: string, interviewId: string, options?: any): AxiosPromise<FreelancerIdInterviewsInterviewIdInterviewDetailInterviewSerializer> {
      return localVarFp.freelancerIdInterviewsInterviewIdGet(id, interviewId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdInterviewsInterviewIdOptions(id: string, interviewId: string, options?: any): AxiosPromise<FreelancerIdInterviewsInterviewIdInterviewDetailInterviewSerializer> {
      return localVarFp.freelancerIdInterviewsInterviewIdOptions(id, interviewId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdInterviewsInterviewIdPut(id: string, interviewId: string, options?: any): AxiosPromise<FreelancerIdInterviewsInterviewIdInterviewDetailInterviewSerializer> {
      return localVarFp.freelancerIdInterviewsInterviewIdPut(id, interviewId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdInterviewsOptions(id: string, options?: any): AxiosPromise<FreelancerIdInterviewsInterviewListInterviewSerializer> {
      return localVarFp.freelancerIdInterviewsOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdInterviewsPost(id: string, options?: any): AxiosPromise<FreelancerIdInterviewsInterviewListInterviewSerializer> {
      return localVarFp.freelancerIdInterviewsPost(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdInvitesGet(id: string, options?: any): AxiosPromise<FreelancerInvite> {
      return localVarFp.freelancerIdInvitesGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} inviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdInvitesInviteIdDelete(id: string, inviteId: string, options?: any): AxiosPromise<FreelancerInvite> {
      return localVarFp.freelancerIdInvitesInviteIdDelete(id, inviteId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} inviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdInvitesInviteIdGet(id: string, inviteId: string, options?: any): AxiosPromise<FreelancerInvite> {
      return localVarFp.freelancerIdInvitesInviteIdGet(id, inviteId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} inviteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdInvitesInviteIdOptions(id: string, inviteId: string, options?: any): AxiosPromise<FreelancerInvite> {
      return localVarFp.freelancerIdInvitesInviteIdOptions(id, inviteId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdInvitesOptions(id: string, options?: any): AxiosPromise<FreelancerInvite> {
      return localVarFp.freelancerIdInvitesOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdMissionInterviewsGet(id: string, options?: any): AxiosPromise<FreelancerMissionInterview> {
      return localVarFp.freelancerIdMissionInterviewsGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionInterviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdMissionInterviewsMissionInterviewIdDelete(id: string, missionInterviewId: string, options?: any): AxiosPromise<FreelancerMissionInterview> {
      return localVarFp.freelancerIdMissionInterviewsMissionInterviewIdDelete(id, missionInterviewId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionInterviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdMissionInterviewsMissionInterviewIdGet(id: string, missionInterviewId: string, options?: any): AxiosPromise<FreelancerMissionInterview> {
      return localVarFp.freelancerIdMissionInterviewsMissionInterviewIdGet(id, missionInterviewId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionInterviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdMissionInterviewsMissionInterviewIdOptions(id: string, missionInterviewId: string, options?: any): AxiosPromise<FreelancerMissionInterview> {
      return localVarFp.freelancerIdMissionInterviewsMissionInterviewIdOptions(id, missionInterviewId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionInterviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdMissionInterviewsMissionInterviewIdPatch(id: string, missionInterviewId: string, options?: any): AxiosPromise<FreelancerMissionInterview> {
      return localVarFp.freelancerIdMissionInterviewsMissionInterviewIdPatch(id, missionInterviewId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} missionInterviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdMissionInterviewsMissionInterviewIdPut(id: string, missionInterviewId: string, options?: any): AxiosPromise<FreelancerMissionInterview> {
      return localVarFp.freelancerIdMissionInterviewsMissionInterviewIdPut(id, missionInterviewId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdMissionInterviewsOptions(id: string, options?: any): AxiosPromise<FreelancerMissionInterview> {
      return localVarFp.freelancerIdMissionInterviewsOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdMissionInterviewsPost(id: string, options?: any): AxiosPromise<FreelancerMissionInterview> {
      return localVarFp.freelancerIdMissionInterviewsPost(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdOptions(id: string, options?: any): AxiosPromise<FreelancerDetail> {
      return localVarFp.freelancerIdOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdPatch(id: string, options?: any): AxiosPromise<FreelancerDetail> {
      return localVarFp.freelancerIdPatch(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdPresentationsGet(id: string, options?: any): AxiosPromise<FreelancerIdPresentationsPresentationListPresentationSerializer> {
      return localVarFp.freelancerIdPresentationsGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdPresentationsOptions(id: string, options?: any): AxiosPromise<FreelancerIdPresentationsPresentationListPresentationSerializer> {
      return localVarFp.freelancerIdPresentationsOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {FreelancerIdPresentationsPresentationIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdPresentationsPresentationIdActionGet(id: string, presentationId: string, action: FreelancerIdPresentationsPresentationIdActionGetActionEnum, options?: any): AxiosPromise<FreelancerIdPresentationsPresentationIdActionPresentationDetailPresentationSerializer> {
      return localVarFp.freelancerIdPresentationsPresentationIdActionGet(id, presentationId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {FreelancerIdPresentationsPresentationIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdPresentationsPresentationIdActionOptions(id: string, presentationId: string, action: FreelancerIdPresentationsPresentationIdActionOptionsActionEnum, options?: any): AxiosPromise<FreelancerIdPresentationsPresentationIdActionPresentationDetailPresentationSerializer> {
      return localVarFp.freelancerIdPresentationsPresentationIdActionOptions(id, presentationId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {FreelancerIdPresentationsPresentationIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdPresentationsPresentationIdActionPut(id: string, presentationId: string, action: FreelancerIdPresentationsPresentationIdActionPutActionEnum, options?: any): AxiosPromise<FreelancerIdPresentationsPresentationIdActionPresentationDetailPresentationSerializer> {
      return localVarFp.freelancerIdPresentationsPresentationIdActionPut(id, presentationId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdPresentationsPresentationIdGet(id: string, presentationId: string, options?: any): AxiosPromise<FreelancerIdPresentationsPresentationIdPresentationDetailPresentationSerializer> {
      return localVarFp.freelancerIdPresentationsPresentationIdGet(id, presentationId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdPresentationsPresentationIdOptions(id: string, presentationId: string, options?: any): AxiosPromise<FreelancerIdPresentationsPresentationIdPresentationDetailPresentationSerializer> {
      return localVarFp.freelancerIdPresentationsPresentationIdOptions(id, presentationId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdPresentationsPresentationIdPut(id: string, presentationId: string, options?: any): AxiosPromise<FreelancerIdPresentationsPresentationIdPresentationDetailPresentationSerializer> {
      return localVarFp.freelancerIdPresentationsPresentationIdPut(id, presentationId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdProjectsGet(id: string, options?: any): AxiosPromise<FreelancerIdProjectsProjectListProjectSerializer> {
      return localVarFp.freelancerIdProjectsGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdProjectsOptions(id: string, options?: any): AxiosPromise<FreelancerIdProjectsProjectListProjectSerializer> {
      return localVarFp.freelancerIdProjectsOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdProjectsPost(id: string, options?: any): AxiosPromise<FreelancerIdProjectsProjectListProjectSerializer> {
      return localVarFp.freelancerIdProjectsPost(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdProjectsProjectIdDelete(id: string, projectId: number, options?: any): AxiosPromise<FreelancerIdProjectsProjectIdProjectDetailProjectSerializer> {
      return localVarFp.freelancerIdProjectsProjectIdDelete(id, projectId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdProjectsProjectIdGet(id: string, projectId: number, options?: any): AxiosPromise<FreelancerIdProjectsProjectIdProjectDetailProjectSerializer> {
      return localVarFp.freelancerIdProjectsProjectIdGet(id, projectId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdProjectsProjectIdOptions(id: string, projectId: number, options?: any): AxiosPromise<FreelancerIdProjectsProjectIdProjectDetailProjectSerializer> {
      return localVarFp.freelancerIdProjectsProjectIdOptions(id, projectId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdProjectsProjectIdPatch(id: string, projectId: number, options?: any): AxiosPromise<FreelancerIdProjectsProjectIdProjectDetailProjectSerializer> {
      return localVarFp.freelancerIdProjectsProjectIdPatch(id, projectId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdProjectsProjectIdPut(id: string, projectId: number, options?: any): AxiosPromise<FreelancerIdProjectsProjectIdProjectDetailProjectSerializer> {
      return localVarFp.freelancerIdProjectsProjectIdPut(id, projectId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdPut(id: string, options?: any): AxiosPromise<FreelancerDetail> {
      return localVarFp.freelancerIdPut(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdReferencesGet(id: string, options?: any): AxiosPromise<FreelancerIdReferencesReferenceListReferenceSerializer> {
      return localVarFp.freelancerIdReferencesGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdReferencesOptions(id: string, options?: any): AxiosPromise<FreelancerIdReferencesReferenceListReferenceSerializer> {
      return localVarFp.freelancerIdReferencesOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdReferencesPost(id: string, options?: any): AxiosPromise<FreelancerIdReferencesReferenceListReferenceSerializer> {
      return localVarFp.freelancerIdReferencesPost(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} referenceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdReferencesReferenceIdDelete(id: string, referenceId: number, options?: any): AxiosPromise<FreelancerIdReferencesReferenceIdReferenceDetailReferenceSerializer> {
      return localVarFp.freelancerIdReferencesReferenceIdDelete(id, referenceId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} referenceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdReferencesReferenceIdGet(id: string, referenceId: number, options?: any): AxiosPromise<FreelancerIdReferencesReferenceIdReferenceDetailReferenceSerializer> {
      return localVarFp.freelancerIdReferencesReferenceIdGet(id, referenceId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} referenceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdReferencesReferenceIdOptions(id: string, referenceId: number, options?: any): AxiosPromise<FreelancerIdReferencesReferenceIdReferenceDetailReferenceSerializer> {
      return localVarFp.freelancerIdReferencesReferenceIdOptions(id, referenceId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} referenceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdReferencesReferenceIdPatch(id: string, referenceId: number, options?: any): AxiosPromise<FreelancerIdReferencesReferenceIdReferenceDetailReferenceSerializer> {
      return localVarFp.freelancerIdReferencesReferenceIdPatch(id, referenceId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} referenceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdReferencesReferenceIdPut(id: string, referenceId: number, options?: any): AxiosPromise<FreelancerIdReferencesReferenceIdReferenceDetailReferenceSerializer> {
      return localVarFp.freelancerIdReferencesReferenceIdPut(id, referenceId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdReviewsGet(id: string, options?: any): AxiosPromise<FreelancerIdReviewsReviewListFreelancerReviewSerializer> {
      return localVarFp.freelancerIdReviewsGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdReviewsOptions(id: string, options?: any): AxiosPromise<FreelancerIdReviewsReviewListFreelancerReviewSerializer> {
      return localVarFp.freelancerIdReviewsOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} reviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdReviewsReviewIdGet(id: string, reviewId: number, options?: any): AxiosPromise<FreelancerIdReviewsReviewIdReviewDetailFreelancerReviewSerializer> {
      return localVarFp.freelancerIdReviewsReviewIdGet(id, reviewId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} reviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdReviewsReviewIdOptions(id: string, reviewId: number, options?: any): AxiosPromise<FreelancerIdReviewsReviewIdReviewDetailFreelancerReviewSerializer> {
      return localVarFp.freelancerIdReviewsReviewIdOptions(id, reviewId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} reviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdReviewsReviewIdPatch(id: string, reviewId: number, options?: any): AxiosPromise<FreelancerIdReviewsReviewIdReviewDetailFreelancerReviewSerializer> {
      return localVarFp.freelancerIdReviewsReviewIdPatch(id, reviewId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} reviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdReviewsReviewIdPut(id: string, reviewId: number, options?: any): AxiosPromise<FreelancerIdReviewsReviewIdReviewDetailFreelancerReviewSerializer> {
      return localVarFp.freelancerIdReviewsReviewIdPut(id, reviewId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} shortlistFreelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdShortlistFreelancersShortlistFreelancerIdGet(id: string, shortlistFreelancerId: string, options?: any): AxiosPromise<ShortlistFreelancer> {
      return localVarFp.freelancerIdShortlistFreelancersShortlistFreelancerIdGet(id, shortlistFreelancerId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} shortlistFreelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdShortlistFreelancersShortlistFreelancerIdOptions(id: string, shortlistFreelancerId: string, options?: any): AxiosPromise<ShortlistFreelancer> {
      return localVarFp.freelancerIdShortlistFreelancersShortlistFreelancerIdOptions(id, shortlistFreelancerId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} shortlistFreelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdShortlistFreelancersShortlistFreelancerIdPatch(id: string, shortlistFreelancerId: string, options?: any): AxiosPromise<ShortlistFreelancer> {
      return localVarFp.freelancerIdShortlistFreelancersShortlistFreelancerIdPatch(id, shortlistFreelancerId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} shortlistFreelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerIdShortlistFreelancersShortlistFreelancerIdPut(id: string, shortlistFreelancerId: string, options?: any): AxiosPromise<ShortlistFreelancer> {
      return localVarFp.freelancerIdShortlistFreelancersShortlistFreelancerIdPut(id, shortlistFreelancerId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerOptions(options?: any): AxiosPromise<Freelancer> {
      return localVarFp.freelancerOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerPatch(options?: any): AxiosPromise<Freelancer> {
      return localVarFp.freelancerPatch(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerPut(options?: any): AxiosPromise<Freelancer> {
      return localVarFp.freelancerPut(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerRateRecommendationsGet(options?: any): AxiosPromise<RateSuggestions> {
      return localVarFp.freelancerRateRecommendationsGet(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerRateRecommendationsOptions(options?: any): AxiosPromise<RateSuggestions> {
      return localVarFp.freelancerRateRecommendationsOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerShareIdGet(id: string, options?: any): AxiosPromise<FreelancerSharingDetail> {
      return localVarFp.freelancerShareIdGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerShareIdOptions(id: string, options?: any): AxiosPromise<FreelancerSharingDetail> {
      return localVarFp.freelancerShareIdOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerShareOptions(options?: any): AxiosPromise<FreelancerSharingCreate> {
      return localVarFp.freelancerShareOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freelancerSharePost(options?: any): AxiosPromise<FreelancerSharingCreate> {
      return localVarFp.freelancerSharePost(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} tier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    hourlyRateTierGet(tier: string, options?: any): AxiosPromise<HourlyRateTierHourlyRateDetailHourlyRateSerializer> {
      return localVarFp.hourlyRateTierGet(tier, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} tier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    hourlyRateTierOptions(tier: string, options?: any): AxiosPromise<HourlyRateTierHourlyRateDetailHourlyRateSerializer> {
      return localVarFp.hourlyRateTierOptions(tier, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobsGet(options?: any): AxiosPromise<JobsJobListJobSerializer> {
      return localVarFp.jobsGet(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobsIdGet(id: string, options?: any): AxiosPromise<JobsIdJobDetailJobSerializer> {
      return localVarFp.jobsIdGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobsIdOptions(id: string, options?: any): AxiosPromise<JobsIdJobDetailJobSerializer> {
      return localVarFp.jobsIdOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    jobsOptions(options?: any): AxiosPromise<JobsJobListJobSerializer> {
      return localVarFp.jobsOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffApplicationsGet(options?: any): AxiosPromise<StaffApplicationsApplicationListJobApplicationSerializer> {
      return localVarFp.staffApplicationsGet(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffApplicationsIdGet(id: string, options?: any): AxiosPromise<StaffApplicationsIdApplicationDetailJobApplicationSerializer> {
      return localVarFp.staffApplicationsIdGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffApplicationsIdOptions(id: string, options?: any): AxiosPromise<StaffApplicationsIdApplicationDetailJobApplicationSerializer> {
      return localVarFp.staffApplicationsIdOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffApplicationsIdPatch(id: string, options?: any): AxiosPromise<StaffApplicationsIdApplicationDetailJobApplicationSerializer> {
      return localVarFp.staffApplicationsIdPatch(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffApplicationsIdPut(id: string, options?: any): AxiosPromise<StaffApplicationsIdApplicationDetailJobApplicationSerializer> {
      return localVarFp.staffApplicationsIdPut(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffApplicationsOptions(options?: any): AxiosPromise<StaffApplicationsApplicationListJobApplicationSerializer> {
      return localVarFp.staffApplicationsOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffAppointmentsGet(options?: any): AxiosPromise<StaffAppointmentsAppointmentListStaffAppointmentSerializer> {
      return localVarFp.staffAppointmentsGet(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffAppointmentsIdGet(id: string, options?: any): AxiosPromise<StaffAppointmentsIdAppointmentDetailStaffAppointmentSerializer> {
      return localVarFp.staffAppointmentsIdGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffAppointmentsIdOptions(id: string, options?: any): AxiosPromise<StaffAppointmentsIdAppointmentDetailStaffAppointmentSerializer> {
      return localVarFp.staffAppointmentsIdOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffAppointmentsIdPatch(id: string, options?: any): AxiosPromise<StaffAppointmentsIdAppointmentDetailStaffAppointmentSerializer> {
      return localVarFp.staffAppointmentsIdPatch(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffAppointmentsIdPut(id: string, options?: any): AxiosPromise<StaffAppointmentsIdAppointmentDetailStaffAppointmentSerializer> {
      return localVarFp.staffAppointmentsIdPut(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffAppointmentsIdRecordingsGet(id: string, options?: any): AxiosPromise<StaffAppointmentsIdRecordingsAppointmentRecordingListRecordingSerializer> {
      return localVarFp.staffAppointmentsIdRecordingsGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffAppointmentsIdRecordingsOptions(id: string, options?: any): AxiosPromise<StaffAppointmentsIdRecordingsAppointmentRecordingListRecordingSerializer> {
      return localVarFp.staffAppointmentsIdRecordingsOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffAppointmentsIdRecordingsPost(id: string, options?: any): AxiosPromise<StaffAppointmentsIdRecordingsAppointmentRecordingListRecordingSerializer> {
      return localVarFp.staffAppointmentsIdRecordingsPost(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffAppointmentsIdRecordingsRecordingIdDelete(id: string, recordingId: number, options?: any): AxiosPromise<StaffAppointmentsIdRecordingsRecordingIdAppointmentRecordingDetailRecordingSerializer> {
      return localVarFp.staffAppointmentsIdRecordingsRecordingIdDelete(id, recordingId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffAppointmentsIdRecordingsRecordingIdGet(id: string, recordingId: number, options?: any): AxiosPromise<StaffAppointmentsIdRecordingsRecordingIdAppointmentRecordingDetailRecordingSerializer> {
      return localVarFp.staffAppointmentsIdRecordingsRecordingIdGet(id, recordingId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffAppointmentsIdRecordingsRecordingIdOptions(id: string, recordingId: number, options?: any): AxiosPromise<StaffAppointmentsIdRecordingsRecordingIdAppointmentRecordingDetailRecordingSerializer> {
      return localVarFp.staffAppointmentsIdRecordingsRecordingIdOptions(id, recordingId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffAppointmentsIdRecordingsRecordingIdPatch(id: string, recordingId: number, options?: any): AxiosPromise<StaffAppointmentsIdRecordingsRecordingIdAppointmentRecordingDetailRecordingSerializer> {
      return localVarFp.staffAppointmentsIdRecordingsRecordingIdPatch(id, recordingId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffAppointmentsIdRecordingsRecordingIdPut(id: string, recordingId: number, options?: any): AxiosPromise<StaffAppointmentsIdRecordingsRecordingIdAppointmentRecordingDetailRecordingSerializer> {
      return localVarFp.staffAppointmentsIdRecordingsRecordingIdPut(id, recordingId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffAppointmentsOptions(options?: any): AxiosPromise<StaffAppointmentsAppointmentListStaffAppointmentSerializer> {
      return localVarFp.staffAppointmentsOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffChoresGet(options?: any): AxiosPromise<StaffChoresChoreListChoreSerializer> {
      return localVarFp.staffChoresGet(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffChoresIdGet(id: string, options?: any): AxiosPromise<StaffChoresIdChoreDetailChoreSerializer> {
      return localVarFp.staffChoresIdGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffChoresIdOptions(id: string, options?: any): AxiosPromise<StaffChoresIdChoreDetailChoreSerializer> {
      return localVarFp.staffChoresIdOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffChoresIdPatch(id: string, options?: any): AxiosPromise<StaffChoresIdChoreDetailChoreSerializer> {
      return localVarFp.staffChoresIdPatch(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffChoresIdPut(id: string, options?: any): AxiosPromise<StaffChoresIdChoreDetailChoreSerializer> {
      return localVarFp.staffChoresIdPut(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffChoresOptions(options?: any): AxiosPromise<StaffChoresChoreListChoreSerializer> {
      return localVarFp.staffChoresOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffChoresPost(options?: any): AxiosPromise<StaffChoresChoreListChoreSerializer> {
      return localVarFp.staffChoresPost(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffCompaniesGet(options?: any): AxiosPromise<StaffCompaniesCompanyListStaffCompanySerializer> {
      return localVarFp.staffCompaniesGet(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {StaffCompaniesIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffCompaniesIdActionGet(id: string, action: StaffCompaniesIdActionGetActionEnum, options?: any): AxiosPromise<StaffCompaniesIdActionCompanyDetailStaffCompanySerializer> {
      return localVarFp.staffCompaniesIdActionGet(id, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {StaffCompaniesIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffCompaniesIdActionOptions(id: string, action: StaffCompaniesIdActionOptionsActionEnum, options?: any): AxiosPromise<StaffCompaniesIdActionCompanyDetailStaffCompanySerializer> {
      return localVarFp.staffCompaniesIdActionOptions(id, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {StaffCompaniesIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffCompaniesIdActionPut(id: string, action: StaffCompaniesIdActionPutActionEnum, options?: any): AxiosPromise<StaffCompaniesIdActionCompanyDetailStaffCompanySerializer> {
      return localVarFp.staffCompaniesIdActionPut(id, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffCompaniesIdGet(id: string, options?: any): AxiosPromise<StaffCompaniesIdCompanyDetailStaffCompanySerializer> {
      return localVarFp.staffCompaniesIdGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffCompaniesIdOptions(id: string, options?: any): AxiosPromise<StaffCompaniesIdCompanyDetailStaffCompanySerializer> {
      return localVarFp.staffCompaniesIdOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffCompaniesIdPut(id: string, options?: any): AxiosPromise<StaffCompaniesIdCompanyDetailStaffCompanySerializer> {
      return localVarFp.staffCompaniesIdPut(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffCompaniesOptions(options?: any): AxiosPromise<StaffCompaniesCompanyListStaffCompanySerializer> {
      return localVarFp.staffCompaniesOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffCompaniesPost(options?: any): AxiosPromise<StaffCompaniesCompanyListStaffCompanySerializer> {
      return localVarFp.staffCompaniesPost(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsGet(options?: any): AxiosPromise<StaffDealsDealListDealSerializer> {
      return localVarFp.staffDealsGet(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdActionGet(id: string, action: string, options?: any): AxiosPromise<StaffDealsIdActionDealDetailDealSerializer> {
      return localVarFp.staffDealsIdActionGet(id, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdActionOptions(id: string, action: string, options?: any): AxiosPromise<StaffDealsIdActionDealDetailDealSerializer> {
      return localVarFp.staffDealsIdActionOptions(id, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdActionPatch(id: string, action: string, options?: any): AxiosPromise<StaffDealsIdActionDealDetailDealSerializer> {
      return localVarFp.staffDealsIdActionPatch(id, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdActionPut(id: string, action: string, options?: any): AxiosPromise<StaffDealsIdActionDealDetailDealSerializer> {
      return localVarFp.staffDealsIdActionPut(id, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdGet(id: string, options?: any): AxiosPromise<StaffDealsIdDealDetailDealSerializer> {
      return localVarFp.staffDealsIdGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdInterviewsGet(id: string, options?: any): AxiosPromise<StaffDealsIdInterviewsDealInterviewListInterviewSerializer> {
      return localVarFp.staffDealsIdInterviewsGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {StaffDealsIdInterviewsInterviewIdActionDeleteActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdInterviewsInterviewIdActionDelete(id: string, interviewId: string, action: StaffDealsIdInterviewsInterviewIdActionDeleteActionEnum, options?: any): AxiosPromise<StaffDealsIdInterviewsInterviewIdActionDealInterviewDetailInterviewSerializer> {
      return localVarFp.staffDealsIdInterviewsInterviewIdActionDelete(id, interviewId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {StaffDealsIdInterviewsInterviewIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdInterviewsInterviewIdActionGet(id: string, interviewId: string, action: StaffDealsIdInterviewsInterviewIdActionGetActionEnum, options?: any): AxiosPromise<StaffDealsIdInterviewsInterviewIdActionDealInterviewDetailInterviewSerializer> {
      return localVarFp.staffDealsIdInterviewsInterviewIdActionGet(id, interviewId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {StaffDealsIdInterviewsInterviewIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdInterviewsInterviewIdActionOptions(id: string, interviewId: string, action: StaffDealsIdInterviewsInterviewIdActionOptionsActionEnum, options?: any): AxiosPromise<StaffDealsIdInterviewsInterviewIdActionDealInterviewDetailInterviewSerializer> {
      return localVarFp.staffDealsIdInterviewsInterviewIdActionOptions(id, interviewId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {StaffDealsIdInterviewsInterviewIdActionPatchActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdInterviewsInterviewIdActionPatch(id: string, interviewId: string, action: StaffDealsIdInterviewsInterviewIdActionPatchActionEnum, options?: any): AxiosPromise<StaffDealsIdInterviewsInterviewIdActionDealInterviewDetailInterviewSerializer> {
      return localVarFp.staffDealsIdInterviewsInterviewIdActionPatch(id, interviewId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {StaffDealsIdInterviewsInterviewIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdInterviewsInterviewIdActionPut(id: string, interviewId: string, action: StaffDealsIdInterviewsInterviewIdActionPutActionEnum, options?: any): AxiosPromise<StaffDealsIdInterviewsInterviewIdActionDealInterviewDetailInterviewSerializer> {
      return localVarFp.staffDealsIdInterviewsInterviewIdActionPut(id, interviewId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdInterviewsInterviewIdDelete(id: string, interviewId: string, options?: any): AxiosPromise<StaffDealsIdInterviewsInterviewIdDealInterviewDetailInterviewSerializer> {
      return localVarFp.staffDealsIdInterviewsInterviewIdDelete(id, interviewId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdInterviewsInterviewIdGet(id: string, interviewId: string, options?: any): AxiosPromise<StaffDealsIdInterviewsInterviewIdDealInterviewDetailInterviewSerializer> {
      return localVarFp.staffDealsIdInterviewsInterviewIdGet(id, interviewId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdInterviewsInterviewIdOptions(id: string, interviewId: string, options?: any): AxiosPromise<StaffDealsIdInterviewsInterviewIdDealInterviewDetailInterviewSerializer> {
      return localVarFp.staffDealsIdInterviewsInterviewIdOptions(id, interviewId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdInterviewsInterviewIdPatch(id: string, interviewId: string, options?: any): AxiosPromise<StaffDealsIdInterviewsInterviewIdDealInterviewDetailInterviewSerializer> {
      return localVarFp.staffDealsIdInterviewsInterviewIdPatch(id, interviewId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} interviewId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdInterviewsInterviewIdPut(id: string, interviewId: string, options?: any): AxiosPromise<StaffDealsIdInterviewsInterviewIdDealInterviewDetailInterviewSerializer> {
      return localVarFp.staffDealsIdInterviewsInterviewIdPut(id, interviewId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdInterviewsOptions(id: string, options?: any): AxiosPromise<StaffDealsIdInterviewsDealInterviewListInterviewSerializer> {
      return localVarFp.staffDealsIdInterviewsOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdInterviewsPost(id: string, options?: any): AxiosPromise<StaffDealsIdInterviewsDealInterviewListInterviewSerializer> {
      return localVarFp.staffDealsIdInterviewsPost(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdOptions(id: string, options?: any): AxiosPromise<StaffDealsIdDealDetailDealSerializer> {
      return localVarFp.staffDealsIdOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPatch(id: string, options?: any): AxiosPromise<StaffDealsIdDealDetailDealSerializer> {
      return localVarFp.staffDealsIdPatch(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPresentationsDeclineOptions(id: string, options?: any): AxiosPromise<StaffDealsIdPresentationsDeclinePresentationBulkDeclinePresentationSerializer> {
      return localVarFp.staffDealsIdPresentationsDeclineOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPresentationsDeclinePatch(id: string, options?: any): AxiosPromise<StaffDealsIdPresentationsDeclinePresentationBulkDeclinePresentationSerializer> {
      return localVarFp.staffDealsIdPresentationsDeclinePatch(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPresentationsDeclinePut(id: string, options?: any): AxiosPromise<StaffDealsIdPresentationsDeclinePresentationBulkDeclinePresentationSerializer> {
      return localVarFp.staffDealsIdPresentationsDeclinePut(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPresentationsGet(id: string, options?: any): AxiosPromise<StaffDealsIdPresentationsPresentationListPresentationSerializer> {
      return localVarFp.staffDealsIdPresentationsGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPresentationsOptions(id: string, options?: any): AxiosPromise<StaffDealsIdPresentationsPresentationListPresentationSerializer> {
      return localVarFp.staffDealsIdPresentationsOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPresentationsPost(id: string, options?: any): AxiosPromise<StaffDealsIdPresentationsPresentationListPresentationSerializer> {
      return localVarFp.staffDealsIdPresentationsPost(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {StaffDealsIdPresentationsPresentationIdActionDeleteActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPresentationsPresentationIdActionDelete(id: string, presentationId: string, action: StaffDealsIdPresentationsPresentationIdActionDeleteActionEnum, options?: any): AxiosPromise<StaffDealsIdPresentationsPresentationIdActionPresentationDetailPresentationSerializer> {
      return localVarFp.staffDealsIdPresentationsPresentationIdActionDelete(id, presentationId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {StaffDealsIdPresentationsPresentationIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPresentationsPresentationIdActionGet(id: string, presentationId: string, action: StaffDealsIdPresentationsPresentationIdActionGetActionEnum, options?: any): AxiosPromise<StaffDealsIdPresentationsPresentationIdActionPresentationDetailPresentationSerializer> {
      return localVarFp.staffDealsIdPresentationsPresentationIdActionGet(id, presentationId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {StaffDealsIdPresentationsPresentationIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPresentationsPresentationIdActionOptions(id: string, presentationId: string, action: StaffDealsIdPresentationsPresentationIdActionOptionsActionEnum, options?: any): AxiosPromise<StaffDealsIdPresentationsPresentationIdActionPresentationDetailPresentationSerializer> {
      return localVarFp.staffDealsIdPresentationsPresentationIdActionOptions(id, presentationId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {StaffDealsIdPresentationsPresentationIdActionPatchActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPresentationsPresentationIdActionPatch(id: string, presentationId: string, action: StaffDealsIdPresentationsPresentationIdActionPatchActionEnum, options?: any): AxiosPromise<StaffDealsIdPresentationsPresentationIdActionPresentationDetailPresentationSerializer> {
      return localVarFp.staffDealsIdPresentationsPresentationIdActionPatch(id, presentationId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {StaffDealsIdPresentationsPresentationIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPresentationsPresentationIdActionPut(id: string, presentationId: string, action: StaffDealsIdPresentationsPresentationIdActionPutActionEnum, options?: any): AxiosPromise<StaffDealsIdPresentationsPresentationIdActionPresentationDetailPresentationSerializer> {
      return localVarFp.staffDealsIdPresentationsPresentationIdActionPut(id, presentationId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPresentationsPresentationIdDelete(id: string, presentationId: string, options?: any): AxiosPromise<StaffDealsIdPresentationsPresentationIdPresentationDetailPresentationSerializer> {
      return localVarFp.staffDealsIdPresentationsPresentationIdDelete(id, presentationId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPresentationsPresentationIdGet(id: string, presentationId: string, options?: any): AxiosPromise<StaffDealsIdPresentationsPresentationIdPresentationDetailPresentationSerializer> {
      return localVarFp.staffDealsIdPresentationsPresentationIdGet(id, presentationId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPresentationsPresentationIdOptions(id: string, presentationId: string, options?: any): AxiosPromise<StaffDealsIdPresentationsPresentationIdPresentationDetailPresentationSerializer> {
      return localVarFp.staffDealsIdPresentationsPresentationIdOptions(id, presentationId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPresentationsPresentationIdPatch(id: string, presentationId: string, options?: any): AxiosPromise<StaffDealsIdPresentationsPresentationIdPresentationDetailPresentationSerializer> {
      return localVarFp.staffDealsIdPresentationsPresentationIdPatch(id, presentationId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} presentationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPresentationsPresentationIdPut(id: string, presentationId: string, options?: any): AxiosPromise<StaffDealsIdPresentationsPresentationIdPresentationDetailPresentationSerializer> {
      return localVarFp.staffDealsIdPresentationsPresentationIdPut(id, presentationId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsIdPut(id: string, options?: any): AxiosPromise<StaffDealsIdDealDetailDealSerializer> {
      return localVarFp.staffDealsIdPut(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsOptions(options?: any): AxiosPromise<StaffDealsDealListDealSerializer> {
      return localVarFp.staffDealsOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffDealsPost(options?: any): AxiosPromise<StaffDealsDealListDealSerializer> {
      return localVarFp.staffDealsPost(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersGet(options?: any): AxiosPromise<StaffFreelancersFreelancerListStaffFreelancerSerializer> {
      return localVarFp.staffFreelancersGet(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {StaffFreelancersIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdActionGet(id: string, action: StaffFreelancersIdActionGetActionEnum, options?: any): AxiosPromise<StaffFreelancersIdActionFreelancerDetailStaffFreelancerSerializer> {
      return localVarFp.staffFreelancersIdActionGet(id, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {StaffFreelancersIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdActionOptions(id: string, action: StaffFreelancersIdActionOptionsActionEnum, options?: any): AxiosPromise<StaffFreelancersIdActionFreelancerDetailStaffFreelancerSerializer> {
      return localVarFp.staffFreelancersIdActionOptions(id, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {StaffFreelancersIdActionPatchActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdActionPatch(id: string, action: StaffFreelancersIdActionPatchActionEnum, options?: any): AxiosPromise<StaffFreelancersIdActionFreelancerDetailStaffFreelancerSerializer> {
      return localVarFp.staffFreelancersIdActionPatch(id, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {StaffFreelancersIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdActionPut(id: string, action: StaffFreelancersIdActionPutActionEnum, options?: any): AxiosPromise<StaffFreelancersIdActionFreelancerDetailStaffFreelancerSerializer> {
      return localVarFp.staffFreelancersIdActionPut(id, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdGet(id: string, options?: any): AxiosPromise<StaffFreelancersIdFreelancerDetailStaffFreelancerSerializer> {
      return localVarFp.staffFreelancersIdGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdHiresGet(id: string, options?: any): AxiosPromise<StaffFreelancersIdHiresFreelancerHireListHireSerializer> {
      return localVarFp.staffFreelancersIdHiresGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdHiresOptions(id: string, options?: any): AxiosPromise<StaffFreelancersIdHiresFreelancerHireListHireSerializer> {
      return localVarFp.staffFreelancersIdHiresOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdHiresPost(id: string, options?: any): AxiosPromise<StaffFreelancersIdHiresFreelancerHireListHireSerializer> {
      return localVarFp.staffFreelancersIdHiresPost(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdMatchesGet(id: string, options?: any): AxiosPromise<StaffFreelancersIdMatchesFreelancerMatchListStaffJobSerializer> {
      return localVarFp.staffFreelancersIdMatchesGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdMatchesOptions(id: string, options?: any): AxiosPromise<StaffFreelancersIdMatchesFreelancerMatchListStaffJobSerializer> {
      return localVarFp.staffFreelancersIdMatchesOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdMessagesGet(id: string, options?: any): AxiosPromise<StaffFreelancersIdMessagesFreelancerMessageListMessageSerializer> {
      return localVarFp.staffFreelancersIdMessagesGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdMessagesOptions(id: string, options?: any): AxiosPromise<StaffFreelancersIdMessagesFreelancerMessageListMessageSerializer> {
      return localVarFp.staffFreelancersIdMessagesOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdNotesGet(id: string, options?: any): AxiosPromise<StaffFreelancersIdNotesFreelancerNoteListNoteSerializer> {
      return localVarFp.staffFreelancersIdNotesGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdNotesOptions(id: string, options?: any): AxiosPromise<StaffFreelancersIdNotesFreelancerNoteListNoteSerializer> {
      return localVarFp.staffFreelancersIdNotesOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdNotesPost(id: string, options?: any): AxiosPromise<StaffFreelancersIdNotesFreelancerNoteListNoteSerializer> {
      return localVarFp.staffFreelancersIdNotesPost(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdOptions(id: string, options?: any): AxiosPromise<StaffFreelancersIdFreelancerDetailStaffFreelancerSerializer> {
      return localVarFp.staffFreelancersIdOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdPatch(id: string, options?: any): AxiosPromise<StaffFreelancersIdFreelancerDetailStaffFreelancerSerializer> {
      return localVarFp.staffFreelancersIdPatch(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdPut(id: string, options?: any): AxiosPromise<StaffFreelancersIdFreelancerDetailStaffFreelancerSerializer> {
      return localVarFp.staffFreelancersIdPut(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdRecordingsGet(id: string, options?: any): AxiosPromise<StaffFreelancersIdRecordingsFreelancerRecordingListRecordingSerializer> {
      return localVarFp.staffFreelancersIdRecordingsGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdRecordingsOptions(id: string, options?: any): AxiosPromise<StaffFreelancersIdRecordingsFreelancerRecordingListRecordingSerializer> {
      return localVarFp.staffFreelancersIdRecordingsOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdRecordingsPost(id: string, options?: any): AxiosPromise<StaffFreelancersIdRecordingsFreelancerRecordingListRecordingSerializer> {
      return localVarFp.staffFreelancersIdRecordingsPost(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdRecordingsRecordingIdDelete(id: string, recordingId: number, options?: any): AxiosPromise<StaffFreelancersIdRecordingsRecordingIdFreelancerRecordingDetailRecordingSerializer> {
      return localVarFp.staffFreelancersIdRecordingsRecordingIdDelete(id, recordingId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdRecordingsRecordingIdGet(id: string, recordingId: number, options?: any): AxiosPromise<StaffFreelancersIdRecordingsRecordingIdFreelancerRecordingDetailRecordingSerializer> {
      return localVarFp.staffFreelancersIdRecordingsRecordingIdGet(id, recordingId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdRecordingsRecordingIdOptions(id: string, recordingId: number, options?: any): AxiosPromise<StaffFreelancersIdRecordingsRecordingIdFreelancerRecordingDetailRecordingSerializer> {
      return localVarFp.staffFreelancersIdRecordingsRecordingIdOptions(id, recordingId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdRecordingsRecordingIdPatch(id: string, recordingId: number, options?: any): AxiosPromise<StaffFreelancersIdRecordingsRecordingIdFreelancerRecordingDetailRecordingSerializer> {
      return localVarFp.staffFreelancersIdRecordingsRecordingIdPatch(id, recordingId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdRecordingsRecordingIdPut(id: string, recordingId: number, options?: any): AxiosPromise<StaffFreelancersIdRecordingsRecordingIdFreelancerRecordingDetailRecordingSerializer> {
      return localVarFp.staffFreelancersIdRecordingsRecordingIdPut(id, recordingId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdScreeningGet(id: string, options?: any): AxiosPromise<StaffFreelancersIdScreeningFreelancerScreeningDebuggerStaffFreelancerSerializer> {
      return localVarFp.staffFreelancersIdScreeningGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersIdScreeningOptions(id: string, options?: any): AxiosPromise<StaffFreelancersIdScreeningFreelancerScreeningDebuggerStaffFreelancerSerializer> {
      return localVarFp.staffFreelancersIdScreeningOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffFreelancersOptions(options?: any): AxiosPromise<StaffFreelancersFreelancerListStaffFreelancerSerializer> {
      return localVarFp.staffFreelancersOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffHiresGet(options?: any): AxiosPromise<StaffHiresHireListHireSerializer> {
      return localVarFp.staffHiresGet(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {StaffHiresIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffHiresIdActionGet(id: number, action: StaffHiresIdActionGetActionEnum, options?: any): AxiosPromise<StaffHiresIdActionHireDetailHireSerializer> {
      return localVarFp.staffHiresIdActionGet(id, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {StaffHiresIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffHiresIdActionOptions(id: number, action: StaffHiresIdActionOptionsActionEnum, options?: any): AxiosPromise<StaffHiresIdActionHireDetailHireSerializer> {
      return localVarFp.staffHiresIdActionOptions(id, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {StaffHiresIdActionPatchActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffHiresIdActionPatch(id: number, action: StaffHiresIdActionPatchActionEnum, options?: any): AxiosPromise<StaffHiresIdActionHireDetailHireSerializer> {
      return localVarFp.staffHiresIdActionPatch(id, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {StaffHiresIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffHiresIdActionPut(id: number, action: StaffHiresIdActionPutActionEnum, options?: any): AxiosPromise<StaffHiresIdActionHireDetailHireSerializer> {
      return localVarFp.staffHiresIdActionPut(id, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffHiresIdGet(id: number, options?: any): AxiosPromise<StaffHiresIdHireDetailHireSerializer> {
      return localVarFp.staffHiresIdGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffHiresIdOptions(id: number, options?: any): AxiosPromise<StaffHiresIdHireDetailHireSerializer> {
      return localVarFp.staffHiresIdOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffHiresIdPatch(id: number, options?: any): AxiosPromise<StaffHiresIdHireDetailHireSerializer> {
      return localVarFp.staffHiresIdPatch(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffHiresIdPut(id: number, options?: any): AxiosPromise<StaffHiresIdHireDetailHireSerializer> {
      return localVarFp.staffHiresIdPut(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffHiresIdScopeOfWorkGet(id: number, options?: any): AxiosPromise<StaffHiresIdScopeOfWorkScopeOfWorkListScopeOfWorkSerializer> {
      return localVarFp.staffHiresIdScopeOfWorkGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffHiresIdScopeOfWorkOptions(id: number, options?: any): AxiosPromise<StaffHiresIdScopeOfWorkScopeOfWorkListScopeOfWorkSerializer> {
      return localVarFp.staffHiresIdScopeOfWorkOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffHiresIdScopeOfWorkPost(id: number, options?: any): AxiosPromise<StaffHiresIdScopeOfWorkScopeOfWorkListScopeOfWorkSerializer> {
      return localVarFp.staffHiresIdScopeOfWorkPost(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {string} scopeOfWorkId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffHiresIdScopeOfWorkScopeOfWorkIdGet(id: number, scopeOfWorkId: string, options?: any): AxiosPromise<StaffHiresIdScopeOfWorkScopeOfWorkIdScopeOfWorkDetailScopeOfWorkSerializer> {
      return localVarFp.staffHiresIdScopeOfWorkScopeOfWorkIdGet(id, scopeOfWorkId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {string} scopeOfWorkId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffHiresIdScopeOfWorkScopeOfWorkIdOptions(id: number, scopeOfWorkId: string, options?: any): AxiosPromise<StaffHiresIdScopeOfWorkScopeOfWorkIdScopeOfWorkDetailScopeOfWorkSerializer> {
      return localVarFp.staffHiresIdScopeOfWorkScopeOfWorkIdOptions(id, scopeOfWorkId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffHiresOptions(options?: any): AxiosPromise<StaffHiresHireListHireSerializer> {
      return localVarFp.staffHiresOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffHiresPost(options?: any): AxiosPromise<StaffHiresHireListHireSerializer> {
      return localVarFp.staffHiresPost(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffInterviewsGet(options?: any): AxiosPromise<StaffInterviewsInterviewListInterviewSerializer> {
      return localVarFp.staffInterviewsGet(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {StaffInterviewsIdActionDeleteActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffInterviewsIdActionDelete(id: string, action: StaffInterviewsIdActionDeleteActionEnum, options?: any): AxiosPromise<StaffInterviewsIdActionInterviewDetailInterviewSerializer> {
      return localVarFp.staffInterviewsIdActionDelete(id, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {StaffInterviewsIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffInterviewsIdActionGet(id: string, action: StaffInterviewsIdActionGetActionEnum, options?: any): AxiosPromise<StaffInterviewsIdActionInterviewDetailInterviewSerializer> {
      return localVarFp.staffInterviewsIdActionGet(id, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {StaffInterviewsIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffInterviewsIdActionOptions(id: string, action: StaffInterviewsIdActionOptionsActionEnum, options?: any): AxiosPromise<StaffInterviewsIdActionInterviewDetailInterviewSerializer> {
      return localVarFp.staffInterviewsIdActionOptions(id, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {StaffInterviewsIdActionPatchActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffInterviewsIdActionPatch(id: string, action: StaffInterviewsIdActionPatchActionEnum, options?: any): AxiosPromise<StaffInterviewsIdActionInterviewDetailInterviewSerializer> {
      return localVarFp.staffInterviewsIdActionPatch(id, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {StaffInterviewsIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffInterviewsIdActionPut(id: string, action: StaffInterviewsIdActionPutActionEnum, options?: any): AxiosPromise<StaffInterviewsIdActionInterviewDetailInterviewSerializer> {
      return localVarFp.staffInterviewsIdActionPut(id, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffInterviewsIdDelete(id: string, options?: any): AxiosPromise<StaffInterviewsIdInterviewDetailInterviewSerializer> {
      return localVarFp.staffInterviewsIdDelete(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffInterviewsIdGet(id: string, options?: any): AxiosPromise<StaffInterviewsIdInterviewDetailInterviewSerializer> {
      return localVarFp.staffInterviewsIdGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffInterviewsIdOptions(id: string, options?: any): AxiosPromise<StaffInterviewsIdInterviewDetailInterviewSerializer> {
      return localVarFp.staffInterviewsIdOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffInterviewsIdPatch(id: string, options?: any): AxiosPromise<StaffInterviewsIdInterviewDetailInterviewSerializer> {
      return localVarFp.staffInterviewsIdPatch(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffInterviewsIdPut(id: string, options?: any): AxiosPromise<StaffInterviewsIdInterviewDetailInterviewSerializer> {
      return localVarFp.staffInterviewsIdPut(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffInterviewsOptions(options?: any): AxiosPromise<StaffInterviewsInterviewListInterviewSerializer> {
      return localVarFp.staffInterviewsOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffInterviewsPost(options?: any): AxiosPromise<StaffInterviewsInterviewListInterviewSerializer> {
      return localVarFp.staffInterviewsPost(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsGet(options?: any): AxiosPromise<StaffJobsJobListStaffJobSerializer> {
      return localVarFp.staffJobsGet(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {StaffJobsIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdActionGet(id: string, action: StaffJobsIdActionGetActionEnum, options?: any): AxiosPromise<StaffJobsIdActionJobDetailStaffJobSerializer> {
      return localVarFp.staffJobsIdActionGet(id, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {StaffJobsIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdActionOptions(id: string, action: StaffJobsIdActionOptionsActionEnum, options?: any): AxiosPromise<StaffJobsIdActionJobDetailStaffJobSerializer> {
      return localVarFp.staffJobsIdActionOptions(id, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {StaffJobsIdActionPatchActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdActionPatch(id: string, action: StaffJobsIdActionPatchActionEnum, options?: any): AxiosPromise<StaffJobsIdActionJobDetailStaffJobSerializer> {
      return localVarFp.staffJobsIdActionPatch(id, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {StaffJobsIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdActionPut(id: string, action: StaffJobsIdActionPutActionEnum, options?: any): AxiosPromise<StaffJobsIdActionJobDetailStaffJobSerializer> {
      return localVarFp.staffJobsIdActionPut(id, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {StaffJobsIdApplicationsApplicationIdActionGetActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdApplicationsApplicationIdActionGet(id: string, applicationId: string, action: StaffJobsIdApplicationsApplicationIdActionGetActionEnum, options?: any): AxiosPromise<StaffJobsIdApplicationsApplicationIdActionJobApplicationDetailJobApplicationSerializer> {
      return localVarFp.staffJobsIdApplicationsApplicationIdActionGet(id, applicationId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {StaffJobsIdApplicationsApplicationIdActionOptionsActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdApplicationsApplicationIdActionOptions(id: string, applicationId: string, action: StaffJobsIdApplicationsApplicationIdActionOptionsActionEnum, options?: any): AxiosPromise<StaffJobsIdApplicationsApplicationIdActionJobApplicationDetailJobApplicationSerializer> {
      return localVarFp.staffJobsIdApplicationsApplicationIdActionOptions(id, applicationId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {StaffJobsIdApplicationsApplicationIdActionPatchActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdApplicationsApplicationIdActionPatch(id: string, applicationId: string, action: StaffJobsIdApplicationsApplicationIdActionPatchActionEnum, options?: any): AxiosPromise<StaffJobsIdApplicationsApplicationIdActionJobApplicationDetailJobApplicationSerializer> {
      return localVarFp.staffJobsIdApplicationsApplicationIdActionPatch(id, applicationId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {StaffJobsIdApplicationsApplicationIdActionPutActionEnum} action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdApplicationsApplicationIdActionPut(id: string, applicationId: string, action: StaffJobsIdApplicationsApplicationIdActionPutActionEnum, options?: any): AxiosPromise<StaffJobsIdApplicationsApplicationIdActionJobApplicationDetailJobApplicationSerializer> {
      return localVarFp.staffJobsIdApplicationsApplicationIdActionPut(id, applicationId, action, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdApplicationsApplicationIdGet(id: string, applicationId: string, options?: any): AxiosPromise<StaffJobsIdApplicationsApplicationIdJobApplicationDetailJobApplicationSerializer> {
      return localVarFp.staffJobsIdApplicationsApplicationIdGet(id, applicationId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdApplicationsApplicationIdOptions(id: string, applicationId: string, options?: any): AxiosPromise<StaffJobsIdApplicationsApplicationIdJobApplicationDetailJobApplicationSerializer> {
      return localVarFp.staffJobsIdApplicationsApplicationIdOptions(id, applicationId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdApplicationsApplicationIdPatch(id: string, applicationId: string, options?: any): AxiosPromise<StaffJobsIdApplicationsApplicationIdJobApplicationDetailJobApplicationSerializer> {
      return localVarFp.staffJobsIdApplicationsApplicationIdPatch(id, applicationId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} applicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdApplicationsApplicationIdPut(id: string, applicationId: string, options?: any): AxiosPromise<StaffJobsIdApplicationsApplicationIdJobApplicationDetailJobApplicationSerializer> {
      return localVarFp.staffJobsIdApplicationsApplicationIdPut(id, applicationId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdApplicationsDeclineOptions(id: string, options?: any): AxiosPromise<StaffJobsIdApplicationsDeclineJobApplicationBulkDeclineJobApplicationSerializer> {
      return localVarFp.staffJobsIdApplicationsDeclineOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdApplicationsDeclinePatch(id: string, options?: any): AxiosPromise<StaffJobsIdApplicationsDeclineJobApplicationBulkDeclineJobApplicationSerializer> {
      return localVarFp.staffJobsIdApplicationsDeclinePatch(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdApplicationsDeclinePut(id: string, options?: any): AxiosPromise<StaffJobsIdApplicationsDeclineJobApplicationBulkDeclineJobApplicationSerializer> {
      return localVarFp.staffJobsIdApplicationsDeclinePut(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdApplicationsGet(id: string, options?: any): AxiosPromise<StaffJobsIdApplicationsJobApplicationListJobApplicationSerializer> {
      return localVarFp.staffJobsIdApplicationsGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdApplicationsOptions(id: string, options?: any): AxiosPromise<StaffJobsIdApplicationsJobApplicationListJobApplicationSerializer> {
      return localVarFp.staffJobsIdApplicationsOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} freelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdFreelancersFreelancerIdGet(id: string, freelancerId: string, options?: any): AxiosPromise<StaffJobsIdFreelancersFreelancerIdJobFreelancerDetailJobFreelancerSerializer> {
      return localVarFp.staffJobsIdFreelancersFreelancerIdGet(id, freelancerId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {string} freelancerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdFreelancersFreelancerIdOptions(id: string, freelancerId: string, options?: any): AxiosPromise<StaffJobsIdFreelancersFreelancerIdJobFreelancerDetailJobFreelancerSerializer> {
      return localVarFp.staffJobsIdFreelancersFreelancerIdOptions(id, freelancerId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdFreelancersGet(id: string, options?: any): AxiosPromise<StaffJobsIdFreelancersJobFreelancerListJobFreelancerSerializer> {
      return localVarFp.staffJobsIdFreelancersGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdFreelancersOptions(id: string, options?: any): AxiosPromise<StaffJobsIdFreelancersJobFreelancerListJobFreelancerSerializer> {
      return localVarFp.staffJobsIdFreelancersOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdGet(id: string, options?: any): AxiosPromise<StaffJobsIdJobDetailStaffJobSerializer> {
      return localVarFp.staffJobsIdGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdOptions(id: string, options?: any): AxiosPromise<StaffJobsIdJobDetailStaffJobSerializer> {
      return localVarFp.staffJobsIdOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdPatch(id: string, options?: any): AxiosPromise<StaffJobsIdJobDetailStaffJobSerializer> {
      return localVarFp.staffJobsIdPatch(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdPut(id: string, options?: any): AxiosPromise<StaffJobsIdJobDetailStaffJobSerializer> {
      return localVarFp.staffJobsIdPut(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdRecordingsGet(id: string, options?: any): AxiosPromise<StaffJobsIdRecordingsJobRecordingListRecordingSerializer> {
      return localVarFp.staffJobsIdRecordingsGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdRecordingsOptions(id: string, options?: any): AxiosPromise<StaffJobsIdRecordingsJobRecordingListRecordingSerializer> {
      return localVarFp.staffJobsIdRecordingsOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdRecordingsPost(id: string, options?: any): AxiosPromise<StaffJobsIdRecordingsJobRecordingListRecordingSerializer> {
      return localVarFp.staffJobsIdRecordingsPost(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdRecordingsRecordingIdDelete(id: string, recordingId: number, options?: any): AxiosPromise<StaffJobsIdRecordingsRecordingIdJobRecordingDetailRecordingSerializer> {
      return localVarFp.staffJobsIdRecordingsRecordingIdDelete(id, recordingId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdRecordingsRecordingIdGet(id: string, recordingId: number, options?: any): AxiosPromise<StaffJobsIdRecordingsRecordingIdJobRecordingDetailRecordingSerializer> {
      return localVarFp.staffJobsIdRecordingsRecordingIdGet(id, recordingId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdRecordingsRecordingIdOptions(id: string, recordingId: number, options?: any): AxiosPromise<StaffJobsIdRecordingsRecordingIdJobRecordingDetailRecordingSerializer> {
      return localVarFp.staffJobsIdRecordingsRecordingIdOptions(id, recordingId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdRecordingsRecordingIdPatch(id: string, recordingId: number, options?: any): AxiosPromise<StaffJobsIdRecordingsRecordingIdJobRecordingDetailRecordingSerializer> {
      return localVarFp.staffJobsIdRecordingsRecordingIdPatch(id, recordingId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} recordingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdRecordingsRecordingIdPut(id: string, recordingId: number, options?: any): AxiosPromise<StaffJobsIdRecordingsRecordingIdJobRecordingDetailRecordingSerializer> {
      return localVarFp.staffJobsIdRecordingsRecordingIdPut(id, recordingId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdSearchesGet(id: string, options?: any): AxiosPromise<StaffJobsIdSearchesJobFreelancerSearchListJobFreelancerSearchSerializer> {
      return localVarFp.staffJobsIdSearchesGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdSearchesOptions(id: string, options?: any): AxiosPromise<StaffJobsIdSearchesJobFreelancerSearchListJobFreelancerSearchSerializer> {
      return localVarFp.staffJobsIdSearchesOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdSearchesPost(id: string, options?: any): AxiosPromise<StaffJobsIdSearchesJobFreelancerSearchListJobFreelancerSearchSerializer> {
      return localVarFp.staffJobsIdSearchesPost(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} searchId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdSearchesSearchIdDelete(id: string, searchId: number, options?: any): AxiosPromise<StaffJobsIdSearchesSearchIdJobFreelancerSearchDetailJobFreelancerSearchSerializer> {
      return localVarFp.staffJobsIdSearchesSearchIdDelete(id, searchId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} searchId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdSearchesSearchIdGet(id: string, searchId: number, options?: any): AxiosPromise<StaffJobsIdSearchesSearchIdJobFreelancerSearchDetailJobFreelancerSearchSerializer> {
      return localVarFp.staffJobsIdSearchesSearchIdGet(id, searchId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} searchId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdSearchesSearchIdOptions(id: string, searchId: number, options?: any): AxiosPromise<StaffJobsIdSearchesSearchIdJobFreelancerSearchDetailJobFreelancerSearchSerializer> {
      return localVarFp.staffJobsIdSearchesSearchIdOptions(id, searchId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} searchId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdSearchesSearchIdPatch(id: string, searchId: number, options?: any): AxiosPromise<StaffJobsIdSearchesSearchIdJobFreelancerSearchDetailJobFreelancerSearchSerializer> {
      return localVarFp.staffJobsIdSearchesSearchIdPatch(id, searchId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {number} searchId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsIdSearchesSearchIdPut(id: string, searchId: number, options?: any): AxiosPromise<StaffJobsIdSearchesSearchIdJobFreelancerSearchDetailJobFreelancerSearchSerializer> {
      return localVarFp.staffJobsIdSearchesSearchIdPut(id, searchId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffJobsOptions(options?: any): AxiosPromise<StaffJobsJobListStaffJobSerializer> {
      return localVarFp.staffJobsOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffMessagesGet(options?: any): AxiosPromise<StaffMessagesMessageListMessageSerializer> {
      return localVarFp.staffMessagesGet(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffMessagesOptions(options?: any): AxiosPromise<StaffMessagesMessageListMessageSerializer> {
      return localVarFp.staffMessagesOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffMessagesPost(options?: any): AxiosPromise<StaffMessagesMessageListMessageSerializer> {
      return localVarFp.staffMessagesPost(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffReportsMetricsGet(options?: any): AxiosPromise<StaffReportsMetricsMetricListMetricSerializer> {
      return localVarFp.staffReportsMetricsGet(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffReportsMetricsOptions(options?: any): AxiosPromise<StaffReportsMetricsMetricListMetricSerializer> {
      return localVarFp.staffReportsMetricsOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffReviewsGet(options?: any): AxiosPromise<StaffReviewsReviewListStaffReviewSerializer> {
      return localVarFp.staffReviewsGet(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffReviewsIdGet(id: number, options?: any): AxiosPromise<StaffReviewsIdReviewDetailStaffReviewSerializer> {
      return localVarFp.staffReviewsIdGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffReviewsIdOptions(id: number, options?: any): AxiosPromise<StaffReviewsIdReviewDetailStaffReviewSerializer> {
      return localVarFp.staffReviewsIdOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffReviewsIdPatch(id: number, options?: any): AxiosPromise<StaffReviewsIdReviewDetailStaffReviewSerializer> {
      return localVarFp.staffReviewsIdPatch(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffReviewsIdPut(id: number, options?: any): AxiosPromise<StaffReviewsIdReviewDetailStaffReviewSerializer> {
      return localVarFp.staffReviewsIdPut(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    staffReviewsOptions(options?: any): AxiosPromise<StaffReviewsReviewListStaffReviewSerializer> {
      return localVarFp.staffReviewsOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscriptionsHashGet(hash: string, options?: any): AxiosPromise<SubscriptionsHashSubscriptionsSubscriptionSerializer> {
      return localVarFp.subscriptionsHashGet(hash, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscriptionsHashOptions(hash: string, options?: any): AxiosPromise<SubscriptionsHashSubscriptionsSubscriptionSerializer> {
      return localVarFp.subscriptionsHashOptions(hash, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscriptionsHashPatch(hash: string, options?: any): AxiosPromise<SubscriptionsHashSubscriptionsSubscriptionSerializer> {
      return localVarFp.subscriptionsHashPatch(hash, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    subscriptionsHashPut(hash: string, options?: any): AxiosPromise<SubscriptionsHashSubscriptionsSubscriptionSerializer> {
      return localVarFp.subscriptionsHashPut(hash, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userAppointmentsGet(options?: any): AxiosPromise<UserAppointmentsAppointmentListAppointmentSerializer> {
      return localVarFp.userAppointmentsGet(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userAppointmentsIdGet(id: string, options?: any): AxiosPromise<UserAppointmentsIdAppointmentDetailAppointmentSerializer> {
      return localVarFp.userAppointmentsIdGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userAppointmentsIdOptions(id: string, options?: any): AxiosPromise<UserAppointmentsIdAppointmentDetailAppointmentSerializer> {
      return localVarFp.userAppointmentsIdOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userAppointmentsOptions(options?: any): AxiosPromise<UserAppointmentsAppointmentListAppointmentSerializer> {
      return localVarFp.userAppointmentsOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userCreateCompanyOptions(options?: any): AxiosPromise<UserCreateCompanyCreateCompanyCompanySerializer> {
      return localVarFp.userCreateCompanyOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userCreateCompanyPost(options?: any): AxiosPromise<UserCreateCompanyCreateCompanyCompanySerializer> {
      return localVarFp.userCreateCompanyPost(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userCreateFreelancerOptions(options?: any): AxiosPromise<UserCreateFreelancerCreateFreelancerFreelancerSerializer> {
      return localVarFp.userCreateFreelancerOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userCreateFreelancerPost(options?: any): AxiosPromise<UserCreateFreelancerCreateFreelancerFreelancerSerializer> {
      return localVarFp.userCreateFreelancerPost(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userGet(options?: any): AxiosPromise<UserUserDetailUserSerializer> {
      return localVarFp.userGet(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userGrantNylasDelete(options?: any): AxiosPromise<UserGrantNylasNylasGrantNylasGrantSerializer> {
      return localVarFp.userGrantNylasDelete(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userGrantNylasGet(options?: any): AxiosPromise<UserGrantNylasNylasGrantNylasGrantSerializer> {
      return localVarFp.userGrantNylasGet(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userGrantNylasOptions(options?: any): AxiosPromise<UserGrantNylasNylasGrantNylasGrantSerializer> {
      return localVarFp.userGrantNylasOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userGrantNylasPost(options?: any): AxiosPromise<UserGrantNylasNylasGrantNylasGrantSerializer> {
      return localVarFp.userGrantNylasPost(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userInterviewsGet(options?: any): AxiosPromise<UserInterviewsInterviewListInterviewSerializer> {
      return localVarFp.userInterviewsGet(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userInterviewsIdGet(id: string, options?: any): AxiosPromise<UserInterviewsIdInterviewDetailInterviewSerializer> {
      return localVarFp.userInterviewsIdGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userInterviewsIdOptions(id: string, options?: any): AxiosPromise<UserInterviewsIdInterviewDetailInterviewSerializer> {
      return localVarFp.userInterviewsIdOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userInterviewsOptions(options?: any): AxiosPromise<UserInterviewsInterviewListInterviewSerializer> {
      return localVarFp.userInterviewsOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userOptions(options?: any): AxiosPromise<UserUserDetailUserSerializer> {
      return localVarFp.userOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userPatch(options?: any): AxiosPromise<UserUserDetailUserSerializer> {
      return localVarFp.userPatch(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userPut(options?: any): AxiosPromise<UserUserDetailUserSerializer> {
      return localVarFp.userPut(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userSubscriptionsGet(options?: any): AxiosPromise<UserSubscriptionsSubscriptionsSubscriptionSerializer> {
      return localVarFp.userSubscriptionsGet(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userSubscriptionsOptions(options?: any): AxiosPromise<UserSubscriptionsSubscriptionsSubscriptionSerializer> {
      return localVarFp.userSubscriptionsOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userSubscriptionsPatch(options?: any): AxiosPromise<UserSubscriptionsSubscriptionsSubscriptionSerializer> {
      return localVarFp.userSubscriptionsPatch(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userSubscriptionsPut(options?: any): AxiosPromise<UserSubscriptionsSubscriptionsSubscriptionSerializer> {
      return localVarFp.userSubscriptionsPut(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userSubscriptionsSlackOptions(options?: any): AxiosPromise<UserSubscriptionsSlackSlackSubscriptionSlackSubscriptionSerializer> {
      return localVarFp.userSubscriptionsSlackOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userSubscriptionsSlackPatch(options?: any): AxiosPromise<UserSubscriptionsSlackSlackSubscriptionSlackSubscriptionSerializer> {
      return localVarFp.userSubscriptionsSlackPatch(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userSubscriptionsSlackPut(options?: any): AxiosPromise<UserSubscriptionsSlackSlackSubscriptionSlackSubscriptionSerializer> {
      return localVarFp.userSubscriptionsSlackPut(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userSubscriptionsTelegramOptions(options?: any): AxiosPromise<UserSubscriptionsTelegramTelegramSubscriptionTelegramSubscriptionSerializer> {
      return localVarFp.userSubscriptionsTelegramOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userSubscriptionsTelegramPatch(options?: any): AxiosPromise<UserSubscriptionsTelegramTelegramSubscriptionTelegramSubscriptionSerializer> {
      return localVarFp.userSubscriptionsTelegramPatch(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userSubscriptionsTelegramPut(options?: any): AxiosPromise<UserSubscriptionsTelegramTelegramSubscriptionTelegramSubscriptionSerializer> {
      return localVarFp.userSubscriptionsTelegramPut(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userWorkstyleGet(options?: any): AxiosPromise<UserWorkstyleWorkStyleAssessmentSelfWorkStyleAssessmentSerializer> {
      return localVarFp.userWorkstyleGet(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userWorkstyleOptions(options?: any): AxiosPromise<UserWorkstyleWorkStyleAssessmentSelfWorkStyleAssessmentSerializer> {
      return localVarFp.userWorkstyleOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersGet(options?: any): AxiosPromise<UsersUserListProfileSerializer> {
      return localVarFp.usersGet(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersOptions(options?: any): AxiosPromise<UsersUserListProfileSerializer> {
      return localVarFp.usersOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workstylesAssessmentsGet(options?: any): AxiosPromise<WorkstylesAssessmentsWorkStyleAssessmentListWorkStyleAssessmentSerializer> {
      return localVarFp.workstylesAssessmentsGet(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workstylesAssessmentsIdGet(id: string, options?: any): AxiosPromise<WorkstylesAssessmentsIdWorkStyleAssessmentDetailWorkStyleAssessmentSerializer> {
      return localVarFp.workstylesAssessmentsIdGet(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workstylesAssessmentsIdOptions(id: string, options?: any): AxiosPromise<WorkstylesAssessmentsIdWorkStyleAssessmentDetailWorkStyleAssessmentSerializer> {
      return localVarFp.workstylesAssessmentsIdOptions(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workstylesAssessmentsIdPatch(id: string, options?: any): AxiosPromise<WorkstylesAssessmentsIdWorkStyleAssessmentDetailWorkStyleAssessmentSerializer> {
      return localVarFp.workstylesAssessmentsIdPatch(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workstylesAssessmentsIdPut(id: string, options?: any): AxiosPromise<WorkstylesAssessmentsIdWorkStyleAssessmentDetailWorkStyleAssessmentSerializer> {
      return localVarFp.workstylesAssessmentsIdPut(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workstylesAssessmentsOptions(options?: any): AxiosPromise<WorkstylesAssessmentsWorkStyleAssessmentListWorkStyleAssessmentSerializer> {
      return localVarFp.workstylesAssessmentsOptions(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workstylesAssessmentsPost(options?: any): AxiosPromise<WorkstylesAssessmentsWorkStyleAssessmentListWorkStyleAssessmentSerializer> {
      return localVarFp.workstylesAssessmentsPost(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workstylesGet(options?: any): AxiosPromise<WorkstylesWorkStyleListWorkStyleSerializer> {
      return localVarFp.workstylesGet(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    workstylesOptions(options?: any): AxiosPromise<WorkstylesWorkStyleListWorkStyleSerializer> {
      return localVarFp.workstylesOptions(options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public authInviteGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).authInviteGet(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public authInviteOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).authInviteOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public authInvitePost(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).authInvitePost(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public authLoginGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).authLoginGet(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public authLoginOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).authLoginOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public authLoginPost(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).authLoginPost(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} dealId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdDealsDealIdGet(id: string, dealId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdDealsDealIdGet(id, dealId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} dealId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdDealsDealIdOptions(id: string, dealId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdDealsDealIdOptions(id, dealId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} dealId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdDealsDealIdPresentationsGet(id: string, dealId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdDealsDealIdPresentationsGet(id, dealId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} dealId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdDealsDealIdPresentationsOptions(id: string, dealId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdDealsDealIdPresentationsOptions(id, dealId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} dealId
   * @param {string} presentationId
   * @param {CompanyIdDealsDealIdPresentationsPresentationIdActionGetActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdDealsDealIdPresentationsPresentationIdActionGet(id: string, dealId: string, presentationId: string, action: CompanyIdDealsDealIdPresentationsPresentationIdActionGetActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdDealsDealIdPresentationsPresentationIdActionGet(id, dealId, presentationId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} dealId
   * @param {string} presentationId
   * @param {CompanyIdDealsDealIdPresentationsPresentationIdActionOptionsActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdDealsDealIdPresentationsPresentationIdActionOptions(id: string, dealId: string, presentationId: string, action: CompanyIdDealsDealIdPresentationsPresentationIdActionOptionsActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdDealsDealIdPresentationsPresentationIdActionOptions(id, dealId, presentationId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} dealId
   * @param {string} presentationId
   * @param {CompanyIdDealsDealIdPresentationsPresentationIdActionPutActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdDealsDealIdPresentationsPresentationIdActionPut(id: string, dealId: string, presentationId: string, action: CompanyIdDealsDealIdPresentationsPresentationIdActionPutActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdDealsDealIdPresentationsPresentationIdActionPut(id, dealId, presentationId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} dealId
   * @param {string} presentationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdDealsDealIdPresentationsPresentationIdGet(id: string, dealId: string, presentationId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdDealsDealIdPresentationsPresentationIdGet(id, dealId, presentationId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} dealId
   * @param {string} presentationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdDealsDealIdPresentationsPresentationIdOptions(id: string, dealId: string, presentationId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdDealsDealIdPresentationsPresentationIdOptions(id, dealId, presentationId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} dealId
   * @param {string} presentationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdDealsDealIdPresentationsPresentationIdPut(id: string, dealId: string, presentationId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdDealsDealIdPresentationsPresentationIdPut(id, dealId, presentationId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdDealsGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdDealsGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdDealsOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdDealsOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} freelancerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdFreelancersFreelancerIdGet(id: string, freelancerId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdFreelancersFreelancerIdGet(id, freelancerId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} freelancerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdFreelancersFreelancerIdOptions(id: string, freelancerId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdFreelancersFreelancerIdOptions(id, freelancerId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdHiresGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdHiresGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} hireId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdHiresHireIdGet(id: string, hireId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdHiresHireIdGet(id, hireId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} hireId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdHiresHireIdOptions(id: string, hireId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdHiresHireIdOptions(id, hireId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} hireId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdHiresHireIdPatch(id: string, hireId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdHiresHireIdPatch(id, hireId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} hireId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdHiresHireIdPut(id: string, hireId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdHiresHireIdPut(id, hireId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} hireId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdHiresHireIdScopeOfWorkGet(id: string, hireId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdHiresHireIdScopeOfWorkGet(id, hireId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} hireId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdHiresHireIdScopeOfWorkOptions(id: string, hireId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdHiresHireIdScopeOfWorkOptions(id, hireId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdHiresOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdHiresOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdInterviewSettingsGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdInterviewSettingsGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} interviewSettingsId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdInterviewSettingsInterviewSettingsIdDelete(id: string, interviewSettingsId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdInterviewSettingsInterviewSettingsIdDelete(id, interviewSettingsId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} interviewSettingsId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdInterviewSettingsInterviewSettingsIdGet(id: string, interviewSettingsId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdInterviewSettingsInterviewSettingsIdGet(id, interviewSettingsId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} interviewSettingsId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdInterviewSettingsInterviewSettingsIdOptions(id: string, interviewSettingsId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdInterviewSettingsInterviewSettingsIdOptions(id, interviewSettingsId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} interviewSettingsId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdInterviewSettingsInterviewSettingsIdPatch(id: string, interviewSettingsId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdInterviewSettingsInterviewSettingsIdPatch(id, interviewSettingsId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} interviewSettingsId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdInterviewSettingsInterviewSettingsIdPut(id: string, interviewSettingsId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdInterviewSettingsInterviewSettingsIdPut(id, interviewSettingsId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdInterviewSettingsOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdInterviewSettingsOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdInterviewSettingsPost(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdInterviewSettingsPost(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdInterviewsGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdInterviewsGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} interviewId
   * @param {CompanyIdInterviewsInterviewIdActionGetActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdInterviewsInterviewIdActionGet(id: string, interviewId: string, action: CompanyIdInterviewsInterviewIdActionGetActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdInterviewsInterviewIdActionGet(id, interviewId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} interviewId
   * @param {CompanyIdInterviewsInterviewIdActionOptionsActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdInterviewsInterviewIdActionOptions(id: string, interviewId: string, action: CompanyIdInterviewsInterviewIdActionOptionsActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdInterviewsInterviewIdActionOptions(id, interviewId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} interviewId
   * @param {CompanyIdInterviewsInterviewIdActionPutActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdInterviewsInterviewIdActionPut(id: string, interviewId: string, action: CompanyIdInterviewsInterviewIdActionPutActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdInterviewsInterviewIdActionPut(id, interviewId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} interviewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdInterviewsInterviewIdGet(id: string, interviewId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdInterviewsInterviewIdGet(id, interviewId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} interviewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdInterviewsInterviewIdOptions(id: string, interviewId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdInterviewsInterviewIdOptions(id, interviewId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} interviewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdInterviewsInterviewIdPut(id: string, interviewId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdInterviewsInterviewIdPut(id, interviewId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdInterviewsOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdInterviewsOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdInterviewsPost(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdInterviewsPost(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdInvitesGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdInvitesGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} inviteId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdInvitesInviteIdDelete(id: string, inviteId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdInvitesInviteIdDelete(id, inviteId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} inviteId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdInvitesInviteIdGet(id: string, inviteId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdInvitesInviteIdGet(id, inviteId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} inviteId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdInvitesInviteIdOptions(id: string, inviteId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdInvitesInviteIdOptions(id, inviteId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} inviteId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdInvitesInviteIdPatch(id: string, inviteId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdInvitesInviteIdPatch(id, inviteId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} inviteId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdInvitesInviteIdPut(id: string, inviteId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdInvitesInviteIdPut(id, inviteId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdInvitesOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdInvitesOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdInvitesPost(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdInvitesPost(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdJobsFromMissionOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdJobsFromMissionOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdJobsFromMissionPost(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdJobsFromMissionPost(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdJobsGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdJobsGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} jobId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdJobsJobIdArchiveGet(id: string, jobId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdJobsJobIdArchiveGet(id, jobId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} jobId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdJobsJobIdArchiveOptions(id: string, jobId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdJobsJobIdArchiveOptions(id, jobId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} jobId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdJobsJobIdArchivePost(id: string, jobId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdJobsJobIdArchivePost(id, jobId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} jobId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdJobsJobIdDelete(id: string, jobId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdJobsJobIdDelete(id, jobId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} jobId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdJobsJobIdGet(id: string, jobId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdJobsJobIdGet(id, jobId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} jobId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdJobsJobIdOptions(id: string, jobId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdJobsJobIdOptions(id, jobId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} jobId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdJobsJobIdPatch(id: string, jobId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdJobsJobIdPatch(id, jobId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} jobId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdJobsJobIdPut(id: string, jobId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdJobsJobIdPut(id, jobId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} jobId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdJobsJobIdReviewGet(id: string, jobId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdJobsJobIdReviewGet(id, jobId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} jobId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdJobsJobIdReviewOptions(id: string, jobId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdJobsJobIdReviewOptions(id, jobId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} jobId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdJobsJobIdReviewPost(id: string, jobId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdJobsJobIdReviewPost(id, jobId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdJobsOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdJobsOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdJobsPost(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdJobsPost(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMembersGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMembersGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMembersOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMembersOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMembersPost(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMembersPost(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionInterviewsGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionInterviewsGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionInterviewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionInterviewsMissionInterviewIdDelete(id: string, missionInterviewId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionInterviewsMissionInterviewIdDelete(id, missionInterviewId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionInterviewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionInterviewsMissionInterviewIdGet(id: string, missionInterviewId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionInterviewsMissionInterviewIdGet(id, missionInterviewId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionInterviewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionInterviewsMissionInterviewIdOptions(id: string, missionInterviewId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionInterviewsMissionInterviewIdOptions(id, missionInterviewId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionInterviewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionInterviewsMissionInterviewIdPatch(id: string, missionInterviewId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionInterviewsMissionInterviewIdPatch(id, missionInterviewId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionInterviewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionInterviewsMissionInterviewIdPut(id: string, missionInterviewId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionInterviewsMissionInterviewIdPut(id, missionInterviewId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionInterviewsOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionInterviewsOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionInterviewsPost(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionInterviewsPost(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionBuilderOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionBuilderOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionBuilderPost(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionBuilderPost(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdDelete(id: string, missionId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdDelete(id, missionId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdGet(id: string, missionId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdGet(id, missionId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityGet(id: string, missionId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityGet(id, missionId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {string} missionInterviewDefaultAvailabilityId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdGet(id: string, missionId: string, missionInterviewDefaultAvailabilityId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdGet(id, missionId, missionInterviewDefaultAvailabilityId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {string} missionInterviewDefaultAvailabilityId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdOptions(id: string, missionId: string, missionInterviewDefaultAvailabilityId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdOptions(id, missionId, missionInterviewDefaultAvailabilityId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {string} missionInterviewDefaultAvailabilityId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdPatch(id: string, missionId: string, missionInterviewDefaultAvailabilityId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdPatch(id, missionId, missionInterviewDefaultAvailabilityId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {string} missionInterviewDefaultAvailabilityId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdPut(id: string, missionId: string, missionInterviewDefaultAvailabilityId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityMissionInterviewDefaultAvailabilityIdPut(id, missionId, missionInterviewDefaultAvailabilityId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityOptions(id: string, missionId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityOptions(id, missionId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityPost(id: string, missionId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdMissionInterviewDefaultAvailabilityPost(id, missionId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdOptions(id: string, missionId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdOptions(id, missionId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdPatch(id: string, missionId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdPatch(id, missionId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdPut(id: string, missionId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdPut(id, missionId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {string} shortlistFreelancerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdDelete(id: string, missionId: string, shortlistFreelancerId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdDelete(id, missionId, shortlistFreelancerId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {string} shortlistFreelancerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdGet(id: string, missionId: string, shortlistFreelancerId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdGet(id, missionId, shortlistFreelancerId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {string} shortlistFreelancerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdOptions(id: string, missionId: string, shortlistFreelancerId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdOptions(id, missionId, shortlistFreelancerId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {string} shortlistFreelancerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdPatch(id: string, missionId: string, shortlistFreelancerId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdPatch(id, missionId, shortlistFreelancerId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {string} shortlistFreelancerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdPut(id: string, missionId: string, shortlistFreelancerId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdShortlistFreelancersShortlistFreelancerIdPut(id, missionId, shortlistFreelancerId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdShortlistsGet(id: string, missionId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdShortlistsGet(id, missionId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdShortlistsOptions(id: string, missionId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdShortlistsOptions(id, missionId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdShortlistsPost(id: string, missionId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdShortlistsPost(id, missionId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {string} shortlistId
   * @param {string} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdShortlistsShortlistIdActionDelete(id: string, missionId: string, shortlistId: string, action: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdShortlistsShortlistIdActionDelete(id, missionId, shortlistId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {string} shortlistId
   * @param {string} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdShortlistsShortlistIdActionGet(id: string, missionId: string, shortlistId: string, action: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdShortlistsShortlistIdActionGet(id, missionId, shortlistId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {string} shortlistId
   * @param {string} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdShortlistsShortlistIdActionOptions(id: string, missionId: string, shortlistId: string, action: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdShortlistsShortlistIdActionOptions(id, missionId, shortlistId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {string} shortlistId
   * @param {string} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdShortlistsShortlistIdActionPatch(id: string, missionId: string, shortlistId: string, action: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdShortlistsShortlistIdActionPatch(id, missionId, shortlistId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {string} shortlistId
   * @param {string} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdShortlistsShortlistIdActionPut(id: string, missionId: string, shortlistId: string, action: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdShortlistsShortlistIdActionPut(id, missionId, shortlistId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {string} shortlistId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdShortlistsShortlistIdDelete(id: string, missionId: string, shortlistId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdShortlistsShortlistIdDelete(id, missionId, shortlistId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {string} shortlistId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdShortlistsShortlistIdGet(id: string, missionId: string, shortlistId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdShortlistsShortlistIdGet(id, missionId, shortlistId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {string} shortlistId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdShortlistsShortlistIdOptions(id: string, missionId: string, shortlistId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdShortlistsShortlistIdOptions(id, missionId, shortlistId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {string} shortlistId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdShortlistsShortlistIdPatch(id: string, missionId: string, shortlistId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdShortlistsShortlistIdPatch(id, missionId, shortlistId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {string} shortlistId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdShortlistsShortlistIdPut(id: string, missionId: string, shortlistId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdShortlistsShortlistIdPut(id, missionId, shortlistId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdShowdownSlotsGet(id: string, missionId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdShowdownSlotsGet(id, missionId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdShowdownSlotsOptions(id: string, missionId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdShowdownSlotsOptions(id, missionId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdShowdownSlotsPost(id: string, missionId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdShowdownSlotsPost(id, missionId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {string} showdownSlotsId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdShowdownSlotsShowdownSlotsIdGet(id: string, missionId: string, showdownSlotsId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdShowdownSlotsShowdownSlotsIdGet(id, missionId, showdownSlotsId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {string} showdownSlotsId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsMissionIdShowdownSlotsShowdownSlotsIdOptions(id: string, missionId: string, showdownSlotsId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsMissionIdShowdownSlotsShowdownSlotsIdOptions(id, missionId, showdownSlotsId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdMissionsPost(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdMissionsPost(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdPatch(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdPatch(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdPublicGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdPublicGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdPublicOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdPublicOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdPut(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdPut(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdReviewsGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdReviewsGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdReviewsOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdReviewsOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdReviewsPost(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdReviewsPost(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} reviewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdReviewsReviewIdGet(id: string, reviewId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdReviewsReviewIdGet(id, reviewId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} reviewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdReviewsReviewIdOptions(id: string, reviewId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdReviewsReviewIdOptions(id, reviewId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} reviewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdReviewsReviewIdPatch(id: string, reviewId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdReviewsReviewIdPatch(id, reviewId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} reviewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyIdReviewsReviewIdPut(id: string, reviewId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyIdReviewsReviewIdPut(id, reviewId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} experienceLevel The experience level PK being considered
   * @param {CompanyMissionBudgetRecommendationsGetCompensationPeriodEnum} compensationPeriod The compensation period being considered
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyMissionBudgetRecommendationsGet(id: string, experienceLevel: number, compensationPeriod: CompanyMissionBudgetRecommendationsGetCompensationPeriodEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyMissionBudgetRecommendationsGet(id, experienceLevel, compensationPeriod, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyMissionBudgetRecommendationsOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyMissionBudgetRecommendationsOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {string} applicationId
   * @param {CompanyMissionJobApplicationActionOptionsActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyMissionJobApplicationActionOptions(id: string, missionId: string, applicationId: string, action: CompanyMissionJobApplicationActionOptionsActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyMissionJobApplicationActionOptions(id, missionId, applicationId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {string} applicationId
   * @param {CompanyMissionJobApplicationActionPutActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyMissionJobApplicationActionPut(id: string, missionId: string, applicationId: string, action: CompanyMissionJobApplicationActionPutActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyMissionJobApplicationActionPut(id, missionId, applicationId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {string} applicationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyMissionJobApplicationDetailGet(id: string, missionId: string, applicationId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyMissionJobApplicationDetailGet(id, missionId, applicationId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {string} applicationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyMissionJobApplicationDetailOptions(id: string, missionId: string, applicationId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyMissionJobApplicationDetailOptions(id, missionId, applicationId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyMissionJobApplicationListGet(id: string, missionId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyMissionJobApplicationListGet(id, missionId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyMissionJobApplicationListOptions(id: string, missionId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyMissionJobApplicationListOptions(id, missionId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyShortlistFreelancerMissionDisplayListGet(id: string, missionId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyShortlistFreelancerMissionDisplayListGet(id, missionId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyShortlistFreelancerMissionDisplayListOptions(id: string, missionId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyShortlistFreelancerMissionDisplayListOptions(id, missionId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public companyShortlistFreelancerMissionDisplayListPost(id: string, missionId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).companyShortlistFreelancerMissionDisplayListPost(id, missionId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public factoriesApplicationsOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).factoriesApplicationsOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public factoriesApplicationsPost(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).factoriesApplicationsPost(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public factoriesCompaniesOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).factoriesCompaniesOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public factoriesCompaniesPost(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).factoriesCompaniesPost(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public factoriesDealsOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).factoriesDealsOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public factoriesDealsPost(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).factoriesDealsPost(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public factoriesFreelancersOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).factoriesFreelancersOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public factoriesFreelancersPost(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).factoriesFreelancersPost(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public factoriesJobsOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).factoriesJobsOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public factoriesJobsPost(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).factoriesJobsPost(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public factoriesMembersOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).factoriesMembersOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public factoriesMembersPost(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).factoriesMembersPost(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public factoriesPresentationsOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).factoriesPresentationsOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public factoriesPresentationsPost(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).factoriesPresentationsPost(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public factoriesUsersOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).factoriesUsersOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public factoriesUsersPost(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).factoriesUsersPost(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerCompleteScreeningGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerCompleteScreeningGet(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerCompleteScreeningOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerCompleteScreeningOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerCompleteScreeningPost(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerCompleteScreeningPost(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerGet(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} applicationId
   * @param {FreelancerIdApplicationsApplicationIdActionGetActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdApplicationsApplicationIdActionGet(id: string, applicationId: string, action: FreelancerIdApplicationsApplicationIdActionGetActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdApplicationsApplicationIdActionGet(id, applicationId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} applicationId
   * @param {FreelancerIdApplicationsApplicationIdActionOptionsActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdApplicationsApplicationIdActionOptions(id: string, applicationId: string, action: FreelancerIdApplicationsApplicationIdActionOptionsActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdApplicationsApplicationIdActionOptions(id, applicationId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} applicationId
   * @param {FreelancerIdApplicationsApplicationIdActionPatchActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdApplicationsApplicationIdActionPatch(id: string, applicationId: string, action: FreelancerIdApplicationsApplicationIdActionPatchActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdApplicationsApplicationIdActionPatch(id, applicationId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} applicationId
   * @param {FreelancerIdApplicationsApplicationIdActionPutActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdApplicationsApplicationIdActionPut(id: string, applicationId: string, action: FreelancerIdApplicationsApplicationIdActionPutActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdApplicationsApplicationIdActionPut(id, applicationId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} applicationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdApplicationsApplicationIdGet(id: string, applicationId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdApplicationsApplicationIdGet(id, applicationId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} applicationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdApplicationsApplicationIdOptions(id: string, applicationId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdApplicationsApplicationIdOptions(id, applicationId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} applicationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdApplicationsApplicationIdPatch(id: string, applicationId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdApplicationsApplicationIdPatch(id, applicationId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} applicationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdApplicationsApplicationIdPut(id: string, applicationId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdApplicationsApplicationIdPut(id, applicationId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdApplicationsGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdApplicationsGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdApplicationsOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdApplicationsOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdApplicationsPost(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdApplicationsPost(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdDelete(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdDelete(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} experienceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdExperiencesExperienceIdDelete(id: string, experienceId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdExperiencesExperienceIdDelete(id, experienceId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} experienceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdExperiencesExperienceIdGet(id: string, experienceId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdExperiencesExperienceIdGet(id, experienceId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} experienceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdExperiencesExperienceIdOptions(id: string, experienceId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdExperiencesExperienceIdOptions(id, experienceId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} experienceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdExperiencesExperienceIdPatch(id: string, experienceId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdExperiencesExperienceIdPatch(id, experienceId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} experienceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdExperiencesExperienceIdPut(id: string, experienceId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdExperiencesExperienceIdPut(id, experienceId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdExperiencesGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdExperiencesGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdExperiencesOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdExperiencesOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdExperiencesPost(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdExperiencesPost(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdHiresGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdHiresGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} hireId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdHiresHireIdGet(id: string, hireId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdHiresHireIdGet(id, hireId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} hireId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdHiresHireIdOptions(id: string, hireId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdHiresHireIdOptions(id, hireId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdHiresOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdHiresOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdInterviewsGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdInterviewsGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} interviewId
   * @param {FreelancerIdInterviewsInterviewIdActionGetActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdInterviewsInterviewIdActionGet(id: string, interviewId: string, action: FreelancerIdInterviewsInterviewIdActionGetActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdInterviewsInterviewIdActionGet(id, interviewId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} interviewId
   * @param {FreelancerIdInterviewsInterviewIdActionOptionsActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdInterviewsInterviewIdActionOptions(id: string, interviewId: string, action: FreelancerIdInterviewsInterviewIdActionOptionsActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdInterviewsInterviewIdActionOptions(id, interviewId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} interviewId
   * @param {FreelancerIdInterviewsInterviewIdActionPutActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdInterviewsInterviewIdActionPut(id: string, interviewId: string, action: FreelancerIdInterviewsInterviewIdActionPutActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdInterviewsInterviewIdActionPut(id, interviewId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} interviewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdInterviewsInterviewIdGet(id: string, interviewId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdInterviewsInterviewIdGet(id, interviewId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} interviewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdInterviewsInterviewIdOptions(id: string, interviewId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdInterviewsInterviewIdOptions(id, interviewId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} interviewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdInterviewsInterviewIdPut(id: string, interviewId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdInterviewsInterviewIdPut(id, interviewId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdInterviewsOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdInterviewsOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdInterviewsPost(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdInterviewsPost(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdInvitesGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdInvitesGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} inviteId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdInvitesInviteIdDelete(id: string, inviteId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdInvitesInviteIdDelete(id, inviteId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} inviteId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdInvitesInviteIdGet(id: string, inviteId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdInvitesInviteIdGet(id, inviteId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} inviteId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdInvitesInviteIdOptions(id: string, inviteId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdInvitesInviteIdOptions(id, inviteId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdInvitesOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdInvitesOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdMissionInterviewsGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdMissionInterviewsGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionInterviewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdMissionInterviewsMissionInterviewIdDelete(id: string, missionInterviewId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdMissionInterviewsMissionInterviewIdDelete(id, missionInterviewId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionInterviewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdMissionInterviewsMissionInterviewIdGet(id: string, missionInterviewId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdMissionInterviewsMissionInterviewIdGet(id, missionInterviewId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionInterviewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdMissionInterviewsMissionInterviewIdOptions(id: string, missionInterviewId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdMissionInterviewsMissionInterviewIdOptions(id, missionInterviewId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionInterviewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdMissionInterviewsMissionInterviewIdPatch(id: string, missionInterviewId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdMissionInterviewsMissionInterviewIdPatch(id, missionInterviewId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} missionInterviewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdMissionInterviewsMissionInterviewIdPut(id: string, missionInterviewId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdMissionInterviewsMissionInterviewIdPut(id, missionInterviewId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdMissionInterviewsOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdMissionInterviewsOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdMissionInterviewsPost(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdMissionInterviewsPost(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdPatch(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdPatch(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdPresentationsGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdPresentationsGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdPresentationsOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdPresentationsOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} presentationId
   * @param {FreelancerIdPresentationsPresentationIdActionGetActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdPresentationsPresentationIdActionGet(id: string, presentationId: string, action: FreelancerIdPresentationsPresentationIdActionGetActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdPresentationsPresentationIdActionGet(id, presentationId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} presentationId
   * @param {FreelancerIdPresentationsPresentationIdActionOptionsActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdPresentationsPresentationIdActionOptions(id: string, presentationId: string, action: FreelancerIdPresentationsPresentationIdActionOptionsActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdPresentationsPresentationIdActionOptions(id, presentationId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} presentationId
   * @param {FreelancerIdPresentationsPresentationIdActionPutActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdPresentationsPresentationIdActionPut(id: string, presentationId: string, action: FreelancerIdPresentationsPresentationIdActionPutActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdPresentationsPresentationIdActionPut(id, presentationId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} presentationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdPresentationsPresentationIdGet(id: string, presentationId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdPresentationsPresentationIdGet(id, presentationId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} presentationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdPresentationsPresentationIdOptions(id: string, presentationId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdPresentationsPresentationIdOptions(id, presentationId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} presentationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdPresentationsPresentationIdPut(id: string, presentationId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdPresentationsPresentationIdPut(id, presentationId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdProjectsGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdProjectsGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdProjectsOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdProjectsOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdProjectsPost(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdProjectsPost(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} projectId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdProjectsProjectIdDelete(id: string, projectId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdProjectsProjectIdDelete(id, projectId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} projectId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdProjectsProjectIdGet(id: string, projectId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdProjectsProjectIdGet(id, projectId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} projectId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdProjectsProjectIdOptions(id: string, projectId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdProjectsProjectIdOptions(id, projectId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} projectId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdProjectsProjectIdPatch(id: string, projectId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdProjectsProjectIdPatch(id, projectId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} projectId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdProjectsProjectIdPut(id: string, projectId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdProjectsProjectIdPut(id, projectId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdPut(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdPut(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdReferencesGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdReferencesGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdReferencesOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdReferencesOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdReferencesPost(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdReferencesPost(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} referenceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdReferencesReferenceIdDelete(id: string, referenceId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdReferencesReferenceIdDelete(id, referenceId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} referenceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdReferencesReferenceIdGet(id: string, referenceId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdReferencesReferenceIdGet(id, referenceId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} referenceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdReferencesReferenceIdOptions(id: string, referenceId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdReferencesReferenceIdOptions(id, referenceId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} referenceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdReferencesReferenceIdPatch(id: string, referenceId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdReferencesReferenceIdPatch(id, referenceId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} referenceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdReferencesReferenceIdPut(id: string, referenceId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdReferencesReferenceIdPut(id, referenceId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdReviewsGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdReviewsGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdReviewsOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdReviewsOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} reviewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdReviewsReviewIdGet(id: string, reviewId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdReviewsReviewIdGet(id, reviewId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} reviewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdReviewsReviewIdOptions(id: string, reviewId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdReviewsReviewIdOptions(id, reviewId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} reviewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdReviewsReviewIdPatch(id: string, reviewId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdReviewsReviewIdPatch(id, reviewId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} reviewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdReviewsReviewIdPut(id: string, reviewId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdReviewsReviewIdPut(id, reviewId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} shortlistFreelancerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdShortlistFreelancersShortlistFreelancerIdGet(id: string, shortlistFreelancerId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdShortlistFreelancersShortlistFreelancerIdGet(id, shortlistFreelancerId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} shortlistFreelancerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdShortlistFreelancersShortlistFreelancerIdOptions(id: string, shortlistFreelancerId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdShortlistFreelancersShortlistFreelancerIdOptions(id, shortlistFreelancerId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} shortlistFreelancerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdShortlistFreelancersShortlistFreelancerIdPatch(id: string, shortlistFreelancerId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdShortlistFreelancersShortlistFreelancerIdPatch(id, shortlistFreelancerId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} shortlistFreelancerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerIdShortlistFreelancersShortlistFreelancerIdPut(id: string, shortlistFreelancerId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerIdShortlistFreelancersShortlistFreelancerIdPut(id, shortlistFreelancerId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerPatch(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerPatch(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerPut(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerPut(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerRateRecommendationsGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerRateRecommendationsGet(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerRateRecommendationsOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerRateRecommendationsOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerShareIdGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerShareIdGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerShareIdOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerShareIdOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerShareOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerShareOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public freelancerSharePost(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).freelancerSharePost(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} tier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public hourlyRateTierGet(tier: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).hourlyRateTierGet(tier, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} tier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public hourlyRateTierOptions(tier: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).hourlyRateTierOptions(tier, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public jobsGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).jobsGet(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public jobsIdGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).jobsIdGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public jobsIdOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).jobsIdOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public jobsOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).jobsOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffApplicationsGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffApplicationsGet(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffApplicationsIdGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffApplicationsIdGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffApplicationsIdOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffApplicationsIdOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffApplicationsIdPatch(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffApplicationsIdPatch(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffApplicationsIdPut(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffApplicationsIdPut(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffApplicationsOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffApplicationsOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffAppointmentsGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffAppointmentsGet(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffAppointmentsIdGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffAppointmentsIdGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffAppointmentsIdOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffAppointmentsIdOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffAppointmentsIdPatch(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffAppointmentsIdPatch(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffAppointmentsIdPut(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffAppointmentsIdPut(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffAppointmentsIdRecordingsGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffAppointmentsIdRecordingsGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffAppointmentsIdRecordingsOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffAppointmentsIdRecordingsOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffAppointmentsIdRecordingsPost(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffAppointmentsIdRecordingsPost(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} recordingId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffAppointmentsIdRecordingsRecordingIdDelete(id: string, recordingId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffAppointmentsIdRecordingsRecordingIdDelete(id, recordingId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} recordingId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffAppointmentsIdRecordingsRecordingIdGet(id: string, recordingId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffAppointmentsIdRecordingsRecordingIdGet(id, recordingId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} recordingId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffAppointmentsIdRecordingsRecordingIdOptions(id: string, recordingId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffAppointmentsIdRecordingsRecordingIdOptions(id, recordingId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} recordingId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffAppointmentsIdRecordingsRecordingIdPatch(id: string, recordingId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffAppointmentsIdRecordingsRecordingIdPatch(id, recordingId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} recordingId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffAppointmentsIdRecordingsRecordingIdPut(id: string, recordingId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffAppointmentsIdRecordingsRecordingIdPut(id, recordingId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffAppointmentsOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffAppointmentsOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffChoresGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffChoresGet(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffChoresIdGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffChoresIdGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffChoresIdOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffChoresIdOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffChoresIdPatch(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffChoresIdPatch(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffChoresIdPut(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffChoresIdPut(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffChoresOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffChoresOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffChoresPost(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffChoresPost(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffCompaniesGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffCompaniesGet(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {StaffCompaniesIdActionGetActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffCompaniesIdActionGet(id: string, action: StaffCompaniesIdActionGetActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffCompaniesIdActionGet(id, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {StaffCompaniesIdActionOptionsActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffCompaniesIdActionOptions(id: string, action: StaffCompaniesIdActionOptionsActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffCompaniesIdActionOptions(id, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {StaffCompaniesIdActionPutActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffCompaniesIdActionPut(id: string, action: StaffCompaniesIdActionPutActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffCompaniesIdActionPut(id, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffCompaniesIdGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffCompaniesIdGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffCompaniesIdOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffCompaniesIdOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffCompaniesIdPut(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffCompaniesIdPut(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffCompaniesOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffCompaniesOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffCompaniesPost(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffCompaniesPost(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsGet(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdActionGet(id: string, action: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdActionGet(id, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdActionOptions(id: string, action: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdActionOptions(id, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdActionPatch(id: string, action: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdActionPatch(id, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdActionPut(id: string, action: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdActionPut(id, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdInterviewsGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdInterviewsGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} interviewId
   * @param {StaffDealsIdInterviewsInterviewIdActionDeleteActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdInterviewsInterviewIdActionDelete(id: string, interviewId: string, action: StaffDealsIdInterviewsInterviewIdActionDeleteActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdInterviewsInterviewIdActionDelete(id, interviewId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} interviewId
   * @param {StaffDealsIdInterviewsInterviewIdActionGetActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdInterviewsInterviewIdActionGet(id: string, interviewId: string, action: StaffDealsIdInterviewsInterviewIdActionGetActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdInterviewsInterviewIdActionGet(id, interviewId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} interviewId
   * @param {StaffDealsIdInterviewsInterviewIdActionOptionsActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdInterviewsInterviewIdActionOptions(id: string, interviewId: string, action: StaffDealsIdInterviewsInterviewIdActionOptionsActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdInterviewsInterviewIdActionOptions(id, interviewId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} interviewId
   * @param {StaffDealsIdInterviewsInterviewIdActionPatchActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdInterviewsInterviewIdActionPatch(id: string, interviewId: string, action: StaffDealsIdInterviewsInterviewIdActionPatchActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdInterviewsInterviewIdActionPatch(id, interviewId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} interviewId
   * @param {StaffDealsIdInterviewsInterviewIdActionPutActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdInterviewsInterviewIdActionPut(id: string, interviewId: string, action: StaffDealsIdInterviewsInterviewIdActionPutActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdInterviewsInterviewIdActionPut(id, interviewId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} interviewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdInterviewsInterviewIdDelete(id: string, interviewId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdInterviewsInterviewIdDelete(id, interviewId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} interviewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdInterviewsInterviewIdGet(id: string, interviewId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdInterviewsInterviewIdGet(id, interviewId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} interviewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdInterviewsInterviewIdOptions(id: string, interviewId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdInterviewsInterviewIdOptions(id, interviewId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} interviewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdInterviewsInterviewIdPatch(id: string, interviewId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdInterviewsInterviewIdPatch(id, interviewId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} interviewId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdInterviewsInterviewIdPut(id: string, interviewId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdInterviewsInterviewIdPut(id, interviewId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdInterviewsOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdInterviewsOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdInterviewsPost(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdInterviewsPost(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdPatch(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdPatch(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdPresentationsDeclineOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdPresentationsDeclineOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdPresentationsDeclinePatch(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdPresentationsDeclinePatch(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdPresentationsDeclinePut(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdPresentationsDeclinePut(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdPresentationsGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdPresentationsGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdPresentationsOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdPresentationsOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdPresentationsPost(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdPresentationsPost(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} presentationId
   * @param {StaffDealsIdPresentationsPresentationIdActionDeleteActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdPresentationsPresentationIdActionDelete(id: string, presentationId: string, action: StaffDealsIdPresentationsPresentationIdActionDeleteActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdPresentationsPresentationIdActionDelete(id, presentationId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} presentationId
   * @param {StaffDealsIdPresentationsPresentationIdActionGetActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdPresentationsPresentationIdActionGet(id: string, presentationId: string, action: StaffDealsIdPresentationsPresentationIdActionGetActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdPresentationsPresentationIdActionGet(id, presentationId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} presentationId
   * @param {StaffDealsIdPresentationsPresentationIdActionOptionsActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdPresentationsPresentationIdActionOptions(id: string, presentationId: string, action: StaffDealsIdPresentationsPresentationIdActionOptionsActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdPresentationsPresentationIdActionOptions(id, presentationId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} presentationId
   * @param {StaffDealsIdPresentationsPresentationIdActionPatchActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdPresentationsPresentationIdActionPatch(id: string, presentationId: string, action: StaffDealsIdPresentationsPresentationIdActionPatchActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdPresentationsPresentationIdActionPatch(id, presentationId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} presentationId
   * @param {StaffDealsIdPresentationsPresentationIdActionPutActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdPresentationsPresentationIdActionPut(id: string, presentationId: string, action: StaffDealsIdPresentationsPresentationIdActionPutActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdPresentationsPresentationIdActionPut(id, presentationId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} presentationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdPresentationsPresentationIdDelete(id: string, presentationId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdPresentationsPresentationIdDelete(id, presentationId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} presentationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdPresentationsPresentationIdGet(id: string, presentationId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdPresentationsPresentationIdGet(id, presentationId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} presentationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdPresentationsPresentationIdOptions(id: string, presentationId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdPresentationsPresentationIdOptions(id, presentationId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} presentationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdPresentationsPresentationIdPatch(id: string, presentationId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdPresentationsPresentationIdPatch(id, presentationId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} presentationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdPresentationsPresentationIdPut(id: string, presentationId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdPresentationsPresentationIdPut(id, presentationId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsIdPut(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsIdPut(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffDealsPost(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffDealsPost(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffFreelancersGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffFreelancersGet(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {StaffFreelancersIdActionGetActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffFreelancersIdActionGet(id: string, action: StaffFreelancersIdActionGetActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffFreelancersIdActionGet(id, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {StaffFreelancersIdActionOptionsActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffFreelancersIdActionOptions(id: string, action: StaffFreelancersIdActionOptionsActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffFreelancersIdActionOptions(id, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {StaffFreelancersIdActionPatchActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffFreelancersIdActionPatch(id: string, action: StaffFreelancersIdActionPatchActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffFreelancersIdActionPatch(id, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {StaffFreelancersIdActionPutActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffFreelancersIdActionPut(id: string, action: StaffFreelancersIdActionPutActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffFreelancersIdActionPut(id, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffFreelancersIdGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffFreelancersIdGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffFreelancersIdHiresGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffFreelancersIdHiresGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffFreelancersIdHiresOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffFreelancersIdHiresOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffFreelancersIdHiresPost(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffFreelancersIdHiresPost(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffFreelancersIdMatchesGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffFreelancersIdMatchesGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffFreelancersIdMatchesOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffFreelancersIdMatchesOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffFreelancersIdMessagesGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffFreelancersIdMessagesGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffFreelancersIdMessagesOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffFreelancersIdMessagesOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffFreelancersIdNotesGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffFreelancersIdNotesGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffFreelancersIdNotesOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffFreelancersIdNotesOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffFreelancersIdNotesPost(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffFreelancersIdNotesPost(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffFreelancersIdOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffFreelancersIdOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffFreelancersIdPatch(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffFreelancersIdPatch(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffFreelancersIdPut(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffFreelancersIdPut(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffFreelancersIdRecordingsGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffFreelancersIdRecordingsGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffFreelancersIdRecordingsOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffFreelancersIdRecordingsOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffFreelancersIdRecordingsPost(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffFreelancersIdRecordingsPost(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} recordingId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffFreelancersIdRecordingsRecordingIdDelete(id: string, recordingId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffFreelancersIdRecordingsRecordingIdDelete(id, recordingId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} recordingId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffFreelancersIdRecordingsRecordingIdGet(id: string, recordingId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffFreelancersIdRecordingsRecordingIdGet(id, recordingId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} recordingId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffFreelancersIdRecordingsRecordingIdOptions(id: string, recordingId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffFreelancersIdRecordingsRecordingIdOptions(id, recordingId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} recordingId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffFreelancersIdRecordingsRecordingIdPatch(id: string, recordingId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffFreelancersIdRecordingsRecordingIdPatch(id, recordingId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} recordingId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffFreelancersIdRecordingsRecordingIdPut(id: string, recordingId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffFreelancersIdRecordingsRecordingIdPut(id, recordingId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffFreelancersIdScreeningGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffFreelancersIdScreeningGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffFreelancersIdScreeningOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffFreelancersIdScreeningOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffFreelancersOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffFreelancersOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffHiresGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffHiresGet(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {StaffHiresIdActionGetActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffHiresIdActionGet(id: number, action: StaffHiresIdActionGetActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffHiresIdActionGet(id, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {StaffHiresIdActionOptionsActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffHiresIdActionOptions(id: number, action: StaffHiresIdActionOptionsActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffHiresIdActionOptions(id, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {StaffHiresIdActionPatchActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffHiresIdActionPatch(id: number, action: StaffHiresIdActionPatchActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffHiresIdActionPatch(id, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {StaffHiresIdActionPutActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffHiresIdActionPut(id: number, action: StaffHiresIdActionPutActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffHiresIdActionPut(id, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffHiresIdGet(id: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffHiresIdGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffHiresIdOptions(id: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffHiresIdOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffHiresIdPatch(id: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffHiresIdPatch(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffHiresIdPut(id: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffHiresIdPut(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffHiresIdScopeOfWorkGet(id: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffHiresIdScopeOfWorkGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffHiresIdScopeOfWorkOptions(id: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffHiresIdScopeOfWorkOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffHiresIdScopeOfWorkPost(id: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffHiresIdScopeOfWorkPost(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {string} scopeOfWorkId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffHiresIdScopeOfWorkScopeOfWorkIdGet(id: number, scopeOfWorkId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffHiresIdScopeOfWorkScopeOfWorkIdGet(id, scopeOfWorkId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {string} scopeOfWorkId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffHiresIdScopeOfWorkScopeOfWorkIdOptions(id: number, scopeOfWorkId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffHiresIdScopeOfWorkScopeOfWorkIdOptions(id, scopeOfWorkId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffHiresOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffHiresOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffHiresPost(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffHiresPost(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffInterviewsGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffInterviewsGet(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {StaffInterviewsIdActionDeleteActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffInterviewsIdActionDelete(id: string, action: StaffInterviewsIdActionDeleteActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffInterviewsIdActionDelete(id, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {StaffInterviewsIdActionGetActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffInterviewsIdActionGet(id: string, action: StaffInterviewsIdActionGetActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffInterviewsIdActionGet(id, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {StaffInterviewsIdActionOptionsActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffInterviewsIdActionOptions(id: string, action: StaffInterviewsIdActionOptionsActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffInterviewsIdActionOptions(id, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {StaffInterviewsIdActionPatchActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffInterviewsIdActionPatch(id: string, action: StaffInterviewsIdActionPatchActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffInterviewsIdActionPatch(id, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {StaffInterviewsIdActionPutActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffInterviewsIdActionPut(id: string, action: StaffInterviewsIdActionPutActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffInterviewsIdActionPut(id, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffInterviewsIdDelete(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffInterviewsIdDelete(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffInterviewsIdGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffInterviewsIdGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffInterviewsIdOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffInterviewsIdOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffInterviewsIdPatch(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffInterviewsIdPatch(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffInterviewsIdPut(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffInterviewsIdPut(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffInterviewsOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffInterviewsOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffInterviewsPost(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffInterviewsPost(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsGet(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {StaffJobsIdActionGetActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdActionGet(id: string, action: StaffJobsIdActionGetActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdActionGet(id, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {StaffJobsIdActionOptionsActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdActionOptions(id: string, action: StaffJobsIdActionOptionsActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdActionOptions(id, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {StaffJobsIdActionPatchActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdActionPatch(id: string, action: StaffJobsIdActionPatchActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdActionPatch(id, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {StaffJobsIdActionPutActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdActionPut(id: string, action: StaffJobsIdActionPutActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdActionPut(id, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} applicationId
   * @param {StaffJobsIdApplicationsApplicationIdActionGetActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdApplicationsApplicationIdActionGet(id: string, applicationId: string, action: StaffJobsIdApplicationsApplicationIdActionGetActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdApplicationsApplicationIdActionGet(id, applicationId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} applicationId
   * @param {StaffJobsIdApplicationsApplicationIdActionOptionsActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdApplicationsApplicationIdActionOptions(id: string, applicationId: string, action: StaffJobsIdApplicationsApplicationIdActionOptionsActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdApplicationsApplicationIdActionOptions(id, applicationId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} applicationId
   * @param {StaffJobsIdApplicationsApplicationIdActionPatchActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdApplicationsApplicationIdActionPatch(id: string, applicationId: string, action: StaffJobsIdApplicationsApplicationIdActionPatchActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdApplicationsApplicationIdActionPatch(id, applicationId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} applicationId
   * @param {StaffJobsIdApplicationsApplicationIdActionPutActionEnum} action
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdApplicationsApplicationIdActionPut(id: string, applicationId: string, action: StaffJobsIdApplicationsApplicationIdActionPutActionEnum, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdApplicationsApplicationIdActionPut(id, applicationId, action, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} applicationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdApplicationsApplicationIdGet(id: string, applicationId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdApplicationsApplicationIdGet(id, applicationId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} applicationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdApplicationsApplicationIdOptions(id: string, applicationId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdApplicationsApplicationIdOptions(id, applicationId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} applicationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdApplicationsApplicationIdPatch(id: string, applicationId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdApplicationsApplicationIdPatch(id, applicationId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} applicationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdApplicationsApplicationIdPut(id: string, applicationId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdApplicationsApplicationIdPut(id, applicationId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdApplicationsDeclineOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdApplicationsDeclineOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdApplicationsDeclinePatch(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdApplicationsDeclinePatch(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdApplicationsDeclinePut(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdApplicationsDeclinePut(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdApplicationsGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdApplicationsGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdApplicationsOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdApplicationsOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} freelancerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdFreelancersFreelancerIdGet(id: string, freelancerId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdFreelancersFreelancerIdGet(id, freelancerId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {string} freelancerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdFreelancersFreelancerIdOptions(id: string, freelancerId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdFreelancersFreelancerIdOptions(id, freelancerId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdFreelancersGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdFreelancersGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdFreelancersOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdFreelancersOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdPatch(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdPatch(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdPut(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdPut(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdRecordingsGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdRecordingsGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdRecordingsOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdRecordingsOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdRecordingsPost(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdRecordingsPost(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} recordingId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdRecordingsRecordingIdDelete(id: string, recordingId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdRecordingsRecordingIdDelete(id, recordingId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} recordingId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdRecordingsRecordingIdGet(id: string, recordingId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdRecordingsRecordingIdGet(id, recordingId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} recordingId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdRecordingsRecordingIdOptions(id: string, recordingId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdRecordingsRecordingIdOptions(id, recordingId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} recordingId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdRecordingsRecordingIdPatch(id: string, recordingId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdRecordingsRecordingIdPatch(id, recordingId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} recordingId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdRecordingsRecordingIdPut(id: string, recordingId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdRecordingsRecordingIdPut(id, recordingId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdSearchesGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdSearchesGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdSearchesOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdSearchesOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdSearchesPost(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdSearchesPost(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} searchId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdSearchesSearchIdDelete(id: string, searchId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdSearchesSearchIdDelete(id, searchId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} searchId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdSearchesSearchIdGet(id: string, searchId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdSearchesSearchIdGet(id, searchId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} searchId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdSearchesSearchIdOptions(id: string, searchId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdSearchesSearchIdOptions(id, searchId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} searchId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdSearchesSearchIdPatch(id: string, searchId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdSearchesSearchIdPatch(id, searchId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {number} searchId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsIdSearchesSearchIdPut(id: string, searchId: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsIdSearchesSearchIdPut(id, searchId, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffJobsOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffJobsOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffMessagesGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffMessagesGet(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffMessagesOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffMessagesOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffMessagesPost(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffMessagesPost(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffReportsMetricsGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffReportsMetricsGet(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffReportsMetricsOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffReportsMetricsOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffReviewsGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffReviewsGet(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffReviewsIdGet(id: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffReviewsIdGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffReviewsIdOptions(id: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffReviewsIdOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffReviewsIdPatch(id: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffReviewsIdPatch(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffReviewsIdPut(id: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffReviewsIdPut(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public staffReviewsOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).staffReviewsOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} hash
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public subscriptionsHashGet(hash: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).subscriptionsHashGet(hash, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} hash
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public subscriptionsHashOptions(hash: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).subscriptionsHashOptions(hash, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} hash
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public subscriptionsHashPatch(hash: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).subscriptionsHashPatch(hash, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} hash
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public subscriptionsHashPut(hash: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).subscriptionsHashPut(hash, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userAppointmentsGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).userAppointmentsGet(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userAppointmentsIdGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).userAppointmentsIdGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userAppointmentsIdOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).userAppointmentsIdOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userAppointmentsOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).userAppointmentsOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userCreateCompanyOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).userCreateCompanyOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userCreateCompanyPost(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).userCreateCompanyPost(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userCreateFreelancerOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).userCreateFreelancerOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userCreateFreelancerPost(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).userCreateFreelancerPost(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).userGet(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userGrantNylasDelete(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).userGrantNylasDelete(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userGrantNylasGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).userGrantNylasGet(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userGrantNylasOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).userGrantNylasOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userGrantNylasPost(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).userGrantNylasPost(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userInterviewsGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).userInterviewsGet(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userInterviewsIdGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).userInterviewsIdGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userInterviewsIdOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).userInterviewsIdOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userInterviewsOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).userInterviewsOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).userOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userPatch(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).userPatch(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userPut(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).userPut(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userSubscriptionsGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).userSubscriptionsGet(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userSubscriptionsOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).userSubscriptionsOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userSubscriptionsPatch(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).userSubscriptionsPatch(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userSubscriptionsPut(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).userSubscriptionsPut(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userSubscriptionsSlackOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).userSubscriptionsSlackOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userSubscriptionsSlackPatch(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).userSubscriptionsSlackPatch(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userSubscriptionsSlackPut(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).userSubscriptionsSlackPut(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userSubscriptionsTelegramOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).userSubscriptionsTelegramOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userSubscriptionsTelegramPatch(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).userSubscriptionsTelegramPatch(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userSubscriptionsTelegramPut(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).userSubscriptionsTelegramPut(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userWorkstyleGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).userWorkstyleGet(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userWorkstyleOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).userWorkstyleOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public usersGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).usersGet(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public usersOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).usersOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public workstylesAssessmentsGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).workstylesAssessmentsGet(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public workstylesAssessmentsIdGet(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).workstylesAssessmentsIdGet(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public workstylesAssessmentsIdOptions(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).workstylesAssessmentsIdOptions(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public workstylesAssessmentsIdPatch(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).workstylesAssessmentsIdPatch(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public workstylesAssessmentsIdPut(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).workstylesAssessmentsIdPut(id, options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public workstylesAssessmentsOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).workstylesAssessmentsOptions(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public workstylesAssessmentsPost(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).workstylesAssessmentsPost(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public workstylesGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).workstylesGet(options).then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public workstylesOptions(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration).workstylesOptions(options).then((request) => request(this.axios, this.basePath))
  }
}

/**
 * @export
 */
export const CompanyIdDealsDealIdPresentationsPresentationIdActionGetActionEnum = {
  Decline: "decline",
  Reset: "reset",
} as const
export type CompanyIdDealsDealIdPresentationsPresentationIdActionGetActionEnum = typeof CompanyIdDealsDealIdPresentationsPresentationIdActionGetActionEnum[keyof typeof CompanyIdDealsDealIdPresentationsPresentationIdActionGetActionEnum]
/**
 * @export
 */
export const CompanyIdDealsDealIdPresentationsPresentationIdActionOptionsActionEnum = {
  Decline: "decline",
  Reset: "reset",
} as const
export type CompanyIdDealsDealIdPresentationsPresentationIdActionOptionsActionEnum = typeof CompanyIdDealsDealIdPresentationsPresentationIdActionOptionsActionEnum[keyof typeof CompanyIdDealsDealIdPresentationsPresentationIdActionOptionsActionEnum]
/**
 * @export
 */
export const CompanyIdDealsDealIdPresentationsPresentationIdActionPutActionEnum = {
  Decline: "decline",
  Reset: "reset",
} as const
export type CompanyIdDealsDealIdPresentationsPresentationIdActionPutActionEnum = typeof CompanyIdDealsDealIdPresentationsPresentationIdActionPutActionEnum[keyof typeof CompanyIdDealsDealIdPresentationsPresentationIdActionPutActionEnum]
/**
 * @export
 */
export const CompanyIdInterviewsInterviewIdActionGetActionEnum = {
  Cancel: "cancel",
  Schedule: "schedule",
  Reschedule: "reschedule",
  Unschedule: "unschedule",
} as const
export type CompanyIdInterviewsInterviewIdActionGetActionEnum = typeof CompanyIdInterviewsInterviewIdActionGetActionEnum[keyof typeof CompanyIdInterviewsInterviewIdActionGetActionEnum]
/**
 * @export
 */
export const CompanyIdInterviewsInterviewIdActionOptionsActionEnum = {
  Cancel: "cancel",
  Schedule: "schedule",
  Reschedule: "reschedule",
  Unschedule: "unschedule",
} as const
export type CompanyIdInterviewsInterviewIdActionOptionsActionEnum = typeof CompanyIdInterviewsInterviewIdActionOptionsActionEnum[keyof typeof CompanyIdInterviewsInterviewIdActionOptionsActionEnum]
/**
 * @export
 */
export const CompanyIdInterviewsInterviewIdActionPutActionEnum = {
  Cancel: "cancel",
  Schedule: "schedule",
  Reschedule: "reschedule",
  Unschedule: "unschedule",
} as const
export type CompanyIdInterviewsInterviewIdActionPutActionEnum = typeof CompanyIdInterviewsInterviewIdActionPutActionEnum[keyof typeof CompanyIdInterviewsInterviewIdActionPutActionEnum]
/**
 * @export
 */
export const CompanyMissionBudgetRecommendationsGetCompensationPeriodEnum = {
  MonthlyContract: "MONTHLY_CONTRACT",
  HourlyContract: "HOURLY_CONTRACT",
  Salary: "SALARY",
} as const
export type CompanyMissionBudgetRecommendationsGetCompensationPeriodEnum = typeof CompanyMissionBudgetRecommendationsGetCompensationPeriodEnum[keyof typeof CompanyMissionBudgetRecommendationsGetCompensationPeriodEnum]
/**
 * @export
 */
export const CompanyMissionJobApplicationActionOptionsActionEnum = {
  Decline: "decline",
} as const
export type CompanyMissionJobApplicationActionOptionsActionEnum = typeof CompanyMissionJobApplicationActionOptionsActionEnum[keyof typeof CompanyMissionJobApplicationActionOptionsActionEnum]
/**
 * @export
 */
export const CompanyMissionJobApplicationActionPutActionEnum = {
  Decline: "decline",
} as const
export type CompanyMissionJobApplicationActionPutActionEnum = typeof CompanyMissionJobApplicationActionPutActionEnum[keyof typeof CompanyMissionJobApplicationActionPutActionEnum]
/**
 * @export
 */
export const FreelancerIdApplicationsApplicationIdActionGetActionEnum = {
  Withdraw: "withdraw",
} as const
export type FreelancerIdApplicationsApplicationIdActionGetActionEnum = typeof FreelancerIdApplicationsApplicationIdActionGetActionEnum[keyof typeof FreelancerIdApplicationsApplicationIdActionGetActionEnum]
/**
 * @export
 */
export const FreelancerIdApplicationsApplicationIdActionOptionsActionEnum = {
  Withdraw: "withdraw",
} as const
export type FreelancerIdApplicationsApplicationIdActionOptionsActionEnum = typeof FreelancerIdApplicationsApplicationIdActionOptionsActionEnum[keyof typeof FreelancerIdApplicationsApplicationIdActionOptionsActionEnum]
/**
 * @export
 */
export const FreelancerIdApplicationsApplicationIdActionPatchActionEnum = {
  Withdraw: "withdraw",
} as const
export type FreelancerIdApplicationsApplicationIdActionPatchActionEnum = typeof FreelancerIdApplicationsApplicationIdActionPatchActionEnum[keyof typeof FreelancerIdApplicationsApplicationIdActionPatchActionEnum]
/**
 * @export
 */
export const FreelancerIdApplicationsApplicationIdActionPutActionEnum = {
  Withdraw: "withdraw",
} as const
export type FreelancerIdApplicationsApplicationIdActionPutActionEnum = typeof FreelancerIdApplicationsApplicationIdActionPutActionEnum[keyof typeof FreelancerIdApplicationsApplicationIdActionPutActionEnum]
/**
 * @export
 */
export const FreelancerIdInterviewsInterviewIdActionGetActionEnum = {
  Cancel: "cancel",
  Schedule: "schedule",
  Reschedule: "reschedule",
  Unschedule: "unschedule",
} as const
export type FreelancerIdInterviewsInterviewIdActionGetActionEnum = typeof FreelancerIdInterviewsInterviewIdActionGetActionEnum[keyof typeof FreelancerIdInterviewsInterviewIdActionGetActionEnum]
/**
 * @export
 */
export const FreelancerIdInterviewsInterviewIdActionOptionsActionEnum = {
  Cancel: "cancel",
  Schedule: "schedule",
  Reschedule: "reschedule",
  Unschedule: "unschedule",
} as const
export type FreelancerIdInterviewsInterviewIdActionOptionsActionEnum = typeof FreelancerIdInterviewsInterviewIdActionOptionsActionEnum[keyof typeof FreelancerIdInterviewsInterviewIdActionOptionsActionEnum]
/**
 * @export
 */
export const FreelancerIdInterviewsInterviewIdActionPutActionEnum = {
  Cancel: "cancel",
  Schedule: "schedule",
  Reschedule: "reschedule",
  Unschedule: "unschedule",
} as const
export type FreelancerIdInterviewsInterviewIdActionPutActionEnum = typeof FreelancerIdInterviewsInterviewIdActionPutActionEnum[keyof typeof FreelancerIdInterviewsInterviewIdActionPutActionEnum]
/**
 * @export
 */
export const FreelancerIdPresentationsPresentationIdActionGetActionEnum = {
  Withdraw: "withdraw",
} as const
export type FreelancerIdPresentationsPresentationIdActionGetActionEnum = typeof FreelancerIdPresentationsPresentationIdActionGetActionEnum[keyof typeof FreelancerIdPresentationsPresentationIdActionGetActionEnum]
/**
 * @export
 */
export const FreelancerIdPresentationsPresentationIdActionOptionsActionEnum = {
  Withdraw: "withdraw",
} as const
export type FreelancerIdPresentationsPresentationIdActionOptionsActionEnum = typeof FreelancerIdPresentationsPresentationIdActionOptionsActionEnum[keyof typeof FreelancerIdPresentationsPresentationIdActionOptionsActionEnum]
/**
 * @export
 */
export const FreelancerIdPresentationsPresentationIdActionPutActionEnum = {
  Withdraw: "withdraw",
} as const
export type FreelancerIdPresentationsPresentationIdActionPutActionEnum = typeof FreelancerIdPresentationsPresentationIdActionPutActionEnum[keyof typeof FreelancerIdPresentationsPresentationIdActionPutActionEnum]
/**
 * @export
 */
export const StaffCompaniesIdActionGetActionEnum = {
  Merge: "merge",
} as const
export type StaffCompaniesIdActionGetActionEnum = typeof StaffCompaniesIdActionGetActionEnum[keyof typeof StaffCompaniesIdActionGetActionEnum]
/**
 * @export
 */
export const StaffCompaniesIdActionOptionsActionEnum = {
  Merge: "merge",
} as const
export type StaffCompaniesIdActionOptionsActionEnum = typeof StaffCompaniesIdActionOptionsActionEnum[keyof typeof StaffCompaniesIdActionOptionsActionEnum]
/**
 * @export
 */
export const StaffCompaniesIdActionPutActionEnum = {
  Merge: "merge",
} as const
export type StaffCompaniesIdActionPutActionEnum = typeof StaffCompaniesIdActionPutActionEnum[keyof typeof StaffCompaniesIdActionPutActionEnum]
/**
 * @export
 */
export const StaffDealsIdInterviewsInterviewIdActionDeleteActionEnum = {
  Cancel: "cancel",
  Reschedule: "reschedule",
  Review: "review",
  Schedule: "schedule",
  Unschedule: "unschedule",
} as const
export type StaffDealsIdInterviewsInterviewIdActionDeleteActionEnum = typeof StaffDealsIdInterviewsInterviewIdActionDeleteActionEnum[keyof typeof StaffDealsIdInterviewsInterviewIdActionDeleteActionEnum]
/**
 * @export
 */
export const StaffDealsIdInterviewsInterviewIdActionGetActionEnum = {
  Cancel: "cancel",
  Reschedule: "reschedule",
  Review: "review",
  Schedule: "schedule",
  Unschedule: "unschedule",
} as const
export type StaffDealsIdInterviewsInterviewIdActionGetActionEnum = typeof StaffDealsIdInterviewsInterviewIdActionGetActionEnum[keyof typeof StaffDealsIdInterviewsInterviewIdActionGetActionEnum]
/**
 * @export
 */
export const StaffDealsIdInterviewsInterviewIdActionOptionsActionEnum = {
  Cancel: "cancel",
  Reschedule: "reschedule",
  Review: "review",
  Schedule: "schedule",
  Unschedule: "unschedule",
} as const
export type StaffDealsIdInterviewsInterviewIdActionOptionsActionEnum = typeof StaffDealsIdInterviewsInterviewIdActionOptionsActionEnum[keyof typeof StaffDealsIdInterviewsInterviewIdActionOptionsActionEnum]
/**
 * @export
 */
export const StaffDealsIdInterviewsInterviewIdActionPatchActionEnum = {
  Cancel: "cancel",
  Reschedule: "reschedule",
  Review: "review",
  Schedule: "schedule",
  Unschedule: "unschedule",
} as const
export type StaffDealsIdInterviewsInterviewIdActionPatchActionEnum = typeof StaffDealsIdInterviewsInterviewIdActionPatchActionEnum[keyof typeof StaffDealsIdInterviewsInterviewIdActionPatchActionEnum]
/**
 * @export
 */
export const StaffDealsIdInterviewsInterviewIdActionPutActionEnum = {
  Cancel: "cancel",
  Reschedule: "reschedule",
  Review: "review",
  Schedule: "schedule",
  Unschedule: "unschedule",
} as const
export type StaffDealsIdInterviewsInterviewIdActionPutActionEnum = typeof StaffDealsIdInterviewsInterviewIdActionPutActionEnum[keyof typeof StaffDealsIdInterviewsInterviewIdActionPutActionEnum]
/**
 * @export
 */
export const StaffDealsIdPresentationsPresentationIdActionDeleteActionEnum = {
  Decline: "decline",
  Reset: "reset",
  SendCancel: "send_cancel",
  SendLater: "send_later",
  SendNow: "send_now",
} as const
export type StaffDealsIdPresentationsPresentationIdActionDeleteActionEnum = typeof StaffDealsIdPresentationsPresentationIdActionDeleteActionEnum[keyof typeof StaffDealsIdPresentationsPresentationIdActionDeleteActionEnum]
/**
 * @export
 */
export const StaffDealsIdPresentationsPresentationIdActionGetActionEnum = {
  Decline: "decline",
  Reset: "reset",
  SendCancel: "send_cancel",
  SendLater: "send_later",
  SendNow: "send_now",
} as const
export type StaffDealsIdPresentationsPresentationIdActionGetActionEnum = typeof StaffDealsIdPresentationsPresentationIdActionGetActionEnum[keyof typeof StaffDealsIdPresentationsPresentationIdActionGetActionEnum]
/**
 * @export
 */
export const StaffDealsIdPresentationsPresentationIdActionOptionsActionEnum = {
  Decline: "decline",
  Reset: "reset",
  SendCancel: "send_cancel",
  SendLater: "send_later",
  SendNow: "send_now",
} as const
export type StaffDealsIdPresentationsPresentationIdActionOptionsActionEnum = typeof StaffDealsIdPresentationsPresentationIdActionOptionsActionEnum[keyof typeof StaffDealsIdPresentationsPresentationIdActionOptionsActionEnum]
/**
 * @export
 */
export const StaffDealsIdPresentationsPresentationIdActionPatchActionEnum = {
  Decline: "decline",
  Reset: "reset",
  SendCancel: "send_cancel",
  SendLater: "send_later",
  SendNow: "send_now",
} as const
export type StaffDealsIdPresentationsPresentationIdActionPatchActionEnum = typeof StaffDealsIdPresentationsPresentationIdActionPatchActionEnum[keyof typeof StaffDealsIdPresentationsPresentationIdActionPatchActionEnum]
/**
 * @export
 */
export const StaffDealsIdPresentationsPresentationIdActionPutActionEnum = {
  Decline: "decline",
  Reset: "reset",
  SendCancel: "send_cancel",
  SendLater: "send_later",
  SendNow: "send_now",
} as const
export type StaffDealsIdPresentationsPresentationIdActionPutActionEnum = typeof StaffDealsIdPresentationsPresentationIdActionPutActionEnum[keyof typeof StaffDealsIdPresentationsPresentationIdActionPutActionEnum]
/**
 * @export
 */
export const StaffFreelancersIdActionGetActionEnum = {
  Approval: "set_approval",
  Rating: "set_rating",
  Status: "set_status",
} as const
export type StaffFreelancersIdActionGetActionEnum = typeof StaffFreelancersIdActionGetActionEnum[keyof typeof StaffFreelancersIdActionGetActionEnum]
/**
 * @export
 */
export const StaffFreelancersIdActionOptionsActionEnum = {
  Approval: "set_approval",
  Rating: "set_rating",
  Status: "set_status",
} as const
export type StaffFreelancersIdActionOptionsActionEnum = typeof StaffFreelancersIdActionOptionsActionEnum[keyof typeof StaffFreelancersIdActionOptionsActionEnum]
/**
 * @export
 */
export const StaffFreelancersIdActionPatchActionEnum = {
  Approval: "set_approval",
  Rating: "set_rating",
  Status: "set_status",
} as const
export type StaffFreelancersIdActionPatchActionEnum = typeof StaffFreelancersIdActionPatchActionEnum[keyof typeof StaffFreelancersIdActionPatchActionEnum]
/**
 * @export
 */
export const StaffFreelancersIdActionPutActionEnum = {
  Approval: "set_approval",
  Rating: "set_rating",
  Status: "set_status",
} as const
export type StaffFreelancersIdActionPutActionEnum = typeof StaffFreelancersIdActionPutActionEnum[keyof typeof StaffFreelancersIdActionPutActionEnum]
/**
 * @export
 */
export const StaffHiresIdActionGetActionEnum = {
  Start: "start",
  Stop: "stop",
} as const
export type StaffHiresIdActionGetActionEnum = typeof StaffHiresIdActionGetActionEnum[keyof typeof StaffHiresIdActionGetActionEnum]
/**
 * @export
 */
export const StaffHiresIdActionOptionsActionEnum = {
  Start: "start",
  Stop: "stop",
} as const
export type StaffHiresIdActionOptionsActionEnum = typeof StaffHiresIdActionOptionsActionEnum[keyof typeof StaffHiresIdActionOptionsActionEnum]
/**
 * @export
 */
export const StaffHiresIdActionPatchActionEnum = {
  Start: "start",
  Stop: "stop",
} as const
export type StaffHiresIdActionPatchActionEnum = typeof StaffHiresIdActionPatchActionEnum[keyof typeof StaffHiresIdActionPatchActionEnum]
/**
 * @export
 */
export const StaffHiresIdActionPutActionEnum = {
  Start: "start",
  Stop: "stop",
} as const
export type StaffHiresIdActionPutActionEnum = typeof StaffHiresIdActionPutActionEnum[keyof typeof StaffHiresIdActionPutActionEnum]
/**
 * @export
 */
export const StaffInterviewsIdActionDeleteActionEnum = {
  Cancel: "cancel",
  Reschedule: "reschedule",
  Review: "review",
  Schedule: "schedule",
  Unschedule: "unschedule",
} as const
export type StaffInterviewsIdActionDeleteActionEnum = typeof StaffInterviewsIdActionDeleteActionEnum[keyof typeof StaffInterviewsIdActionDeleteActionEnum]
/**
 * @export
 */
export const StaffInterviewsIdActionGetActionEnum = {
  Cancel: "cancel",
  Reschedule: "reschedule",
  Review: "review",
  Schedule: "schedule",
  Unschedule: "unschedule",
} as const
export type StaffInterviewsIdActionGetActionEnum = typeof StaffInterviewsIdActionGetActionEnum[keyof typeof StaffInterviewsIdActionGetActionEnum]
/**
 * @export
 */
export const StaffInterviewsIdActionOptionsActionEnum = {
  Cancel: "cancel",
  Reschedule: "reschedule",
  Review: "review",
  Schedule: "schedule",
  Unschedule: "unschedule",
} as const
export type StaffInterviewsIdActionOptionsActionEnum = typeof StaffInterviewsIdActionOptionsActionEnum[keyof typeof StaffInterviewsIdActionOptionsActionEnum]
/**
 * @export
 */
export const StaffInterviewsIdActionPatchActionEnum = {
  Cancel: "cancel",
  Reschedule: "reschedule",
  Review: "review",
  Schedule: "schedule",
  Unschedule: "unschedule",
} as const
export type StaffInterviewsIdActionPatchActionEnum = typeof StaffInterviewsIdActionPatchActionEnum[keyof typeof StaffInterviewsIdActionPatchActionEnum]
/**
 * @export
 */
export const StaffInterviewsIdActionPutActionEnum = {
  Cancel: "cancel",
  Reschedule: "reschedule",
  Review: "review",
  Schedule: "schedule",
  Unschedule: "unschedule",
} as const
export type StaffInterviewsIdActionPutActionEnum = typeof StaffInterviewsIdActionPutActionEnum[keyof typeof StaffInterviewsIdActionPutActionEnum]
/**
 * @export
 */
export const StaffJobsIdActionGetActionEnum = {
  Close: "close",
  Decline: "decline",
  Extend: "extend",
  GenerateAutomatches: "generate_automatches",
  Publish: "publish",
  Reopen: "reopen",
  SendNotifications: "send_notifications",
  Unarchive: "unarchive",
  Unwithdraw: "unwithdraw",
  Withdraw: "withdraw",
  Viability: "viability",
  Archive: "archive",
} as const
export type StaffJobsIdActionGetActionEnum = typeof StaffJobsIdActionGetActionEnum[keyof typeof StaffJobsIdActionGetActionEnum]
/**
 * @export
 */
export const StaffJobsIdActionOptionsActionEnum = {
  Close: "close",
  Decline: "decline",
  Extend: "extend",
  GenerateAutomatches: "generate_automatches",
  Publish: "publish",
  Reopen: "reopen",
  SendNotifications: "send_notifications",
  Unarchive: "unarchive",
  Unwithdraw: "unwithdraw",
  Withdraw: "withdraw",
  Viability: "viability",
  Archive: "archive",
} as const
export type StaffJobsIdActionOptionsActionEnum = typeof StaffJobsIdActionOptionsActionEnum[keyof typeof StaffJobsIdActionOptionsActionEnum]
/**
 * @export
 */
export const StaffJobsIdActionPatchActionEnum = {
  Close: "close",
  Decline: "decline",
  Extend: "extend",
  GenerateAutomatches: "generate_automatches",
  Publish: "publish",
  Reopen: "reopen",
  SendNotifications: "send_notifications",
  Unarchive: "unarchive",
  Unwithdraw: "unwithdraw",
  Withdraw: "withdraw",
  Viability: "viability",
  Archive: "archive",
} as const
export type StaffJobsIdActionPatchActionEnum = typeof StaffJobsIdActionPatchActionEnum[keyof typeof StaffJobsIdActionPatchActionEnum]
/**
 * @export
 */
export const StaffJobsIdActionPutActionEnum = {
  Close: "close",
  Decline: "decline",
  Extend: "extend",
  GenerateAutomatches: "generate_automatches",
  Publish: "publish",
  Reopen: "reopen",
  SendNotifications: "send_notifications",
  Unarchive: "unarchive",
  Unwithdraw: "unwithdraw",
  Withdraw: "withdraw",
  Viability: "viability",
  Archive: "archive",
} as const
export type StaffJobsIdActionPutActionEnum = typeof StaffJobsIdActionPutActionEnum[keyof typeof StaffJobsIdActionPutActionEnum]
/**
 * @export
 */
export const StaffJobsIdApplicationsApplicationIdActionGetActionEnum = {
  Decline: "decline",
  Reset: "reset",
} as const
export type StaffJobsIdApplicationsApplicationIdActionGetActionEnum = typeof StaffJobsIdApplicationsApplicationIdActionGetActionEnum[keyof typeof StaffJobsIdApplicationsApplicationIdActionGetActionEnum]
/**
 * @export
 */
export const StaffJobsIdApplicationsApplicationIdActionOptionsActionEnum = {
  Decline: "decline",
  Reset: "reset",
} as const
export type StaffJobsIdApplicationsApplicationIdActionOptionsActionEnum = typeof StaffJobsIdApplicationsApplicationIdActionOptionsActionEnum[keyof typeof StaffJobsIdApplicationsApplicationIdActionOptionsActionEnum]
/**
 * @export
 */
export const StaffJobsIdApplicationsApplicationIdActionPatchActionEnum = {
  Decline: "decline",
  Reset: "reset",
} as const
export type StaffJobsIdApplicationsApplicationIdActionPatchActionEnum = typeof StaffJobsIdApplicationsApplicationIdActionPatchActionEnum[keyof typeof StaffJobsIdApplicationsApplicationIdActionPatchActionEnum]
/**
 * @export
 */
export const StaffJobsIdApplicationsApplicationIdActionPutActionEnum = {
  Decline: "decline",
  Reset: "reset",
} as const
export type StaffJobsIdApplicationsApplicationIdActionPutActionEnum = typeof StaffJobsIdApplicationsApplicationIdActionPutActionEnum[keyof typeof StaffJobsIdApplicationsApplicationIdActionPutActionEnum]
