<template>
  <router-link
    v-if="userStore.isAuthenticated"
    :to="{ name: 'redirect' }"
    class="flex w-32 h-8 mx-4 mt-4 z-50"
    title="Gun.io"
  >
    <img src="@/images/logo-text.svg" alt="Gun.io">
  </router-link>
  <a
    v-else
    href="/"
    class="flex w-32 h-8 mx-4 mt-4 z-50"
    title="Gun.io"
  >
    <img src="@/images/logo-text.svg" alt="Gun.io">
  </a>
</template>

<script setup lang="ts">
import { useUserStore } from "@/store/user"

const userStore = useUserStore()

</script>
